import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import { Text } from 'components/text';
import { ReactComponent as InDevelopmentIcon } from './in-development.svg';
import { services, ServiceType } from '../service-info-list/dict';
import styles from './service-info.module.css';
import { sendMetrik } from '_helpers/metrika';
import PromocodeForm from '../../../../components/promocode-form';

const cx = classNames.bind(styles);

type ServiceInfoProps = {
  type: ServiceType;
  children?: React.ReactNode;
  isContentVisible?: boolean;
  isInDevelopment?: boolean;
  isProAccount?: boolean;
};

export const ServiceInfo = ({
  type,
  children,
  isProAccount,
  isContentVisible = true,
  isInDevelopment,
}: ServiceInfoProps) => {
  const handleSendMetrik = useCallback(() => {
    sendMetrik('reachGoal', 'ChangeOnProButtonClick');
  }, []);

  return (
    <div
      className={cx('service-info', {
        'service-info_blue': services[type].theme === 'blue',
        'service-info_in-development': isInDevelopment,
      })}
    >
      <div className={cx('label')}>
        <Text size="l" semibold>
          {services[type].label}
        </Text>
      </div>
      <div className={cx('image-wrap')}>
        {services[type].image && (
          <img
            className={cx('image')}
            src={services[type].image}
            alt=""
          />
        )}
      </div>
      <div className={cx('title')}>
        <Text size="xl" semibold>
          {services[type].title}
        </Text>
      </div>
      {isContentVisible && (
        <div className={cx('text')}>
          <Text size="m">
            {services[type].text}
          </Text>
        </div>
      )}
      {children && (
        <div className={cx('content')}>
          {children}
        </div>
      )}
      {services[type].link && (
        <div className={cx('link-wrap')}>
            {services[type].link?.component === 'native' && (
              <a
                className={cx('link')}
                href={services[type].link?.to}
              >
                <Text size="s" semibold>
                  {services[type].link?.text}
                </Text>
              </a>
            )}
            {services[type].link?.component === 'route' && (
              <Link
                className={cx('link')}
                to={services[type].link?.to}
              >
                <Text size="s" semibold>
                  {services[type].link?.text}
                </Text>
              </Link>
            )}
            {services[type].link?.component === 'button' && (
              <div>
                <div>
                  <Link
                    className={cx('button')}
                    to={services[type].link?.to}
                    onClick={handleSendMetrik}
                  >
                    <Text size="s" semibold>
                      {!isProAccount ? services[type].link?.text : services[type].link?.secondaryText}
                    </Text>
                  </Link>
                </div>
              </div>
            )}
            {services[type].link?.component === 'button_external' && (
              <div>
                <div>
                  <a
                    className={cx('button')}
                    href={services[type].link?.to}
                    target="_blank"
                    rel="noreferrer"
                    onClick={handleSendMetrik}
                  >
                    <Text size="s" semibold>
                      {!isProAccount ? services[type].link?.text : services[type].link?.secondaryText}
                    </Text>
                  </a>
                </div>
              </div>
            )}
        </div>
      )}
      {type === 'account' && (<PromocodeForm />)}
      {isInDevelopment && (
        <div className={cx('in-development')}>
          <div className={cx('in-development-icon')}>
            <InDevelopmentIcon />
          </div>
          <Text>
            В разработке...
          </Text>
        </div>
      )}
    </div>
  );
};
