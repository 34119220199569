import React from 'react';

import cn from 'classnames';

import styles from './context-menu-button.module.css';

interface ContextMenuButtonProps {
  children: React.ReactNode;
  icon?: React.ReactNode;
  onClick?: () => void;
  active?: boolean;
  disabled?: boolean;
  underline?: boolean;
}

export const ContextMenuButton = ({
  children,
  icon,
  onClick,
  active,
  disabled,
  underline,
}: ContextMenuButtonProps) => (
  <button
    className={cn(styles['context-menu-button'], {
      [styles['context-menu-button-active']]: active,
      [styles['context-menu-button-underline']]: underline,
    })}
    disabled={disabled}
    onClick={onClick}
    type="button"
  >
    {icon && (
      <div className={styles['context-menu-icon']}>
        {icon}
      </div>
    )}

    <div className={styles['context-menu-text']}>
      {children}
    </div>
  </button>
);

export default ContextMenuButton;
