import React from 'react';

import { ReactComponent as CloseIcon } from 'assets/svg/Close.svg';

import styles from './Tag.module.css';

interface DefaultProps {
  id: any;
  text: string;
}

interface StaticProps extends DefaultProps {
  type: 'static';
  focus?: boolean;
  onDeleteById: (id: any) => void;
}

interface InputProps extends DefaultProps {
  name: string;
  checked: boolean;
  onChecked: (id: any) => void;
}

interface CheckboxProps extends InputProps {
  type: 'checkbox';
}

interface RadioProps extends InputProps {
  type: 'radio';
}

export type TagProps =
  | StaticProps
  | CheckboxProps
  | RadioProps;

const Tag = (props: TagProps) => {
  const {
    id,
    text,
  } = props;

  if (props.type === 'static') {
    const onClickByDeleteButton = () => {
      props.onDeleteById(id);
    };

    return (
      <div
        className={`
          ${styles.tag}
          ${props.focus && styles.tagHasFocus}
        `}
        tabIndex={0}
      >
        {text}

        {
          !!props.onDeleteById && (
            <button
              className={styles.deleteButton}
              type="button"
              onClick={onClickByDeleteButton}
            >
              <CloseIcon />
            </button>
          )
        }
      </div>
    );
  }

  const onInputCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { checked } } = e;

    if (props.type === 'checkbox' || checked) {
      props.onChecked(id);
    }
  };

  const onKeyDownByLabel = (e: React.KeyboardEvent<HTMLLabelElement>) => {
    const { key } = e;

    if (key === ' ') {
      e.preventDefault();

      props.onChecked(id);
    }
  };

  return (
    <div className={styles.checkboxWrap}>
      <input
        className="visually-hidden"
        type={props.type}
        name={props.name}
        id={`${props.name}-${id}`}
        onChange={onInputCheckbox}
        tabIndex={-1}
        checked={props.checked}
      />

      <label
        className={`
          ${styles.tag}
          ${styles.tagCheckbox}
          ${props.checked && styles.tagChecked}
        `}
        onKeyDown={onKeyDownByLabel}
        tabIndex={0}
        htmlFor={`${props.name}-${id}`}
      >
        {text}
      </label>
    </div>
  );
};

export default Tag;
