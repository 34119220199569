import { FieldState } from '_types/Field';

export const agentsFilterFields: FieldState[] = [
  {
    id: 1,
    type: 'price',
    label: 'Цена',
    values: {
      'price[from]': {
        value: '',
      },
      'price[to]': {
        value: '',
      },
    },
  },
  {
    id: 2,
    type: 'price_for_meter',
    label: 'Цена',
    values: {
      'price_for_meter[from]': {
        value: '',
      },
      'price_for_meter[to]': {
        value: '',
      },
    },
  },
  {
    id: 3,
    type: 'area',
    label: 'Площадь (м²)',
    values: {
      'area[from]': {
        value: '',
      },
      'area[to]': {
        value: '',
      },
    },
  },
  {
    id: 4,
    type: 'exclusive',
    label: 'Эксклюзив',
    values: {
      exclusive: {
        value: '',
      },
    },
  },
  {
    id: 5,
    type: 'agency',
    label: 'Объекты команды',
    values: {
      agency: {
        value: '',
      },
    },
  },
  {
    id: 6,
    type: 'mine',
    label: 'Мои объекты',
    values: {
      mine: {
        value: '',
      },
    },
  },
  {
    id: 7,
    type: 'mortgage',
    label: 'Возможность ипотеки',
    values: {
      mortgage: {
        value: '',
      },
    },
  },
  {
    id: 8,
    type: 'new',
    label: 'Новостройка',
    values: {
      new: {
        value: '',
      },
    },
  },
  {
    id: 9,
    type: 'apartment',
    label: 'Апартаменты',
    values: {
      apartment: {
        value: '',
      },
    },
  },
  {
    id: 10,
    type: 'townhouse',
    label: 'Таунхаус',
    values: {
      townhouse: {
        value: '',
      },
    },
  },
  {
    id: 11,
    type: 'appliance',
    label: 'Техника',
    values: {
      appliance: {
        value: '',
      },
    },
  },
  {
    id: 12,
    type: 'furniture',
    label: 'Мебель',
    values: {
      furniture: {
        value: '',
      },
    },
  },
  {
    id: 13,
    type: 'security',
    label: 'Охрана',
    values: {
      security: {
        value: '',
      },
    },
  },
  {
    id: 14,
    type: 'closed_area',
    label: 'Закрытая территория',
    values: {
      closed_area: {
        value: '',
      },
    },
  },
  {
    id: 15,
    type: 'lift',
    label: 'Лифт',
    values: {
      lift: {
        value: '',
      },
    },
  },
  {
    id: 16,
    type: 'gas',
    label: 'Газ',
    values: {
      gas: {
        value: '',
      },
    },
  },
  {
    id: 17,
    type: 'balcony',
    label: 'Балкон',
    values: {
      balcony: {
        value: '',
      },
    },
  },
  {
    id: 18,
    type: 'plain_plot',
    label: 'Ровный участок',
    values: {
      plain_plot: {
        value: '',
      },
    },
  },
  {
    id: 19,
    type: 'specific_plot',
    label: 'Видовой участок',
    values: {
      specific_plot: {
        value: '',
      },
    },
  },
  {
    id: 20,
    type: 'guest_house',
    label: 'Гостевой дом',
    values: {
      guest_house: {
        value: '',
      },
    },
  },
  {
    id: 21,
    type: 'sauna',
    label: 'Сауна',
    values: {
      sauna: {
        value: '',
      },
    },
  },
  {
    id: 21,
    type: 'electricity',
    label: 'Электричество',
    values: {
      electricity: {
        value: '',
      },
    },
  },
  {
    id: 22,
    type: 'water',
    label: 'Вода',
    values: {
      water: {
        value: '',
      },
    },
  },
  {
    id: 23,
    type: 'pool',
    label: 'Бассейн',
    values: {
      pool: {
        value: '',
      },
    },
  },
  {
    id: 24,
    type: 'ready_business',
    label: 'Готовый бизнес',
    values: {
      ready_business: {
        value: '',
      },
    },
  },
  {
    id: 25,
    type: 'floor',
    label: 'Этаж',
    values: {
      'floor[from]': {
        value: '',
      },
      'floor[to]': {
        value: '',
      },
    },
  },
  {
    id: 26,
    type: 'floors_number',
    label: 'Всего этажей',
    values: {
      'floors_number[from]': {
        value: '',
      },
      'floors_number[to]': {
        value: '',
      },
    },
  },
];
