import React, { useCallback, useContext, useEffect, useState } from 'react';
import Media from 'react-media';
import { Theme } from 'lib/theme';
import { makeStyles } from 'hooks/makeStyles';
import {
  useHistory, useLocation, Route, Switch,
} from 'react-router-dom';
import clsx from 'clsx';

import { URLParams } from '../_types/urlParams';
import { useParams } from '../_helpers/add-params';

import { Tabs, Tab, Icons, IconButton } from 'ui/shared';
import Modal from 'ui/components/Modal';

import { Header } from 'components/header';
import SwitchButton from 'ui/components/Switch';
import AccessProvide from 'ui/components/AccessProvide';

import Agents from 'ui/pages/objects/Agents';
import { SearchPage } from 'domains/objects/pages/search';
import Property from 'ui/pages/objects/Property';
import { Requests } from 'ui/pages/objects/requests';
import { ApplicationContext } from 'global-stores/application-context';
import { ObjectsRoutes } from 'domains/objects/constants';

import styles from './Objects.module.css';
import cn from 'classnames';
import Button from 'ui/components/Button';

const useStyles = makeStyles((theme: Theme) => ({
  nav: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `0.5px solid ${theme.colors.grey.tertiary}`,
    '@media screen and (min-width: 1135px)': {
      padding: '16px 96px 0',
    },
    '@media screen and (max-width: 768px)': {
      justifyContent: 'flex-start',
      padding: '8px 24px 0',
    },
  },
  tabs: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    '@media screen and (max-width: 768px)': {
      width: '100%',
      justifyContent: 'space-around',
    },
  },
  tab: {
    padding: 16,
    color: 'var(--color-blue)',
    background: 'none',
    textTransform: 'none',
    fontSize: 14,
    borderRadius: 0,
    '@media screen and (min-width: 956px)': {
      '&:not(:last-child)': {
        marginRight: 48,
      },
    },
    borderBottom: '2px solid transparent',
    '&:focus, &:hover': {
      background: 'none',
      borderBottomColor: 'var(--color-blue)',
    },
    '&$active': {
      background: 'none',
      borderBottomColor: 'var(--color-blue)',
    },
  },
  active: {},
  switchWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: theme.spacing(7.5),
    '@media screen and (max-width: 1100px)': {
      flexDirection: 'column',
    },
    '@media screen and (max-width: 768px)': {
      position: 'absolute',
      bottom: 9.5,
      right: 16,
      padding: `${theme.spacing(0)}`,
    },
  },
  switchLabel: {
    marginRight: theme.spacing(3),
    '@media screen and (max-width: 1100px)': {
      marginRight: theme.spacing(0),
    },
  },
  addIcon: {
    marginLeft: theme.spacing(2),
  },
  switchDesctop: {
    flexWrap: 'wrap',
    '@media screen and (max-width: 846px)': {
      justifyContent: 'flex-end',
      marginRight: '10px',
    },
    '@media screen and (max-width: 769px)': {
      display: 'none',
    },
  },
  modalButton: {
    cursor: 'default',
  },
}));

const Objects = () => {
  const [params, setParam] = useParams<URLParams>();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [error, setError] = useState(false);
  const [animate, setAnimate] = useState(false);

  const applicationContext = useContext(ApplicationContext);

  const history = useHistory();
  const location = useLocation();
  const [value, setValue] = useState<string | number>();
  const css = useStyles();

  const appLink = 'getagent://created_add';
  const appleStoreLink = 'https://apps.apple.com/ru/app/getagent/id1087001957';

  const handleClickSwitchButton = () => {
    setParam('withClient', params.withClient ? null : 'true');
  };

  useEffect(() => {
    const path = location.pathname.split('?')[0].split('/objects/')[1];

    if (path) {
      setValue(path);
    }
  }, [location]);

  useEffect(() => {
    applicationContext.setScrollableContainer(styles.main);
  }, [applicationContext]);

  const handleSelectTab = (selectedValue: string | number) => {
    if (`${selectedValue}` === history.location.pathname) return;
    setValue(selectedValue);
    history.push({
      pathname: `/objects/${selectedValue}`,
      search: location.search,
    });
  };

  const handleOpenModal = useCallback(() => {
    setIsOpenModal(!isOpenModal);
  }, [isOpenModal, setIsOpenModal]);

  return (
    <div className={styles.root}>
      <Media query="(max-width: 768px)">
        <Header
          title="Объекты"
          path="/"
        >
          <Media query="(max-width: 768px)">
            <div className={styles['switch-button-wrap']}>
              <div className={styles.switchButtonColumn}>
                <p
                  className={cn(styles['switch-button-label'], {
                    [styles.switchButtonLabel_hide]: params.withClient,
                  })}
                >
                  С клиентом
                </p>

                <SwitchButton
                  onChange={handleClickSwitchButton}
                  checked={params.withClient === 'true'}
                />
              </div>
            </div>
          </Media>
        </Header>
      </Media>

      <div className={css.nav}>
        <Tabs value={value} onChange={handleSelectTab} className={css.tabs}>
          <Tab value="agents" className={clsx(css.tab, { [css.active]: value === 'agents' })}>
            Объекты
          </Tab>
          <Tab value="property" className={clsx(css.tab, { [css.active]: value === 'property' })}>
            Подборки
          </Tab>
          <IconButton className={css.tab} onClick={handleOpenModal}>
            Добавить объект
            <Icons.Add className={css.addIcon} color="#136FFB" height={18} width={18} viewBox="0 0 18 18" />
          </IconButton>
        </Tabs>

        <div className={styles['switch-button-wrap']}>
          <div className={clsx(styles.switchButtonColumn, css.switchDesctop)}>
            <p
              className={cn(styles['switch-button-label'], {
                [styles.switchButtonLabel_hide]: params.withClient,
              })}
            >
              С клиентом
            </p>

            <SwitchButton
              onChange={handleClickSwitchButton}
              checked={params.withClient === 'true'}
            />
          </div>
        </div>
      </div>

      <div className={styles.container}>
        <AccessProvide>
          <Switch>
            <Route
              path="/objects/agents"
              component={Agents}
            />

            <Route
              path={ObjectsRoutes.SEARCH_PAGE}
              component={SearchPage}
            />

            <Route
              path="/objects/property"
              component={Property}
            />

            <Route
              path="/objects/requests"
              component={Requests}
            />
          </Switch>
        </AccessProvide>
      </div>

      {
          isOpenModal && (
            <Modal
              onClose={handleOpenModal}
              error={error}
              animate={animate}
            >
              <Modal.Body>
                В данный момент добавить объект можно только через мобильное приложение
              </Modal.Body>

              <Modal.Buttons>
                <Button
                  component="link"
                  href={applicationContext.mobileDevice ? appLink : appleStoreLink}
                  theme="black"
                  target="_blank"
                  className={css.modalButton}
                >
                  Перейти в приложение
                </Button>

                <Button
                  component="button"
                  theme="red"
                  onClick={handleOpenModal}
                >
                  Отмена
                </Button>
              </Modal.Buttons>
            </Modal>
          )
        }
    </div>
  );
};

export default React.memo(Objects);
