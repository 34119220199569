import React from 'react';
import { CreateSvgIcon } from '../icon';
import { IconProps } from '../names';

export const Objects = (props: IconProps) => (
  <CreateSvgIcon {...props}>
    {({ color }) => (
      <>
        <path
          d="M0 22H28V16.5H0V22ZM2.8 17.875H5.6V20.625H2.8V17.875ZM0 0V5.5H28V0H0ZM5.6 4.125H2.8V1.375H5.6V4.125ZM0 13.75H28V8.25H0V13.75ZM2.8 9.625H5.6V12.375H2.8V9.625Z"
          fill={color}
        />
      </>
    )}
  </CreateSvgIcon>
);
