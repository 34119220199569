import React, { useContext, useState, useEffect } from 'react';
import {
  AnimatedPage, Box, Button, Dropdown, Icons, Input, Typography,
} from 'ui/shared';
import className from 'classnames/bind';
import { makeStyles } from 'hooks/makeStyles';
import { useHistory } from 'react-router-dom';
import { fade } from 'utils';
import { observer } from 'mobx-react';
import { find } from 'lodash/fp';
import { ApplicationContext } from 'global-stores/application-context';
import { INewUser } from 'interfaces';
import { Background } from 'ui/components/background/background';
import { Text } from 'components/text';
import styles from './sign-up.module.css';
import { getUserCities } from 'domains/objects/requests';

const cx = className.bind(styles);

const useStyles = makeStyles((theme) => ({
  wrap: {
    display: 'flex',
    alignItems: 'center',
    padding: '56px 16px',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  container: {
    maxWidth: 1000,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '&$animating': {
      overflowY: 'hidden',
    },
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    marginBottom: `${theme.spacing(4.25)}`,
    '@media screen and (max-width: 768px)': {
      fontSize: 25,
      marginBottom: `${theme.spacing(6.25)}`,
    },
  },
  subtitle: {
    marginBottom: theme.spacing(13.25),
    '@media screen and (max-width: 768px)': {
      fontSize: 23,
      marginBottom: `${theme.spacing(10.5)}`,
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputContainer: {
    maxWidth: 324,
  },
  inputContainerRow: {
    maxWidth: 324,
    display: 'flex',
  },
  gender: {
    position: 'relative',
    width: '100%',
  },
  iconArrow: {
    position: 'absolute',
    right: 10,
    top: 15,
    '&:hover, &:focus, &:active': {
      outline: 'none',
      backgroundColor: 'transparent',
    },
  },
  button: {
    width: 230,
    height: 50,
    borderRadius: 6,
    fontSize: 22,
    padding: `${theme.spacing(2)}`,
    border: '2px solid #020202',
    color: '#020202',
    '&:hover, &:focus, &:active': {
      outline: 'none',
      borderColor: 'var(--color-blue)',
      color: 'var(--color-blue)',
    },
    '@media screen and (max-width: 768px)': {
      marginBottom: `${theme.spacing(22.5)}`,
    },
  },
  overlay: {
    backgroundColor: theme.colors.background.paper.default,
    borderRadius: 10,
    border: '2px solid #CBCBCB',
  },
  dropdown: {
    color: '#000000',
    border: '2px solid #CBCBCB',
    padding: `${theme.spacing(3)} ${theme.spacing(10)} ${theme.spacing(3)} ${theme.spacing(3)}`,
    fontSize: 18,
    textTransform: 'none',
    textAlign: 'left',
    fontFamily: `${theme.fonts.semibold}, sans-serif`,
    transition: 'border .2s',
    borderRadius: '10px',
    backgroundColor: '#ffffff',
    width: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: theme.colors.background.paper.default,
    },
    '&:focus': {
      border: `2px solid  ${theme.colors.blue.main}`,
      backgroundColor: theme.colors.background.paper.default,
    },
    '&.error': {
      border: `2px solid ${theme.colors.red.main}`,
    },
  },
  greyText: { color: theme.colors.textGray },
  dropdownBtn: {
    color: '#000000',
    border: 'none',
    padding: 12,
    fontSize: 18,
    textTransform: 'none',
    textAlign: 'center',
    fontFamily: `${theme.fonts.semibold}, sans-serif`,
    borderRadius: 0,
    backgroundColor: 'transparent',
    width: '100%',
    '&:hover, &:focus': {
      backgroundColor: fade(theme.colors.grey.main, 0.2),
      borderRadius: 0,
    },
  },
  // Styles for date picker
  dayPickerSelect: {
    fontSize: '1.85rem',
  },
  dayPickerInputOverlayWrapper: {
    position: 'relative',
  },
  dayPickerInputOverlay: {
    left: 'unset',
    right: 0,
    bottom: '50px',
    position: 'absolute',
    zIndex: 1,
    background: 'white',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
  },
  customDayPicker: {
    width: '100%',
    fontSize: '1.8rem',
  },
}));

const DateInput = (props: any) => (
  <Input
    {...props}
    height="44px"
  />
);

export default DateInput;

const GENDERS = [
  { key: 'male', value: 'Мужской' },
  { key: 'female', value: 'Женский' },
];

interface ISignUpStep {
  firstName: undefined | boolean
  lastName: undefined | boolean
  email: undefined | boolean
  gender: undefined | boolean
  city: undefined | boolean
}

interface City{
  id: number;
  inactive: boolean;
  name: string;
  northeast_lat: number;
  northeast_lng: number;
  southwest_lat: number;
  southwest_lng: number;
}

export const SignUp = observer(() => {
  const [isValidState, setIsValidState] = useState<ISignUpStep>({
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    gender: undefined,
    city: undefined,
  });

  const [citiesList, setCitiesList] = useState<City[]>([]);

  const css = useStyles();
  const history = useHistory();
  const app = useContext(ApplicationContext);

  const handleUserPropertyChange = (name: string) => (e: any) => {
    app.updateNewUserProperties({ [name]: e.target.value });
    setIsValidState((prev) => ({ ...prev, [name]: undefined }));
  };

  const validateStep = () => {
    if (!app.newUser) {
      return false;
    }

    const {
      first_name: firstName,
      last_name: lastName,
      email,
      city,
      gender,
    } = app.newUser;

    setIsValidState({
      firstName: !!(firstName && firstName.trim().length),
      lastName: !!(lastName && lastName.trim().length),
      email: !!(email && email.trim().length),
      city: city !== undefined,
      gender: gender !== undefined,
    });
    return (
      !!(firstName && firstName.trim().length)
      && !!(lastName && lastName.trim().length)
      && !!(email && email.trim().length)
      && city !== undefined
      && gender !== undefined
    );
  };

  const handlePushNextPage = () => {
    if (validateStep()) {
      app.newUser = { ...app.currentUser, ...app.newUser } as INewUser;
      history.push('/signup/information');
    }
  };

  const gender = find({ key: app.newUser?.gender }, GENDERS) as any;
  const city = find({ name: app.newUser?.city }, citiesList) as any;

  useEffect(() => {
    const authParamsKey = 'auth-params';

    const authParams = JSON.parse(localStorage.getItem(authParamsKey) || '{}');

    // eslint-disable-next-line
    const { first_name, last_name, email } = authParams;

    const haveAuthParams = [first_name, last_name, email].every(Boolean);

    if (!haveAuthParams) {
      return;
    }

    if (haveAuthParams) {
      app.updateNewUserProperties({ first_name });
      app.updateNewUserProperties({ last_name });
      app.updateNewUserProperties({ email });
    }
  }, [app]);

  useEffect(() => {
    Promise.any([
      getUserCities(),
    ]).then((cityList) => setCitiesList(cityList));
  }, []);

  return (
    <div className={css.wrap}>
      <AnimatedPage animation="horizontal">
        <div className={css.container}>
          <div className={cx('title')}>
            <div className={cx('title-box')}>
              <Text size="xl" as="p" semibold>
                Добро пожаловать в&nbsp;сообщество!
              </Text>
            </div>
            <div className={cx('title-box')}>
              <Text size="xl" as="p" semibold>
                Расскажите немного о&nbsp;себе
              </Text>
            </div>
          </div>
          <div className={css.wrapper}>
            <Box mb={5.75} className={css.inputContainer}>
              <Input
                value={app.currentUser?.phone}
                // onChange={handleUserPhone}
                placeholder="Телефон"
                // error={error}
                // succeed={succeed}
                readOnly
                type="number"
                height="44px"
              />
            </Box>
            <Box mb={5.75} className={css.inputContainer}>
              <Input
                value={app.newUser?.first_name || ''}
                onChange={(e) => app.updateNewUserProperties({ first_name: e.target.value })}
                placeholder="Имя"
                required
                error={isValidState.firstName === false}
                // succeed={!!loginStore.message}
                height="44px"
              />
            </Box>
            <Box mb={5.75} className={css.inputContainer}>
              <Input
                value={app.newUser?.last_name || ''}
                onChange={handleUserPropertyChange('last_name')}
                placeholder="Фамилия"
                error={isValidState.lastName === false}
                // succeed={!!loginStore.message}
                height="44px"
              />
            </Box>
            <Box mb={5.75} className={css.inputContainer}>
              <Input
                value={app.newUser?.email || ''}
                onChange={handleUserPropertyChange('email')}
                placeholder="Email"
                error={isValidState.email === false}
                // succeed={!!loginStore.message}
                height="44px"
              />
            </Box>
            <Box mb={5.75} className={css.inputContainerRow}>
              <div className={css.gender}>
                <Dropdown
                  preferTop
                  items={GENDERS}
                  renderItem={(item) => (
                    <Button
                      key={`gender-${item.key}`}
                      onClick={() => {
                        app.updateNewUserProperties({ gender: item.key });
                        setIsValidState((prev) => ({
                          ...prev,
                          gender: undefined,
                        }));
                      }}
                      className={css.dropdownBtn}
                    >
                      {item.value}
                    </Button>
                  )}
                  overlayClassName={css.overlay}
                >
                  <Button className={`${css.dropdown} ${isValidState.gender === false && 'error'}`}>
                    <span>
                      {gender?.value || (
                        <Typography size="subtitle1(18px)" font="semibold" className={css.greyText}>
                          Пол
                        </Typography>
                      )}
                    </span>
                    <Icons.TriangularArrow
                      width={29}
                      height={29}
                      viewBox="0 0 29 29"
                      color="#9D9D9D"
                      className={css.iconArrow}
                    />
                  </Button>
                </Dropdown>
              </div>
            </Box>
            <Box mb={12} className={css.inputContainer}>
              <div className={css.gender}>
                <Dropdown
                  preferTop
                  items={citiesList}
                  renderItem={(item) => (
                    <Button
                      key={`city-${item.name}`}
                      onClick={() => {
                        app.updateNewUserProperties({ city: item.name });
                        setIsValidState((prev) => ({
                          ...prev,
                          city: undefined,
                        }));
                      }}
                      className={css.dropdownBtn}
                    >
                      {item.name}
                    </Button>
                  )}
                  overlayClassName={css.overlay}
                >
                  <Button className={`${css.dropdown} ${isValidState.city === false && 'error'}`}>
                    <span>
                      {city?.name || (
                        <Typography size="subtitle1(18px)" font="semibold" className={css.greyText}>
                          Город
                        </Typography>
                      )}
                    </span>
                    <Icons.TriangularArrow
                      width={29}
                      height={29}
                      viewBox="0 0 29 29"
                      color="#9D9D9D"
                      className={css.iconArrow}
                    />
                  </Button>
                </Dropdown>
              </div>
            </Box>
            <Box className={css.centered}>
              <Button secondary className={css.button} onClick={handlePushNextPage}>
                Далее
              </Button>
            </Box>
          </div>
        </div>
      </AnimatedPage>

      <Background />
    </div>
  );
});
