import React, { Fragment, useContext, useState } from 'react';
import Media from 'react-media';

import { observer } from 'mobx-react';

import { SelectionsContext } from 'global-stores/selections-context';
import { ApplicationContext } from 'global-stores/application-context';

import Apartment from 'ui/components/Apartment';
import Pagination from 'ui/components/Pagination';

import styles from './ApartmentList.module.css';
import CopyToClipboardPopup from '../CopyToClipboardPopup';
import { useParams } from '../../pages/objects/_helpers/add-params';
import { URLParams } from '../../pages/objects/_types/urlParams';

interface Props {
  selectedId: number;
}

const ApartmentList = observer(({ selectedId }: Props) => {
  const [params] = useParams<URLParams>();

  const [popupAdsDeletedFromSelectionIsOpen, setPopupAdsDeletedFromSelectionIsOpen] = useState(false);
  const [popupAdsDeletedFromSelectionTimeout, setPopupAdsDeletedFromSelectionTimeout] = useState(null as any);

  const selectionsContext = useContext(SelectionsContext);
  const applicationContext = useContext(ApplicationContext);

  const { currentUser } = applicationContext;

  const ads = selectionsContext.getAdsBySelectedId(selectedId);

  if (ads?.error) {
    return (
      <p className={styles['selection-empty']}>
        Проблемы на сервере, попробуйте добавить объект в подборку позже или создайте подборку ещё раз
      </p>
    );
  }

  if (!ads?.ads) {
    return null;
  }

  const handleDeleteAds = (adsId: number) => new Promise((resolve, reject) => {
    selectionsContext.deleteAdsBySelectedId(selectedId, adsId)
      .then((o) => {
        setPopupAdsDeletedFromSelectionIsOpen(true);

        clearTimeout(popupAdsDeletedFromSelectionTimeout);

        setPopupAdsDeletedFromSelectionTimeout(setTimeout(() => {
          setPopupAdsDeletedFromSelectionIsOpen(false);
        }, 3000));

        resolve(o);
      })
      .catch(() => {
        reject();
      });
  });

  function handleClickApartmentLink(e: React.SyntheticEvent) {
    const targetElement = e.target as HTMLDivElement;

    if (targetElement.closest('button')) {
      e.preventDefault();
    }
  }

  return (
    <>
      <Media query="(min-width: 769px)">
        <div className={styles.apartmentListHeader}>
          <p>
            Адрес объекта
          </p>

          <p>
            Комнат/площадь
          </p>

          <p>
            {!params.withClient && 'Общая комиссия'}
          </p>

          <p>
            {!params.withClient && 'На руки'}
          </p>

          <p>
            Цена
          </p>
        </div>
      </Media>

      {ads.ads.length !== 0 && (
        <ol className={styles.apartmentList}>
          {
            ads.ads.map((apartment) => (
              <Fragment key={apartment.encrypted_id}>
                <li className={styles.apartmentListItem}>
                  <a
                    className={styles.apartmentLink}
                    href={`https://catalog.reagent.pro/${apartment.encrypted_id}/${params?.withClient ? currentUser?.public_id || '' : ''}`}
                    target="_blank"
                    rel="noreferrer"
                    onClick={handleClickApartmentLink}
                  >
                    <Apartment selectedId={selectedId} {...apartment} onDelete={handleDeleteAds} />
                  </a>
                </li>
              </Fragment>
            ))
          }
        </ol>
      )}

      {ads.ads.length === 0 && (
        <p className={styles['selection-empty']}>
          Здесь пока ничего нет
        </p>
      )}

      {Math.ceil(((ads.meta?.total || 0) / selectionsContext.PER_PAGE_ADS)) > 1 && (
        <div className={styles.pagination}>
          <Media query="(max-width: 425px)">
            <Pagination
              pageCount={Math.ceil(((ads.meta?.total || 0) / selectionsContext.PER_PAGE_ADS))}
              onPageChange={selectionsContext.setAdsPage({ selectedId })}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              disabled={ads.loading}
            />
          </Media>

          <Media query="(min-width: 426px)">
            <Pagination
              pageCount={Math.ceil(((ads.meta?.total || 0) / selectionsContext.PER_PAGE_ADS))}
              onPageChange={selectionsContext.setAdsPage({ selectedId })}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              disabled={ads.loading}
            />
          </Media>
        </div>
      )}

      <CopyToClipboardPopup text="Объект удалён из подборки" active={popupAdsDeletedFromSelectionIsOpen} />
    </>
  );
});

export default ApartmentList;
