import ApiService from './api-service';
import { IUser } from '../interfaces';

interface UserResponse {
  user: IUser,
}

interface RegistrationUserResponse extends UserResponse {
  token: string
}

class UserService {
  private apiService: ApiService;

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  async registerUser(
    user: IUser,
    termsOfUse = false,
    privacyPolicy = false,
  ): Promise<RegistrationUserResponse> {
    if (!termsOfUse || !privacyPolicy) {
      return Promise.reject(
        'Для регистрации необходимо принять соглашение об использовании и политику конфиденциальности',
      );
    }
    const formData = this.createFormData(user);
    if (termsOfUse) formData.append('user[terms_of_use]', 't');
    if (privacyPolicy) formData.append('user[privacy_policy]', 't');
    return this.apiService.post('v3/registration', { body: formData }).json();
  }

  async saveUser(
    user: IUser,
  ): Promise<RegistrationUserResponse> {
    const formData = this.createFormData(user);
    return this.apiService.patch('v3/user', { body: formData }).json();
  }

  async applyPromocode(
    promocode: string,
  ): Promise<RegistrationUserResponse> {
    console.log(promocode);
    return this.apiService.patch('v3/user/apply_promocode', { json: { promocode } }).json();
  }

  async saveCertPhoto(image: File) {
    this.apiService.post('v1/cert_photo', { body: image });
  }

  async getCurentUser(): Promise<IUser> {
    const response = await this.apiService.get('v3/user');

    if (!response) {
      return Promise.reject({
        code: 401,
        message: 'Internet disconnected',
      });
    }

    if (response.status === 401) {
      return Promise.reject({
        code: response.status,
        message: response.statusText,
      });
    }
    return response.json().then((result) => result.user);
  }

  private createFormData(user: IUser) {
    const formData = new FormData();
    Object.keys(user).forEach((property) => {
      // @ts-ignore
      const value = typeof user[property] === 'string' ? user[property].trim() : user[property];
      if (typeof value === 'boolean' || typeof value === 'number' || value || value === '') {
        formData.append(`user[${property}]`, value);
      }
    });
    return formData;
  }
}

export default UserService;
