import React from 'react';
import { Theme } from 'lib/theme';
import { makeStyles } from 'hooks/makeStyles';

import bg from 'assets/objects-bg.jpg';
import mobileBg from 'assets/objects-bg-mobile.jpg';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    background: theme.colors.background.paper.default,
    backgroundImage: `url(${bg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100%',
    '@media screen and (max-width: 768px)': {
      height: '900px',
      backgroundImage: `url(${mobileBg})`,
    },
  },
  blur: {
    backdropFilter: 'blur(200px)',
    backgroundColor: 'rgb(234 241 250)',
    opacity: 0.4,
    height: '100%',
  },
}));

export const Requests = () => {
  const css = useStyles();

  return (
    <div className={css.root}>
      <div className={css.blur} />
    </div>
  );
};
