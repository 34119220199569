import { createContext } from 'react';

import axios from 'axios';

import { action, computed, makeAutoObservable, observable } from 'mobx';

import { ObjectServerProps, SelectionProps, SelectionsProps } from '_types';

import Config from '../config';

class SelectionsManager {
  PER_PAGE = 10;

  PER_PAGE_ADS = 10;

  @observable page = 0;

  @observable loaded = false;

  @observable loading = false;

  @observable selections: SelectionProps[] | null = null;

  @observable isScrollSelections = false;

  @observable isSmoothScrollSelections = false;

  @observable ads: {
    [key: string]: {
      loading: boolean;
      page: number;
      error?: boolean;
      meta?: {
        total: number;
      };
      ads?: ObjectServerProps[];
    }
  } = {};

  @observable total: number | null = null;

  constructor() {
    makeAutoObservable(this);

    this.setPage = this.setPage.bind(this);
  }

  @action
  uploadSelections() {
    if (this.canLoad) {
      const token = localStorage.getItem('token');

      this.loading = true;
      this.loaded = true;

      return new Promise((resolve, reject) => {
        axios(`${Config.API_URL}/api/v3/selections`, {
          method: 'GET',
          headers: {
            Authorization: `Token ${token}`,
          },
          params: {
            per_page: this.PER_PAGE,
            page: this.page + 1,
          },
        })
          .then(({ data }: { data: SelectionsProps }) => {
            if (this.isScrollSelections && this.selections) {
              this.selections.push(...data.selections);
            } else {
              this.selections = data.selections;
            }
            this.total = data.meta.total;

            resolve(data);
          })
          .finally(() => {
            this.loading = false;

            reject();
          });
      });
    }

    return new Promise((resolve, reject) => {
      reject('Your cannot load selection');
    });
  }

  scrollAfterCallback(selector: string, callback: any) {
    return (props: any) => {
      document.querySelector(selector)?.scrollIntoView({ behavior: 'smooth' });

      callback(props);
    };
  }

  @action
  setAdsPage({ selectedId }: { selectedId: number, }) {
    if (this.selections) {
      const selectionIndex = this.selections.findIndex((s) => s.id === selectedId);

      return ({ selected }: { selected: number }) => {
        this.ads[selectionIndex].page = selected;

        this.uploadAdsBySelectionId(selectedId);
      };
    }

    return () => {};
  }

  @action
  setPage({ selected }: { selected: number }) {
    if (selected !== this.page) {
      this.page = selected;
    }
  }

  @computed
  get canLoad() {
    if (this.loading) {
      return false;
    }

    if (!this.total) {
      return true;
    }

    if (this.page >= Math.ceil(this.total / this.PER_PAGE)) {
      return false;
    }

    return true;
  }

  @action
  async uploadAdsBySelectionId(selectionId: number) {
    const token = localStorage.getItem('token');

    if (!this.selections) return;

    const selectionIndex = this.selections.findIndex((s) => s.id === selectionId);

    if (!this.selections[selectionIndex]) return;

    this.ads[selectionIndex] = {
      ...this.ads[selectionIndex],
      loading: true,
      page: this.ads[selectionIndex]?.page || 0,
    };

    await axios(`${Config.API_URL}/api/v3/selections/${selectionId}/ads`, {
      method: 'GET',
      headers: {
        Authorization: `Token ${token}`,
      },
      params: {
        per_page: this.PER_PAGE_ADS,
        page: this.ads[selectionIndex].page + 1,
      },
    })
      .then(({ data }) => {
        this.ads[selectionIndex].error = false;
        this.ads[selectionIndex].ads = data.ads;
        this.ads[selectionIndex].meta = data.meta;
      })
      .catch(() => {
        this.ads[selectionIndex].error = true;
      })
      .finally(() => {
        this.ads[selectionIndex].loading = false;
      });
  }

  @action
  getAdsBySelectedId(selectionId: number) {
    if (this.selections) {
      const selectionIndex = this.selections.findIndex((s) => s.id === selectionId);

      return this.ads[selectionIndex];
    }

    return null;
  }

  @action
  deleteAdsBySelectedId(selectionId: number, adsId: number) {
    const token = localStorage.getItem('token');

    this.loading = true;

    return new Promise<void>((resolve, reject) => {
      axios(`${Config.API_URL}/api/v3/selections/${selectionId}/ads/${adsId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Token ${token}`,
        },
      })
        .then(() => {
          this.loading = false;

          this.uploadAdsBySelectionId(selectionId)
            .then(() => {
              resolve();
            })
            .catch(() => {
              reject();
            });
        })
        .catch(() => {
          reject();
        });
    });
  }

  @action
  async addAdsToSelection(selectionId: number, adsId: number) {
    const token = localStorage.getItem('token');

    this.loading = true;

    if (!token) {
      throw new Error('You have not authorization token');
    }

    await axios(`${Config.API_URL}/api/v3/selections/${selectionId}/ads/add`, {
      method: 'POST',
      headers: {
        Authorization: `Token ${token}`,
      },
      data: {
        id: adsId,
      },
    })
      .finally(() => {
        this.loading = false;
      });
  }

  @action
  addAdsIdFromSelection(selectionId: number, adsId: number) {
    this.selections?.forEach((selection) => {
      if (selection.id === selectionId) {
        if (selection.ads) {
          selection.ads.push(adsId);
        } else {
          selection.ads = [adsId];
        }
      }
    });
  }

  @action
  deleteAdsIdFromSelection(selectionId: number, adsId: number) {
    this.selections?.forEach((selection) => {
      if (selection.id === selectionId) {
        selection.ads?.splice(selection.ads?.indexOf(adsId), 1);
      }
    });
  }

  @action
  setScrollSelection(scroll: boolean) {
    this.isScrollSelections = scroll;
  }

  @action
  setSmoothScrollSelection(scroll: boolean) {
    this.isSmoothScrollSelections = scroll;
  }

  @action
  scrollUp() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  @action
  clearSelections() {
    this.selections = null;
  }
}

export default SelectionsManager;

export const SelectionsContext = createContext<SelectionsManager>(
  new SelectionsManager(),
);
