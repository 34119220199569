import React, {
  useContext, useEffect, useState,
} from 'react';
import Media from 'react-media';
import {
  Button as LegacyButton,
} from 'ui/shared';

import { observer } from 'mobx-react';
import { isEqual } from 'lodash';

import { Header } from 'components/header';
import { Tabs, TabAsButton, TabAsLink } from 'components/tabs';

import { EditAboutMeContainer } from 'ui/pages/profile/containers/edit-about-me-container';
import { EditProfessionalInformationContainer } from 'ui/pages/profile/containers/edit-professional-information-container';
import { EditSocials } from 'ui/pages/profile/components/edit-socials';
import { EditPicturesContainer } from 'ui/pages/profile/containers/edit-pictures-container';

import {
  normalizeUserDataForSafe,
  normalizeUserDataForUI,
} from 'ui/pages/profile/helpers/date';

import { ApplicationContext } from 'global-stores/application-context';

import styles from './styles.module.css';

export const USER_TYPES = [
  { type: 'agent', description: 'Агент' },
  { type: 'expert', description: 'Эксперт' },
  { type: 'broker', description: 'Брокер' },
];

export const EditProfile = observer(() => {
  const { currentUser, saveUser, loading } = useContext(ApplicationContext);
  const [userProperties, setUserProperties] = useState(normalizeUserDataForUI(currentUser));
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    if (!loading && currentUser) {
      setUserProperties(normalizeUserDataForUI(currentUser));
    }
  }, [loading, currentUser]);

  useEffect(() => {
    if (currentUser && userProperties) {
      setIsUpdated(!isEqual(normalizeUserDataForUI(currentUser), userProperties));
    }
  }, [currentUser, userProperties]);

  const updateUserProperties = async () => {
    if (userProperties) await saveUser(normalizeUserDataForSafe(userProperties));
  };

  const updateDataByKey = (key: string, value: any) => {
    // @ts-ignore
    setUserProperties({
      ...(userProperties || {}),
      [key]: value,
    });
  };

  // eslint-disable-next-line max-len
  const handleChangeUserProperty = (name: string) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => updateDataByKey(name, e.target.value);

  if (!currentUser) {
    return null;
  }

  return (
    <div>
      <Media query="(max-width: 768px)">
        <Header
          title="Мой цифровой профиль"
          path="/"
        />
      </Media>

      <Tabs>
        <TabAsButton active>
          Профиль
        </TabAsButton>

        {
          isUpdated
            ? (
              <LegacyButton
                className={styles['tab-button']}
                onClick={updateUserProperties}
              >
                Сохранить
              </LegacyButton>
            ) : (
              <TabAsLink
                component="link"
                href={currentUser!.www_link}
                target="_blank"
                rel="noreferrer"
                active={false}
              >
                Профиль для клиента
              </TabAsLink>
            )
        }
      </Tabs>

      <div className={styles.main}>
        <EditPicturesContainer
          userProperties={userProperties}
          updateDataByKey={updateDataByKey}
        />
        <EditSocials
          userProperties={userProperties}
          onChangeUserProperty={handleChangeUserProperty}
        />
        <EditAboutMeContainer
          userProperties={userProperties}
          updateDataByKey={updateDataByKey}
          onChangeUserProperty={handleChangeUserProperty}
        />
        <EditProfessionalInformationContainer
          userProperties={userProperties}
          updateDataByKey={updateDataByKey}
          onChangeUserProperty={handleChangeUserProperty}
        />
      </div>
    </div>
  );
});
