import React, { useContext, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames/bind';
import { ApplicationContext } from 'global-stores/application-context';
import styles from './context-menu.module.css';

const cx = classNames.bind(styles);

export type Props = {
  isOpen: boolean;
  button: HTMLButtonElement | null;
  children: React.ReactNode;
  onScroll?: (e: React.UIEvent<HTMLDivElement>) => void;
};

export const ContextMenu = ({ isOpen, children, button, onScroll }: Props) => {
  const contextMenuNode = document.getElementById('context-menu');

  const applicationContext = useContext(ApplicationContext);

  const [clientRect, setClientRect] = useState({} as DOMRect);

  const top = useMemo(() => clientRect?.top && button?.offsetHeight
    && document.documentElement.scrollTop + clientRect.top + button.offsetHeight + 8,
  [button, clientRect]);

  const right = useMemo(() => clientRect?.right
    && document.documentElement.offsetWidth - clientRect.right, [clientRect]);

  useEffect(() => {
    // eslint-disable-next-line no-useless-return
    if (!button) return;

    setClientRect(button.getBoundingClientRect());
  }, [button]);

  useEffect(() => {
    // eslint-disable-next-line no-useless-return
    if (!button) return;

    const scrollableContainer = document.querySelector(`.${applicationContext.scrollableContainer}`);

    const onScrollDocument = () => {
      setClientRect(button.getBoundingClientRect());
    };

    scrollableContainer?.addEventListener('scroll', onScrollDocument);

    // eslint-disable-next-line consistent-return
    return () => {
      scrollableContainer?.removeEventListener('scroll', onScrollDocument);
    };
  }, [applicationContext.scrollableContainer, button, isOpen]);

  if (!contextMenuNode || !isOpen) {
    return null;
  }

  return createPortal(
    <div
      className={cx('context-menu')}
      style={{ top, right, maxHeight: 400 }}
      onScroll={onScroll}
    >
      {children}
    </div>,
    contextMenuNode,
  );
};
