import React from 'react';
import classNames from 'classnames/bind';
import { CSSProperties } from 'hooks/makeStyles';
import { useComposedRef } from 'hooks/useComposedRef';
import { useWindowResizeListener } from 'hooks/useWindowResizeListener';
import { getSizingData, SizingData } from './getSize';
import { calculateNodeHeight } from './calculateNodeHeight';
import styles from './styles.module.css';

const cx = classNames.bind(styles);

interface TextareaProps
  extends React.DetailsHTMLAttributes<HTMLTextAreaElement> {
  minRows?: number
  maxRows?: number
  value: string
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  onHeightChange?: (height: number) => void
  placeholder?: string
  className?: string
  style?: CSSProperties
}

export const Textarea = React.forwardRef(
  (props: TextareaProps, ref: React.Ref<HTMLTextAreaElement>) => {
    const {
      value = '',
      onChange,
      onHeightChange,
      minRows = 1,
      maxRows,
      placeholder = '',
      className,
      style,
      ...passthrough
    } = props;
    const measurementsCacheRef = React.useRef<SizingData>();
    const libRef = React.useRef<HTMLTextAreaElement | null>(null);
    const elementRef = useComposedRef(libRef, ref);
    const heightRef = React.useRef(0);

    function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
      onChange(e);
    }

    function resizeTextArea() {
      const node = libRef.current!;
      const nodeSizingData = measurementsCacheRef.current
        ? measurementsCacheRef.current
        : getSizingData(node);

      if (!nodeSizingData) {
        return;
      }

      measurementsCacheRef.current = nodeSizingData;

      const height = calculateNodeHeight(
        nodeSizingData,
        node.value || node.placeholder || 'x',
        minRows,
        maxRows,
      );

      if (heightRef.current !== height) {
        heightRef.current = height;
        node.style.setProperty('height', `${height}px`, 'important');
        if (typeof onHeightChange === 'function') {
          onHeightChange(height);
        }
      }
    }

    if (typeof document !== 'undefined') {
      React.useEffect(resizeTextArea);
    }
    useWindowResizeListener(resizeTextArea);

    return (
      <textarea
        value={value}
        onChange={handleChange}
        ref={elementRef}
        placeholder={placeholder}
        className={cx('input', className)}
        {...passthrough}
      />
    );
  },
);
