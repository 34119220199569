import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';

export function useParams<T>(o?: T): [T, (key: string, value: any) => void, (o: { [key: string]: any }) => void] {
  const history = useHistory();
  const location = useLocation();

  // @ts-ignore
  const search = qs.parse(location.search) as T;

  const addParam = (key: string, value: string) => {
    const { pathname } = location;

    history.push({
      pathname,
      search: qs.stringify({
        ...(o || {}),
        ...search,
        [key]: value,
      }),
    });
  };

  const addParams = (no: { [key: string]: any }) => {
    const { pathname } = location;

    history.push({
      pathname,
      search: qs.stringify({
        ...(o || {}),
        ...search,
        ...no,
      }),
    });
  };

  return [
    search,
    addParam,
    addParams,
  ];
}
