import React from 'react';
import { createPortal } from 'react-dom';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { PhotoProps } from '_types';

import { ReactComponent as CloseIcon } from './Close.svg';

import styles from './PhotoGallery.module.css';

interface PhotoGalleryProps {
  open: boolean;
  selectedPhotoIndex: number | null;
  photos: PhotoProps[][];
  onClose: () => void;
}

const PhotoGallery = ({ open, photos, selectedPhotoIndex, onClose }: PhotoGalleryProps) => {
  if (!open) {
    return null;
  }

  const portalElement = document.getElementById('photo-gallery');

  const handlerClickOnGalleryClose = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (onClose) {
      onClose();
    }
  };

  if (portalElement && selectedPhotoIndex != null) {
    return createPortal(
      <div className={styles.modal}>
        <div
          className={styles.galleryBackdrop}
          onClick={handlerClickOnGalleryClose}
        />

        <div className={styles.carouselWrap}>
          <Carousel
            showThumbs={false}
            showStatus
            infiniteLoop={false}
            swipeable
            useKeyboardArrows
            transitionTime={500}
            showIndicators={false}
            selectedItem={selectedPhotoIndex}
            statusFormatter={(current, total) => `${current} / ${total}`}
          >
            {
              photos.flat().map((photo) => (
                <img src={photo.original} alt="" />
              ))
            }
          </Carousel>
        </div>

        <button className={styles.close} type="button" onClick={handlerClickOnGalleryClose}>
          <CloseIcon />
        </button>
      </div>,
      portalElement,
    );
  }

  return null;
};

export default PhotoGallery;
