import React from 'react';
import { makeStyles } from 'hooks/makeStyles';
import { darken } from 'utils/fade';
import clsx from 'clsx';
import { Button } from '../button/button';

export interface ToggleButtonProps {
  value: string
  selected?: boolean
  onChange?: (e: React.MouseEvent, value: string) => void
  disabled?: boolean
  children: React.ReactNode
}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    '&:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
    // "&:not(:first-child):not(:last-child)": {
    //   borderRadius: theme.sizes.defaultRadius
    // },
    // "&:first-child": {
    //   borderTopRightRadius: theme.sizes.defaultRadius,
    //   borderBottomRightRadius: theme.sizes.defaultRadius
    // },
    // "&:last-child": {
    //   borderTopLeftRadius: theme.sizes.defaultRadius,
    //   borderBottomLeftRadius: theme.sizes.defaultRadius
    // },
    '&$selected': {
      color: theme.colors.common.black,
      backgroundColor: theme.colors.grey.light,
      '&:hover': {
        backgroundColor: darken(theme.colors.grey.light, 0.2),
      },
    },
  },
  selected: {},
}));

export const ToggleButton = (props: ToggleButtonProps) => {
  const {
    value,
    selected,
    onChange,
    disabled,
    children,
    ...passthrough
  } = props;
  const css = useStyles();

  const cx = clsx(css.root, {
    [css.selected]: selected,
  });

  const handleClick = (e: React.MouseEvent) => {
    if (onChange) {
      onChange(e, value);
    }
  };

  return (
    <Button
      disabled={disabled}
      value={value}
      onClick={handleClick}
      className={cx}
      {...passthrough}
    >
      {children}
    </Button>
  );
};
