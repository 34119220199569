import { observable } from 'mobx';

class ModalManager {
  @observable modals: string[] = [];

  add(modalId: string) {
    if (this.modals.indexOf(modalId) === -1) {
      this.modals.push(modalId);
    }
  }

  pop() {
    this.modals.pop();
  }

  getCurrentModal() {
    return this.modals.length && this.modals[this.modals.length - 1];
  }

  isTopModal(modalId: string) {
    return (
      !!this.modals.length && this.modals[this.modals.length - 1] === modalId
    );
  }
}

export const modalManager = new ModalManager();
