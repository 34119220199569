import React from 'react';
import { makeStyles } from 'hooks/makeStyles';
import clsx from 'clsx';

interface AvatarProps {
  img?: string
  border?: boolean
  loading?: boolean
  className?: string
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    boxShadow: `0 0 0 2px ${theme.colors.blue.main}`,
    borderRadius: '50%',
    flexShrink: 0,
    '&:hover': {
      boxShadow: `0 0 0 2px ${theme.colors.blue.main}`,
    },
    '&$borderless': {
      boxShadow: '0 0 0 2px transparent',
      '&:hover': {
        boxShadow: `0 0 0 2px ${theme.colors.blue.main}`,
      },
    },
  },
  big: {
    width: 113,
    height: 113,
  },
  medium: {
    width: 96,
    height: 96,
  },
  avatar: {
    width: '100%',
    height: '100%',
  },
  borderless: {},
}));

export const Big = (props: AvatarProps) => {
  const {
    img, border, loading, className,
  } = props;
  const css = useStyles(props);

  return (
    <div
      className={clsx(css.container, className, css.big, {
        [css.borderless]: !border,
      })}
    >
      {!loading ? (
        <div
          className={css.avatar}
          style={{
            backgroundImage: `url(${img})`,
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            borderRadius: '50%',
          }}
        />
      ) : (
        'loading...'
      )}
    </div>
  );
};

export const Medium = (props: AvatarProps) => {
  const {
    img, border, loading, className,
  } = props;
  const css = useStyles(props);

  return (
    <div
      className={clsx(css.container, className, css.medium, {
        [css.borderless]: !border,
      })}
    >
      {!loading ? (
        <div
          className={css.avatar}
          style={{
            backgroundImage: `url(${img})`,
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            borderRadius: '50%',
          }}
        />
      ) : (
        'loading...'
      )}
    </div>
  );
};
