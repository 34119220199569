import React, { Fragment } from 'react';
import Media from 'react-media';
import classNames from 'classnames/bind';
import { Header } from 'components/header';
import { ServiceInfoList } from 'domains/services/components/service-info-list';
import styles from './services.module.css';

const cx = classNames.bind(styles);

export const ServicesPage = () => (
  <Fragment>
    <Media query="(max-width: 768px)">
      <Header
        title="Сервисы"
        path="/"
      />
    </Media>
    <div className={cx('services')}>
      <ServiceInfoList />
    </div>
  </Fragment>
);
