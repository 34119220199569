import { fade } from 'utils';
import { Theme } from 'lib/theme';

export const focusKeyframeMixin = (theme: Theme) => ({
  '@keyframes default-focus-pulse': {
    '0%': {
      boxShadow: `0 0 0 3px ${fade(theme.colors.grey.light, 0.5)}`,
    },
    '50%': { boxShadow: `0 0 0 3px ${fade(theme.colors.grey.light, 0.15)}` },
    '100%': {
      boxShadow: `0 0 0 3px ${fade(theme.colors.grey.light, 0.5)}`,
    },
  },
});

export const focusKeyframeAnimationMixin = '$default-focus-pulse 2s ease-in-out infinite';
