import React, { useEffect, useState, HTMLAttributes } from 'react';
import uniqueId from 'lodash/uniqueId';
import { makeStyles } from 'hooks/makeStyles';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { IconButton, Icons } from 'ui/shared';
import { Portal } from './portal';
import { BackDrop } from './backdrop';
import { TrapFocus } from './trap-focus';
import { modalManager } from '../../../global-stores/modal-manager';
import { AnimatedWrapper } from './animated';

export interface ModalProps extends HTMLAttributes<HTMLDivElement> {
  open: boolean
  onClose: (e?: React.MouseEvent | React.KeyboardEvent) => void
  onBackdropClick?: (e: React.MouseEvent) => void
  invisibleBackdrop?: boolean
  children: React.ReactElement
  withCloseButton?: boolean
  onCrossClick?: (e: React.MouseEvent) => void
}

const useStyles = makeStyles((theme) => ({
  dialog: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1200,
  },
  modalContainer: {
    width: '100%',
    height: '100%',
  },
  animatedWrapper: {
    width: 'auto',
    maxWidth: '60%',
    '&:focus': {
      outline: 'none',
    },
  },
  hidden: {
    display: 'none',
  },
  relative: {
    position: 'relative',
  },
  cross: {
    position: 'absolute',
    top: -28,
    right: -28,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  maxHeight: {
    maxHeight: '80vh',
  },
}));

export const Modal = observer((props: ModalProps) => {
  const [modalId] = useState(uniqueId('modal-'));
  const [visible, setVisible] = useState(false);

  const {
    open,
    onClose,
    onBackdropClick,
    invisibleBackdrop,
    withCloseButton = false,
    onCrossClick,
    children,
    ...passthrough
  } = props;
  const css = useStyles();

  function handleOpen(modalIdForOpen: string) {
    modalManager.add(modalIdForOpen);
    setVisible(true);
  }

  function handleClose() {
    modalManager.pop();
    setVisible(false);
  }

  useEffect(() => {
    if (open) {
      handleOpen(modalId);
    }

    return () => {
      if (open) {
        handleClose();
      }
    };
  }, [modalId, open]);

  const cx = clsx(css.dialog, {
    [css.hidden]: !open,
  });

  const handleBackdropClick = (e: React.MouseEvent) => {
    if (e.target !== e.currentTarget) {
      return;
    }

    if (onBackdropClick) {
      onBackdropClick(e);
    }

    if (onClose) {
      onClose(e);
    }
  };

  function handleKeyDown(e: React.KeyboardEvent) {
    if (e.key !== 'Escape' || !modalManager.isTopModal(modalId)) {
      return;
    }

    e.stopPropagation();

    if (onClose) {
      onClose(e);
    }
  }

  const handleCrossClick = (e: React.MouseEvent) => {
    if (onCrossClick) {
      onCrossClick(e);
    }

    if (onClose) {
      onClose(e);
    }
  };

  return (
    <Portal>
      <div
        key={modalId}
        role="dialog"
        aria-modal
        onKeyDown={handleKeyDown}
        className={cx}
      >
        <BackDrop
          open={visible}
          onClick={handleBackdropClick}
          invisible={invisibleBackdrop}
        />
        <TrapFocus
          key="trap"
          open={open}
          isActive={modalManager.isTopModal(modalId)}
        >
          <AnimatedWrapper
            open={visible}
            id={modalId}
            {...passthrough}
            className={clsx(children.props.className, css.relative)}
          >
            <>
              {withCloseButton ? (
                <IconButton
                  className={css.cross}
                  onClick={handleCrossClick}
                  data-cy="modal.closeButton"
                >
                  <Icons.Cross height={20} width={20} />
                </IconButton>
              ) : null}
              {React.cloneElement(children, { className: css.maxHeight })}
            </>
          </AnimatedWrapper>
        </TrapFocus>
      </div>
    </Portal>
  );
});
