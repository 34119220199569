import React from 'react';
import cn from 'classnames';

import styles from './Block.module.css';

interface Props {
  className?: string;
  children: React.ReactNode;
}

const Block = ({ className, children }: Props) => (
  <div className={cn(styles.block, className)}>
    {children}
  </div>
);

export default Block;
