import React, { useContext, useEffect } from 'react';
import { makeStyles } from 'hooks/makeStyles';
import { Theme } from 'lib/theme';
import {
  Redirect, Route, Switch, useLocation,
} from 'react-router-dom';

import { SignIn } from 'ui/pages/sign-in/sign-in';
import { SignUp } from 'ui/pages/sign-up/sign-up';
import { ProfessionalInfo } from 'ui/pages/sign-up/professional-info';
import AddPhoto from 'ui/pages/sign-up/add-photo';
import SocialNetworks from 'ui/pages/sign-up/social-networks';
import AboutMe from 'ui/pages/sign-up/about-me';
import { ProfileRoutes } from 'ui/pages/profile/routes';

import { observer } from 'mobx-react';
import Objects from 'ui/pages/objects/Objects';

import { Support } from 'ui/pages/support/support';
import { Analytics } from 'ui/pages/analytics/analytics';

import Sidebar from '../../sidebar/sidebar';
import { ApplicationContext } from '../../../../global-stores/application-context';

import logo from '../../../../assets/svg/footer-logo.svg';
import { Background } from '../../background/background';
import { ServicesRoutes } from 'domains/services/routes';
import { Routes as DocsRouteConstants } from 'domains/docs/constants';
import { DocsRoutes } from 'domains/docs/routes';
import { getShortPhoneNumber } from '_helpers/get-short-phone-number';
import { useParams } from 'ui/pages/objects/_helpers/add-params';
import { ServerDownPage } from 'domains/server-down/pages/server-down';

const useStyles = makeStyles((theme: Theme) => ({
  signWrap: {
    display: 'flex',
    padding: '56px 16px',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  '@global': {
    body: {
      margin: 0,
      padding: 0,
    },
    html: {
      fontSize: '10px',
    },
  },
  appContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.background.paper.footer,
    fontSize: 10,
    color: theme.colors.textSecondary,
    zIndex: 9999,
  },
  footerLogo: {
    width: 10,
    height: 10,
    marginLeft: theme.spacing(2),
  },
  whiteBackground: {
    background: theme.colors.background.paper.default,
  },
}));

export const MobileLayout = observer(() => {
  const css = useStyles();
  const location = useLocation();
  const applicationContext = useContext(ApplicationContext);
  const isSingInPage = location.pathname.startsWith('/signin');
  const isDocsPage = location.pathname.startsWith('/docs');

  const [params] = useParams<any>();

  useEffect(() => {
    const authParamsKey = 'auth-params';

    // eslint-disable-next-line
    const { first_name, last_name, phone, email } = params;

    if (typeof phone === 'undefined' || getShortPhoneNumber(phone) === '') {
      localStorage.removeItem(authParamsKey);
      return;
    }

    const shortPhoneNumber = getShortPhoneNumber(phone);

    const haveAuthParams = [first_name, last_name, shortPhoneNumber, email].every(Boolean);

    if (haveAuthParams) {
      localStorage.setItem(authParamsKey, JSON.stringify({
        first_name, last_name, phone: shortPhoneNumber, email,
      }));
    }
  }, [params]);

  if (applicationContext.serverIsNotAvailable) {
    return (
      <ServerDownPage />
    );
  }

  if (!(isSingInPage || isDocsPage) && !applicationContext.isAuthenticated) {
    return <Redirect to="/signin" />;
  }

  return (
    <>
      <React.Suspense fallback={null}>
        {applicationContext.isRegistered ? (
          <>
            <Switch>
              <Route
                exact
                path="/"
                component={Sidebar}
              />

              <Route
                path="/profile"
                component={ProfileRoutes}
              />

              <Route
                path="/analytics"
                component={Analytics}
              />

              <Route
                path="/services"
                component={ServicesRoutes}
              />

              <Route
                path="/support"
                component={Support}
              />

              <Route
                path="/objects"
                component={Objects}
              />

              <Route
                path={DocsRouteConstants.DOCS_HOME}
                component={DocsRoutes}
              />

              <Route path="/" render={(props) => <Redirect to="/" {...props} />} />
            </Switch>

            <footer className={css.footer}>
              Powered by Reagent <img src={logo} className={css.footerLogo} alt="Reagent logo" />
            </footer>
          </>
        ) : (
          <Switch>
            <Route exact path="/signin" component={SignIn} />
            <div>
              <Route exact path="/signup" component={SignUp} />
              <Route exact path="/signup/information" component={ProfessionalInfo} />
              <Route exact path="/signup/photo" component={AddPhoto} />
              <Route exact path="/signup/social" component={SocialNetworks} />
              <Route exact path="/signup/aboutme" component={AboutMe} />

              <Route
                path={DocsRouteConstants.DOCS_HOME}
                component={DocsRoutes}
              />

              {!isDocsPage && (
                <Background />
              )}
            </div>
            <Route>
              <div>Not found</div>
            </Route>
          </Switch>
        )}
      </React.Suspense>
    </>
  );
});
