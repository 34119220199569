import React from 'react';

import cn from 'classnames';

import { ReactComponent as ArrowIcon } from 'assets/svg/Arrow.svg';

import styles from './Button.module.css';

interface General {
  className?: string;
  children: React.ReactNode;
  width?: 'wider';
  theme?: 'black' | 'red' | 'black-full' | 'red-full' | 'blue' | 'blue-full';
  arrow?: boolean;
  collapse?: boolean;
}

interface ButtonProps extends General {
  component: 'button';
  submit?: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

interface LinkProps extends General {
  component: 'link';
  href?: string;
  target?: '_blank';
}

type Props =
  | LinkProps
  | ButtonProps;

const Button = (props: Props) => {
  const {
    children, theme, arrow = false, collapse, width, className,
  } = props;

  const classList = cn(styles.button, {
    [styles.buttonThemeBlack]: theme === 'black',
    [styles.buttonThemeBlackFull]: theme === 'black-full',
    [styles.buttonThemeRed]: theme === 'red',
    [styles.buttonThemeRedFull]: theme === 'red-full',
    [styles.buttonThemeBlue]: theme === 'blue',
    [styles.buttonThemeBlueFull]: theme === 'blue-full',
    [styles.widthThemeWider]: width === 'wider',
  }, className);

  if (props.component === 'button') {
    return (
      <button
        className={classList}
        type={props.submit ? 'submit' : 'button'}
        onClick={props.onClick}
        disabled={props.disabled}
      >
        {children}

        {
          arrow && (
            <ArrowIcon
              className={`
                ${styles.arrow}
                ${collapse && styles.arrowCollapsed}
              `}
            />
          )
        }
      </button>
    );
  }

  return (
    <a
      className={classList}
      href={props.href || '#'}
      target={props.target}
    >
      {children}

      {
        arrow && (
          <ArrowIcon
            className={`
              ${styles.arrow}
              ${collapse && styles.arrowCollapsed}
            `}
          />
        )
      }
    </a>
  );
};

export default Button;
