import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import { ReactComponent as BackIcon } from './back.svg';
import { HeaderProps } from './types';
import styles from './header.module.css';

const cx = classNames.bind(styles);

export const Header = ({ title, path, children }: HeaderProps) => (
  <div className={cx('header')}>
    <div className={cx('wrap')}>
      <Link className={cx('back')} to={path}>
        <BackIcon />
      </Link>

      <h1 className={cx('title')}>
        {title}
      </h1>

      <div className={cx('content')}>
        {children}
      </div>
    </div>
  </div>
);
