import React, { useContext, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import classNames from 'classnames/bind';
import { ApplicationContext } from 'global-stores/application-context';
import { Text } from 'components/text';
import Logo from 'domains/docs/assets/urban-agent-logo.png';
import Logo2x from 'domains/docs/assets/urban-agent-logo@2x.png';
import Logo3x from 'domains/docs/assets/urban-agent-logo@3x.png';
import styles from 'domains/docs/docs.module.css';

const cx = classNames.bind(styles);

export const PublicOfferPage = () => {
  const { isAuthenticated } = useContext(ApplicationContext);

  return (
    <Fragment>
      <Helmet>
        <title>Публичная оферта на заключение договора оказания услуг посредством сервиса reagent.pro</title>
      </Helmet>

      <section className={cx('page')}>
        {!isAuthenticated && (
          <header className={cx('header')}>
            <a href="/">
              <picture>
                <source srcSet={`${Logo2x} 2x, ${Logo3x} 3x`} />
                <img src={Logo} width={256} height={60} alt="" />
              </picture>
            </a>
          </header>
        )}

        <h1 className={cx('title')}>
          ПУБЛИЧНАЯ ОФЕРТА НА&nbsp;ЗАКЛЮЧЕНИЕ ДОГОВОРА ОКАЗАНИЯ УСЛУГ ПОСРЕДСТВОМ СЕРВИСА REAGENT.PRO
        </h1>

        <Text as="p" size="m">
          Настоящим ООО &laquo;РЕАГЕНТ.ПРО&raquo; в&nbsp;лице генерального директора Иволина Андрея Михайловича, действующего на&nbsp;основании Устава (далее&nbsp;&mdash; Исполнитель) предлагает любому дееспособному физическому лицу (далее&nbsp;&mdash; Заказчик), использовать услуги, предоставляемые на&nbsp;сайте reagent.pro (далее&nbsp;&mdash; Сервис).
        </Text>

        <Text as="p" size="m">
          В&nbsp;соответствии с&nbsp;настоящей публичной офертой Исполнитель предлагает Заказчику услуги по&nbsp;использованию Сервиса в&nbsp;качестве закрытого информационного портала о&nbsp;недвижимости Российской Федерации и&nbsp;зарубежных стран в&nbsp;коммерческих целях и&nbsp;не&nbsp;коммерческих целях.
        </Text>

        <Text as="p" size="m">
          Настоящий договор признается публичной офертой. В&nbsp;соответствии со&nbsp;статьей 438 Гражданского Кодекса Российской Федерации (ГК&nbsp;РФ), безусловным принятием (акцептом) условий настоящей публичной оферты считается осуществление Заказчиком регистрации в&nbsp;Сервисе путем заполнения регистрационной формы и&nbsp;выражения согласия с&nbsp;условиями настоящей публичной оферты, а&nbsp;также использование функционала Сервиса.
        </Text>

        <Text as="p" size="m">
          Настоящая публичная оферта, заключаемая путем акцепта, не&nbsp;требует двустороннего подписания и&nbsp;действительна в&nbsp;электронном виде. Использование Сервиса Заказчиком означает, что Заказчик принимает и&nbsp;обязуется соблюдать все нижеприведенные условия публичной оферты.
        </Text>

        <Text as="p" size="m">
          СТОРОНЫ ЗАКЛЮЧАЮТ ПУБЛИЧНУЮ ОФЕРТУ НА&nbsp;СЛЕДУЮЩИХ УСЛОВИЯХ:
        </Text>

        <ol className={cx('list')}>
          <li>
            Термины и&nbsp;определения

            <Text as="p" size="m">
              Сервис&nbsp;&mdash; это идентифицированное место в&nbsp;сети Интернет (виртуальном пространстве), в&nbsp;котором располагается информация, оформленная и&nbsp;структурированная определенным образом. Для целей настоящей Оферты Сервисом признается сайт, расположенный в&nbsp;сети Интернет по&nbsp;адресу: reagent.pro, а&nbsp;также его производные.
            </Text>

            <Text as="p" size="m">
              Сервис является закрытым информационным порталом и&nbsp;предназначен для использования исключительно для зарегистрированных пользователей.
            </Text>

            <Text as="p" size="m">
              Заказчик&nbsp;&mdash; зарегистрированный пользователь Сервиса, который прошел процедуру регистрации в&nbsp;Сервисе, исключительно способом, регламентированным Пользовательским соглашением Сервиса.
            </Text>

            <Text as="p" size="m">
              Личный кабинет&nbsp;&mdash; совокупность защищенных страниц в&nbsp;Сервисе, создаваемых при регистрации Заказчика, на&nbsp;которых Заказчик может использовать функционал Сервиса.
            </Text>

            <Text as="p" size="m">
              Доступ к&nbsp;Личному кабинету осуществляется Заказчиком посредством ввода Учетной информации.
            </Text>

            <Text as="p" size="m">
              Услуги&nbsp;&mdash; предоставляемые Исполнителем Услуги посредством Сервиса.
            </Text>

            <Text as="p" size="m">
              Учетная запись/профиль&nbsp;&mdash; контактный номер мобильного телефона Пользователя и&nbsp;приходящее на&nbsp;него сообщение с&nbsp;цифровым кодом для входа в&nbsp;Личный кабинет.
            </Text>

            <Text as="p" size="m">
              Исполнитель&nbsp;&mdash; ООО &laquo;РЕАГЕНТ.ПРО&raquo;, ИНН 2320232357, КПП 232001001 , ОГРН 1152366007089. 354000, Краснодарский край, город Сочи, улица Навагинская (Центральный Р-Н), дом&nbsp;9д, офис 427. Исполнитель является правообладателем исключительных прав на&nbsp;Сервис.
            </Text>

            <Text as="p" size="m">
              Оферта&nbsp;&mdash; настоящий публичный договор, который содержит перечень норм и&nbsp;правил, на&nbsp;основании которых функционирует настоящий Сервис, а&nbsp;также происходят все процессы взаимоотношений между Исполнителем и&nbsp;Заказчиком.
            </Text>
          </li>
          <li>
            Предмет Оферты
            <ol className={cx('list')}>
              <li>
                В&nbsp;рамках настоящей Оферты Исполнитель, с&nbsp;момента акцепта Заказчиком настоящей Оферты, предоставляет Заказчику возможность доступа к&nbsp;Личному кабинету для дальнейшего пользования Сервисом и&nbsp;заказа Услуг.
              </li>
              <li>
                Исполнитель предоставляет Заказчику следующие виды Услуг:
                <ol className={cx('list')}>
                  <li>
                    Услуги доступа к&nbsp;базе недвижимости с&nbsp;целью поиска и&nbsp;просмотра информации о&nbsp;новостройках и&nbsp;квартирах в&nbsp;них.
                  </li>
                  <li>
                    Услуги разработки коммерческого предложения для своих клиентов посредством Сервиса. Заказчик имеет возможность создать подборку из&nbsp;объектов недвижимости и&nbsp;отправить активную ссылку клиенту. По&nbsp;ссылке будет доступен интерфейс для просмотра информации об&nbsp;объектах и&nbsp;контакты Заказчика.
                  </li>
                  <li>
                    Услуги по&nbsp;формирования информации об&nbsp;объектах недвижимости.
                  </li>
                  <li>
                    Другие виды Услуг, доступные Заказчику на&nbsp;момент акцепта настоящей Оферты.
                  </li>
                </ol>
              </li>
              <li>
                Заказчик берет на&nbsp;себя обязательства по&nbsp;оплате Услуг, согласно выбранному тарифу, описание и&nbsp;стоимость которого указана в&nbsp;Сервисе.
              </li>
              <li>
                Исполнитель обязуется надлежащим образом и&nbsp;в&nbsp;срок оказать Заказчику Услуги.
              </li>
              <li>
                Исполнитель обязуется предоставить Заказчику возможность использовать Сервис на&nbsp;условиях, описанных в&nbsp;настоящей Оферте.
              </li>
              <li>
                Для полноценного использования Сервиса и&nbsp;заказа Услуг, Заказчику необходимо произвести регистрацию в&nbsp;Сервисе и&nbsp;оплату Услуг, согласно выбранному тарифу.
              </li>
              <li>
                Оплата Заказчиком оказанных и&nbsp;принятых Услуг производится в&nbsp;порядке и&nbsp;на&nbsp;условиях, предусмотренных настоящей Офертой.
              </li>
              <li>
                Услуги оказываются Исполнителем удаленно посредством Сервиса.
              </li>
            </ol>
          </li>
          <li>
            Акцепт Оферты
            <ol className={cx('list')}>
              <li>
                Акцептом настоящей Оферты Заказчиком является:
                <ol className={cx('list')}>
                  <li>
                    регистрация Заказчика в&nbsp;Сервисе и&nbsp;создание Учетной записи;
                  </li>
                  <li>
                    осуществление оплаты за&nbsp;Услуги способом, предусмотренным настоящей Офертой и&nbsp;функциональными возможностями Сервиса;
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            Условия и&nbsp;порядок оказания Услуг
            <ol className={cx('list')}>
              <li>
                После совершения Заказчиком акцепта настоящей Оферты, Заказчику доступен минимальный бесплатный функционал предоставления Услуг Сервиса на&nbsp;момент оферты.
              </li>
              <li>
                Заказчик не&nbsp;имеет права регистрировать несколько Учетных записей в&nbsp;Сервисе. В&nbsp;случае выявления Исполнителем нарушения настоящего условия, Исполнитель имеет право заблокировать Учетную запись Заказчика для входа в&nbsp;Личный кабинет.
              </li>
              <li>
                После осуществления Заказчиком оплаты Стоимости Услуг согласно выбранному тарифу, Заказчику доступны Услуги Сервиса в&nbsp;полном объеме. В&nbsp;случае не&nbsp;предоставления Заказчиком мотивированного отказа от&nbsp;приемки Услуг в&nbsp;течение&nbsp;2 (двух) дней с&nbsp;момента окончания каждого календарного месяца оказания Услуг, такие Услуги считаются оказанными Исполнителем в&nbsp;полном объеме и&nbsp;принятыми Заказчиком.
              </li>
              <li>
                Исполнитель оставляет за&nbsp;собой право в&nbsp;любой момент потребовать от&nbsp;Заказчика подтверждения данных, указанных им&nbsp;при регистрации или при последующем использовании Сервиса. В&nbsp;случае если Заказчик их&nbsp;не&nbsp;предоставит, то&nbsp;Исполнитель может расценивать это как предоставление недостоверной информации Заказчиком с&nbsp;последующей блокировкой Учетной записи Заказчика для входа в&nbsp;Личный кабинет.
              </li>
            </ol>
          </li>
          <li>
            Права и&nbsp;обязательства Сторон
            <ol className={cx('list')}>
              <li>
                Исполнитель обязуется:
                <ol className={cx('list')}>
                  <li>
                    Качественно и&nbsp;в&nbsp;полном объеме оказать Услуги в&nbsp;соответствии с&nbsp;условиями настоящей Оферты.
                  </li>
                  <li>
                    Предоставлять консультации и&nbsp;техническую поддержку на&nbsp;протяжении действия настоящей Оферты.
                  </li>
                  <li>
                    Предоставить Заказчику доступ к&nbsp;Сервису и&nbsp;Личному кабинету после акцепта настоящей Оферты и&nbsp;выполнения условий по&nbsp;оплате Услуг.
                  </li>
                  <li>
                    Предоставить Заказчику актуальную информацию о&nbsp;тарифах на&nbsp;Услуги, а&nbsp;также описание Услуг.
                  </li>
                  <li>
                    Сохранять конфиденциальность информации и&nbsp;коммерческую тайну, ставшую известной в&nbsp;результате исполнения настоящей Оферты.
                  </li>
                  <li>
                    Своевременно напоминать Заказчику об&nbsp;истечении срока оказания Услуг Сервиса, оплаченного Заказчиком.
                  </li>
                  <li>
                    Оказывать Услуги методами, незапрещенными действующим законодательством Российской Федерации.
                  </li>
                </ol>
              </li>
              <li>
                Исполнитель имеет право:
                <ol className={cx('list')}>
                  <li>
                    Изменять условия настоящей Оферты и&nbsp;стоимость Услуг в&nbsp;одностороннем порядке, уведомив об&nbsp;этом Заказчика путем публикации новой версии Оферты в&nbsp;Сервисе.
                  </li>
                  <li>
                    Приостановить оказание Услуг в&nbsp;случае не&nbsp;осуществления Заказчиком оплаты согласно условиям настоящей Оферты и&nbsp;выбранного тарифа.
                  </li>
                  <li>
                    Требовать от&nbsp;Заказчика оплаты за&nbsp;Услуги согласно выбранному тарифу.
                  </li>
                  <li>
                    Осуществлять рассылку информационных писем Заказчику посредством Сервиса или электронной почты.
                  </li>
                </ol>
              </li>
              <li>
                Заказчик обязуется:
                <ol className={cx('list')}>
                  <li>
                    Своевременно производить оплату Услуг в&nbsp;порядке и&nbsp;на&nbsp;условиях предусмотренных Офертой.
                  </li>
                  <li>
                    Полностью ознакомиться с&nbsp;Офертой до&nbsp;момента прохождения процедуры регистрации в&nbsp;Сервисе. Использование Сервиса означает полное и&nbsp;безоговорочное принятие Заказчиком настоящей Оферты в&nbsp;соответствии с&nbsp;нормами действующего законодательства Российской Федерации.
                  </li>
                  <li>
                    Не&nbsp;реже, чем раз в&nbsp;два месяца знакомиться с&nbsp;условиями настоящей Офертой и&nbsp;стоимостью на&nbsp;Услуги.
                  </li>
                  <li>
                    Не&nbsp;использовать какое-либо оборудование, устройства, программное обеспечение или иные способы с&nbsp;целью вмешательства в&nbsp;деятельность Исполнителя в&nbsp;процессе оказания Услуг.
                  </li>
                  <li>
                    Использовать Сервис только по&nbsp;его прямому функциональному назначению.
                  </li>
                  <li>
                    Сохранять конфиденциальность информации и&nbsp;коммерческую тайну, ставшую известной в&nbsp;результате исполнения настоящей Оферты.
                  </li>
                </ol>
              </li>
              <li>
                Заказчик имеет право:
                <ol className={cx('list')}>
                  <li>
                    Требовать от&nbsp;Исполнителя надлежащего выполнения условий настоящей Оферты.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            Стоимость Услуг и&nbsp;порядок оплаты
            <ol className={cx('list')}>
              <li>
                Стоимость и&nbsp;срок оказания Услуг, которые оказывает Исполнитель Заказчику по&nbsp;настоящей Оферте, указываются в&nbsp;Сервисе в&nbsp;зависимости от&nbsp;выбранного тарифа.
              </li>
              <li>
                Стоимость Услуг актуальна на&nbsp;момент акцепта настоящей Оферты.
              </li>
              <li>
                После выбора необходимых Услуг, Заказчику посредством Сервиса формируется счет на&nbsp;оплату с&nbsp;указанием суммарной стоимости выбранных Услуг.
              </li>
              <li>
                Оплата Услуг производится Заказчиком способом, указанным в&nbsp;Сервисе (в&nbsp;том числе посредством функциональных возможностей платежных сервисов).
              </li>
              <li>
                После оплаты Услуг, Заказчику предоставляется возможность использовать Услуги Сервиса.
              </li>
              <li>
                Согласием Заказчика с&nbsp;новыми ценами Услуг является факт оплаты таких Услуг с&nbsp;учетом измененных цен. При несогласии Заказчика с&nbsp;новыми ценами Заказчик вправе отказаться от&nbsp;оплаты Услуг. При этом Оферта между Заказчиком и&nbsp;Исполнителем считается расторгнутым и&nbsp;теряет юридическую силу.
              </li>
              <li>
                В&nbsp;случае расторжения Оферты Заказчиком неистраченные денежные средства, остающиеся на&nbsp;дату расторжения, Заказчику не&nbsp;возвращаются.
              </li>
              <li>
                Услуги Сервиса НДС не&nbsp;облагаются (п.2, ст.346.11, гл.26.2&nbsp;НК РФ).
              </li>
              <li>
                Расчет между Сторонами производится посредством функциональных возможностей Сервиса в&nbsp;рублях Российской Федерации.
              </li>
            </ol>
          </li>
          <li>
            Срок действия, порядок изменения и&nbsp;расторжения Оферты
            <ol className={cx('list')}>
              <li>
                Оферта вступает в&nbsp;силу с&nbsp;момента принятия его условий Заказчиком (акцепта Оферты), в&nbsp;порядке, установленном настоящей Офертой, и&nbsp;действует до&nbsp;момента отзыва Оферты Исполнителем.
              </li>
              <li>
                Оферта может быть расторгнут Исполнителем или Заказчиком в&nbsp;любое время в&nbsp;одностороннем порядке.
                <ol className={cx('list')}>
                  <li>
                    Исполнитель имеет право отказать в&nbsp;предоставлении услуг до&nbsp;внесения Заказчиком оплаты за&nbsp;Услуги.
                  </li>
                  <li>
                    Исполнитель имеет право в&nbsp;одностороннем порядке отказать Заказчику в&nbsp;предоставлении Услуги при условии надлежащего обоснования данного отказа в&nbsp;следующих случаях:<br />
                    &mdash;&nbsp;предоставление Услуги может создать угрозу безопасности и&nbsp;здоровью людей;<br />
                    &mdash;&nbsp;предоставление Услуги невозможно ввиду каких-либо технических, физических или иных естественных препятствий.
                  </li>
                  <li>
                    Исполнитель имеет право прекратить предоставление Услуг Заказчику, с&nbsp;расторжением Оферты в&nbsp;одностороннем порядке, в&nbsp;случаях:<br />
                    &mdash;&nbsp;нарушения Заказчиком условий настоящей Оферты и&nbsp;других приложений к&nbsp;ней;<br />
                    &mdash;&nbsp;любых действий Заказчика, повлекших за&nbsp;собой сбой в&nbsp;работе Сервиса;<br />
                    &mdash;&nbsp;нарушения Заказчиком Пользовательского соглашения и&nbsp;других приложений к&nbsp;настоящей Оферте;<br />
                    &mdash;&nbsp;использования получаемых услуг в&nbsp;незаконных целях.
                  </li>
                </ol>
              </li>
              <li>
                По&nbsp;всем вопросам, не&nbsp;урегулированным в&nbsp;настоящей Оферте, Стороны руководствуются действующим законодательством Российской Федерации.
              </li>
            </ol>
          </li>
          <li>
            Ответственность
            <ol className={cx('list')}>
              <li>
                Стороны несут ответственность за&nbsp;неисполнение или ненадлежащее исполнение своих обязательств по&nbsp;настоящей Оферте в&nbsp;соответствии с&nbsp;ее&nbsp;условиями, а&nbsp;в&nbsp;части, не&nbsp;урегулированной Офертой&nbsp;&mdash; в&nbsp;соответствии с&nbsp;действующим законодательством Российской Федерации.
              </li>
              <li>
                В&nbsp;связи с&nbsp;использованием компьютерного и&nbsp;иного оборудования, каналов связи и/или программ для ЭВМ, принадлежащих третьим лицам, при оказании Услуг, Стороны соглашаются с&nbsp;тем, что Исполнитель не&nbsp;несет ответственность за&nbsp;любые задержки, прерывания, прямой и&nbsp;косвенный ущерб или потери, происходящие из&nbsp;за&nbsp;дефектов в&nbsp;любом электронном или механическом оборудовании и/или программах для ЭВМ, либо вследствие иных объективных технологических причин, а&nbsp;также в&nbsp;результате действий или бездействий третьих лиц, проблем при передаче данных или соединении, перебоев в&nbsp;электропитании, произошедшие не&nbsp;по&nbsp;вине Исполнителя.
              </li>
              <li>
                Ответственность Исполнителя за&nbsp;неисполнение обязательств по&nbsp;настоящей Оферте не&nbsp;может превышать размер ежемесячной оплаты Заказчиком за&nbsp;Услуги по&nbsp;настоящей Оферте.
              </li>
              <li>
                В&nbsp;случае нарушения Заказчиком сроков оплаты Услуг, Исполнитель имеет право в&nbsp;одностороннем порядке приостановить оказание Услуг до&nbsp;момента выполнения Заказчиком своих финансовых обязательств или заблокировать Учетную запись для входа в&nbsp;Личный кабинет.
              </li>
            </ol>
          </li>
          <li>
            Форс &mdash;мажор
            <ol className={cx('list')}>
              <li>
                Стороны освобождаются от&nbsp;ответственности за&nbsp;частичное или полное неисполнение обязательств по&nbsp;настоящей Оферте, если такое неисполнение явилось следствием действия обстоятельств непреодолимой силы, возникших после заключения Оферты, которые Стороны не&nbsp;могли предвидеть или предотвратить.
              </li>
              <li>
                К&nbsp;обстоятельствам непреодолимой силы Стороны относят исключительно следующие события, делающие исполнение соответствующей Стороной обязательств невозможным: землетрясения, наводнения, иные стихийные бедствия, пожары, ядерные и&nbsp;иные промышленные аварии, а&nbsp;также забастовки, военные действия, гражданские беспорядки либо акты государственных органов, препятствующие выполнению условий Оферты. Все остальные препятствия независимо от&nbsp;их&nbsp;природы или характеристик непреодолимой силой не&nbsp;считаются, за&nbsp;исключением тех препятствий, которые специально будут признаны Сторонами как вызванные действием обстоятельств непреодолимой силы.
              </li>
              <li>
                Сторона, ссылающаяся на&nbsp;обстоятельства непреодолимой силы, обязана незамедлительно информировать другую Сторону о&nbsp;наступлении и&nbsp;прекращении подобных обстоятельств и&nbsp;об&nbsp;их&nbsp;влиянии на&nbsp;возможность исполнения соответствующей Стороной обязательств по&nbsp;настоящей Оферте в&nbsp;письменном виде.
              </li>
              <li>
                Действие непреодолимой силы отодвигает исполнение обязательств на&nbsp;срок, в&nbsp;течение которого имеет место такое действие. По&nbsp;прекращению действия обстоятельств непреодолимой силы Стороны обязаны немедленно приступить к&nbsp;исполнению своих обязательств.
              </li>
              <li>
                В&nbsp;случае наступления форс-мажорных обстоятельств Российская торгово-промышленная палата будет призвана подтвердить наличие этих обстоятельств и&nbsp;их&nbsp;продолжительность.
              </li>
            </ol>
          </li>
          <li>
            Арбитражная оговорка
            <ol className={cx('list')}>
              <li>
                В&nbsp;случае возникновения споров по&nbsp;вопросам, предусмотренным настоящей Офертой или в&nbsp;связи с&nbsp;ним, Стороны примут все меры к&nbsp;их&nbsp;разрешению путем переговоров.
              </li>
              <li>
                В&nbsp;случае невозможности разрешения указанных споров путем переговоров они будут разрешаться в&nbsp;соответствии с&nbsp;действующим законодательством Российской Федерации.
              </li>
              <li>
                Все имущественные споры между Сторонами, по&nbsp;которым не&nbsp;было достигнуто соглашение, разрешаются в&nbsp;органах судебной власти по&nbsp;местонахождению Исполнителя.
              </li>
            </ol>
          </li>
          <li>
            Исключительное право
            <ol className={cx('list')}>
              <li>
                Заказчик признает, что программное обеспечение Сервиса, программные коды, установка и&nbsp;настройка которых производится для оказания Услуг по&nbsp;настоящей Оферте, является собственной разработкой Исполнителя.
              </li>
              <li>
                Заказчик не&nbsp;вправе модифицировать программное обеспечение, Сервис, программные коды и&nbsp;производить их&nbsp;обратное проектирование, под которым подразумевается декомпилирование программного обеспечения с&nbsp;целью определения структуры, конструкции, концепции и&nbsp;методологии по&nbsp;предоставлению Услуг.
              </li>
              <li>
                Заказчик вправе только использовать функционал предоставляемого Исполнителем программного обеспечения, Сервиса по&nbsp;его прямому назначению для целей настоящей Оферты.
              </li>
              <li>
                В&nbsp;случае нарушения Заказчиком прав Исполнителя, указанных в&nbsp;настоящем разделе Оферты, Заказчик несёт ответственность в&nbsp;соответствии с&nbsp;действующим законодательством Российской Федерации.
              </li>
            </ol>
          </li>
          <li>
            Заключительные положения
            <ol className={cx('list')}>
              <li>
                Исполнитель не&nbsp;дает гарантий и&nbsp;не&nbsp;несет ответственности за&nbsp;несоответствие оказанных Услуг конкретным целям и/или ожиданиям Заказчика. Исполнитель имеет право в&nbsp;одностороннем порядке лишить Заказчика дотсупа к&nbsp;платным или бесплатным функциям, предварительно уведомив его об&nbsp;этом посредством Сервиса или контактной информации оставленной о&nbsp;себе в&nbsp;Личном кабинете или по&nbsp;email.
              </li>
              <li>
                Заказчик не&nbsp;вправе передавать (уступать) любой третьей стороне свои права и/или обязанности по&nbsp;настоящей Оферте без предварительного письменного согласия Исполнителя.
              </li>
              <li>
                В&nbsp;случае если любое из&nbsp;условий и/или положений настоящей Оферты будет признано недействительным, то&nbsp;это не&nbsp;повлечет за&nbsp;собой недействительность других его условий/положений и&nbsp;настоящей Оферты и&nbsp;ее&nbsp;исполнение в&nbsp;целом, как если&nbsp;бы такое условие никогда не&nbsp;было частью настоящей Оферты.
              </li>
              <li>
                Настоящая Оферта представляет собой полную договорённость между Заказчиком и&nbsp;Исполнителем. Исполнитель не&nbsp;принимает на&nbsp;себя никаких условий и&nbsp;обязательств в&nbsp;отношении предмета Оферты, за&nbsp;исключением указанных в&nbsp;Оферте.
              </li>
              <li>
                Настоящая Оферта, составлена на&nbsp;русском языке, является публичным договором, содержит все существенные условия договора оказания услуг посредством Сервиса и&nbsp;адресована неопределенному кругу лиц.
              </li>
            </ol>
          </li>
        </ol>

        <Text as="p" size="m">
          Дата публикации: 01.02.2021г.
        </Text>
        <br />
        <Text as="p" size="m">
          Исполнитель:<br />
          ООО &laquo;РЕАГЕНТ.ПРО&raquo;<br />
          Юр.адрес: 354000, Краснодарский край, город Сочи, улица Навагинская (Центральный Р-Н), дом&nbsp;9д, офис 427<br />
          Факт.адрес: 354000, Краснодарский край, город Сочи, улица Навагинская (Центральный Р-Н), дом&nbsp;9д, офис 427<br />
          ИНН 2320232357, КПП 232001001<br />
          В&nbsp;Филиале &laquo;РОСТОВСКИЙ&raquo; АО&nbsp;&laquo;АЛЬФА-БАНК&raquo;<br />
          БИК 046015207
        </Text>
      </section>
    </Fragment>
  );
};
