import React, { useState } from 'react';
import YouTube from 'react-youtube';

import styles from './VideoSection.module.css';

import Vimeo from '@u-wave/react-vimeo';
import { fullUrl } from '_helpers';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

interface VideoSectionProps {
  videoUrl: any;
}

const VideoSection = ({ videoUrl }: VideoSectionProps) => {
  const [vimeoError, setVimeoError] = useState(null);

  /*eslint-disable */
  const youtubeRegExp = /\/\/(?:www\.)?youtu(?:\.be|be\.com)\/(?:watch\?v=|embed\/)?([a-z0-9_\-]+)/i;
  const vimeoRegExp = /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i;

  if (videoUrl.match(youtubeRegExp)) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = videoUrl.match(regExp);

    return (
      <YouTube
        className={cx('presentation-block-section', 'presentation-block-area-youtube')}
        videoId={match && match[2].length === 11 ? match[2] : undefined}
      />
    );
  } else if (vimeoRegExp && !vimeoError) {
    return (
    <div className={cx('presentation-block-section')}>
      <Vimeo
        video={videoUrl}
        className={cx('presentation-block-area-vimeo')}
        responsive
        onError={(error: any) => {
          setVimeoError(error);
        }}
      />
    </div>
    );
  } else {
    return (
      <div className={cx('presentation-block-section')}>
        <div className={cx('presentation-block-area-youtube')}>
          <a
            className={styles.videoLink}
            href={fullUrl(videoUrl)}
            target="_blank"
            rel="noreferrer"
          >
            {videoUrl}
          </a>
        </div>
      </div>
    );
  }
};

export default VideoSection;
/*eslint-enable */
