import React, { useState } from 'react';
import { Theme } from 'lib/theme';
import { makeStyles } from 'hooks/makeStyles';
import { useTheme } from 'react-jss';
import clsx from 'clsx';

import { Typography, Icons, IconButton } from 'ui/shared';
import { fade } from 'utils';

interface AlertProps {
  className?: string
  children?: React.ReactNode
  success?: boolean;
  warning?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    backgroundColor: fade(theme.colors.red.secondary, 0.05),
    padding: `${theme.spacing(3.25)} ${theme.spacing(5)} ${theme.spacing(4.75)} ${theme.spacing(5)}`,
    borderRadius: 20,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 'auto',
    fontFamily: `${theme.fonts.semibold}, sans-serif`,
    '&:not(:last-child)': {
      marginBottom: 20,
    },
    '&:not(:first-child)': {
      marginTop: 20,
    },
    '& a': {
      fontWeight: '600',
      color: theme.colors.blue.main,
    },
  },
  wrapper: {
    display: 'inline-flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(3.75),
  },
  text: {
    fontSize: 15,
    color: '#4e4e4e',
    maxWidth: '80%',
    fontFamily: 'inherit',
  },
  button: {
    '&:focus, &:hover': {
      backgroundColor: 'transparent',
    },
  },
  cross: {},
  hidden: {
    display: 'none',
  },
  success: {
    backgroundColor: 'rgba(65, 216, 149, 0.3)',
  },
  warning: {
    backgroundColor: 'rgba(255,208,138,0.3)',
  },
}));

export const Alert = (props: AlertProps) => {
  const { className, children, success = false, warning = false } = props;
  const [open, setOpen] = useState(true);
  const css = useStyles(props);
  const theme = useTheme() as Theme;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div
      className={clsx(css.root, className, {
        [css.hidden]: !open,
        [css.success]: success,
        [css.warning]: warning,
      })}
    >
      <div className={css.wrapper}>
        <Icons.Alert
          width={30}
          height={30}
          viewBox="0 0 30 30"
          color={success
            ? theme.colors.green.main
            : theme.colors.red.main}
          className={css.icon}
        />
        <Typography className={css.text}>{children}</Typography>
      </div>

      <IconButton onClick={handleClose} className={css.button}>
        <Icons.Cross
          width={17}
          height={17}
          viewBox="0 0 17 17"
          color={success
            ? theme.colors.green.main
            : theme.colors.red.main}
          className={css.cross}
        />
      </IconButton>
    </div>
  );
};
