import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames/bind';
import * as Routes from 'constants/routes';
import { Routes as ServicesRoutes } from 'domains/services/constants';
import { ReactComponent as AgentIdIcon } from './agentId.svg';
import { ReactComponent as AdsIcon } from './ads.svg';
import { ReactComponent as MarketingIcon } from './marketing.svg';
import { ReactComponent as SettingIcon } from './setting.svg';
import { ReactComponent as SupportIcon } from './support.svg';
import { ReactComponent as ArrowIcon } from './arrow.svg';
import styles from './menu.module.css';
import { sendMetrik } from '_helpers/metrika';

const cx = classNames.bind(styles);

type MenuProps = {
  onlyIcons?: boolean;
};

export const Menu = ({ onlyIcons }: MenuProps) => {
  const handleSendMetrikObjeckt = useCallback(() => {
    sendMetrik('reachGoal', 'ObjectAndCollectionsButtonClick');
  }, []);

  const handleSendMetrikSevices = useCallback(() => {
    sendMetrik('reachGoal', 'ServicesButtonClick');
  }, []);

  return (
    <nav>
      <ul
        className={cx('list', {
          'list-only-icons': onlyIcons,
        })}
      >
        <li className={cx('list-item')}>
          <NavLink
            className={cx('item')}
            activeClassName={cx('item-active')}
            to={Routes.PROFILE_EDIT}
          >
            <span className={cx('icon-wrap')}>
              <AgentIdIcon className={cx('icon')} />
            </span>

            Мой цифровой профиль

            <ArrowIcon className={cx('arrow')} />
          </NavLink>
        </li>

        <li className={cx('list-item')}>
          <NavLink
            className={cx('item')}
            activeClassName={cx('item-active')}
            to={Routes.OBJECTS}
            onClick={handleSendMetrikObjeckt}
          >
            <span className={cx('icon-wrap')}>
              <AdsIcon className={cx('icon')} />
            </span>

            Объекты и подборки

            <ArrowIcon className={cx('arrow')} />
          </NavLink>
        </li>

        <li className={cx('list-item')}>
          <NavLink
            className={cx('item')}
            activeClassName={cx('item-active')}
            to={Routes.ANALYTICS}
          >
            <span className={cx('icon-wrap')}>
              <MarketingIcon className={cx('icon')} />
            </span>

            Аналитика и маркетинг

            <ArrowIcon className={cx('arrow')} />
          </NavLink>
        </li>

        <li className={cx('list-item')}>
          <NavLink
            className={cx('item')}
            activeClassName={cx('item-active')}
            to={ServicesRoutes.SERVICES_PAGE}
            onClick={handleSendMetrikSevices}
          >
            <span className={cx('icon-wrap')}>
              <SettingIcon className={cx('icon')} />
            </span>

            Сервисы

            <ArrowIcon className={cx('arrow')} />
          </NavLink>
        </li>

        <li className={cx('list-item')}>
          <NavLink
            className={cx('item')}
            activeClassName={cx('item-active')}
            to={Routes.SUPPORT}
          >
            <span className={cx('icon-wrap')}>
              <SupportIcon className={cx('icon')} />
            </span>

            Поддержка

            <ArrowIcon className={cx('arrow')} />
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};
