import RangeFields from './RangeFields';
import Switch from './Switch';

export const widgets = {
  price: RangeFields,
  price_for_meter: RangeFields,
  area: RangeFields,
  floor: RangeFields,
  floors_number: RangeFields,
  exclusive: Switch,
  agency: Switch,
  mine: Switch,
  mortgage: Switch,
  new: Switch,
  apartment: Switch,
  townhouse: Switch,
  appliance: Switch,
  furniture: Switch,
  security: Switch,
  closed_area: Switch,
  lift: Switch,
  gas: Switch,
  balcony: Switch,
  plain_plot: Switch,
  specific_plot: Switch,
  guest_house: Switch,
  sauna: Switch,
  electricity: Switch,
  water: Switch,
  pool: Switch,
  ready_business: Switch,
};
