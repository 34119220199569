import React from 'react';
import { CreateSvgIcon } from '../icon';
import { IconProps } from '../names';

export const Share = (props: IconProps) => (
  <CreateSvgIcon {...props}>
    {({ color }) => (
      <>
        <path
          d="M17.5998 6.58699V1.62455C17.5999 1.30323 17.6938 0.989138 17.8696 0.721989C18.0454 0.454839 18.2953 0.246626 18.5876 0.123669C18.8799 0.000713244 19.2015 -0.0314647 19.5119 0.0312037C19.8222 0.0938721 20.1072 0.248573 20.331 0.475749L31.5309 11.85C31.6796 12.0008 31.7976 12.1799 31.8781 12.377C31.9586 12.5741 32 12.7854 32 12.9988C32 13.2122 31.9586 13.4236 31.8781 13.6207C31.7976 13.8178 31.6796 13.9969 31.5309 14.1476L20.331 25.5219C20.1075 25.7499 19.8224 25.9053 19.5118 25.9684C19.2013 26.0316 18.8792 25.9995 18.5866 25.8765C18.2939 25.7534 18.0439 25.5448 17.8682 25.2771C17.6925 25.0094 17.5991 24.6948 17.5998 24.3731V19.3425C17.1454 19.31 16.6958 19.2953 16.251 19.2953C7.56953 19.2953 3.06877 25.0995 2.87197 25.3562C2.67027 25.6271 2.38976 25.8267 2.07015 25.9268C1.75055 26.0268 1.40806 26.0222 1.09119 25.9135C0.773321 25.8053 0.496973 25.5983 0.301178 25.3219C0.105383 25.0455 3.05176e-05 24.7136 0 24.3731C0 9.54756 12.9375 7.01759 17.5998 6.58699ZM16.251 16.0455C17.2862 16.0455 18.3502 16.1187 19.411 16.2616C19.7954 16.314 20.1481 16.5062 20.4035 16.8027C20.6589 17.0991 20.7997 17.4796 20.7998 17.8735V20.4506L28.1373 12.9988L20.7998 5.54706V8.12415C20.7998 9.02109 20.0462 9.74905 19.1646 9.74905C17.7086 9.74905 6.18234 10.0757 3.67836 20.189C6.32474 18.2099 10.5279 16.0455 16.251 16.0455Z"
          fill={color}
        />
      </>
    )}
  </CreateSvgIcon>
);
