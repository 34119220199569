import React from 'react';
import { CreateSvgIcon } from '../icon';
import { IconProps } from '../names';

export const TriangularArrow = (props: IconProps) => (
  <CreateSvgIcon {...props}>
    {({ color }) => (
      <>
        <path d="M13.5 22L0.942631 0.25L26.0574 0.25L13.5 22Z" fill={color} />
      </>
    )}
  </CreateSvgIcon>
);
