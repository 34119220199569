import {
  action, computed, makeAutoObservable, observable,
} from 'mobx';
import { createContext } from 'react';
import { INewUser, IUser } from '../interfaces';
import ApiService from './api-service';
import AuthService from './auth-service';
import UserService from './user-service';

class ApplicationManager {
  apiService = new ApiService();

  authService = new AuthService(this.apiService);

  userService = new UserService(this.apiService);

  @observable currentUser: IUser | null = null;

  @observable newUser: INewUser | null = null;

  @observable authToken: string | null = localStorage.getItem('token');

  @observable isConfirmed = false;

  @observable isCodeRequested = false;

  @observable loading = false;

  @observable sidebarSmall = false;

  @observable scrollableContainer = '';

  @observable serverIsNotAvailable = false;

  @observable mobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  constructor() {
    makeAutoObservable(this);
    this.loading = true;
    if (this.authToken) {
      this.userService
        .getCurentUser()
        .then((user) => {
          this.setCurrentUser(user);
        })
        .catch((error) => {
          if (error.code === 401) {
            this.clearAuthToken();
          }
          this.serverIsNotAvailable = true;
        }).finally(() => this.setLoading(false));
    }
  }

  @computed get isAuthenticated() {
    return !!this.authToken;
  }

  @computed get isUnregistered() {
    return this.isAuthenticated && !this.currentUser?.authorized;
  }

  @computed get isTempAuthToken() {
    return !!this.authToken?.startsWith('temp');
  }

  @computed get isRegistered() {
    return this.isAuthenticated && !!this.authToken?.startsWith('token:');
  }

  @action
  setCurrentUser(user: IUser) {
    this.currentUser = user;
  }

  @action
  setScrollableContainer(scrollableContainer: string) {
    this.scrollableContainer = scrollableContainer;
  }

  @action
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @action.bound
  setAuthToken(token: string) {
    this.authToken = token;
    localStorage.setItem('token', token);
    this.apiService.setToken(token);
  }

  @action.bound
  clearAuthToken() {
    this.authToken = null;
    this.isCodeRequested = false;
    localStorage.removeItem('token');
    this.apiService.clearToken();
  }

  @action.bound
  requestCode = async (phone: string) => {
    this.setLoading(true);
    await this.authService.requestCode(phone).finally(() => this.setLoading(false));
    this.isCodeRequested = true;
  };

  @action.bound
  toggleSidebar = () => {
    this.sidebarSmall = !this.sidebarSmall;
  };

  @action.bound
  reset = () => {
    this.isCodeRequested = false;
    this.authToken = null;
    this.apiService.setToken('');
  };

  @action.bound
  confirmCode = async (phone: string, code: string) => {
    this.setLoading(true);
    const response = await this.authService.confirmCode(phone, code);
    this.setAuthToken(response.token);
    this.authToken = response.token;
    this.currentUser = response.user;
    this.isConfirmed = true;
    this.setLoading(false);
    return response;
  };

  @action.bound
  updateUserProperty = (property: string, value: any) => {
    if (this.isAuthenticated && this.currentUser) {
      this.currentUser = { ...this.currentUser, [property]: value };
    }
  };

  @action.bound
  updateNewUserProperties = (props: any) => {
    if (this.isAuthenticated) {
      this.newUser = { ...this.currentUser, ...this.newUser, ...props };
    }
  };

  @action.bound
  registerAndSignIn = async (
    userProperties: IUser,
    termsOfUse = false,
    privacyPolicy = false,
  ): Promise<IUser> => {
    this.setLoading(true);
    const registration = await this.userService.registerUser(
      userProperties,
      termsOfUse,
      privacyPolicy,
    );
    this.currentUser = registration.user;
    this.authToken = registration.token;
    this.setLoading(false);
    return this.currentUser;
  };

  @action.bound
  saveUser = async (
    userProperties: IUser,
  ): Promise<IUser> => {
    this.setLoading(true);
    const registration = await this.userService.saveUser(userProperties);
    this.currentUser = registration.user;
    this.setLoading(false);
    return this.currentUser;
  };

  @action.bound
  applyPromocode = async (
    promocode: string,
  ): Promise<IUser> => {
    this.setLoading(true);
    console.log(promocode);
    const result = await this.userService.applyPromocode(promocode);
    if (!result.user) {
      this.setLoading(false);
      // @ts-ignore
      return this.currentUser;
    }
    this.currentUser = result.user;
    this.setLoading(false);
    return this.currentUser;
  };

  @action.bound
  saveCertPhoto = async (photo: File) => {
    await this.userService.saveCertPhoto(photo);
  };
}

export default ApplicationManager;
export const ApplicationContext = createContext<ApplicationManager>(
  new ApplicationManager(),
);
