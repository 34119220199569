import React from 'react';
import { CreateSvgIcon } from '../icon';
import { IconProps } from '../names';

export const Exit = (props: IconProps) => (
  <CreateSvgIcon {...props}>
    {({ color }) => (
      <path
        d="M7.09 12.59L8.5 14L13.5 9L8.5 4L7.09 5.41L9.67 8H0V10H9.67L7.09 12.59ZM16 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V6H2V2H16V16H2V12H0V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0Z"
        fill={color}
      />
    )}
  </CreateSvgIcon>
);
