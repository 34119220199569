import React from 'react';
import classNames from 'classnames/bind';
import { Text } from 'components/text';
import { Icons, Input, Tooltip } from 'ui/shared';
import { EditCollapse } from '../edit-collapse';
import { EditSocialsProps, SocialTooltipProps } from './types';
import { ReactComponent as HelpIcon } from './help.svg';
import styles from './styles.module.css';

const cx = classNames.bind(styles);

const SocialTooltip = ({ header, text, footer, warn }: SocialTooltipProps) => (
  <Tooltip
    header={() => (
      <div className={cx('tooltip-header')}>
        {header}
      </div>
    )}
    footer={() => (
      <div className={cx('tooltip-header')}>
        {footer}
      </div>
    )}
    text={text}
    multiline
    top
    right={false}
    center={false}
    tailRight
  >
    <div className={cx('question-button')}>
      <HelpIcon
        className={cx(warn ? 'question-icon-red' : 'question-icon')}
        width="24"
        height="24"
        color="red"
      />
    </div>
  </Tooltip>
);

export const EditSocials = ({
  userProperties,
  onChangeUserProperty,
}: EditSocialsProps) => (
  <EditCollapse title="Социальные сети и месcенджеры">
    <div className={cx('field')}>
      <Input
        className={cx('field-input')}
        type="text"
        value={userProperties?.email}
        onChange={onChangeUserProperty('email')}
        placeholder="Email"
        secondary
      />
      <Icons.Email className={cx('field-icon')} width={20} height={20} viewBox="0 0 32 32" color="#136FFB" />
      <SocialTooltip
        header={(
          <>
            <Icons.Email width={20} height={17} color="#136FFB" />
            <Text size="m">
              Email
            </Text>
          </>
        )}
        text="Введите свой актуальный Email. На этот Email будут приходить заявки с формы"
      />
    </div>
    <div className={cx('field')}>
      <Input
        className={cx('field-input')}
        type="text"
        value={userProperties?.whatsapp}
        onChange={onChangeUserProperty('whatsapp')}
        placeholder="WhatsApp"
        secondary
      />
      <Icons.Whatsapp className={cx('field-icon')} width={20} height={20} viewBox="0 0 32 32" />
      <SocialTooltip
        header={(
          <>
            <Icons.Whatsapp width={20} height={20} />
            <Text size="m">
              Whatsapp
            </Text>
          </>
        )}
        text="Введите свой номер телефона накотором установлен WhatsApp"
      />
    </div>
    <div className={cx('field')}>
      <Input
        className={cx('field-input')}
        type="text"
        value={userProperties?.telegram}
        onChange={onChangeUserProperty('telegram')}
        placeholder="Telegram"
        secondary
      />
      <Icons.Telegram className={cx('field-icon')} width={20} height={20} viewBox="0 0 32 32" color="#32AFED" />
      <SocialTooltip
        header={(
          <>
            <Icons.Telegram width={20} height={20} color="#32AFED" />
            <Text size="m">
              Telegram
            </Text>
          </>
        )}
        text="Обратите внимание, что вы должны ввести имя пользователя своей учетной записи Telegram, а НЕ свое имя. Если у вас есть канал - вместо имени пользователя, нужно ввести название канала, которое указано в ссылке после https://t.me/. Ссылку на закрытую группу нужно ввести полностью, она обязательно должна начинаться с https://t.me/joinchat/"
      />
    </div>
    <div className={cx('field')}>
      <Input
        className={cx('field-input')}
        type="text"
        value={userProperties?.instagram}
        onChange={onChangeUserProperty('instagram')}
        placeholder="Instagram"
        secondary
      />
      <Icons.Instagram className={cx('field-icon')} width={20} height={20} viewBox="0 0 32 32" />
      <SocialTooltip
        warn
        header={(
          <>
            <Icons.Instagram width={20} height={20} />
            <Text size="m">
              Instagram
            </Text>
          </>
        )}
        text="Введите имя пользователя. Обратите внимание, что вы должны ввести имя пользователя своей учетной записи Instagram, а НЕ свое имя.
Имя вводится без @"
        footer={(
          <>
            <Text size="s" color="red" align="center">
              * Деятельность социальной сети запрещена на территории Российской Федерации.
            </Text>
          </>
        )}
      />
    </div>
    <div className={cx('field')}>
      <Input
        className={cx('field-input')}
        type="text"
        value={userProperties?.vk}
        onChange={onChangeUserProperty('vk')}
        placeholder="Vkontakte"
        secondary
      />
      <Icons.Vkontakte className={cx('field-icon')} width={20} height={20} viewBox="0 0 32 32" color="#4680C2" />
      <SocialTooltip
        header={(
          <>
            <Icons.Vkontakte width={20} height={20} color="#4680C2" />
            <Text size="m">
              Vkontakte
            </Text>
          </>
        )}
        text="Введите имя пользователя или URL группы. Обратите внимание, что вы должны ввести имя пользователя своей учетной записи Vkontakte, а НЕ свое имя."
      />
    </div>
    <div className={cx('field')}>
      <Input
        className={cx('field-input')}
        type="text"
        value={userProperties?.youtube}
        onChange={onChangeUserProperty('youtube')}
        placeholder="Youtube"
        secondary
      />
      <Icons.Youtube className={cx('field-icon')} width={20} height={20} viewBox="0 0 32 32" color="#FF0000" />
      <SocialTooltip
        header={(
          <>
            <Icons.Youtube width={20} height={20} color="#FF0000" />
            <Text size="m">
              Youtube
            </Text>
          </>
        )}
        text="Введите URL своего видео или youtube канала"
      />
    </div>
  </EditCollapse>
);
