import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { EditProfile } from './pages/edit-page/edit-profile';
import { Routes } from './constants';

export const ProfileRoutes = () => (
  <Switch>
    <Route path={Routes.EDIT_PROFILE} component={EditProfile} />
    <Redirect from={Routes.ROOT} to={Routes.EDIT_PROFILE} />
  </Switch>
);
