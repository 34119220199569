import React from 'react';
import cn from 'classnames';

import styles from './HeaderToggleButtons.module.css';

export interface HeaderToggleButtonsProps {
  className?: string;
  children: React.ReactNode;
}

const HeaderToggleButtons = (props: HeaderToggleButtonsProps) => {
  const {
    className,
    children,
  } = props;

  return (
    <div
      className={cn(styles['header-toggle-buttons'], className)}
    >
      {children}
    </div>
  );
};

export default HeaderToggleButtons;
