import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import {
  Button as LegacyButton,
  Checkbox, Dropdown, Icons,
  Input, Textarea, Typography,
  ProLabel,
} from 'ui/shared';
import { EditCollapse } from 'ui/pages/profile/components/edit-collapse';
import { EditAboutMeProps } from './types';
import styles from './styles.module.css';
import { getUserCities } from 'domains/objects/requests';

const cx = classNames.bind(styles);

interface City{
  id: number;
  inactive: boolean;
  name: string;
  northeast_lat: number;
  northeast_lng: number;
  southwest_lat: number;
  southwest_lng: number;
}

const GENDERS = [
  { key: 'male', value: 'Мужской' },
  { key: 'female', value: 'Женский' },
];

// @ts-ignore
const getGenderValue = (key?: number) => GENDERS.find((gender) => gender.key === key) || null;

export const EditAboutMe = ({
  userProperties,
  updateDataByKey,
  onChangeUserProperty,
  onChangeAboutMe,
  onChangeInterests,
}: EditAboutMeProps) => {
  const [cities, setCities] = useState<City[]>([]);

  useEffect(() => {
    Promise.any([
      getUserCities(),
    ]).then((cityList) => setCities(cityList));
  }, []);

  const getCityValue = (name?: string) => cities.find((city) => city.name === name) || null;

  return (
    <EditCollapse title="Обо мне">
      <div className={cx('field')}>
        <Input
          type="phone"
          value={userProperties?.phone}
          readOnly
          placeholder="Телефон"
          secondary
        />
      </div>
      <div className={cx('field')}>
        <Input
          type="text"
          value={userProperties?.first_name || ''}
          onChange={onChangeUserProperty('first_name')}
          placeholder="Имя"
          secondary
        />
      </div>
      <div className={cx('field')}>
        <Input
          type="text"
          value={userProperties?.last_name || ''}
          onChange={onChangeUserProperty('last_name')}
          placeholder="Фамилия"
          secondary
        />
      </div>
      <div className={cx('field')}>
        <Input
          type="text"
          value={userProperties?.middle_name || ''}
          onChange={onChangeUserProperty('middle_name')}
          placeholder="Отчество"
          secondary
        />
      </div>
      <div className={cx('field', 'field-row')}>
        <div className={cx('field-column')}>
          <Dropdown
            preferTop
            items={GENDERS}
            renderItem={(item) => (
              <LegacyButton
                key={`gender-${item.key}`}
                onClick={() => updateDataByKey('gender', item.key)}
                className={cx('dropdown-button')}
              >
                {item.value}
              </LegacyButton>
            )}
            overlayClassName={cx('dropdown-overlay')}
          >
            <LegacyButton className={cx('dropdown')}>
              <span>
                {getGenderValue(userProperties?.gender)?.value || (
                  <Typography size="subtitle1(18px)" font="semibold" className={cx('dropdown-grey-text')}>
                    Пол
                  </Typography>
                )}
              </span>
              <Icons.TriangularArrow
                width={19}
                height={20}
                viewBox="0 0 32 32"
                color="#C4C4C4"
                className={cx('dropdown-arrow')}
              />
            </LegacyButton>
          </Dropdown>
        </div>

        <div className={cx('field-column')}>
          <Input
            type="text"
            inputMode="numeric"
            value={userProperties?.birth_date || ''}
            onChange={onChangeUserProperty('birth_date')}
            placeholder="Дата рождения"
            mask="00.00.0000"
            secondary
          />
        </div>
      </div>
      <div className={cx('field')}>
        <Dropdown
          preferTop
          items={cities}
          renderItem={(item) => (
            <LegacyButton
              key={`user-city-${item.name}`}
              onClick={() => updateDataByKey('city', item.name)}
              className={cx('dropdown-button')}
            >
              {item.name}
            </LegacyButton>
          )}
          overlayClassName={cx('dropdown-overlay')}
        >
          <LegacyButton className={cx('dropdown')}>
            <span>
              {getCityValue(userProperties?.city)?.name || (
                <Typography size="subtitle1(18px)" font="semibold" className={cx('dropdown-grey-text')}>
                  Город
                </Typography>
              )}
            </span>
            <Icons.TriangularArrow
              width={19}
              height={20}
              viewBox="0 0 32 32"
              color="#C4C4C4"
              className={cx('dropdown-arrow')}
            />
          </LegacyButton>
        </Dropdown>
      </div>
      <div className={cx('field')}>
        <Textarea
          className={cx('textarea')}
          value={userProperties?.description || ''}
          onChange={onChangeAboutMe}
          minRows={5}
          maxRows={5}
          placeholder="Несколько строк о себе"
        />
      </div>
      <div className={cx('field')}>
        <Textarea
          className={cx('textarea')}
          value={userProperties?.interests || ''}
          onChange={onChangeInterests}
          minRows={5}
          maxRows={5}
          placeholder="Ваша специализация"
        />
      </div>
      <div className={cx('field')}>
        <div className={cx('social-field-wrap')}>
          <Input
            className={cx('social-field')}
            type="text"
            value={(!userProperties?.expired && userProperties?.site) || ''}
            onChange={onChangeUserProperty('site')}
            placeholder="Ссылка на ваш сайт"
            secondary
            disabled={userProperties?.expired}
          />
          <Icons.Planet width={20} height={20} viewBox="0 0 32 32" className={cx('social-icon')} />
          {userProperties?.expired && <ProLabel className={cx('pro-label')} />}
        </div>
      </div>
      <div className={cx('field')}>
        <div className={cx('social-field-wrap')}>
          <Input
            className={cx('social-field')}
            type="text"
            value={(!userProperties?.expired && userProperties?.uservideo) || ''}
            onChange={onChangeUserProperty('uservideo')}
            placeholder="Ссылка на ваше видео"
            secondary
            disabled={userProperties?.expired}
          />
          <Icons.Video width={20} height={20} viewBox="0 0 32 32" className={cx('social-icon')} />
          {userProperties?.expired && <ProLabel className={cx('pro-label')} />}
        </div>
      </div>
    </EditCollapse>
  );
};
