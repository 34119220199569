import React from 'react';
import cn from 'classnames';

import styles from './Title.module.css';

interface Props {
  className?: string;
  children: React.ReactNode;
}

const Title = ({ className, children }: Props) => (
  <p className={cn(styles.title, className)}>
    {children}
  </p>
);

export default Title;
