import React, { useContext, useEffect, useState } from 'react';
import Media from 'react-media';

import axios from 'axios';
import dayjs from 'dayjs';
import copyToClipboard from 'copy-to-clipboard';
import cn from 'classnames';

import Config from 'config';
import { declOfNum } from '_helpers';
import { SelectionProps } from '_types';

import { ApplicationContext } from 'global-stores/application-context';
import { SelectionsContext } from 'global-stores/selections-context';

import Button from 'ui/components/Button';
import ApartmentList from 'ui/components/ApartmentList';
import CopyToClipboardPopup from 'ui/components/CopyToClipboardPopup';
import Modal from 'ui/components/Modal';
import Field from 'ui/components/Field';

import { ReactComponent as FolderIcon } from 'assets/svg/Folder.svg';
import { ReactComponent as ArrowIcon } from 'assets/svg/Arrow.svg';
import { ReactComponent as EditIcon } from 'assets/svg/Edit.svg';
import { ReactComponent as TrashIcon } from 'assets/svg/Trash.svg';
import { ReactComponent as ShareIcon } from 'assets/svg/Share.svg';
import { ReactComponent as DuplicateIcon } from 'assets/svg/Duplicate.svg';
import { ReactComponent as OkIcon } from 'assets/svg/Ok.svg';

import styles from './ApartmentFolder.module.css';

interface ApartmentFolderProps {
  selection: SelectionProps;
}

const ApartmentFolder = ({
  selection: { name, id, ads_count, created_at, updated_at, encrypted_id,
  } }: ApartmentFolderProps) => {
  const [popupDuplicateIsOpen, setPopupDuplicateIsOpen] = useState(false);
  const [popupDeleteIsOpen, setPopupDeleteIsOpen] = useState(false);

  const selectionsContext = useContext(SelectionsContext);
  const applicationContext = useContext(ApplicationContext);

  const {
    currentUser,
  } = applicationContext;

  const [error, setError] = useState(false);
  const [animate, setAnimate] = useState(false);

  const [editable, setEditable] = useState(false);

  const [nameValue, setNameValue] = useState(name);

  const [isLoadingCopy, setIsLoadingCopy] = useState(false);
  const [isLoadingEditTitle, setIsLoadingEditTitle] = useState(false);

  const [copy, setCopy] = useState(false);
  const [copyTimeout, setCopyTimeout] = useState(null as any);

  const [duplicate, setDuplicate] = useState(false);
  const [duplicateTitleValue, setDuplicateTitleValue] = useState('');

  const [expanded, setExpanded] = useState(false);
  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false);

  const handleClickOnRemoveButton = () => {
    setIsOpenRemoveModal(true);
  };

  const handleClickOnRemoveModalClose = () => {
    setIsOpenRemoveModal(false);
  };

  const onExpend = (e: React.SyntheticEvent) => {
    const targetElement = e.target as HTMLDivElement;

    const { classList } = targetElement;

    if (
      (
        classList.contains(styles.header)
        || classList.contains(styles.arrowButton)
        || targetElement.closest(`.${styles.arrowButton}`)
        || classList.length === 0
      )
      && (
        targetElement.closest(`.${styles.arrowButton}`)
        || !targetElement.closest('button')
      )) {
      setExpanded(!expanded);
    }
  };

  const onChangeDuplicateTitleValue = (e: React.SyntheticEvent) => {
    if (!isLoadingCopy) {
      const { value } = e.target as HTMLInputElement;
      setDuplicateTitleValue(value);
    }
  };

  const onDuplicateModalOpen = () => {
    setDuplicateTitleValue(`${nameValue} (копия)`);
    setDuplicate(true);
  };

  const onDuplicateModalClose = () => {
    setDuplicateTitleValue('');
    setDuplicate(false);
  };

  const handleClickDuplicate = () => {
    setIsLoadingCopy(true);

    const token = localStorage.getItem('token');

    axios(`${Config.API_URL}/api/v3/selections/copy`, {
      method: 'POST',
      headers: {
        Authorization: `Token ${token}`,
      },
      data: {
        id,
        name: duplicateTitleValue,
      },
    })
      .then(() => {
        selectionsContext.uploadSelections();
        setDuplicate(false);

        setPopupDuplicateIsOpen(true);

        clearTimeout(copyTimeout);

        setCopyTimeout(setTimeout(() => {
          setPopupDuplicateIsOpen(false);
        }, 3000));
      })
      .finally(() => {
        setIsLoadingCopy(false);
      });
  };

  const handleClickDeleteSelection = () => {
    const token = localStorage.getItem('token');

    axios(`${Config.API_URL}/api/v3/selections/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Token ${token}`,
      },
    }).then(() => {
      setIsOpenRemoveModal(false);
      setAnimate(false);
      setError(false);

      selectionsContext.uploadSelections();

      setPopupDeleteIsOpen(true);

      clearTimeout(copyTimeout);

      setCopyTimeout(setTimeout(() => {
        setPopupDeleteIsOpen(false);
      }, 3000));
    }).catch(() => {
      setAnimate(true);
      setError(true);

      setTimeout(() => {
        setAnimate(false);
        setError(false);
      }, 1000);
    });
  };

  const onCopy = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Это пока ещё в разработке',
        url: `https://selection.reagent.pro/${currentUser?.public_id}/${encrypted_id}/`,
      }).then(() => {
        console.log('Thanks for sharing!');
      }).catch(() => {
        console.log('Error sharing!');
      });
    } else {
      console.log('Web Share API do not support ed in your browser');

      copyToClipboard(`https://selection.reagent.pro/${currentUser?.public_id}/${encrypted_id}/`);
      setCopy(true);

      clearTimeout(copyTimeout);

      setCopyTimeout(setTimeout(() => {
        setCopy(false);
      }, 3000));
    }
  };

  const handleClickEditSelection = () => {
    if (editable) {
      setIsLoadingEditTitle(true);

      const token = localStorage.getItem('token');

      axios(`${Config.API_URL}/api/v3/selections/${id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Token ${token}`,
        },
        data: {
          name: nameValue,
        },
      })
        .then(() => {
          setEditable(false);

          selectionsContext.uploadSelections();
        })
        .finally(() => {
          setIsLoadingEditTitle(false);
        });
    } else {
      setEditable(true);
    }
  };

  const handleChangeNameValue = (e: React.ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setNameValue(value);
  };

  const handleKeyUpNameValue = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && nameValue !== name) {
      handleClickEditSelection();
    }
  };

  useEffect(() => {
    selectionsContext.uploadAdsBySelectionId(id);
  }, [selectionsContext, selectionsContext.selections, id]);

  return (
    <>
      <div>
        <header
          className={styles.header}
          onClick={onExpend}
        >
          <div
            className={cn(styles.folderTitle, {
              [styles.folderTitleExpanded]: expanded,
            })}
          >
            <button
              className={`
                ${styles.button}
                ${styles.arrowButton}
                ${expanded && styles.arrowButtonExpended}
              `}
              type="button"
              onClick={onExpend}
            >
              <ArrowIcon className={styles.arrowIcon} />
            </button>

            <div className={styles.titleWrap}>
              <p className={styles.folderTitleText}>
                <FolderIcon
                  className={cn(styles.folderIcon, {
                    [styles.folderIconExpanded]: expanded,
                  })}
                />

                {editable
                  ? (
                    <Field
                      theme="small"
                      onChange={handleChangeNameValue}
                      onKeyUp={handleKeyUpNameValue}
                      value={nameValue}
                      readonly={isLoadingEditTitle}
                    />
                  )
                  : (
                    <a
                      className={styles.nameLink}
                      href={`https://selection.reagent.pro/${currentUser?.public_id}/${encrypted_id}/`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {nameValue}
                    </a>
                  )}
              </p>

              <button
                className={cn(styles.button, {
                  [styles.editButton]: !editable,
                  [styles.okButton]: editable,
                  [styles.editButtonExpanded]: expanded || editable,
                  [styles.okButtonLoading]: isLoadingEditTitle,
                })}
                type="button"
                onClick={handleClickEditSelection}
                disabled={editable && !nameValue}
              >
                {editable
                  ? <OkIcon className={styles.okIcon} width={30} height={30} />
                  : <EditIcon className={styles.editIcon} />}
              </button>
            </div>
          </div>

          <Media query="(min-width: 769px)">
            <>
              <p>
                {ads_count}
                {' '}
                {declOfNum(ads_count, ['объект', 'объекта', 'объектов'])}
              </p>

              <p>
                {dayjs(created_at).format('DD-MM-YYYY')}
              </p>

              <p>
                {dayjs(updated_at).format('DD-MM-YYYY')}
              </p>
            </>
          </Media>

          <div
            className={cn(styles.folderControls, {
              [styles.folderControlsExpanded]: expanded,
            })}
          >
            <button
              className={cn(styles.button, styles.trashButton)}
              type="button"
              onClick={handleClickOnRemoveButton}
              disabled={editable}
            >
              <TrashIcon className={styles.trashIcon} />
            </button>

            {isOpenRemoveModal && (
              <Modal
                onClose={handleClickOnRemoveModalClose}
                error={error}
                animate={animate}
              >
                <Modal.Body>
                  Вы уверены, что хотите удалить подборку «
                  {name}
                  » полностью?
                </Modal.Body>

                <Modal.Buttons>
                  <Button
                    component="button"
                    onClick={handleClickOnRemoveModalClose}
                    theme="black"
                  >
                    Отменить
                  </Button>

                  <Button
                    component="button"
                    theme="red"
                    onClick={handleClickDeleteSelection}
                  >
                    Удалить
                  </Button>
                </Modal.Buttons>
              </Modal>
            )}

            <button
              className={cn(styles.button, styles.duplicateButton)}
              type="button"
              onClick={onDuplicateModalOpen}
              disabled={editable}
            >
              <DuplicateIcon className={styles.duplicateIcon} />
            </button>

            <button
              className={cn(styles.button, styles.shareButton)}
              type="button"
              onClick={onCopy}
              disabled={editable}
            >
              <ShareIcon className={styles.shareIcon} />
            </button>

            <CopyToClipboardPopup active={copy} />
          </div>
        </header>

        {expanded && (
          <div className={styles['apartment-list']}>
            <ApartmentList selectedId={id} />
          </div>
        )}
      </div>

      {
        duplicate && (
          <div>
            <header
              className={`
                ${styles.header}
                ${styles.headerDuplicate}
              `}
            >
              <div className={styles.folderTitle}>
                <div />

                <div className={styles.titleWrap}>
                  <div className={styles.folderTitleText}>
                    <FolderIcon
                      className={cn(styles.folderIcon, {
                        [styles.folderIconExpanded]: expanded,
                      })}
                    />

                    <div className={styles.duplicateTitle}>
                      <div className={styles.duplicateField}>
                        <Field
                          className={styles.field}
                          type="text"
                          onChange={onChangeDuplicateTitleValue}
                          value={duplicateTitleValue}
                          theme="small"
                          placeholder="Название подборки"
                          autoFocus
                        />
                      </div>

                      <div className={styles.buttons}>
                        <Button
                          component="button"
                          theme="black-full"
                          onClick={handleClickDuplicate}
                          disabled={isLoadingCopy}
                        >
                          Создать
                        </Button>

                        <Button
                          component="button"
                          theme="black"
                          onClick={onDuplicateModalClose}
                          disabled={isLoadingCopy}
                        >
                          Отменить
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </header>
          </div>
        )
      }

      <CopyToClipboardPopup text="Копия подборки создана" active={popupDuplicateIsOpen} />
      <CopyToClipboardPopup text="Подборка удалена" active={popupDeleteIsOpen} />
    </>
  );
};

export default React.memo(ApartmentFolder);
