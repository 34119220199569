import axios from 'axios';
import Config from '../../../../config';

export const requestLeaveAgency = () => {
  const token = localStorage.getItem('token');

  return axios(`${Config.API_URL}/api/v1/user/leave_agency`, {
    method: 'PUT',
    headers: {
      Authorization: `Token ${token}`,
    },
  });
};
