import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { Avatar, Icons } from 'ui/shared';
import { Text } from 'components/text';
import { ReactComponent as UploadIcon } from './upload.svg';
import { EditPicturesProps } from './types';
import styles from './styles.module.css';

const cx = classNames.bind(styles);

const useBlobPicture = (file: Blob | undefined) => {
  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    const reader = new FileReader();

    reader.onload = (e) => {
      if (typeof e.target?.result === 'string') {
        setUrl(e.target?.result);
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    }

    return () => {
      reader.abort();
    };
  }, [file]);

  return [
    url,
  ];
};

type UploadSuggestProp = {
  figure?: 'circle' | 'rect';
};

const UploadSuggest = ({
  figure,
}: UploadSuggestProp) => (
  <div className={cx('upload-suggest', `figure_${figure}`)}>
    <div className={cx('upload-suggest-icon')}>
      <UploadIcon />
    </div>
    <div className={cx('upload-suggest-text')}>
      <Text size="xxs" color="white" semibold>
        Загрузите<br />изображение
      </Text>
    </div>
  </div>
);

export const EditPictures = ({
  userProperties,
  onChangeAvatar,
  onChangeBackground,
}: EditPicturesProps) => {
  const [avatarURL] = useBlobPicture(userProperties?.avatar_blob);
  const [backgroundURL] = useBlobPicture(userProperties?.background_blob);

  const haveAvatar = avatarURL || userProperties?.avatar?.original;
  const haveBackground = backgroundURL || userProperties?.background?.original;

  return (
    <div className={cx('wrap')}>
      <div className={cx('upload-file-container')}>
        <label>
          {haveAvatar && (
            <div className={cx('upload-file', 'avatar')}>
              <Avatar.Medium img={avatarURL || userProperties?.avatar.original} />
              <div className={cx('upload-suggest-wrap', 'upload-suggest-wrap_avatar')}>
                <UploadSuggest figure="circle" />
              </div>
            </div>
          )}

          {!haveAvatar && (
            <div className={cx('upload-file', 'avatar')}>
              <Icons.Person height={29} width={25} viewBox="0 0 29 25" />
              <div className={cx('upload-suggest-wrap', 'upload-suggest-wrap_avatar')}>
                <UploadSuggest figure="circle" />
              </div>
            </div>
          )}
          <input type="file" id="avatar-file" className={cx('input')} onChange={onChangeAvatar} />
        </label>
        <div className={cx('upload-file-title')}>
          Фото
        </div>
      </div>

      <div className={cx('upload-file-container')}>
        <label>
          {haveBackground && (
            <div className={cx('upload-file', 'cover')}>
              <div className={cx('cover-image')}>
                <img src={backgroundURL || userProperties?.background.original} alt="" />
              </div>
              <div className={cx('upload-suggest-wrap', 'upload-suggest-wrap_avatar')}>
                <UploadSuggest figure="rect" />
              </div>
            </div>
          )}

          {!haveBackground && (
            <div className={cx('upload-file', 'cover')}>
              <Icons.Camera height={32} width={32} viewBox="0 0 32 32" />
              <div className={cx('upload-suggest-wrap', 'upload-suggest-wrap_avatar')}>
                <UploadSuggest figure="rect" />
              </div>
            </div>
          )}
          <input type="file" id="avatar-file" className={cx('input')} onChange={onChangeBackground} />
        </label>
        <div className={cx('upload-file-title')}>
          Обложка
        </div>
      </div>
    </div>
  );
};
