import React, { useCallback, useContext, useState } from 'react';
import { ApplicationContext } from 'global-stores/application-context';
import { EditProfessionalInformation } from 'ui/pages/profile/components/edit-professional-information';
import { requestLeaveAgency } from '../../requests';
import { EditProfessionalInformationContainerProps } from './types';

export const EditProfessionalInformationContainer = ({
  userProperties,
  updateDataByKey,
  onChangeUserProperty,
}: EditProfessionalInformationContainerProps) => {
  const { currentUser: currentUserProperties, saveUser, saveCertPhoto } = useContext(ApplicationContext);
  const [isOpenInAgencyModal, setIsOpenInAgencyModal] = useState(false);
  const [uploadedLicense, setUploadedLicense] = useState(null as string | null);

  const handleOpenInAgencyModal = () => {
    setIsOpenInAgencyModal(true);
  };

  const handleCloseInAgencyModal = () => {
    setIsOpenInAgencyModal(false);
  };

  const handleLeaveAgency = () => {
    requestLeaveAgency()
      .then(() => {
        setIsOpenInAgencyModal(false);
        updateDataByKey('agency_name', '');
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const uploadLicense = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const certPhoto = e.target.files?.[0];

    if (!certPhoto) {
      return;
    }

    saveCertPhoto(certPhoto);
    setUploadedLicense(URL.createObjectURL(e.target.files?.[0] as Blob || null));
  }, [saveCertPhoto]);

  return (
    <EditProfessionalInformation
      userProperties={userProperties}
      updateDataByKey={updateDataByKey}
      onChangeUserProperty={onChangeUserProperty}
      onLeaveAgency={handleLeaveAgency}
      onOpenLeaveAgencyModal={handleOpenInAgencyModal}
      onCloseLeaveAgencyModal={handleCloseInAgencyModal}
      isOpenInAgencyModal={isOpenInAgencyModal}
      uploadLicense={uploadLicense}
      certPhoto={uploadedLicense || userProperties?.cert_photo?.original || null}
    />
  );
};
