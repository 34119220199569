import React, {
  ChangeEvent, useCallback, useContext, useState,
} from 'react';
import {
  AnimatedPage, Avatar, Box, Button, EmptyContent, Icons, Typography,
} from 'ui/shared';
import { makeStyles } from 'hooks/makeStyles';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { BackButton } from '../../components/layout/back-button';
import { ApplicationContext } from '../../../global-stores/application-context';
import { Text } from '../../../components/text';

const useStyles = makeStyles((theme) => ({
  animationContainer: {
    width: '100%',
    display: 'contents',
  },
  wrap: {
    display: 'flex',
    alignItems: 'center',
    padding: '56px 16px',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  container: {
    width: '100%',
    maxWidth: 359,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '&$animating': {
      overflowY: 'hidden',
    },
  },
  title: {
    '@media screen and (max-width: 768px)': {
      textAlign: 'center',
    },
  },
  iconBack: {
    '&:hover, &:focus, &:active': {
      outline: 'none',
      backgroundColor: 'transparent',
      //   borderColor: theme.colors.blue.main
    },
  },
  button: {
    width: 230,
    height: 50,
    borderRadius: 6,
    fontSize: 22,
    padding: `${theme.spacing(2)}`,
    border: '2px solid #020202',
    color: '#020202',
    '&:hover, &:focus, &:active': {
      outline: 'none',
      borderColor: 'var(--color-blue)',
      color: 'var(--color-blue)',
    },
    '@media screen and (max-width: 768px)': {
      marginBottom: `${theme.spacing(22.5)}`,
    },
  },
  buttonSkip: {
    border: 'none',
    fontSize: 22,
    color: '#020202',
    marginBottom: `${theme.spacing(6)}`,
    cursor: 'pointer',
    '&:hover, &:focus, &:active': {
      outline: 'none',
      backgroundColor: 'transparent',
      color: 'var(--color-blue)',
    },
    '@media screen and (max-width: 768px)': {
      marginBottom: `${theme.spacing(22.5)}`,
    },
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  skeletonContainer: {
    padding: `${theme.spacing(3.5)} ${theme.spacing(10.75)} ${theme.spacing(3.75)} ${theme.spacing(
      10.75,
    )}`,
    backgroundColor: theme.colors.background.paper.default,
    borderRadius: 10,
  },
  avatarBtn: {
    margin: `${theme.spacing(0)} ${theme.spacing(8)} ${theme.spacing(4)} ${theme.spacing(0)}`,
  },
  emptyAva: {
    width: 99,
    height: 99,
  },
  inputfile: {
    width: '0.1px',
    height: '0.1px',
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: -1,
  },
  buttonFile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 230,
    height: 50,
    borderRadius: 6,
    fontSize: 22,
    padding: `${theme.spacing(2)}`,
    border: '2px solid #020202',
    color: '#020202',
    '&:hover, &:focus, &:active': {
      outline: 'none',
      borderColor: 'var(--color-blue)',
      color: 'var(--color-blue)',
    },
    '@media screen and (max-width: 768px)': {
      marginBottom: `${theme.spacing(22.5)}`,
    },
  },
}));

const AddPhoto = () => {
  const css = useStyles();
  const history = useHistory();
  const app = useContext(ApplicationContext);
  const [selectedFile, setSelectedFile] = useState<any>();

  const goToNextPage = () => history.push('/signup/social');
  const handlePushNextPage = () => {
    if (selectedFile) {
      app.updateNewUserProperties({
        avatar_blob: selectedFile,
        avatarSrc: selectedFile && URL.createObjectURL(selectedFile),
      });
    }
    goToNextPage();
  };

  const onFileChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSelectedFile(e.target.files?.[0]);
  }, []);

  const avatarSrc = selectedFile && URL.createObjectURL(selectedFile);

  return (
    <div className={css.wrap}>
      <AnimatedPage animation="horizontal" className={css.animationContainer}>
        <div className={css.container}>
          <BackButton />

          <Box mb={14.5} mt={10.5}>
            <Text size="xl" as="p" semibold>
              Добавьте ваше фото
            </Text>
          </Box>

          <Box mb={12.25} className={clsx(css.skeletonContainer)}>
            <Box className={css.centered}>
              <Box className={css.avatar}>
                {avatarSrc ? (
                  <label>
                    <Avatar.Medium img={avatarSrc} className={css.avatarBtn} border />
                    <input type="file" className={css.inputfile} onChange={onFileChange} />
                  </label>
                ) : (
                  <EmptyContent.Circle size="large" className={clsx(css.centered, css.emptyAva)}>
                    <Icons.Person height={29} width={25} viewBox="0 0 29 25" />
                  </EmptyContent.Circle>
                )}
              </Box>
              <Box className={css.column}>
                <EmptyContent.Line height={17} size="large" />
                <EmptyContent.Line width="80%" height={17} size="small" />
              </Box>
            </Box>
            <Box>
              <EmptyContent.Line width="80%" height={17} size="medium" />
            </Box>
            <Box className={css.row}>
              <EmptyContent.Square size="large" />
              <EmptyContent.Square size="large" />
              <EmptyContent.Square size="large" />
            </Box>
          </Box>

          <Box className={css.centered}>
            {avatarSrc ? (
              <Button secondary className={css.button} onClick={handlePushNextPage}>
                Далее
              </Button>
            ) : (
              <>
                <input
                  className={css.inputfile}
                  type="file"
                  id="avatar"
                  name="avatar"
                  onChange={onFileChange}
                  accept="image/*"
                  multiple={false}
                />
                <label className={css.buttonFile} htmlFor="avatar">
                  Добавить фото
                </label>
              </>
            )}
          </Box>
          <Box className={css.centered}>
            <Button secondary className={css.buttonSkip} onClick={() => goToNextPage()}>
              Пропустить
            </Button>
          </Box>
        </div>
      </AnimatedPage>
    </div>
  );
};

export default AddPhoto;
