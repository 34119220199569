import React from 'react';

import { FieldState } from '_types/Field';

import { widgets } from '../widgets';

interface SlotProps {
  field: FieldState
}

const Slot = ({ field }: SlotProps) => {
  if (!field?.type) {
    return null;
  }

  // @ts-ignore
  const Widget = widgets[field.type];

  return <Widget {...field} />;
};

export default Slot;
