import React from 'react';
import classNames from 'classnames/bind';
import styles from './subscription-options.module.css';

const cx = classNames.bind(styles);

type SubscriptionOptionsProps = {
  children: React.ReactNode;
};

export const SubscriptionOptions = ({
  children,
}: SubscriptionOptionsProps) => (
  <>
    {
      React.Children
        .toArray(children)
        .map((child) => (
          <div className={cx('subscription-option')}>
            {child}
          </div>
        ))
    }
  </>
);
