import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { observer } from 'mobx-react';

import { isEqual } from 'lodash';
import Config from 'config';
import axios from 'axios';

import Media from 'react-media';
import { EnumApartment } from '_enums';
import { ObjectServerProps } from '_types';
import { URLParams } from '../_types/urlParams';

import { ApplicationContext } from 'global-stores/application-context';
import { SelectionsContext } from 'global-stores/selections-context';
import { ObjectFilterFieldsContext } from 'global-stores/fields';
import { useParams } from '../_helpers/add-params';
import { detectConvertedValue } from './helpers';

import SearchField from 'ui/components/SearchField';
import Tag from 'ui/components/Tag';
import FieldWrap, { Backdrop, HorizontalList, Label } from 'ui/components/FieldWrap';
import Select from 'ui/components/Select';
import Button from 'ui/components/Button';
import ObjectSearchResults from 'ui/components/ObjectSearchResults';
import Pagination from 'ui/components/Pagination';
import Slot from 'ui/components/Slot';

import { agentsFilterFields } from './filterData';

import ObjectStyles from '../Objects/Objects.module.css';
import styles from './Agents.module.css';
import { NotFound } from '../components/not-found';
import { sendMetrik } from '_helpers/metrika';
import { Sort, SortDirections, SortTypes } from './sort';

interface SearchResponse {
  ads: ObjectServerProps[];
  meta: {
    total: number;
  };
}

interface OnChangeProps {
  selected: number;
}

const Agents = () => {
  const location = useLocation();

  const applicationContext = useContext(ApplicationContext);
  const selectionsContext = useContext(SelectionsContext);
  const objectSearchResults = useContext(ObjectFilterFieldsContext);

  const {
    currentUser,
  } = applicationContext;

  const [params, setParam, setParams] = useParams<URLParams>({
    type: EnumApartment.Flat,

    'price[from]': null,
    'price[to]': null,
    'price_for_meter[from]': null,
    'price_for_meter[to]': null,
    'area[from]': null,
    'area[to]': null,

    lift: null,
    new: null,
    appliance: null,
    balcony: null,
    security: null,
    closed_area: null,
    mortgage: null,
    gas: null,
    furniture: null,
    pool: null,
    ready_business: null,
    sauna: null,
    electricity: null,
    townhouse: null,
    ownership_type: null,
    destination: null,
    destinationCommerce: null,
    water: null,
    apartment: null,
    plain_plot: null,
    specific_plot: null,
    guest_house: null,

    rooms: null,

    stage: null,
    level: null,
    furnish: null,
    'floor[from]': null,
    'floor[to]': null,
    'floors_number[from]': null,
    'floors_number[to]': null,
    exclusive: null,
    agency: null,
    mine: null,

    currency: null,
    page: '0',

    name: null,

    city: '',
    city_id: null,

    withClient: null,

    q: null,

    order_attr: 'updated_at',
    order_type: 'desc',
  });

  const [withClient, setWithClient] = useState(params.withClient);

  const defaultParams: URLParams = {
    withClient: null,
    type: EnumApartment.Flat,

    'price[from]': null,
    'price[to]': null,
    'price_for_meter[from]': null,
    'price_for_meter[to]': null,
    'area[from]': null,
    'area[to]': null,

    lift: null,
    new: null,
    appliance: null,
    balcony: null,
    security: null,
    closed_area: null,
    mortgage: null,
    gas: null,
    furniture: null,
    pool: null,
    ready_business: null,
    sauna: null,
    electricity: null,
    townhouse: null,
    ownership_type: null,
    destination: null,
    destinationCommerce: null,
    water: null,
    apartment: null,
    plain_plot: null,
    specific_plot: null,
    guest_house: null,

    rooms: null,

    stage: null,
    level: null,
    furnish: null,
    'floor[from]': null,
    'floor[to]': null,
    'floors_number[from]': null,
    'floors_number[to]': null,
    exclusive: null,
    agency: null,
    mine: null,

    currency: null,
    page: '0',

    name: null,

    city: '',
    city_id: null,

    q: null,

    order_attr: 'updated_at',
    order_type: 'desc',
  };

  const [cacheParams, setCacheParams] = useState({} as URLParams);

  const [isLoading, setIsLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState(params.q || '');

  const [paramsCache, setParamsCache] = useState({} as { [key: string]: any });

  const [ownCity, setOwnCity] = useState(false);

  const PER_PAGE = 10;

  const [filterCollapse, setFilterCollapse] = useState(true);

  const [apartmentList, setApartmentList] = useState({} as SearchResponse);
  const [countOfSetFilter, setCountOfSetFilter] = useState(0);

  const onClickByFilterButton = () => {
    setFilterCollapse(!filterCollapse);

    // Yandex Metrika
    sendMetrik('reachGoal', 'FilterButtonClick');
  };

  const handleClickResetButton = () => {
    objectSearchResults.reset();
    setParams(defaultParams);

    setSearchQuery('');
  };

  const onClickBySearchButton = () => {
    setFilterCollapse(!filterCollapse);
  };

  const getRequestParams = useCallback((q?: string, userPage?: string) => {
    const correctPage = userPage || params.page;

    const requestParams: URLParams = {
      ...params,
      per_page: PER_PAGE,
      page: correctPage ? String(parseInt(correctPage, 10) + 1) : null,
    };

    if (requestParams.type !== EnumApartment.Flat) {
      requestParams.new = null;
      requestParams.apartment = null;
      requestParams.closed_area = null;
      requestParams.lift = null;
      requestParams.balcony = null;
      requestParams.level = null;
    }

    if (requestParams.type === EnumApartment.Flat) {
      requestParams.ownership_type = null;
    }

    if (requestParams.type !== EnumApartment.House) {
      requestParams.townhouse = null;
    }

    if (requestParams.type !== EnumApartment.Land) {
      requestParams.electricity = null;
      requestParams.water = null;
    }

    if (requestParams.type === EnumApartment.Land) {
      requestParams.stage = null;
      requestParams.furnish = null;
      requestParams.security = null;
      requestParams.appliance = null;
      requestParams.furniture = null;
      requestParams['floor[from]'] = null;
      requestParams['floor[to]'] = null;
    }

    if (requestParams.type === EnumApartment.Land) {
      requestParams['floors_number[from]'] = null;
      requestParams['floors_number[to]'] = null;
    }

    if (params.type === EnumApartment.Commerce) {
      requestParams.gas = null;
      requestParams.destination = requestParams.destinationCommerce;
    }

    if (params.type !== EnumApartment.Commerce) {
      requestParams.ready_business = null;
    }

    if (params.type === EnumApartment.House) {
      requestParams.plain_plot = null;
      requestParams.specific_plot = null;
      requestParams.pool = null;
      requestParams.sauna = null;
      requestParams.guest_house = null;
    }

    if ((params.type === EnumApartment.House || params.type === EnumApartment.Land)) {
      requestParams.plain_plot = null;
      requestParams.specific_plot = null;
    }

    requestParams.destinationCommerce = null;

    if (q) {
      params.q = q;
      requestParams.q = q;
    }

    if (requestParams.city && !requestParams.city_id) {
      requestParams.city = '';
    }

    requestParams.q = params.q;

    Object.keys(params).forEach((param) => {
      if (typeof params[param] === 'string' && params[param] === '') {
        delete requestParams[param];
      }
    });

    return requestParams;
  }, [params]);

  const getApartmentList = useCallback(async (q?: string, userPage?: string) => {
    if (!currentUser) {
      return;
    }

    setIsLoading(true);

    const token = localStorage.getItem('token');

    const requestParams = getRequestParams(q, userPage);

    if (requestParams.type === EnumApartment.Land && requestParams.order_attr === 'area') {
      // requestParams.order_attr = 'land_area';
      setParams({
        order_attr: 'land_area',
        order_type: requestParams.order_type,
      });
      return;
    }

    if (requestParams.type !== EnumApartment.Land && requestParams.order_attr === 'land_area') {
      // requestParams.order_attr = 'area';
      setParams({
        order_attr: 'area',
        order_type: requestParams.order_type,
      });
      return;
    }

    axios(`${Config.API_URL}/api/v1/objects/search`, {
      method: 'GET',
      params: requestParams,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then(({ data }) => {
        if (data?.ads) {
          setApartmentList(data);
        }
      })
      .finally(() => {
        setParamsCache(requestParams);
        setIsLoading(false);
      });
  }, [currentUser, getRequestParams, setParams]);

  const onChangeFieldValue = (searchFieldValue: string) => {
    setSearchQuery(searchFieldValue);
  };

  const onKeyDownFieldValue = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      if (searchQuery !== params.q) {
        setFilterCollapse(true);

        setParams({
          ...params,
          page: '0',
          q: searchQuery,
        });
      }
    }
  };

  const onSearch = (q?: string) => {
    if (typeof q !== 'undefined') {
      setParam('q', q);
    } else if (searchQuery !== params.q) {
      setFilterCollapse(true);
      getApartmentList(searchQuery);
    }
  };

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (!isEqual(getRequestParams(), paramsCache)) {
      getApartmentList();
    }
  };

  const onChangeApartmentType = (id: EnumApartment) => {
    setParams({
      ...params,
      type: id,
    });
  };

  function updateQueryParam<T, K extends keyof T>(obj: T, key: K, value: T[K]) {
    setParams({
      ...params,
      [key]: detectConvertedValue<T[K]>(value),
    });
  }

  const onChangeRooms = useCallback((key: string) => {
    if (params.rooms && params.rooms.includes(key)) {
      if (typeof params.rooms === 'object') {
        params.rooms.splice(params.rooms.indexOf(key), 1);
        setParam('rooms', [...params.rooms]);
      }

      if (typeof params.rooms === 'string' && params.rooms === key) {
        setParam('rooms', null);
      }
    } else {
      if (params.rooms && typeof params.rooms === 'string') {
        setParam('rooms', [params.rooms, key]);
      }

      if (params.rooms && typeof params.rooms === 'object') {
        setParam('rooms', [...params.rooms, key]);
      }

      if (!params.rooms) {
        setParam('rooms', [key]);
      }
    }
  }, [params.rooms, setParam]);

  const onChangeServiceOwnershipType = (value: string) => updateQueryParam(params, 'ownership_type', value);
  const onChangeServiceDestination = (value: string) => updateQueryParam(params, 'destination', value);
  const onChangeServiceDestinationCommerce = (value: string) => updateQueryParam(params, 'destinationCommerce', value);

  const onChangeObjectStatus = (value: any) => updateQueryParam(params, 'stage', value);
  const onChangeObjectLevel = (value: any) => updateQueryParam(params, 'level', value);
  const onChangeObjectFurnish = (value: any) => updateQueryParam(params, 'furnish', value);

  const onPagination = ({ selected }: OnChangeProps) => {
    if (String(selected) !== params.page) {
      document.querySelector(`.${ObjectStyles.container}`)
        ?.scrollIntoView({ behavior: 'smooth' });

      setParam('page', String(selected));
    }
  };

  useEffect(() => {
    selectionsContext.setScrollSelection(true);
    selectionsContext.setSmoothScrollSelection(false);

    if (selectionsContext.page !== 0 || selectionsContext.selections === null) {
      selectionsContext.clearSelections();
      selectionsContext.setPage({ selected: 0 });
      selectionsContext.uploadSelections();
    }

    // Fill filter fields values from params values
    const fields = [...agentsFilterFields];
    const fieldsKeys = fields.map((field) => field.type);

    Object.keys(params).forEach((param) => {
      const fieldKey = param.split('[')[0];

      if (fieldsKeys.includes(fieldKey)) {
        // @ts-ignore
        fields[fieldsKeys.indexOf(fieldKey)].values[param].value = String(params[param] || '');
      }
    });

    objectSearchResults.setFields(agentsFilterFields);
  }, [objectSearchResults, selectionsContext, params]);

  useEffect(() => {
    if (!ownCity) {
      return;
    }

    if (currentUser?.city && !params.city) {
      const token = localStorage.getItem('token');

      axios(`${Config.API_URL}/api/v1/location/cities_search`, {
        method: 'GET',
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          q: currentUser.city,
          limit: 10,
        },
      })
        .then(({ data }) => {
          if (data?.cities) {
            setParams({
              city: currentUser.city,
              city_id: data.cities[0].id,
            });
          }
        });
    }
  }, [ownCity, currentUser, params.city, setParams]);

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    if (currentUser?.city && !params.city && !ownCity) {
      setOwnCity(true);

      return;
    }

    if (withClient !== params.withClient) {
      setWithClient(params.withClient);

      return;
    }

    if (isEqual(cacheParams, params)) {
      return;
    }

    if (cacheParams.city && (cacheParams.city !== params.city && cacheParams.city_id === params.city_id)) {
      return;
    }

    if (
      params.page !== '0'
      && !isEqual({ ...cacheParams, page: '0' }, { ...params, page: '0' })
      && params.page === cacheParams.page) {
      setParam('page', '0');
      return;
    }

    getApartmentList(searchQuery);
    setCacheParams(params);
  }, [
    location,
    currentUser,
    params.withClient,
    withClient,
    cacheParams,
    getApartmentList,
    ownCity,
    searchQuery,
    setParam,
    params,
  ]);

  useEffect(() => {
    const getCountOfFilter = () => {
      const listOfParams = { ...params };
      const cleanListOfParams = new Set();

      delete listOfParams.page;
      delete listOfParams.city;
      delete listOfParams.city_id;
      delete listOfParams.type;
      // @ts-ignore
      delete listOfParams.withClient;

      Object.keys(listOfParams).forEach((key) => {
        // @ts-ignore
        if (listOfParams[key]) {
          if (/.*\[/.test(key)) {
            cleanListOfParams.add(key.split('[')[0]);
          } else {
            cleanListOfParams.add(key);
          }
        }
      });

      return cleanListOfParams.size;
    };

    setCountOfSetFilter(getCountOfFilter());
  }, [params]);

  const handleChangeSortType = useCallback((type: SortTypes, direction: SortDirections) => {
    setParams({
      order_attr: type,
      order_type: direction,
    });
  }, [setParams]);

  if (!currentUser && Object.keys(objectSearchResults.fields).length === 0) {
    return (
      <div />
    );
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <Media query="(max-width: 1200px)">
          <HorizontalList column gap={20}>
            <SearchField
              value={searchQuery}
              onKeyDown={onKeyDownFieldValue}
              onChange={onChangeFieldValue}
              onSearch={onSearch}
              isLoading={isLoading}
              city={params.city}
              placeholder="Введите запрос, ЖК, район, улица"
            />
          </HorizontalList>
        </Media>

        <Media query="(min-width: 1201px)">
          <HorizontalList verticalCenter gap={20}>
            <div className={styles['search-field']}>
              <SearchField
                value={searchQuery}
                onKeyDown={onKeyDownFieldValue}
                onChange={onChangeFieldValue}
                onSearch={onSearch}
                isLoading={isLoading}
                city={params.city}
                placeholder="Введите запрос, ЖК, район, улица"
              />
            </div>
          </HorizontalList>
        </Media>

        <div className={styles['apartment-filter']}>
          <HorizontalList className={styles.headerFilters} verticalCenter>
            <FieldWrap>
              <Backdrop>
                <HorizontalList row verticalCenter wrap gap={10}>
                  <Tag
                    id={EnumApartment.Flat}
                    type="radio"
                    text="Квартира"
                    name="apartment-type"
                    checked={params.type === EnumApartment.Flat}
                    onChecked={onChangeApartmentType}
                  />

                  <Tag
                    id={EnumApartment.House}
                    type="radio"
                    text="Дом"
                    name="apartment-type"
                    checked={params.type === EnumApartment.House}
                    onChecked={onChangeApartmentType}
                  />

                  <Tag
                    id={EnumApartment.Land}
                    type="radio"
                    text="Участок"
                    name="apartment-type"
                    checked={params.type === EnumApartment.Land}
                    onChecked={onChangeApartmentType}
                  />

                  <Tag
                    id={EnumApartment.Commerce}
                    type="radio"
                    text="Коммерция"
                    name="apartment-type"
                    checked={params.type === EnumApartment.Commerce}
                    onChecked={onChangeApartmentType}
                  />
                </HorizontalList>
              </Backdrop>
            </FieldWrap>

            {params.type !== EnumApartment.Land && (
              <FieldWrap>
                <Backdrop>
                  <HorizontalList row verticalCenter wrap gap={10}>
                    <Tag
                      id="free"
                      type="checkbox"
                      text="Свободная"
                      name="apartment-size"
                      checked={params.rooms?.includes('free') || false}
                      onChecked={onChangeRooms}
                    />

                    <Tag
                      id="studio"
                      type="checkbox"
                      text="Студия"
                      name="apartment-size"
                      checked={params.rooms?.includes('studio') || false}
                      onChecked={onChangeRooms}
                    />

                    <Media query="(max-width: 1200px)">
                      <br />
                    </Media>

                    <Tag
                      id="1"
                      type="checkbox"
                      text="1"
                      name="apartment-size"
                      checked={params.rooms?.includes('1') || false}
                      onChecked={onChangeRooms}
                    />

                    <Tag
                      id="2"
                      type="checkbox"
                      text="2"
                      name="apartment-size"
                      checked={params.rooms?.includes('2') || false}
                      onChecked={onChangeRooms}
                    />

                    <Tag
                      id="3"
                      type="checkbox"
                      text="3"
                      name="apartment-size"
                      checked={params.rooms?.includes('3') || false}
                      onChecked={onChangeRooms}
                    />

                    <Tag
                      id="4"
                      type="checkbox"
                      text="4"
                      name="apartment-size"
                      checked={params.rooms?.includes('4') || false}
                      onChecked={onChangeRooms}
                    />

                    <Tag
                      id="5"
                      type="checkbox"
                      text="5 и более"
                      name="apartment-size"
                      checked={params.rooms?.includes('5') || false}
                      onChecked={onChangeRooms}
                    />
                  </HorizontalList>
                </Backdrop>
              </FieldWrap>
            )}

            <Media query="(min-width: 1201px)">
              <div className={styles.headerButtons}>
                <Button
                  component="button"
                  theme="black-full"
                  onClick={onClickByFilterButton}
                  arrow
                  collapse={filterCollapse}
                >
                  Фильтр

                  {countOfSetFilter !== 0 && (
                    <span className={styles['count-of-set-filter']}>
                      {countOfSetFilter}
                    </span>
                  )}
                </Button>

                <Button
                  component="button"
                  theme="black"
                  onClick={handleClickResetButton}
                >
                  Сбросить
                </Button>
              </div>
            </Media>
          </HorizontalList>

          <Media query="(max-width: 1200px)">
            <div className={styles.headerButtons}>
              <Button
                component="button"
                theme="black-full"
                onClick={onClickByFilterButton}
                arrow
                collapse={filterCollapse}
              >
                Фильтр

                {countOfSetFilter !== 0 && (
                  <span className={styles['count-of-set-filter']}>
                    {countOfSetFilter}
                  </span>
                )}
              </Button>

              <Button
                component="button"
                theme="black"
                onClick={handleClickResetButton}
              >
                Сбросить
              </Button>
            </div>
          </Media>
        </div>

        {
          !filterCollapse && (
            <div className={styles.filter}>
              <div className={styles['filter-fieldset']}>
                {
                  params.type !== EnumApartment.Flat && (
                    <div className={styles['filter-row']}>
                      <FieldWrap.Column>
                        <Label>
                          Вид права
                        </Label>

                        <Select
                          selected={params.ownership_type}
                          options={[
                            {
                              value: 'no-matter',
                              text: 'Не важно',
                              default: true,
                            },
                            {
                              value: 'property',
                              text: 'Собственность',
                            },
                            {
                              value: 'rental',
                              text: 'Аренда',
                            },
                          ]}
                          onChange={onChangeServiceOwnershipType}
                        />
                      </FieldWrap.Column>

                      {
                        params.type === EnumApartment.Land && (
                          <FieldWrap.Column>
                            <Label>
                              Назначение
                            </Label>

                            <Select
                              selected={params.destination}
                              options={[
                                {
                                  value: 'no-matter',
                                  text: 'Не важно',
                                  default: true,
                                },
                                {
                                  value: 'other',
                                  text: 'Другой',
                                },
                                {
                                  value: 'rest_zone',
                                  text: 'Зона отдыха',
                                },
                                {
                                  value: 'trade_storage',
                                  text: 'Торговля, склад',
                                },
                                {
                                  value: 'gardening',
                                  text: 'Садоводство, земледелие',
                                },
                                {
                                  value: 'trade_storage',
                                  text: 'Аренда',
                                },
                                {
                                  value: 'agricultural',
                                  text: 'Сельскохозяйственное',
                                },
                                {
                                  value: 'industrial',
                                  text: 'Промышленное',
                                },
                                {
                                  value: 'commerce_construction',
                                  text: 'Коммерческое строительство',
                                },
                                {
                                  value: 'multiapartment_building',
                                  text: 'Многоквартирный дом',
                                },
                                {
                                  value: 'izhs',
                                  text: 'ИЖС',
                                },
                                {
                                  value: 'hotel_business',
                                  text: 'Гостиничный бизнес',
                                },
                              ]}
                              onChange={onChangeServiceDestination}
                            />
                          </FieldWrap.Column>
                        )
                      }

                      {
                        params.type === EnumApartment.Commerce && (
                          <FieldWrap.Column>
                            <Label>
                              Назначение
                            </Label>

                            <Select
                              selected={params.destinationCommerce}
                              options={[
                                {
                                  value: 'no-matter',
                                  text: 'Не важно',
                                  default: true,
                                },
                                {
                                  value: 'office',
                                  text: 'Офис',
                                },
                                {
                                  value: 'trade',
                                  text: 'Торговля',
                                },
                                {
                                  value: 'storage',
                                  text: 'Склад',
                                },
                                {
                                  value: 'production',
                                  text: 'Производство',
                                },
                                {
                                  value: 'catering',
                                  text: 'Общепит',
                                },
                                {
                                  value: 'free_destination',
                                  text: 'Свободное назначение',
                                },
                                {
                                  value: 'full_building',
                                  text: 'Здание целиком',
                                },
                                {
                                  value: 'hotel_business',
                                  text: 'Гостиничный бизнес',
                                },
                              ]}
                              onChange={onChangeServiceDestinationCommerce}
                            />
                          </FieldWrap.Column>
                        )
                      }
                    </div>
                  )
                }

                <div className={styles['filter-row']}>
                  <Slot field={objectSearchResults.fields.mortgage} />

                  {
                    params.type === EnumApartment.Flat && (
                      <Slot field={objectSearchResults.fields.new} />
                    )
                  }

                  {
                    params.type === EnumApartment.Flat && (
                      <Slot field={objectSearchResults.fields.apartment} />
                    )
                  }

                  {
                    params.type === EnumApartment.House && (
                      <Slot field={objectSearchResults.fields.townhouse} />
                    )
                  }
                </div>
              </div>

              <div className={styles['filter-fieldset']}>
                <p className={styles['filter-column-title']}>
                  Площадь / Цена
                </p>

                <div className={styles['filter-row']}>
                  <Slot
                    field={objectSearchResults.fields.price}
                  />

                  <Slot
                    field={{
                      ...objectSearchResults.fields.price_for_meter,
                      label: `Цена за ${params.type === EnumApartment.Land ? 'сотку' : 'метр'}`,
                    }}
                  />

                  <Slot
                    field={{
                      ...objectSearchResults.fields.area,
                      label: `${params.type === EnumApartment.Land ? 'Площадь (сотки)' : 'Площадь (м²)'}`,
                    }}
                  />
                </div>
              </div>

              {
                params.type !== EnumApartment.Land && (
                  <div className={styles['filter-fieldset']}>
                    <p className={styles['filter-column-title']}>
                      Дом / Этаж / Ремонт
                    </p>

                    <div className={styles['filter-column']}>
                      <div className={styles['filter-row']}>
                        <FieldWrap.Column>
                          <Label>
                            Состояние дома
                          </Label>

                          <Select
                            selected={params.stage}
                            options={[
                              {
                                value: 'no-matter',
                                text: 'Не важно',
                                default: true,
                              },
                              {
                                value: 'preparing',
                                text: 'Проект',
                              },
                              {
                                value: 'processing',
                                text: 'Строится',
                              },
                              {
                                value: 'ready',
                                text: 'Построен',
                              },
                            ]}
                            onChange={onChangeObjectStatus}
                          />
                        </FieldWrap.Column>

                        {
                          params.type === EnumApartment.Flat && (
                            <FieldWrap.Column>
                              <Label>
                                Класс дома
                              </Label>

                              <Select
                                selected={params.level}
                                options={[
                                  {
                                    value: 'no-matter',
                                    text: 'Не важно',
                                    default: true,
                                  },
                                  {
                                    value: 'economy',
                                    text: 'Эконом',
                                  },
                                  {
                                    value: 'comfort',
                                    text: 'Комфорт',
                                  },
                                  {
                                    value: 'premium',
                                    text: 'Премиум',
                                  },
                                ]}
                                onChange={onChangeObjectLevel}
                              />
                            </FieldWrap.Column>
                          )
                        }

                        <FieldWrap.Column>
                          <Label>
                            Тип отделки
                          </Label>

                          <Select
                            selected={params.furnish}
                            options={[
                              {
                                value: 'no-matter',
                                text: 'Не важно',
                                default: true,
                              },
                              {
                                value: 'clear',
                                text: 'Чистовая',
                              },
                              {
                                value: 'rough',
                                text: 'Черновая',
                              },
                              {
                                value: 'repair_economy',
                                text: 'Эконом',
                              },
                              {
                                value: 'repair_comfort',
                                text: 'Комфорт',
                              },
                              {
                                value: 'repair_premium',
                                text: 'Премиум',
                              },
                            ]}
                            onChange={onChangeObjectFurnish}
                          />
                        </FieldWrap.Column>
                      </div>

                      <div className={styles['filter-row']}>
                        <>
                          {
                            params.type !== EnumApartment.House && (
                              <Slot field={objectSearchResults.fields.floor} />
                            )
                          }

                          <Slot field={objectSearchResults.fields.floors_number} />
                        </>
                      </div>
                    </div>
                  </div>
                )
              }

              <div className={styles['filter-fieldset']}>
                <p className={styles['filter-column-title']}>
                  Услуги / Функции
                </p>

                <div className={styles['filter-row']}>
                  {
                    params.type !== EnumApartment.Land && (
                      <Slot field={objectSearchResults.fields.appliance} />
                    )
                  }

                  {
                    params.type !== EnumApartment.Land && (
                      <Slot field={objectSearchResults.fields.furniture} />
                    )
                  }

                  {
                    params.type !== EnumApartment.Land && (
                      <Slot field={objectSearchResults.fields.security} />
                    )
                  }

                  {
                    params.type === EnumApartment.Flat && (
                      <Slot field={objectSearchResults.fields.closed_area} />
                    )
                  }

                  {
                    params.type === EnumApartment.Flat && (
                      <Slot field={objectSearchResults.fields.lift} />
                    )
                  }

                  {
                    params.type !== EnumApartment.Commerce && (
                      <Slot field={objectSearchResults.fields.gas} />
                    )
                  }

                  {
                    params.type === EnumApartment.Flat && (
                      <Slot field={objectSearchResults.fields.balcony} />
                    )
                  }

                  {
                    (params.type === EnumApartment.House || params.type === EnumApartment.Land) && (
                      <Slot field={objectSearchResults.fields.plain_plot} />
                    )
                  }

                  {
                    (params.type === EnumApartment.House || params.type === EnumApartment.Land) && (
                      <Slot field={objectSearchResults.fields.specific_plot} />
                    )
                  }

                  {
                    params.type === EnumApartment.House && (
                      <Slot field={objectSearchResults.fields.guest_house} />
                    )
                  }

                  {
                    params.type === EnumApartment.House && (
                      <Slot field={objectSearchResults.fields.sauna} />
                    )
                  }

                  {
                    params.type === EnumApartment.Land && (
                      <Slot field={objectSearchResults.fields.electricity} />
                    )
                  }

                  {
                    params.type === EnumApartment.Land && (
                      <Slot field={objectSearchResults.fields.water} />
                    )
                  }

                  {
                    params.type === EnumApartment.House && (
                      <Slot field={objectSearchResults.fields.pool} />
                    )
                  }

                  {
                    params.type === EnumApartment.Commerce && (
                      <Slot field={objectSearchResults.fields.ready_business} />
                    )
                  }
                </div>
              </div>

              <div className={styles['filter-fieldset']}>
                <div className={styles['filter-row']}>
                  <Slot field={objectSearchResults.fields.exclusive} />
                  <Slot field={objectSearchResults.fields.agency} />
                  <Slot field={objectSearchResults.fields.mine} />
                </div>

                <div className={styles.filterButtons}>
                  <Button
                    component="button"
                    theme="black-full"
                    width="wider"
                    onClick={onClickBySearchButton}
                  >
                    Найти
                  </Button>
                </div>
              </div>
            </div>
          )
        }
      </form>

      <div className={styles.sort}>
        <Sort
          type={params.order_attr === 'land_area' ? 'area' : params.order_attr}
          direction={params.order_type}
          onChange={handleChangeSortType}
        />
      </div>

      {
        apartmentList.ads?.length > 0
          ? (
            <>
              <ObjectSearchResults
                apartments={apartmentList.ads}
                total={apartmentList.meta.total}
                disabled={isLoading}
              />

              <div className={styles.pagination}>
                <Media query="(max-width: 425px)">
                  {
                    (matches) => (
                      matches
                        ? (
                          <Pagination
                            pageCount={Math.ceil((apartmentList.meta.total / PER_PAGE))}
                            onPageChange={onPagination}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            forcePage={params.page ? parseInt(params.page, 10) : 0}
                            disabled={isLoading}
                          />
                        )
                        : (
                          <Pagination
                            pageCount={Math.ceil((apartmentList.meta.total / PER_PAGE))}
                            onPageChange={onPagination}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            forcePage={params.page ? parseInt(params.page, 10) : 0}
                            disabled={isLoading}
                          />
                        )
                    )
                  }
                </Media>
              </div>
            </>
          )
          : (
            <p className={styles.notFound}>
              {
                isLoading || typeof apartmentList.ads === 'undefined'
                  ? 'Загрузка...'
                  : (
                    <NotFound
                      apartmentType={params.type}
                      onReset={handleClickResetButton}
                    />
                  )
              }
            </p>
          )
      }
    </>
  );
};

export default React.memo(observer(Agents));
