import React from 'react';
import { CreateSvgIcon } from '../icon';
import { IconProps } from '../names';

export const QuestionMark = (props: IconProps) => (
  <CreateSvgIcon {...props}>
    {({ color }) => (
      <>
        <circle cx="15" cy="15" r="13" stroke={color} strokeWidth="4" />
        <path
          d="M14.0498 18.0713C14.0498 17.251 14.1494 16.5977 14.3486 16.1113C14.5479 15.625 14.9111 15.1475 15.4385 14.6787C15.9717 14.2041 16.3262 13.8203 16.502 13.5273C16.6777 13.2285 16.7656 12.915 16.7656 12.5869C16.7656 11.5967 16.3086 11.1016 15.3945 11.1016C14.9609 11.1016 14.6123 11.2363 14.3486 11.5059C14.0908 11.7695 13.9561 12.1357 13.9443 12.6045H11.3955C11.4072 11.4854 11.7676 10.6094 12.4766 9.97656C13.1914 9.34375 14.1641 9.02734 15.3945 9.02734C16.6367 9.02734 17.6006 9.3291 18.2861 9.93262C18.9717 10.5303 19.3145 11.377 19.3145 12.4727C19.3145 12.9707 19.2031 13.4424 18.9805 13.8877C18.7578 14.3271 18.3682 14.8164 17.8115 15.3555L17.0996 16.0322C16.6543 16.46 16.3994 16.9609 16.335 17.5352L16.2998 18.0713H14.0498ZM13.7949 20.7695C13.7949 20.377 13.9268 20.0547 14.1904 19.8027C14.46 19.5449 14.8027 19.416 15.2188 19.416C15.6348 19.416 15.9746 19.5449 16.2383 19.8027C16.5078 20.0547 16.6426 20.377 16.6426 20.7695C16.6426 21.1562 16.5107 21.4756 16.2471 21.7275C15.9893 21.9795 15.6465 22.1055 15.2188 22.1055C14.791 22.1055 14.4453 21.9795 14.1816 21.7275C13.9238 21.4756 13.7949 21.1562 13.7949 20.7695Z"
          fill={color}
        />
      </>
    )}
  </CreateSvgIcon>
);
