import React, { useRef, useState } from 'react';
import uniqueId from 'lodash/uniqueId';
import noop from 'lodash/noop';
import { makeStyles } from 'hooks/makeStyles';
import clsx from 'clsx';
import { Typography } from '../typography/typography';
import { OverlayPositioner } from '../dropdown/overlay-positioner';
import { Icons } from '../icons';

interface TooltipProps {
  text: string
  multiline?: boolean
  children: React.ReactNode
  top?: boolean
  right?: boolean
  tailLeft?: boolean
  tailRight?: boolean
  center?: boolean,
  header?: () => React.ReactNode
  footer?: () => React.ReactNode
}

const useStyles = makeStyles((theme) => ({
  tooltipRootContainer: {
    position: 'relative',
  },
  tooltipContainer: {
    width: '100%',
    maxWidth: 320,
    padding: theme.spacing(3),
    margin: theme.spacing(2),
    borderRadius: 10,
    backgroundColor: theme.colors.background.paper.dark,
  },
  tail: {
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  tailTop: {
    top: -1,
  },
  tailBottom: {
    bottom: -1,
    transform: 'rotate(180deg)',
  },
  tailRight: {
    right: 3,
  },
  tailLeft: {
    left: 3,
  },
  singleLine: {
    whiteSpace: 'nowrap',
  },
  multiline: {
    whiteSpace: 'unset',
    minWidth: 200,
  },
}));

export const Tooltip = ({
  text,
  multiline,
  children,
  top,
  right,
  center,
  header,
  footer,
  tailLeft,
  tailRight,
}: TooltipProps) => {
  const [open, setOpen] = useState(false);
  const overlayRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLButtonElement>(null);
  const firstChild = React.Children.toArray(children)[0] as React.ReactElement<any>;

  const css = useStyles();

  const overlayId = uniqueId('overlay-');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={css.tooltipRootContainer}>
      {React.cloneElement(firstChild, { ref: triggerRef })}
      <OverlayPositioner
        preferCenter={center}
        preferTop={top}
        preferRight={right}
        trigger={triggerRef}
        open={handleOpen}
        close={handleClose}
        visible={open}
        overlayId={overlayId}
        onOverlayKeyDown={noop}
        onTriggerKeyUp={noop}
        onOverlayBlur={noop}
        on="hover"
        role="tooltip"
        ref={overlayRef}
      >
        <div className={css.tooltipContainer}>
          {header ? header() : null}
          <Typography
            size="caption(12px)"
            align="center"
            className={clsx({
              [css.singleLine]: !multiline,
              [css.multiline]: multiline,
            })}
            color="white"
            font="semibold"
          >
            {text}
          </Typography>
          <Icons.TooltipTail
            color="#222222"
            width={32}
            height={32}
            className={clsx(css.tail, {
              [css.tailTop]: !top,
              [css.tailBottom]: top,
              [css.tailLeft]: tailLeft,
              [css.tailRight]: tailRight,
            })}
          />
          {footer ? footer() : null}
        </div>
      </OverlayPositioner>
    </div>
  );
};
