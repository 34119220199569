import React from 'react';
import cn from 'classnames';

import { declOfNum, getFurnish, getLevel, getStatus, getApartmentSize } from '_helpers';

import { EnumApartment } from '_enums';
import { ObjectServerProps } from '_types';

import styles from './GeneralInfo.module.css';

interface GeneralInfoProps {
  className?: string;
  object: ObjectServerProps;
}

const GeneralInfo = ({ className, object: { latitude: lat, longitude: lng, region, ...object } }: GeneralInfoProps) => (
  <div className={cn(className, styles.tableWrap)}>
    <table className={styles.table}>
      <tbody>
        {
        object.name && (
          <tr>
            <td>
              {EnumApartment.House === object.type ? 'Коттеджный посёлок' : 'Жилой комплекс'}
            </td>

            <td>
              {object.name}
            </td>
          </tr>
        )
      }

        {
        object.area && (
          <tr>
            <td>
              Общая площадь
            </td>

            <td>
              {object.area}м²
            </td>
          </tr>
        )
      }

        {
        !!object.land_area && (
          <tr>
            <td>
              Площадь участка
            </td>

            <td>
              {object.land_area} {declOfNum(object.land_area, [' сотка', ' сотки', ' соток'])}
            </td>
          </tr>
        )
      }

        {
        object.ownership_type && (
          <tr>
            <td>
              Вид права
            </td>

            <td>
              {object.ownership_type === 'property' && 'Собственность'}
              {object.ownership_type === 'rental' && 'Долгосрочная аренда'}
            </td>
          </tr>
        )
      }

        {
        object.home_number && (
          <tr>
            <td>
              Номер дома
            </td>

            <td>
              {object.home_number}
            </td>
          </tr>
        )
      }

        {
        object.rooms && (
          <tr>
            <td>
              Комнат
            </td>

            <td>
              {getApartmentSize({ rooms: object.rooms, type: object.type, floors_number: object.floors_number })}
            </td>
          </tr>
        )
      }

        {
        (object.floor && object.floors_number) && (
          <tr>
            <td>
              Этаж
            </td>

            <td>
              {object.floor} из {object.floors_number}
            </td>
          </tr>
        )
      }

        {
        object.stage && (
          <tr>
            <td>
              Состояние дома
            </td>

            <td>
              {getStatus(object.stage)}
            </td>
          </tr>
        )
      }

        {
        object.level && (
          <tr>
            <td>
              Класс дома
            </td>

            <td>
              {getLevel(object.level)}
            </td>
          </tr>
        )
      }

        {
        object.furnish && (
          <tr>
            <td>
              Тип отделки
            </td>

            <td>
              {getFurnish(object.furnish)}
            </td>
          </tr>
        )
      }

        {
        !![
          object.parking_covered,
          object.parking_underground,
          object.parking_near_house,
          object.parking_near,
        ].filter(Boolean).length && (
          <tr>
            <td>
              Парковка
            </td>

            <td>
              {
                [
                  object.parking_covered && 'Крытая',
                  object.parking_underground && 'Подземная',
                  object.parking_near_house && 'Возле дома',
                  object.parking_near && 'Рядом',
                ].filter(Boolean).join(', ')
              }
            </td>
          </tr>
        )
      }

        {
        !![
          object.hotel_business,
          object.izhs,
          object.multiapartment_building,
          object.commerce_construction,
          object.industrial,
          object.agricultural,
          object.gardening,
          object.trade_storage,
          object.rest_zone,
          object.other,
        ].filter(Boolean).length && (
          <tr>
            <td>
              Назначение земли
            </td>

            <td>
              {
                [
                  object.hotel_business && 'Гостиничный бизнес',
                  object.izhs && 'ИЖС',
                  object.multiapartment_building && 'Многоквартирный дом',
                  object.commerce_construction && 'Коммерческое строительство',
                  object.industrial && 'Промышленное',
                  object.agricultural && 'Сельскохозяйственное',
                  object.gardening && 'Садоводство, земледелие',
                  object.trade_storage && 'Торговля, склад',
                  object.rest_zone && 'Зона отдыха',
                  object.other && 'Другой',
                ].filter(Boolean).join(', ')
              }
            </td>
          </tr>
        )
      }

        {
        !![
          object.hotel_business,
          object.office,
          object.trade,
          object.storage,
          object.production,
          object.catering,
          object.free_destination,
          object.full_building,
        ].filter(Boolean).length && (
          <tr>
            <td>
              Назначение
            </td>

            <td>
              {
                [
                  object.office && 'Офис',
                  object.trade && 'Торговля',
                  object.storage && 'Склад',
                  object.production && 'Производство',
                  object.catering && 'Общепит',
                  object.free_destination && 'Свободное назначение',
                  object.full_building && 'Здание целиком',
                  object.hotel_business && 'Гостиничный бизнес',
                ].filter(Boolean).join(', ')
              }
            </td>
          </tr>
        )
      }
      </tbody>
    </table>
  </div>
);

export default GeneralInfo;
