import React, { useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { isUndefined, isNull } from 'lodash/fp';
import { searchCities } from 'domains/objects/requests';
import { useParams } from 'ui/pages/objects/_helpers/add-params';
import { URLParams } from 'ui/pages/objects/_types/urlParams';
import { Text } from 'components/text';
import { City } from './types';
import styles from './search-city-field.module.css';

const cx = classNames.bind(styles);

export const SearchCityField = () => {
  const wrapRef = useRef<HTMLInputElement>(null);
  const fieldRef = useRef<HTMLInputElement>(null);

  const [params, setParam] = useParams<URLParams>();

  const [selected, setSelected] = useState(false);
  const [focusedCity, setFocusedCity] = useState<City | null>(null);
  const [cities, setCities] = useState<City[]>([]);

  const handleSearchCities = (q: string) => {
    Promise.any([
      searchCities(q),
    ]).then((cityList) => setCities(cityList));
  };

  const handleChangeCity = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelected(false);
    setParam('city', e.target.value);
    handleSearchCities(e.target.value);
  };

  const handleFocus = () => {
    if (cities.length !== 0 && !isUndefined(params.city)) {
      return;
    }

    // @ts-ignore
    handleSearchCities(params.city);
  };

  const handleKeyUp = (e: React.KeyboardEvent) => {
    if (e.code === 'ArrowDown') {
      setFocusedCity(cities[0]);

      (document.querySelector(
        `[data-marker="search-city-field/dropdown-item-${cities[0].id}"]`,
      ) as HTMLElement).focus();
    }
  };

  const handleKeyUpDropdown = (e: React.KeyboardEvent) => {
    if (!focusedCity) {
      return;
    }

    const focusedElement = document.querySelector(
      `[data-marker="search-city-field/dropdown-item-${focusedCity.id}"]`,
    ) as HTMLElement;

    const prevFocusedElement = focusedElement.previousElementSibling as HTMLDivElement;
    const nextFocusedElement = focusedElement.nextElementSibling as HTMLDivElement;

    if (e.code === 'ArrowUp') {
      if (focusedElement && prevFocusedElement) {
        prevFocusedElement.focus();
        setFocusedCity(
          cities[cities.indexOf(focusedCity) - 1],
        );
      }

      if (focusedElement && isNull(prevFocusedElement)) {
        fieldRef.current?.focus();
      }
    }

    if (e.code === 'ArrowDown') {
      if (focusedElement && nextFocusedElement) {
        nextFocusedElement.focus();
        setFocusedCity(
          cities[cities.indexOf(focusedCity) + 1],
        );
      }
    }

    if (!['Enter', 'Space', 'ArrowUp', 'ArrowDown'].includes(e.code) && e.key.length === 1) {
      if (params.city?.length === 0) {
        setParam('city', params.city + e.key.toUpperCase());
      } else {
        setParam('city', params.city + e.key.toLowerCase());
      }

      fieldRef.current?.focus();
    }
  };

  const handleKeyDownDropdown = (e: React.KeyboardEvent) => {
    if (e.code === 'ArrowDown' || e.code === 'ArrowUp') {
      e.preventDefault();
    }
  };

  const setCity = (city: City) => {
    setParam('city_id', city.id);
    setParam('city', city.name);

    setFocusedCity(null);
    setCities([]);

    wrapRef.current?.focus();

    setSelected(true);
  };

  const handleKeyDownDropdownItem = (city: City) => (e: React.KeyboardEvent) => {
    if (['Enter', 'Space'].includes(e.code)) {
      e.preventDefault();
      setCity(city);
    }
  };

  const handleClickDropdownItem = (city: City) => () => {
    setCity(city);
  };

  return (
    <div
      className={cx('wrap', {
        wrap_dropdown: cities.length !== 0 && !selected,
      })}
      ref={wrapRef}
      tabIndex={0}
    >
      <input
        className={cx('field')}
        ref={fieldRef}
        value={params.city}
        onChange={handleChangeCity}
        onFocus={handleFocus}
        onKeyUp={handleKeyUp}
        placeholder="Город"
      />
      <div
        className={cx('dropdown')}
        onKeyDown={handleKeyDownDropdown}
        onKeyUp={handleKeyUpDropdown}
      >
        {!selected && cities.map((city) => (
          <div
            className={cx('dropdown-item')}
            key={city.id}
            tabIndex={0}
            data-marker={`search-city-field/dropdown-item-${city.id}`}
            onKeyDown={handleKeyDownDropdownItem(city)}
            onMouseDown={handleClickDropdownItem(city)}
            onTouchEnd={handleClickDropdownItem(city)}
          >
            <Text>
              {city.name}
            </Text>
          </div>
        ))}
      </div>
    </div>
  );
};
