import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import { TabsProps, TabAsButtonProps, TabAsLinkProps } from './types';
import styles from './styles.module.css';

const cx = classNames.bind(styles);

export const Tabs = ({
  children,
}: TabsProps) => (
  <div className={cx('wrap')}>
    <div className={cx('tabs')}>
      {children}
    </div>
  </div>
);

export const TabAsButton = ({
  active,
  children,
  onClick,
}: TabAsButtonProps) => (
  <button
    className={cx('tab', {
      'tab-active': active,
    })}
    type="button"
    onClick={onClick}
  >
    {children}
  </button>
);

export const TabAsLink = (props: TabAsLinkProps) => {
  const { active, children } = props;

  if (props.component === 'route') {
    return (
      <Link
        className={cx('tab', {
          'tab-active': active,
        })}
        to={props.to}
      >
        {children}
      </Link>
    );
  }

  return (
    <a
      className={cx('tab', {
        'tab-active': active,
      })}
      href={props.href}
      rel={props.rel}
      target={props.target}
    >
      {children}
    </a>
  );
};
