import React, {
  useState, useContext, createRef, useCallback } from 'react';
import Media from 'react-media';
import { Theme } from 'lib/theme';
import { makeStyles } from 'hooks/makeStyles';
import { Link, useHistory } from 'react-router-dom';
import copyToClipboard from 'copy-to-clipboard';
import CopyToClipboardPopup from 'ui/components/CopyToClipboardPopup';
import { format as formatDateFns, parseISO } from 'date-fns';
import ru from 'date-fns/locale/ru';
import {
  IconButton, Icons, Typography, Box, Input, Button,
} from 'ui/shared';

import logo from 'assets/logo.svg';
import { fade } from 'utils';
import { observer } from 'mobx-react';
import { find } from 'lodash/fp';
import { ApplicationContext } from '../../../global-stores/application-context';
import { USER_TYPES } from '../../pages/profile/pages/edit-page/edit-profile';
import { AppStoresMob } from '../app-stores/app-stores';
import { Menu } from './menu';
import { ExitButton } from '../../../components/exit-button';
import { Text } from 'components/text';
import { sendMetrik } from '_helpers';
import PromocodeForm from '../../../components/promocode-form';

const useStyles = makeStyles((theme: Theme) => ({
  sidebar: {
    minHeight: '100vh',
    background: theme.colors.background.paper.default,
    borderRight: `0.5px solid ${theme.colors.grey.tertiary}`,
  },
  sidebarWrap: {
    padding: '32px 24px 48px',
  },
  sidebarSmall: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: `${theme.spacing(5)} 10px ${theme.spacing(25)}`,
  },
  logoWrapSmall: {
    justifyContent: 'center',
    marginBottom: 120,
  },
  row: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  logo: {
    height: 56,
    marginRight: theme.spacing(3.75),
    '@media (max-width: 768px)': {
      height: 'auto',
      maxWidth: 'calc(100% - 120px)',
    },
  },
  logoSmall: {
    display: 'none',
  },
  title: { fontSize: 22 },
  beta: {
    fontSize: 16,
    color: '#FF6565',
  },
  subtitle: {},
  caption: {
    fontSize: 24,
  },
  titleWrap: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  exitBtn: {
    position: 'absolute',
    top: 48,
    right: 24,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    font: 'inherit',
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
    },
  },
  exitLabel: {
    fontSize: 20,
    marginRight: `${theme.spacing(2.25)}`,
    textTransform: 'capitalize',
  },
  input: {
    backgroundColor: theme.colors.background.paper.darkGrey,
    borderRadius: 10,
    border: 'none',
    padding: `${theme.spacing(2.8)} ${theme.spacing(8.75)}`,
    fontSize: 16,
    '&:focus': {
      // border: `2px solid ${theme.colors.blue.main}`,
      border: 'none',
    },
  },
  tabs: {
    display: 'flex',
    flexDirection: 'column',
  },
  tabWrap: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    textTransform: 'none',
    width: '100%',
  },
  icon: {
    marginRight: theme.spacing(3.25),
    marginLeft: theme.spacing(1.25),
    width: theme.spacing(5.5),
    height: 'auto',
  },
  buttonsWrap: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    marginBottom: 56,
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: `0 ${theme.spacing(3.75)}`,
    fontSize: 13,
    textAlign: 'center',
  },
  buttonIcon: {
    width: 53,
    height: 53,
    marginBottom: theme.spacing(2.5),
    backgroundColor: theme.colors.background.paper.darkGrey,
    '&:focus, &:hover': {
      backgroundColor: fade(theme.colors.common.black, 0.1),
    },
  },
  buttonsWrapVertical: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > *': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 40,
      height: 40,
      margin: 0,
      backgroundColor: 'transparent',
      '& > *': {
        width: 30,
        height: 30,
        backgroundColor: 'transparent',
        marginBottom: 0,
      },
    },
  },
  qrCodeLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  stock: {
    marginTop: 40,
    marginBottom: 16,
    padding: `${theme.spacing(5.75)}`,
    backgroundColor: theme.colors.background.paper.darkGrey,
    borderRadius: 10,
    textAlign: 'center',
  },
  stockTitle: {
    display: 'inline-flex',
    alignItems: 'center',
    marginBottom: `${theme.spacing(1.5)}`,
  },
  label: {
    padding: `${theme.spacing(1.25)} ${theme.spacing(2.75)}`,
    backgroundColor: theme.colors.red.main,
    textTransform: 'uppercase',
    color: theme.colors.textSecondary,
    borderRadius: 6,
    marginLeft: `${theme.spacing(2)}`,
    fontSize: 18,
  },
  stockTextGray: {
    color: theme.colors.textMiddleGrey,
    fontSize: 15,
  },
  paymentBtn: {
    borderRadius: 10,
    color: theme.colors.textSecondary,
    textTransform: 'uppercase',
    height: 44,
    padding: `${theme.spacing(2.25)} ${theme.spacing(9)}`,
  },
  stores: {
    backgroundColor: 'transparent',
    color: theme.colors.textBlack,
    flexDirection: 'column',
    padding: 0,
    marginTop: theme.spacing(9.5),
  },
  proLabel: {
    fontSize: '15px',
  },
}));

const Sidebar = observer(() => {
  const history = useHistory();
  const css = useStyles();
  const app = useContext(ApplicationContext);
  const linkInput = createRef<HTMLInputElement>();

  const { currentUser } = app;
  const userType = find({ type: currentUser?.user_type }, USER_TYPES) as any;

  const copyProfileLink = (e: any) => {
    if (!e.isDefaultPrevented()) e.preventDefault();
    if (linkInput?.current) {
      linkInput.current.select();
      document.execCommand('copy');
    }
  };

  const [copy, setCopy] = useState(false);
  const [copyTimeout, setCopyTimeout] = useState(null as any);

  const handleClick = useCallback(() => {
    sendMetrik('reachGoal', 'BuyNowMenuButtonClick');
    history.push('/services/subscriptions');
  }, [history]);

  const shareProfile = (e: any) => {
    e.preventDefault();
    if (navigator.share) {
      navigator
        .share({
          title: `${currentUser?.first_name} ${currentUser?.last_name} ${userType?.description} по недвижимости`,
          text: `${currentUser?.first_name} ${currentUser?.last_name} ${userType?.description} по недвижимости \n${currentUser?.description}`,
          url: `${currentUser?.www_link} `,
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    } else {
      console.log('Web Share API is not supported in your browser');

      if (linkInput?.current?.value) {
        copyToClipboard(linkInput?.current?.value);
      }
      setCopy(true);

      clearTimeout(copyTimeout);

      setCopyTimeout(setTimeout(() => {
        setCopy(false);
      }, 3000));
    }
  };

  const expiresDate = () => {
    if (!currentUser || !currentUser?.subscription_expires_date || !!currentUser?.expired) {
      return (
        <Button
          onClick={handleClick}
          className={css.paymentBtn}
        >
          Стать участником
        </Button>
      );
    }

    const formatted = formatDateFns(parseISO(currentUser?.subscription_expires_date), 'dd MMMM yyyy', { locale: ru });

    return (
      <Typography font="semibold" size="subtitle1(18px)" className={css.stockTextGray}>
        Действует до
        {' '}
        {formatted}
      </Typography>
    );
  };

  const StockBox = () => (
    <Box className={css.stock}>
      <div className={css.stockTitle}>
        {currentUser?.expired && (
          <Typography font="semibold" className={css.proLabel} color="black">
            Ассоциация Профессиональных Риэлторов
          </Typography>
        )}
      </div>
      {expiresDate()}
      {/* Выпилить нахер после НГ 2022 */}
      <PromocodeForm />
    </Box>
  );

  return (
    <div
      className={`
        ${css.sidebar}
        ${app.sidebarSmall && css.sidebarSmall}
      `}
    >
      <div className={css.sidebarWrap}>
        <Box
          className={`
            ${css.row}
            ${app.sidebarSmall && css.logoWrapSmall}
          `}
          mb={6}
        >
          <img
            className={`
              ${css.logo}
              ${app.sidebarSmall && css.logoSmall}
            `}
            src={logo}
            alt="ASP logo"
          />
        </Box>

        {
          !app.sidebarSmall && (
            <Box mb={6}>
              <div className={css.titleWrap}>
                <Text size="m" semibold>
                  Ссылка на мой цифровой профиль
                </Text>

                <Media query="(max-width: 768px)">
                  <div className={css.exitBtn}>
                    <ExitButton />
                  </div>
                </Media>
              </div>

              <Input
                // onChange={handle("")}
                placeholder="Вставьте ссылку на профиль"
                // error={!!loginStore.usernameError}
                // succeed={!!loginStore.message}
                onFocus={(e) => {
                  e.target.select();
                }}
                readOnly
                ref={linkInput}
                type="text"
                height="40px"
                className={css.input}
                value={currentUser?.www_link || ''}
              />
            </Box>
          )
        }

        <div
          className={`
            ${css.buttonsWrap}
            ${app.sidebarSmall && css.buttonsWrapVertical}
          `}
        >
          <div className={css.button}>
            <IconButton className={css.buttonIcon} onClick={copyProfileLink}>
              <Icons.Copy height={33} width={33} />
            </IconButton>
            {
              !app.sidebarSmall && (
                <>
                  Скопировать
                </>
              )
            }
          </div>
          <div className={css.button}>
            <IconButton className={css.buttonIcon}>
              <a href={currentUser?.qr_code} target="_blank" rel="noreferrer" className={css.qrCodeLink}>
                <Icons.QrCode height={26} width={26} />
              </a>
            </IconButton>
            {
              !app.sidebarSmall && (
                <>
                  QR код
                </>
              )
            }
          </div>
          {!!navigator.share && (
            <div className={css.button}>
              <IconButton className={css.buttonIcon} onClick={shareProfile}>
                <Icons.Share height={32} width={26} viewBox="0 0 32 32" color="#136FFB" />
              </IconButton>
              {
                !app.sidebarSmall && (
                  <>
                    Поделиться
                  </>
                )
              }
            </div>
          )}
        </div>

        <Menu onlyIcons={app.sidebarSmall} />

        {
          !app.sidebarSmall && (
            <>
              {
                currentUser && (
                  <StockBox />
                )
              }

              {
                currentUser && !currentUser?.expired && (
                  <AppStoresMob isShow className={css.stores} />
                )
              }
            </>
          )
        }
        <CopyToClipboardPopup active={copy} />
      </div>
    </div>
  );
});

export default Sidebar;
