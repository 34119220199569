export const getShortPhoneNumber = (phoneNumber: string) => {
  if (phoneNumber.length > 12) {
    return '';
  }

  if (phoneNumber[0] === '+' && phoneNumber[1] === '7') {
    return phoneNumber.substr(2);
  }

  if (phoneNumber[0] === '+' && phoneNumber[1] === '8') {
    return phoneNumber.substr(2);
  }

  if (phoneNumber[0] === '7' || phoneNumber[0] === '8') {
    return phoneNumber.substr(1);
  }

  return '';
};
