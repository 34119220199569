import React from 'react';

import { ObjectServerProps } from '_types';
import { declOfNum } from '_helpers';

import ObjectOfSearchResult from './components/ObjectOfSearchResult';

import styles from './ObjectSearchResults.module.css';

interface SearchResultsProps {
  apartments: ObjectServerProps[];
  total: number;
  disabled?: boolean;
}

const ObjectSearchResults = ({
  apartments, total, disabled = false,
}: SearchResultsProps) => (
  <section className={styles.objectSearchResults}>
    <div className={styles.scrollableWrap}>
      <h2 className={styles.title}>
        Результат поиска <span className={styles.titleSub}>({total} {declOfNum(total, ['объект', 'объекта', 'объектов'])})</span>
      </h2>

      <ul className={styles.apartmentList}>
        {
          apartments.map((apartment) => (
            <ObjectOfSearchResult
              key={apartment.encrypted_id}
              apartment={apartment}
            />
          ))
        }
      </ul>

      <div
        className={`
          ${styles.backdrop}
          ${disabled && styles.disabled}
        `}
      />
    </div>
  </section>
);

export default ObjectSearchResults;
