import React, { useContext, useState } from 'react';
import {
  AnimatedPage, Box, Button, Dropdown, Icons, Input, Typography,
} from 'ui/shared';
import { makeStyles } from 'hooks/makeStyles';
import { useHistory } from 'react-router-dom';

import { fade } from 'utils';
import { observer } from 'mobx-react';
import { find } from 'lodash/fp';
import { ApplicationContext } from '../../../global-stores/application-context';
import { BackButton } from '../../components/layout/back-button';
import { Text } from '../../../components/text';

const useStyles = makeStyles((theme) => ({
  wrap: {
    display: 'flex',
    alignItems: 'center',
    padding: '56px 16px',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  container: {
    maxWidth: 324,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '&$animating': {
      overflowY: 'hidden',
    },
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
  },
  wrapTitle: {
    margin: `${theme.spacing(7.5)} 0 ${theme.spacing(10.5)}`,
    '@media screen and (max-width: 768px)': {
      margin: `${theme.spacing(11.25)} 0 ${theme.spacing(19.5)}`,
    },
  },
  title: {
    maxWidth: 326,
    '@media screen and (max-width: 768px)': {
      fontSize: 23,
      textAlign: 'center',
    },
  },
  iconArrow: {
    position: 'absolute',
    right: 10,
    top: 15,
    '&:hover, &:focus, &:active': {
      outline: 'none',
      backgroundColor: 'transparent',
    },
  },
  iconCalendar: {
    position: 'absolute',
    right: 9,
    top: 13,
    '&:hover, &:focus, &:active': {
      outline: 'none',
      backgroundColor: 'transparent',
    },
  },
  iconButton: {
    maxWidth: 324,
    position: 'relative',
  },
  iconBack: {
    '&:hover, &:focus, &:active': {
      outline: 'none',
      backgroundColor: 'transparent',
    },
  },
  button: {
    width: 230,
    height: 50,
    borderRadius: 6,
    fontSize: 22,
    padding: `${theme.spacing(2)}`,
    border: '2px solid #020202',
    color: '#020202',
    '&:hover, &:focus, &:active': {
      outline: 'none',
      borderColor: 'var(--color-blue)',
      color: 'var(--color-blue)',
    },
    '@media screen and (max-width: 768px)': {
      marginBottom: `${theme.spacing(22.5)}`,
    },
  },
  overlay: {
    backgroundColor: theme.colors.background.paper.default,
    borderRadius: 10,
    border: '2px solid #CBCBCB',
  },
  dropdown: {
    display: 'block',
    color: '#000000',
    border: '2px solid #CBCBCB',
    padding: `${theme.spacing(3)} ${theme.spacing(10)} ${theme.spacing(3)} ${theme.spacing(3)}`,
    fontSize: 18,
    textTransform: 'none',
    textAlign: 'left',
    fontFamily: `${theme.fonts.semibold}, sans-serif`,
    transition: 'border .2s',
    borderRadius: '10px',
    backgroundColor: '#ffffff',
    width: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: theme.colors.background.paper.default,
    },
    '&:focus': {
      border: `2px solid  ${theme.colors.blue.main}`,
      backgroundColor: theme.colors.background.paper.default,
    },
    '&.error': {
      border: `2px solid ${theme.colors.red.main}`,
    },
  },
  greyText: { color: theme.colors.textGray },
  dropdownBtn: {
    color: '#000000',
    border: 'none',
    padding: 12,
    fontSize: 18,
    textTransform: 'none',
    textAlign: 'center',
    fontFamily: `${theme.fonts.semibold}, sans-serif`,
    borderRadius: 0,
    backgroundColor: 'transparent',
    width: '100%',
    '&:hover, &:focus': {
      backgroundColor: fade(theme.colors.grey.main, 0.2),
    },
  },
}));

export default (props: any) => (
  <>
    <Input
      {...props}
      // value={""}
      // error={!!loginStore.usernameError}
      // succeed={!!loginStore.message}
      height="44px"
    />
    <Icons.Calendar
      width={27}
      height={25}
      viewBox="0 0 27 27"
      color="#136FFB"
      style={{
        position: 'absolute',
        right: 12,
        top: 12,
      }}
    />
  </>
);

const currentYear = new Date().getFullYear();

const USER_TYPES = [
  { type: 'agent', description: 'Агент' },
  { type: 'expert', description: 'Эксперт' },
  { type: 'broker', description: 'Брокер' },
];

interface IProfessionalInfoStep {
  userType: undefined | boolean
  yearCareerStart: undefined | boolean
}

function validateYearStart(yearCareerStart: string | null) {
  if (!yearCareerStart) return false;
  // @ts-ignore
  return (
    /\d{4}/.test(yearCareerStart || '')
    && parseInt(yearCareerStart, 10) >= 1900
    && currentYear >= parseInt(yearCareerStart, 10)
  );
}

export const ProfessionalInfo = observer(() => {
  const [isValidState, setIsValidState] = useState<IProfessionalInfoStep>({
    userType: undefined,
    yearCareerStart: undefined,
  });

  const history = useHistory();
  const app = useContext(ApplicationContext);

  const css = useStyles();

  const userType = find({ type: app.newUser?.user_type }, USER_TYPES) as any;

  const validateStep = () => {
    if (!app.newUser) return false;
    const { user_type: userTypeOfNewUser, year_career_start: yearCareerStart } = app.newUser;
    setIsValidState({
      userType: !!userTypeOfNewUser,
      yearCareerStart: validateYearStart(yearCareerStart),
    });
    return !!userTypeOfNewUser && validateYearStart(yearCareerStart);
  };

  return (
    <div className={css.wrap}>
      <AnimatedPage animation="horizontal">
        <div className={css.container}>
          <BackButton />
          <Box className={css.wrapTitle}>
            <Text size="xl" as="p" semibold>
              Добавьте профессиональную информацию
            </Text>
          </Box>
          <Box mb={11} className={css.iconButton}>
            <Dropdown
              preferTop
              items={USER_TYPES}
              renderItem={(item) => (
                <Button
                  key={`gender-${item.type}`}
                  onClick={() => {
                    app.updateNewUserProperties({ user_type: item.type });
                    setIsValidState((prevState) => ({
                      ...prevState,
                      user_type: undefined,
                    }));
                  }}
                  className={css.dropdownBtn}
                >
                  {item.description}
                </Button>
              )}
              overlayClassName={css.overlay}
            >
              <Button className={`${css.dropdown} ${isValidState.userType === false && 'error'}`}>
                <span>
                  {userType?.description || (
                    <Typography size="subtitle1(18px)" font="semibold" className={css.greyText}>
                      Квалификация
                    </Typography>
                  )}
                </span>
                <Icons.TriangularArrow
                  width={29}
                  height={29}
                  viewBox="0 0 29 29"
                  color="#9D9D9D"
                  className={css.iconArrow}
                />
              </Button>
            </Dropdown>
          </Box>
          <Box mb={14.75} className={css.iconButton}>
            <Input
              value={app.newUser?.year_career_start || ''}
              onChange={(e) => {
                app.updateNewUserProperties({ year_career_start: e.target.value });
                setIsValidState((prevState) => ({
                  ...prevState,
                  year_career_start: undefined,
                }));
              }}
              placeholder="Год начала карьеры"
              error={isValidState.yearCareerStart === false}
              type="number"
              height="44px"
            />
          </Box>
          <Box className={css.centered}>
            <Button secondary className={css.button} onClick={() => validateStep() && history.push('/signup/photo')}>
              Далее
            </Button>
          </Box>
        </div>
      </AnimatedPage>
    </div>
  );
});
