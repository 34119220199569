import React from 'react';
import className from 'classnames/bind';
import { Text } from 'components/text';
import Logo2x from './logo@2x.png';
import Logo from './logo.png';
import styles from './footer.module.css';

const cx = className.bind(styles);

export const Footer = () => {
  return (
    <div className={cx('footer')}>
      <Text size="xs">
        Powered by Reagent
      </Text>
      <picture className={cx('logo')}>
        <source srcSet={`${Logo2x} 2x`} />
        <img src={Logo} alt="" />
      </picture>
    </div>
  );
};
