import React from 'react';
import { CreateSvgIcon } from '../icon';
import { IconProps } from '../names';

export const Add = (props: IconProps) => (
  <CreateSvgIcon {...props}>
    {({ color }) => (
      <>
        <circle cx="9" cy="9" r="9" fill={color} />
        <path d="M9.66009 8.4709L9.66405 4.84788C9.66404 4.68904 9.60093 4.53669 9.48861 4.42437C9.37628 4.31204 9.22394 4.24893 9.0651 4.24892C8.90625 4.24891 8.75392 4.312 8.64161 4.42431C8.5293 4.53662 8.46621 4.68895 8.46622 4.84779L8.4707 8.47082L4.84767 8.46634C4.68883 8.46633 4.5365 8.52942 4.42419 8.64173C4.31188 8.75404 4.24879 8.90637 4.2488 9.06522C4.24881 9.22406 4.31192 9.3764 4.42425 9.48873C4.53657 9.60105 4.68892 9.66416 4.84776 9.66418L8.47078 9.66021L8.46682 13.2832C8.4665 13.362 8.48178 13.44 8.51177 13.5128C8.54176 13.5856 8.58587 13.6518 8.64155 13.7075C8.69723 13.7632 8.76339 13.8073 8.8362 13.8372C8.90901 13.8672 8.98704 13.8825 9.06578 13.8822C9.14452 13.8825 9.22255 13.8673 9.29535 13.8373C9.36816 13.8073 9.43431 13.7632 9.48999 13.7075C9.54566 13.6519 9.58976 13.5857 9.61974 13.5129C9.64972 13.4401 9.66498 13.3621 9.66465 13.2833L9.66018 9.6603L13.2832 9.66477C13.3619 9.6651 13.44 9.64984 13.5128 9.61986C13.5856 9.58988 13.6517 9.54578 13.7074 9.49011C13.7631 9.43443 13.8072 9.36828 13.8372 9.29547C13.8671 9.22267 13.8824 9.14464 13.8821 9.0659C13.8824 8.98716 13.8671 8.90913 13.8371 8.83632C13.8071 8.76351 13.763 8.69735 13.7073 8.64167C13.6517 8.58599 13.5855 8.54188 13.5127 8.51189C13.4399 8.4819 13.3619 8.46662 13.2831 8.46694L9.66009 8.4709Z" fill="white" />
      </>
    )}
  </CreateSvgIcon>
);
