import React, { useCallback, useState } from 'react';
import cn from 'classnames';

import { ReactComponent as SubmitIcon } from './Submit.svg';

import styles from './SearchField.module.css';
import { sendMetrik } from '_helpers/metrika';
import { suggestAddress, SuggestItem } from 'domains/objects/requests';
import { Suggests } from './suggests';

interface SearchFieldProps {
  value?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange?: (value: string) => void;
  onSearch?: (value: string) => void;
  isLoading?: boolean;
  city?: string;
  placeholder?: string;
}

const SearchField = ({
  value = '',
  onChange,
  onSearch,
  onKeyDown,
  isLoading = false,
  city,
  placeholder,
}: SearchFieldProps) => {
  const [isSuggestsVisible, setIsSuggestsVisible] = useState(false);

  const [suggestItems, setSuggestItems] = useState<SuggestItem[]>([]);

  const onChangeSearchValue = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { value: fieldValue } } = e;

    if (onChange) {
      onChange(fieldValue);
    }
    // выключил на время пока не подключим 2гис
    try {
      const items = await suggestAddress(`${fieldValue}`);

      // @ts-ignore
      setSuggestItems(items);

      setIsSuggestsVisible(items.length > 0 && fieldValue !== '');
    } catch (error) {
      console.log(error);
      setIsSuggestsVisible(false);
    }
  };

  const onKeyDownSearchValue = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  const handleClickSuggestion = useCallback((suggestion: string) => {
    if (onChange) {
      onChange(suggestion);
    }

    if (onSearch) {
      onSearch(suggestion);
    }

    setSuggestItems([]);
  }, [onChange, onSearch]);

  const handleClick = () => {
    if (onSearch) {
      onSearch(value);

      // Yandex Metrika
      sendMetrik('reachGoal', 'SearchButtonClick');
    }
  };

  const handleCloseSuggests = useCallback(() => {
    setIsSuggestsVisible(false);
  }, []);

  return (
    <>
      <div className={styles.wrap}>
        <input
          className={styles.field}
          onKeyDown={onKeyDownSearchValue}
          onChange={onChangeSearchValue}
          placeholder={placeholder || 'Введите запрос для поиска'}
          value={value}
        />

        <button
          type="button"
          className={cn(styles.submitButton, {
            [styles.disabled]: isLoading || value?.length === 0,
          })}
          onClick={handleClick}
          disabled={isLoading || value?.length === 0}
        >
          <SubmitIcon />
        </button>
        {isSuggestsVisible && (
          <Suggests
            suggests={suggestItems}
            onClick={handleClickSuggestion}
            onClose={handleCloseSuggests}
          />
        )}
      </div>
    </>
  );
};

export default React.memo(SearchField);
