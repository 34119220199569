import React from 'react';
import { CreateSvgIcon } from '../icon';
import { IconProps } from '../names';

export const CameraSuccess = (props: IconProps) => (
  <CreateSvgIcon {...props}>
    {({ color }) => (
      <>
        <g clipPath="url(#clip-camera-success)">
          <path d="M31.2532 14.054H33.7743V16.5675H31.2532V14.054Z" fill={color} />
          <path d="M31.2532 9.02686H33.7743V11.5404H31.2532V9.02686Z" fill={color} />
          <path d="M31.2532 4H33.7743V6.51351H31.2532V4Z" fill={color} />
          <path d="M26.211 4H28.7321V6.51351H26.211V4Z" fill={color} />
          <path d="M21.1688 4H23.6899V6.51351H21.1688V4Z" fill={color} />
          <path d="M1 19.0808H3.52113V21.5943H1V19.0808Z" fill={color} />
          <path d="M1 24.1077H3.52113V26.6212H1V24.1077Z" fill={color} />
          <path d="M1 29.1348H3.52113V31.6483H1V29.1348Z" fill={color} />
          <path d="M1 34.1619H3.52113V36.6754H1V34.1619Z" fill={color} />
          <path
            d="M15.9598 0.676659C16.2953 0.291719 16.8669 0.221405 17.2858 0.513562L18.1801 1.13743C18.6329 1.45336 18.744 2.07654 18.4282 2.5295L8.67382 16.5206C8.48681 16.7888 8.18048 16.9487 7.8535 16.9487H6.43814C6.11598 16.9487 5.81354 16.7934 5.6257 16.5317L0.514149 9.409C0.220343 8.99959 0.277063 8.43554 0.6465 8.09283L1.76997 7.05063C2.1537 6.69466 2.74701 6.69482 3.13055 7.051L6.39392 10.0816C6.80766 10.4658 7.45725 10.4315 7.82825 10.0059L15.9598 0.676659Z"
            fill={color}
          />
          <path
            d="M21.6055 33.339C20.6083 33.339 19.6334 33.0441 18.8042 32.4918C17.975 31.9394 17.3288 31.1543 16.9471 30.2357C16.5655 29.3171 16.4656 28.3064 16.6602 27.3312C16.8547 26.3561 17.335 25.4603 18.0401 24.7573C18.7453 24.0543 19.6438 23.5755 20.6219 23.3815C21.6 23.1875 22.6138 23.2871 23.5351 23.6676C24.4565 24.0481 25.244 24.6924 25.798 25.5191C26.3521 26.3458 26.6478 27.3177 26.6478 28.3119C26.6461 29.6447 26.1144 30.9224 25.1691 31.8647C24.2239 32.8071 22.9423 33.3373 21.6055 33.339ZM21.6055 25.7984C21.1069 25.7984 20.6195 25.9458 20.2049 26.222C19.7903 26.4982 19.4672 26.8908 19.2763 27.3501C19.0855 27.8093 19.0356 28.3147 19.1329 28.8023C19.2301 29.2899 19.4703 29.7377 19.8228 30.0893C20.1754 30.4408 20.6247 30.6802 21.1137 30.7772C21.6028 30.8741 22.1097 30.8244 22.5703 30.6341C23.031 30.4439 23.4248 30.1217 23.7018 29.7084C23.9788 29.295 24.1267 28.8091 24.1267 28.3119C24.126 27.6455 23.8602 27.0066 23.3875 26.5354C22.9149 26.0641 22.274 25.7991 21.6055 25.7984Z"
            fill={color}
          />
          <path
            d="M31.6901 37.1081H11.5211C10.8525 37.1081 10.2112 36.8433 9.73842 36.3719C9.26562 35.9005 9 35.2612 9 34.5946V23.2838C9 22.6172 9.26562 21.9778 9.73842 21.5065C10.2112 21.0351 10.8525 20.7703 11.5211 20.7703H15.6558L16.3403 18.7192C16.5075 18.2185 16.8286 17.783 17.2581 17.4744C17.6875 17.1659 18.2035 16.9999 18.7328 17H24.4785C25.0078 16.9999 25.5238 17.1659 25.9532 17.4744C26.3826 17.783 26.7037 18.2185 26.871 18.7192L27.5555 20.7703H31.6901C32.3588 20.7703 33 21.0351 33.4728 21.5065C33.9456 21.9778 34.2113 22.6172 34.2113 23.2838V34.5946C34.2113 35.2612 33.9456 35.9005 33.4728 36.3719C33 36.8433 32.3588 37.1081 31.6901 37.1081ZM11.5211 34.5946H31.6901V23.2838H25.7403L24.4797 19.5135H18.7315L17.471 23.2838H11.5211V34.5946Z"
            fill={color}
          />
        </g>
      </>
    )}
  </CreateSvgIcon>
);
