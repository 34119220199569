import React, { useContext, useEffect, useState } from 'react';

import { SelectionsContext } from 'global-stores/selections-context';

import axios from 'axios';

import Config from '../../../config';

import { Label } from 'ui/components/FieldWrap';
import Field from 'ui/components/Field';
import Button from 'ui/components/Button';

import { ReactComponent as CreateFolderIcon } from './CreateFolder.svg';

import styles from './CreateApartmentList.module.css';

const CreateApartmentList = () => {
  const selectionsContext = useContext(SelectionsContext);

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = useState('');

  const handleClickOpen = () => {
    setIsOpen(true);
  };

  const handleClickClose = () => {
    setIsOpen(false);
  };

  const handleChangeValue = (e: React.SyntheticEvent) => {
    const { value: fieldValue } = e.target as HTMLInputElement;
    setName(fieldValue);
  };

  const handleClickCreate = () => {
    setIsLoading(true);

    const token = localStorage.getItem('token');

    axios(`${Config.API_URL}/api/v3/selections/`, {
      method: 'POST',
      headers: {
        Authorization: `Token ${token}`,
      },
      data: {
        name,
      },
    })
      .then(() => {
        selectionsContext.uploadSelections();

        setIsOpen(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    handleClickCreate();
  };

  useEffect(() => {
    if (!isOpen) {
      setName('');
    }
  }, [isOpen]);

  return (
    <div className={styles.wrap}>
      <div className={styles.createApartmentButton}>
        <button
          className={styles.button}
          type="button"
          onClick={handleClickOpen}
          disabled={isOpen}
        >
          <CreateFolderIcon />

          Создать подборку
        </button>
      </div>

      {
        isOpen && (
          <div className={styles.form}>
            <Label className={styles.title}>
              Название подборки
            </Label>

            <form
              className={styles.formRow}
              onSubmit={handleSubmit}
              action={`${Config.API_URL}/api/v3/selections/`}
              method="POST"
            >
              <Field
                className={styles.field}
                type="text"
                onChange={handleChangeValue}
                value={name}
                placeholder="Например «объекты до 3 млн»"
                readonly={isLoading}
              />

              <div className={styles.buttons}>
                <Button
                  component="button"
                  theme="black-full"
                  onClick={handleClickCreate}
                  disabled={!name || isLoading}
                >
                  Создать
                </Button>

                <Button
                  component="button"
                  theme="black"
                  onClick={handleClickClose}
                  disabled={isLoading}
                >
                  Отменить
                </Button>
              </div>
            </form>
          </div>
        )
      }
    </div>
  );
};

export default CreateApartmentList;
