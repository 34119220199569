import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as ArrowRight } from 'assets/svg/Arrow.svg';

import styles from './Select.module.css';

interface Option {
  value: string;
  text: string;
  disabled?: boolean;
  default?: boolean;
}

interface SelectProps {
  selected?: string | null;
  onChange?: (selected: string) => void;
  options: Option[];
}

const Select = ({ selected, onChange, options }: SelectProps) => {
  const selectWrapRef = useRef<HTMLDivElement>(null);

  const [selectedValue, setSelectedValue] = useState(selected);
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

  const onClickBySelectButton = () => {
    setDropdownIsOpen(!dropdownIsOpen);
  };

  const onClickByOption = (value: string) => () => {
    setSelectedValue(value);
    if (onChange) onChange(value);
    setDropdownIsOpen(false);
  };

  useEffect(() => {
    if (selected && selected !== selectedValue) {
      setSelectedValue(selected);
    }

    if (selected === null) {
      setSelectedValue('no-matter');
    }
  }, [selected, selectedValue]);

  useEffect(() => {
    if (!selectedValue) {
      setSelectedValue(options.find((option) => option.value === selectedValue)?.value || options[0].value);
    }

    const closeByClickOutSelect = (e: MouseEvent) => {
      const element = e.target as HTMLDivElement;

      if (!selectWrapRef.current?.contains(element)) {
        setDropdownIsOpen(false);
      }
    };

    document.documentElement.addEventListener('click', closeByClickOutSelect);

    return () => {
      document.documentElement.removeEventListener('click', closeByClickOutSelect);
    };
  }, [options, selectedValue]);

  return (
    <div
      ref={selectWrapRef}
      className={styles.selectWrap}
      style={{
        zIndex: dropdownIsOpen ? 3 : 1,
      }}
    >
      <select className="visually-hidden">
        {
          options.map((option) => (
            <option
              key={option.value}
              value={option.value}
              selected={option.value === selectedValue}
            >
              {option.text}
            </option>
          ))
        }
      </select>

      <button
        className={`
          ${styles.selectButton}
          ${options.find((option) => option.value === selectedValue)?.default && styles.selectButtonDefault}
          ${dropdownIsOpen && styles.selectButtonIsOpen}
        `}
        type="button"
        onClick={onClickBySelectButton}
      >
        <span className={styles['select-text']}>
          {options.find((option) => option.value === selectedValue)?.text}
        </span>

        <ArrowRight
          className={`
            ${styles.selectButtonRightArrow}
            ${dropdownIsOpen && styles.selectButtonRightArrowUp}
          `}
        />
      </button>

      {
        dropdownIsOpen && (
          <div className={styles.dropdown}>
            {
              options.map((option) => (
                <button
                  key={option.value}
                  className={`
                    ${styles.dropdownItem}
                    ${option.value === selectedValue && styles.dropdownItemSelected}
                  `}
                  type="button"
                  onClick={onClickByOption(option.value)}
                >
                  {option.text}
                </button>
              ))
            }
          </div>
        )
      }
    </div>
  );
};

export default Select;
