import React, { useContext } from 'react';
import classNames from 'classnames/bind';
import { ApplicationContext } from 'global-stores/application-context';
import { Text } from 'components/text';
import { ReactComponent as ExitButtonIcon } from './exit-button-icon.svg';
import styles from './exit-button.module.css';

const cx = classNames.bind(styles);

export const ExitButton = () => {
  const app = useContext(ApplicationContext);

  const handleLogOut = () => {
    app.clearAuthToken();
  };

  return (
    <button className={cx('button')} type="button" onClick={handleLogOut}>
      <Text size="l" semibold>
        Выход
      </Text>
      <div className={cx('icon')}>
        <ExitButtonIcon />
      </div>
    </button>
  );
};
