import React from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames/bind';
import { PopupProps } from './types';
import { ReactComponent as CrossIcon } from 'assets/svg/cross.svg';
import styles from './popup.module.css';

const cx = classNames.bind(styles);

export const Popup = ({
  children,
  padding,
  withPortal,
  onClose,
}: PopupProps) => {
  const PopupContent = () => (
    <div
      className={cx('popup-content', {
        // eslint-disable-next-line
        'padding_none': padding === 'none',
      })}
    >
      <div
        className={cx('popup-backdrop')}
        onClick={onClose}
      />
      <button
        type="button"
        onClick={onClose}
        className={cx('popup-close')}
      >
        <CrossIcon className={cx('popup-close-icon')} />
      </button>
      <div className={cx('popup-main')}>
        {children}
      </div>
    </div>
  );

  if (withPortal) {
    const popupRootNode = document.getElementById('popup-root');

    if (popupRootNode) {
      return createPortal(
        <div className={cx('popup-wrap')}>
          <PopupContent />
        </div>,
        popupRootNode,
      );
    }
  }

  return (
    <div className={cx('popup-wrap')}>
      <PopupContent />
    </div>
  );
};
