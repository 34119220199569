import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from 'hooks/makeStyles';
import { useEventCallback } from 'hooks/useEventCallback';
import { KEY_CODES } from 'lib';
import uniqueId from 'lodash/uniqueId';
import { findDOMNode } from 'react-dom';
import clsx from 'clsx';
import { Trigger } from './trigger';
import { OverlayPositioner, OverlayPositionerProps } from './overlay-positioner';

export interface DropdownProps extends Partial<OverlayPositionerProps> {
  render?: (close: (force?: boolean) => void) => React.ReactNode
  items?: any[]
  on?: 'hover' | 'click'
  renderItem?: (
    item: any,
    index: number,
    forceFocus: boolean
  ) => React.ReactElement
  withTail?: boolean
  withArrow?: boolean
  overlayClassName?: string
  onClose?: () => void
  /** Use visibility instead of display none */
  useVisibility?: boolean
  children: React.ReactElement
  className?: string
}

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
}));

export const Dropdown = (props: DropdownProps) => {
  const [isOpen, setOpen] = useState(false);
  const [forceFocus, setForceFocus] = useState(false);
  const itemElements = useRef<{ [key: number]: HTMLElement }>({});
  const focusedItem = useRef<number | undefined>();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const triggerRef = React.useRef<HTMLButtonElement>(null);
  const overlayRef = React.useRef<HTMLDivElement>(null);
  const {
    render,
    items,
    renderItem,
    on = 'click',
    onClose,
    children,
    className,
    ...passthrough
  } = props;

  const css = useStyles();
  const overlayId = uniqueId('overlay-');

  function open() {
    setOpen(true);
  }

  const handleClose = (force?: boolean) => {
    if (force || focusedItem.current == null) {
      setOpen(false);
      setForceFocus(false);
    }
    if (onClose) {
      onClose();
    }
  };

  const handleOverlayKeyDown = useEventCallback((e: KeyboardEvent) => {
    const key = e.which || e.keyCode;

    switch (key) {
      case KEY_CODES.RETURN:
      case KEY_CODES.ESC:
        e.preventDefault();
        handleClose(false);
        if (triggerRef && triggerRef.current) {
          triggerRef.current.focus();
        }
        break;
      default:
        break;
    }
  });

  const handleTriggerKeyUp = useEventCallback((e: KeyboardEvent) => {
    const key = e.which || e.keyCode;

    switch (key) {
      case KEY_CODES.UP:
      case KEY_CODES.DOWN:
        e.preventDefault();
        open();
        break;
      default:
        break;
    }
  });

  const handleTriggerClick = useEventCallback(() => {
    if (isOpen) {
      handleClose(true);
    } else {
      open();
    }
  });

  const handleOverlayOnBlur = useEventCallback(
    () => {
      setTimeout(() => {
        if (
          !focusedItem.current
          && containerRef.current
          && !containerRef.current.contains(document.activeElement)
        ) {
          handleClose(false);
        }
      }, 50);
    },
  );

  function focusOverlay() {
    setTimeout(() => {
      overlayRef.current?.focus();
    }, 0);
  }

  const focusFirstItem = useEventCallback(() => {
    if (items && renderItem && itemElements.current[0]) {
      setTimeout(() => {
        itemElements.current[0].focus();
      }, 0);
    } else {
      focusOverlay();
    }
  });

  const triggerProps = {
    'aria-controls': overlayId,
    'aria-haspopup': true,
    'aria-expanded': isOpen ? true : undefined,
    ref: triggerRef,
    onClick: handleTriggerClick,
  };

  useEffect(() => {
    if (isOpen) {
      focusFirstItem();
    }
  }, [isOpen, focusFirstItem]);

  function focusLastItem() {
    if (items && itemElements.current[items.length - 1]) {
      setTimeout(() => itemElements.current[items.length - 1].focus(), 0);
    }
  }

  function focusPreviousItem() {
    if (focusedItem.current == null) return;

    if (focusedItem.current === 0) {
      focusLastItem();
    } else {
      itemElements.current[focusedItem.current - 1].focus();
    }
  }

  function focusNextItem() {
    if (focusedItem.current == null) return;

    if (items && focusedItem.current === items.length - 1) {
      focusFirstItem();
    } else {
      itemElements.current[focusedItem.current + 1].focus();
    }
  }

  function storeItemElementRef(index: number) {
    return (ref: HTMLElement | React.Component<any> | null) => {
      if (ref) {
        itemElements.current[index] = ref instanceof HTMLElement ? ref : (findDOMNode(ref) as HTMLElement);
      } else {
        delete itemElements.current[index];
      }
    };
  }

  function setFocusToTrigger() {
    if (triggerRef && triggerRef.current) {
      triggerRef.current.focus();
    }
  }

  function handleItemClick() {
    setFocusToTrigger();
    handleClose(true);
  }

  function handleItemFocus(index: number) {
    return () => {
      focusedItem.current = index;
    };
  }

  function handleItemBlur() {
    focusedItem.current = undefined;
    setTimeout(() => handleClose(), 150);
  }

  function handleItemKeyDown(e: React.KeyboardEvent) {
    let flag = false;

    if (
      e.ctrlKey
      || e.altKey
      || e.metaKey
      || e.keyCode === KEY_CODES.SPACE
      || e.keyCode === KEY_CODES.RETURN
    ) {
      return;
    }

    if (e.shiftKey) {
      if (e.keyCode === KEY_CODES.TAB) {
        setFocusToTrigger();
        handleClose(true);

        flag = true;
      }
    } else {
      switch (e.keyCode) {
        case KEY_CODES.ESC:
          setFocusToTrigger();
          handleClose(true);
          flag = true;
          break;

        case KEY_CODES.UP:
          focusPreviousItem();
          setForceFocus(true);
          flag = true;
          break;

        case KEY_CODES.DOWN:
          focusNextItem();
          setForceFocus(true);
          flag = true;
          break;

        case KEY_CODES.HOME:
        case KEY_CODES.PAGEUP:
          focusFirstItem();
          flag = true;
          break;

        case KEY_CODES.END:
        case KEY_CODES.PAGEDOWN:
          focusLastItem();
          flag = true;
          break;

        case KEY_CODES.TAB:
          setFocusToTrigger();
          handleClose(true);
          break;

        default:
          break;
      }
    }

    if (flag) {
      e.stopPropagation();
      e.preventDefault();
    }
  }

  function renderItems(renderedItems: any[]) {
    return renderedItems.map((item, index) => {
      const Item = renderItem!(item, index, forceFocus);
      return React.cloneElement(Item, {
        role: 'menu-item',
        tabIndex: -1,
        key: item.id,
        ref: storeItemElementRef(index),
        onKeyDown: handleItemKeyDown,
        onClick: (e: React.MouseEvent) => {
          if (Item.props.onClick) {
            Item.props.onClick(e);
          }
          handleItemClick();
        },
        onFocus: handleItemFocus(index),
        onBlur: handleItemBlur,
      });
    });
  }

  let childElement;

  if (render) {
    childElement = render(handleClose);
  } else if (items && renderItem) {
    childElement = renderItems(items);
  } else {
    childElement = null;
  }

  return (
    <div
      className={clsx(css.root, {
        [className!]: !!className,
      })}
      ref={containerRef}
    >
      <Trigger {...triggerProps}>
        {children}
      </Trigger>
      <OverlayPositioner
        visible={isOpen}
        open={open as any}
        close={handleClose}
        overlayId={overlayId}
        trigger={triggerRef}
        onOverlayKeyDown={handleOverlayKeyDown as any}
        onTriggerKeyUp={handleTriggerKeyUp as any}
        onOverlayBlur={handleOverlayOnBlur}
        vertPosition="Bottom"
        on={on}
        ref={overlayRef}
        {...passthrough}
      >
        {childElement}
      </OverlayPositioner>
    </div>
  );
};
