import React from 'react';
import className from 'classnames/bind';
import { Text } from 'components/text';
import styles from './footer-links.module.css';

const cx = className.bind(styles);

export const FooterLinks = () => {
  return (
    <div className={cx('footer-links')}>
      <div className="container">
        <div className={cx('row')}>
          <div className={cx('copyright')}>
            <Text size="s">
              Copyright © 2023 Reagent pro. Все права защищены.
            </Text>
          </div>
          <ul className={cx('list')}>
            <li className={cx('list-item')}>
              <a
                href="/docs/politics"
                target="_blank"
              >
                <Text size="s">
                  Политика конфиденциальности
                </Text>
              </a>
            </li>
            <li className={cx('list-item')}>
              <a
                href="/docs/agreement"
                target="_blank"
              >
                <Text size="s">
                  Пользовательское соглашения
                </Text>
              </a>
            </li>
            <li className={cx('list-item')}>
              <a
                href="/docs/public-offer"
                target="_blank"
              >
                <Text size="s">
                  Публичная оферта
                </Text>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
