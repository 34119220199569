import React, { Fragment, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { Text } from 'components/text';
import ErrorPicture from './error-picture.png';
import { ReactComponent as ReloadIcon } from './reload.svg';
import styles from './styles.module.css';

const cx = classNames.bind(styles);

const getFormattedTime = (seconds: number) => new Date(seconds * 1000).toISOString().substr(14, 5);

export const ServerDownPage = () => {
  const [seconds, setSeconds] = useState(300);

  useEffect(() => {
    const timer = setTimeout(() => {
      const featureSecondsValue = seconds - 1;

      if (featureSecondsValue >= 0) {
        setSeconds(seconds - 1);
      } else {
        window.location.reload();
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [seconds]);

  const handleReloadClick = () => {
    window.location.reload();
  };

  return (
    <div className={cx('main')}>
      <div className={cx('timer')}>
        <Text as="p" size="xxl" semibold>
          {getFormattedTime(seconds)}
        </Text>
      </div>
      <img className={cx('error-picture')} src={ErrorPicture} alt="" />
      <div className={cx('message')}>
        <Text as="p" size="l" semibold>
          Извините,<br />
          у&nbsp;нас технические работы
        </Text>
        <button
          className={cx('reload-button')}
          type="button"
          onClick={handleReloadClick}
        >
          <ReloadIcon className={cx('reload-icon')} />
          <Text size="s" semibold>
            Перезагрузить страницу
          </Text>
        </button>
      </div>
    </div>
  );
};
