import React from 'react';
import { CreateSvgIcon } from '../icon';
import { IconProps } from '../names';

export const Facebook = (props: IconProps) => (
  <CreateSvgIcon {...props}>
    {({ color }) => (
      <path
        d="M23.0882 1.00576L19.3274 1C15.1018 1 12.3725 3.80137 12.3725 8.1374V11.4276H8.59152C8.43462 11.4276 8.28414 11.4899 8.1732 11.6009C8.06226 11.7118 7.99993 11.8623 7.99993 12.0192V16.7874C7.99993 16.9443 8.06226 17.0947 8.1732 17.2057C8.28414 17.3166 8.43462 17.3789 8.59152 17.3789H12.3725V29.4084C12.3725 29.5653 12.4348 29.7158 12.5457 29.8267C12.6567 29.9377 12.8071 30 12.964 30H17.8974C17.9752 30.0001 18.0522 29.9849 18.1241 29.9552C18.196 29.9256 18.2613 29.882 18.3163 29.8271C18.3714 29.7721 18.415 29.7069 18.4448 29.635C18.4746 29.5632 18.49 29.4862 18.49 29.4084V17.378H22.9076C23.0645 17.378 23.215 17.3157 23.3259 17.2047C23.4369 17.0938 23.4992 16.9433 23.4992 16.7864V12.0182C23.4992 11.8613 23.4369 11.7108 23.3259 11.5999C23.215 11.489 23.0645 11.4266 22.9076 11.4266H18.49V8.64159C18.49 7.29708 18.8098 6.62483 20.5557 6.62483H23.0891C23.2459 6.62458 23.3961 6.56214 23.5068 6.45122C23.6176 6.34031 23.6798 6.18998 23.6798 6.03325V1.59734C23.6798 1.44045 23.6174 1.28998 23.5065 1.17903C23.3955 1.06809 23.2451 1.00576 23.0882 1.00576Z"
        fill={color}
      />
    )}
  </CreateSvgIcon>
);
