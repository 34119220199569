import React, { DetailsHTMLAttributes } from 'react';

export interface TriggerProps extends DetailsHTMLAttributes<HTMLElement> {
  children: React.ReactElement;
}

export const Trigger = React.forwardRef(
  (props: TriggerProps, ref: React.Ref<HTMLElement>) => {
    const { children, ...passthrough } = props;

    return React.cloneElement(children, {
      ...passthrough,
      ref,
    });
  },
);
