import React, { useContext, useState } from 'react';
import {
  AnimatedPage, Avatar, Box, Button, Checkbox, EmptyContent, Icons, Textarea, Typography,
} from 'ui/shared';
import { makeStyles } from 'hooks/makeStyles';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import { observer } from 'mobx-react';
import { ApplicationContext } from '../../../global-stores/application-context';
import { BackButton } from '../../components/layout/back-button';
import { Text } from '../../../components/text';

const useStyles = makeStyles((theme) => ({
  wrap: {
    display: 'flex',
    alignItems: 'center',
    padding: '56px 16px',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  container: {
    maxWidth: 324,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '&$animating': {
      overflowY: 'hidden',
    },
  },
  title: {},
  subtitle: {
    fontSize: 20,
  },
  avatarContainer: { position: 'relative' },
  avatar: { position: 'absolute', top: -59, left: 0 },
  iconBack: {
    '&:hover, &:focus, &:active': {
      outline: 'none',
      backgroundColor: 'transparent',
      //   borderColor: theme.colors.blue.main
    },
  },
  wrapper: {
    paddingLeft: 137,
  },
  paper: {
    backgroundColor: theme.colors.background.paper.default,
    borderRadius: 10,
  },
  icon: {
    minWidth: 43,
    maxWidth: 43,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(3.75),
  },
  button: {
    width: 230,
    height: 50,
    borderRadius: 6,
    fontSize: 22,
    padding: `${theme.spacing(2)}`,
    border: '2px solid #020202',
    color: '#020202',
    '&:hover, &:focus, &:active': {
      outline: 'none',
      borderColor: 'var(--color-blue)',
      color: 'var(--color-blue)',
    },
    '@media screen and (max-width: 768px)': {
      marginBottom: `${theme.spacing(22.5)}`,
    },
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  skeletonContainer: {
    padding: `${theme.spacing(4.5)} ${theme.spacing(4.5)} ${theme.spacing(4.5)} ${theme.spacing(4.5)}`,
  },
  textarea: {
    border: 'none',
    padding: `${theme.spacing(4.75)}`,
    fontSize: 18,
    color: theme.colors.textPrimary,
    backgroundColor: theme.colors.grey.secondary,
    resize: 'none',
    '&:focus': {
      outline: 0,
      border: `2px solid ${theme.colors.blue.main}`,
    },
    '&::placeholder': {
      color: theme.colors.textSecondary,
    },
  },
  checkbox: {
    color: 'black',
    '&[aria-checked="mixed"]:before, &[aria-checked="true"]:before': {
      backgroundColor: theme.colors.common.white,
    },
    '&[aria-checked="mixed"]:active:after, &[aria-checked="true"]:after': {
      display: 'block',
      width: '6px',
      height: '10px',
      border: `solid ${theme.colors.green.secondary}`,
      borderWidth: '0 2px 2px 0',
      transform: 'translateY(-65%) translateX(-50%) rotate(45deg)',
    },
  },
}));

const AboutMe = observer(() => {
  const css = useStyles();
  const history = useHistory();

  const [termsOfUse, setTermsOfUse] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const app = useContext(ApplicationContext);

  const [description, setDescription] = useState(app.newUser?.description || '');
  const [interests, setInterests] = useState(app.newUser?.interests || '');

  const handleCreateProfile = () => {
    if (!termsOfUse || !privacyPolicy) {
      setIsValid(false);
    }

    if (termsOfUse && privacyPolicy && app.newUser) {
      const newUser = { ...app.newUser, interests, description };
      app
        .registerAndSignIn(newUser, termsOfUse, privacyPolicy)
        .then(() => history.push('/'))
        .catch((error) => console.error(error.reason));
    }
  };

  return (
    <div className={css.wrap}>
      <AnimatedPage animation="horizontal">
        <div className={css.container}>
          <BackButton />
          <Box mb={8.25} mt={10.5} pl={12}>
            <Text size="xl" as="p" semibold>
              Можете еще немного рассказать о себе
            </Text>
          </Box>

          <Box mb={2.75} className={clsx(css.paper, css.skeletonContainer)}>
            <Box className={clsx(css.centered, css.avatarContainer)}>
              <Box className={css.avatar}>
                {app.newUser?.avatarSrc ? (
                  <Avatar.Big img={app.newUser?.avatarSrc} border />
                ) : (
                  <EmptyContent.Circle
                    size="large"
                    width={113}
                    height={113}
                    className={clsx(css.centered, css.emptyAvatar)}
                  >
                    <Icons.Person height={29} width={25} viewBox="0 0 29 25" />
                  </EmptyContent.Circle>
                )}
              </Box>
              <Box className={clsx(css.column, css.wrapper)}>
                <EmptyContent.Line height={17} size="large" />
                <EmptyContent.Line width="60%" height={17} size="small" />
              </Box>
            </Box>
            <Box>
              <EmptyContent.Line width="80%" height={17} size="medium" />
            </Box>
            <Box className={css.row}>
              <EmptyContent.Square size="large" />
              <EmptyContent.Square size="large" />
              <EmptyContent.Square size="large" />
            </Box>

            <Box mt={13.75}>
              <Textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                minRows={4}
                maxRows={4}
                placeholder="Несколько строк о себе"
                className={css.textarea}
              />
            </Box>
            <Box mt={6}>
              <Textarea
                value={interests}
                onChange={(e) => setInterests(e.target.value)}
                minRows={4}
                maxRows={4}
                placeholder="Ваша специализация"
                className={css.textarea}
              />
            </Box>
          </Box>

          <Box mb={4.25} mt={8}>
            <Checkbox
              className={css.checkbox}
              error={(!isValid && !termsOfUse)}
              checked={termsOfUse}
              onChange={(value) => setTermsOfUse(value)}
              label="Принимаю условия пользования"
              labelColorGrey
            />
          </Box>
          <Box mb={11.5}>
            <Checkbox
              className={css.checkbox}
              error={(!isValid && !termsOfUse)}
              checked={privacyPolicy}
              onChange={(value) => setPrivacyPolicy(value)}
              label="Принимаю политику конфиденциальности и обработки персональных данных"
              labelColorGrey
            />
          </Box>

          <Box className={css.centered}>
            <Button
              secondary
              className={css.button}
              onClick={handleCreateProfile}
              disabled={!termsOfUse || !privacyPolicy}
            >
              Завершить
            </Button>
          </Box>
        </div>
      </AnimatedPage>
    </div>
  );
});

export default AboutMe;
