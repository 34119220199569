import React, { useCallback } from 'react';
import { EditPictures } from '../../components/edit-pictures';
import { EditPicturesContainerProps } from './types';

export const EditPicturesContainer = ({
  userProperties,
  updateDataByKey,
}: EditPicturesContainerProps) => {
  const onChangeAvatar = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    updateDataByKey('avatar_blob', e.target.files?.[0]);
  }, [updateDataByKey]);

  const onChangeBackground = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    updateDataByKey('background_blob', e.target.files?.[0]);
  }, [updateDataByKey]);

  return (
    <EditPictures
      userProperties={userProperties}
      onChangeAvatar={onChangeAvatar}
      onChangeBackground={onChangeBackground}
    />
  );
};
