import React, { useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import { SuggestItem } from 'domains/objects/requests';
import { Text } from 'components/text';
import styles from './suggests.module.css';

const cx = classNames.bind(styles);

type SuggestProps = {
  suggest: string;
  onClick: () => void;
};

const Suggest: React.FC<SuggestProps> = ({
  suggest,
  onClick,
}) => (
  <button
    className={cx('suggest-button')}
    type="button"
    onClick={onClick}
  >
    <Text size="m">
      {suggest}
    </Text>
  </button>
);

type SuggestsProps = {
  suggests: SuggestItem[];
  onClick: (suggest: string) => void;
  onClose: () => void;
};

export const Suggests: React.FC<SuggestsProps> = ({
  suggests,
  onClick,
  onClose,
}) => {
  const suggestsRef = useRef<HTMLDivElement | null>(null);

  const handleSuggestClick = useCallback((suggest: string) => () => {
    onClick(suggest);
    onClose();
  }, [onClick, onClose]);

  useEffect(() => {
    const onClickByOutside = (e: MouseEvent) => {
      const element = e.target as HTMLDivElement;

      if (!suggestsRef.current?.contains(element)) {
        onClose();
      }
    };

    document.documentElement.addEventListener('click', onClickByOutside);

    return () => {
      document.documentElement.removeEventListener('click', onClickByOutside);
    };
  }, [onClose]);

  if (suggests.length === 0) {
    return null;
  }

  return (
    <div className={cx('suggests-wrap')} ref={suggestsRef}>
      <ul className={cx('suggests-list')}>
        {suggests.map((suggest) => (
          <li className={cx('suggest-item')} key={suggest.id}>
            <Suggest
              suggest={suggest.suggested_text}
              onClick={handleSuggestClick(suggest.suggested_text)}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
