import { fade } from 'utils';

export const lightTheme: Theme = {
  fonts: {
    bold: 'ProximaNova-Bold',
    regular: 'ProximaNova-Regular',
    montserrat: 'Montserrat-SemiBold',
    semibold: 'ProximaNova-Semibold',
    script: 'SegoeSC',
  },
  colors: {
    background: {
      paper: {
        default: '#ffffff',
        dark: '#222222',
        lightGray: '#FAFAFA',
        darkGrey: '#efefef',
        footer: '#2B2B2B',
        blue: '#00BFFE',
      },
      darkGradient: 'linear-gradient(180deg, #1F2C3B 0%, #0759A8 100%)',
    },
    common: {
      white: '#fff',
      black: '#000',
    },
    textPrimary: '#2F2F2F',
    textSecondary: '#fff',
    textTertiary: '#8F8F8F',
    textDisabled: '#9D9D9D',
    textBlack: '#000000',
    textGray: '#5B5B5B',
    textDarkGrey: '#515151',
    textLightGrey: '#949494',
    textPrimaryGray: '#5C5C5C',
    textMiddleGrey: '#666666',
    primary: '#292929',
    blue: {
      main: '#136FFB',
      secondary: '#009AFC',
      dark: '#0077EE',
    },
    green: {
      main: '#00B993',
      secondary: '#2DC966',
      dark: '#41D895',
      light: '#A0FFD5',
    },
    red: {
      main: '#FF6565',
      secondary: '#FF0000',
    },
    pink: {
      main: '#FB139E',
      secondary: '#FD5ABC',
    },
    grey: {
      main: '#848484',
      light: '#CBCBCB',
      secondary: '#C4C4C4',
      tertiary: '#989898',
      dark: '#A2A2A2',
      quaternary: '#BEBEBE',
    },
    backdrop: fade('#0A2C43', 0.4),
  },
  sizes: {
    header: 67,
    footer: 32,
    borderRadius: {
      default: 4,
      medium: 10,
    },
    typography: {
      tiny: '10px',
      caption: '12px',
      body: '14px',
      subtitle1: '18px',
      subtitle2: '16px',
      h1: '33px',
      h2: '23px',
      h3: '20px',
    },
  },
  shadows: {
    big: '0px 6px 6px -3px rgba(0,0,0,0.2), 0px 10px 14px 1px rgba(0,0,0,0.14), 0px 4px 18px 3px rgba(0,0,0,0.12)',
    dropdown: '0px 3px 6px #000029',
    panel: '0px 3px 6px #000029',
  },
  spacing(value: number) {
    return `${value * this.unit}px`;
  },
  unit: 4,
};

export const configureTheme = (type: ThemeType): Theme => (type === 'light' ? lightTheme : lightTheme);

export interface Theme {
  fonts: Fonts
  colors: Colors
  shadows: Shadows
  sizes: Sizes
  spacing: (value: number) => string
  unit: number
}

export type ThemeType = 'light' | 'dark';

interface Colors {
  background: {
    paper: {
      lightGray: string
      darkGrey: string
      default: string
      dark: string
      footer: string
      blue: string
    }
    darkGradient: string
  }
  common: {
    white: string
    black: string
  }
  textPrimary: string
  textSecondary: string
  textTertiary: string
  textDisabled: string
  textBlack: string
  textGray: string
  textDarkGrey: string
  textLightGrey: string
  textPrimaryGray: string
  textMiddleGrey: string
  primary: string
  blue: {
    main: string
    secondary: string
    dark: string
  }
  green: {
    main: string
    secondary: string
    dark: string
    light: string
  }
  red: {
    main: string
    secondary: string
  }
  pink: {
    main: string
    secondary: string
  }
  grey: {
    light: string
    main: string
    secondary: string
    tertiary: string
    dark: string
    quaternary: string
  }
  backdrop: string
}

interface Shadows {
  big: string
  dropdown: string
  panel: string
}

interface Fonts {
  bold: string
  regular: string
  montserrat: string
  semibold: string
  script: string
}

export interface Sizes {
  header: number
  footer: number
  borderRadius: {
    default: number
    medium: number
  }
  typography: {
    tiny: string
    caption: string
    body: string
    subtitle1: string
    subtitle2: string
    h1: string
    h2: string
    h3: string
  }
}
