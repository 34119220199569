import React from 'react';
import { makeStyles } from 'hooks/makeStyles';
import clsx from 'clsx';

export interface ToggleButtonGroupProps {
  fullWidth?: boolean
  value?: string | string[]
  onChange?: (e: React.MouseEvent, value?: string | string[]) => void
  exclusive?: boolean
  className?: string
  children: React.ReactNode
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-flex',
  },
  fullWidth: {
    width: '100%',
  },
}));

export const ToggleButtonGroup = (props: ToggleButtonGroupProps) => {
  const {
    fullWidth,
    value,
    onChange,
    exclusive,
    className,
    children,
    ...passthrough
  } = props;
  const css = useStyles();

  const cx = clsx(css.root, className, {
    [css.fullWidth]: fullWidth,
  });

  function handleChange(e: React.MouseEvent, buttonValue: string) {
    if (!onChange) {
      return;
    }

    const index = value ? value.indexOf(buttonValue) : -1;
    let newValue;

    if (value && index >= 0) {
      newValue = [...(value as string[])];
      newValue.splice(index, 1);
    } else {
      newValue = value ? [...(value as string[]), buttonValue] : [buttonValue];
    }

    onChange(e, newValue);
  }

  function handleExclusiveChange(e: React.MouseEvent, buttonValue: string) {
    if (!onChange) {
      return;
    }

    onChange(e, value === buttonValue ? undefined : buttonValue);
  }

  function isValueSelected(selectedValue?: string, values?: string | string[]) {
    if (values == null || selectedValue == null) {
      return false;
    }

    if (Array.isArray(values)) {
      return values.indexOf(selectedValue) >= 0;
    }

    return selectedValue === values;
  }

  return (
    <div className={cx} {...passthrough}>
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return null;
        }

        const {
          selected: toggleButtonSelected,
          value: toggleButtonValue,
        } = child.props;

        const selected = toggleButtonSelected == null
          ? isValueSelected(toggleButtonValue, value)
          : toggleButtonSelected;

        return React.cloneElement(child, {
          selected,
          onChange: exclusive ? handleExclusiveChange : handleChange,
        });
      })}
    </div>
  );
};
