import React, { useState } from 'react';

import cn from 'classnames';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';

import { ReactComponent as ArrowIcon } from 'assets/svg/Arrow.svg';

import styles from './Pagination.module.css';

interface PaginationProps extends ReactPaginateProps {
  disabled?: boolean;
  scrollToSelector?: string;
}

const Pagination = (props: PaginationProps) => {
  if (props.pageCount <= 1) {
    return null;
  }

  const [page, setPage] = useState(0);

  const onChange = ({ selected }: { selected: number }) => {
    if (props.onPageChange) {
      setPage(selected);

      props.onPageChange({ selected });
    }
  };

  return (
    <ReactPaginate
      containerClassName={cn(styles.pagination, props.disabled && styles.disabled)}
      pageLinkClassName={styles.pageLinkClassName}
      nextLinkClassName={cn(styles.pageLinkClassName, styles.nextLink)}
      previousLinkClassName={cn(styles.pageLinkClassName, styles.previousLink, {
        [styles.hidePageLink]: page === 0,
      })}
      breakClassName={styles.breakMe}
      activeClassName={styles.active}
      previousLabel={<ArrowIcon className={cn(styles.arrow, styles.arrowLeft)} />}
      nextLabel={<ArrowIcon className={styles.arrow} />}
      {...props}
      onPageChange={onChange}
    />
  );
};

export default Pagination;
