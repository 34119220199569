import React from 'react';
import { makeStyles } from 'hooks/makeStyles';
import clsx from 'clsx';
import { Button } from '../button/button';

interface SwitchProps {
  checked: boolean
  onChange: (value: boolean) => void
  defaultBgColor?: string
  activeBgColor?: string
  defaultSwitchColor?: string
  activeSwitchColor?: string
  translateX?: string
  className?: string
}

const useStyles = makeStyles((theme) => ({
  input: {
    display: 'none',
  },
  switch: {
    position: 'relative',
    padding: theme.spacing(1),
    width: 48,
    height: 32,
    backgroundColor: ({ defaultBgColor }: SwitchProps) => defaultBgColor || theme.colors.background.paper.default,
    '&:hover, &:active': {
      backgroundColor: ({ defaultBgColor }: SwitchProps) => defaultBgColor || theme.colors.background.paper.default,
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 4,
      top: 4,
      width: 24,
      height: 24,
      borderRadius: '50%',
      backgroundColor: (
        { defaultSwitchColor }: SwitchProps,
      ) => defaultSwitchColor || theme.colors.background.paper.default,
      transition: 'transform .2s, backgroundColor .2s',
    },
  },
  checked: {
    backgroundColor: ({ activeBgColor }: SwitchProps) => activeBgColor || theme.colors.primary,
    '&:hover, &:active': {
      backgroundColor: ({ activeBgColor }: SwitchProps) => activeBgColor || theme.colors.primary,
    },
    '&:before': {
      transform: ({ translateX }: SwitchProps) => `translateX(${translateX})` || 'translateX(16px)',
      backgroundColor: (
        { activeSwitchColor }: SwitchProps,
      ) => activeSwitchColor || theme.colors.background.paper.default,
    },
  },
}));

export const Switch = (props: SwitchProps) => {
  const css = useStyles(props);

  const handleChange = () => {
    props.onChange(!props.checked);
  };

  return (
    <span>
      <Button
        role="switch"
        className={clsx(css.switch, props.className, {
          [css.checked]: props.checked,
        })}
        onClick={handleChange}
        aria-checked={props.checked}
      />
    </span>
  );
};
