import React, { useContext } from 'react';
import { Theme } from 'lib/theme';
import { makeStyles } from 'hooks/makeStyles';
import { IconButton, Icons, Typography } from 'ui/shared';
import { observer } from 'mobx-react';
import { ApplicationContext } from '../../../global-stores/application-context';

import { ReactComponent as BurgerIcon } from './Burger.svg';
import { ReactComponent as BurgerArrowIcon } from './BurgerArrow.svg';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    width: '100%',
    height: 'var(--header-size-height)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.colors.background.paper.lightGray,
    boxSizing: 'border-box',
    flexShrink: 0,
    borderBottom: `0.5px solid ${theme.colors.grey.tertiary}`,
  },
  burger: {
    padding: 20,
    margin: 0,

    backgroundColor: 'transparent',
    border: 'none',

    appearance: 'none',
  },
  button: {
    marginRight: `${theme.spacing(22)}`,
    flexDirection: 'row',
    flexWrap: 'nowrap',
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
    },
  },
  label: {
    fontSize: 16,
    marginLeft: `${theme.spacing(2.25)}`,
    textTransform: 'capitalize',
  },
}));

export const Header = observer(() => {
  const css = useStyles();
  const app = useContext(ApplicationContext);

  return (
    <header className={css.header}>
      <button
        className={css.burger}
        type="button"
        onClick={app.toggleSidebar}
      >
        {
          app.sidebarSmall
            ? <BurgerIcon />
            : <BurgerArrowIcon />
        }
      </button>

      <IconButton className={css.button} onClick={() => app.clearAuthToken()}>
        <Icons.Exit
          width={18}
          height={18}
          viewBox="0 0 18 18"
          color="#FB139E"
        />

        <Typography className={css.label} font="semibold">
          Выход
        </Typography>
      </IconButton>
    </header>
  );
});
