import React from 'react';
import { CreateSvgIcon } from '../icon';
import { IconProps } from '../names';

export const Alert = (props: IconProps) => (
  <CreateSvgIcon {...props}>
    {({ color }) => (
      <>
        <circle cx="15" cy="15" r="13" stroke={color} strokeWidth="4" />
        <path
          d="M16.6112 21.7722H14.0624V12.2625H16.6112V21.7722ZM13.913 9.80151C13.913 9.42065 14.0389 9.10718 14.2909 8.86108C14.5487 8.61499 14.8973 8.49194 15.3368 8.49194C15.7704 8.49194 16.1161 8.61499 16.3739 8.86108C16.6317 9.10718 16.7606 9.42065 16.7606 9.80151C16.7606 10.1882 16.6288 10.5046 16.3651 10.7507C16.1073 10.9968 15.7645 11.1199 15.3368 11.1199C14.9091 11.1199 14.5634 10.9968 14.2997 10.7507C14.0419 10.5046 13.913 10.1882 13.913 9.80151Z"
          fill={color}
        />
      </>
    )}
  </CreateSvgIcon>
);
