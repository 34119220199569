import { EnumApartment } from '_enums';
import { ObjectRoomsType } from '_types';

type Props = {
  type: EnumApartment;
  rooms: ObjectRoomsType;
  floors_number?: number;
};

export const getApartmentSize = ({ rooms, type }: Props): string => {
  switch (type) {
    case EnumApartment.Flat:
      switch (rooms) {
        case 'studio':
          return 'Студия';
        case 'free':
          return 'Свободная планировка';
        default:
          if (rooms) {
            return `${rooms}-к. квартира`;
          }
          return 'квартира';
      }
    case EnumApartment.House:
      switch (rooms) {
        case '0':
          return 'Свободная планировка';
        case null:
          return 'Свободная планировка';
        default:
          return `${rooms}-к. дом`;
      }
    case EnumApartment.Land:
      return 'Участок';
    case EnumApartment.Commerce:
      return 'Коммерция';
    default:
      return '';
  }
};
