export const PROFILE_EDIT = '/profile/edit';

export const OBJECTS = '/objects/agents';

export const ANALYTICS = '/analytics';

export const SETTINGS = '/settings';

export const SUPPORT = '/support';

export const SIGN_IN = '/sign-in';
