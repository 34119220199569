import { UserType } from 'interfaces';

export const getAgentType = (user_type: UserType) => {
  switch (user_type) {
    case 'expert':
      return 'Эксперт';
    case 'agent':
      return 'Агент';
    case 'broker':
      return 'Брокер';
    default:
      return 'Специалист';
  }
};
