import React from 'react';
import { makeStyles } from 'hooks/makeStyles';
import clsx from 'clsx';
import google from 'assets/svg/GooglePlay.svg';
import apple from 'assets/svg/AppStore.svg';
import huawei from 'assets/svg/huawei-store.svg';
import styles from './app-store.module.css';

import { Typography } from 'ui/shared';

interface AppStoresProps {
  className?: string;
  isShow: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(4.5)} 4%`,
    color: theme.colors.textSecondary,
    backgroundColor: theme.colors.background.paper.blue,
    '@media screen and (max-width: 835px)': {
      flexDirection: 'column',
      padding: `${theme.spacing(5)} ${theme.spacing(4.25)}`,
    },
  },
  stores: {
    display: 'flex',
    marginRight: 32,
    '@media screen and (max-width: 1114px)': {
      flexDirection: 'column',
    },
    '@media screen and (max-width: 835px)': {
      marginTop: theme.spacing(7.25),
      marginRight: theme.spacing(0),
      order: 1,
      flexDirection: 'row',
      flexWrap: 'nowrap',
    },
    '@media screen and (max-width: 768px)': {
      flexDirection: 'row',
      justifyContent: 'center',
      flexWrap: 'wrap',
      marginRight: theme.spacing(0),
    },
  },
  storesMob: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-around',
    marginTop: theme.spacing(9),
  },
  googleLink: {
    width: 135,
    height: 40,
    cursor: 'pointer',
    marginRight: 18,
    '@media screen and (max-width: 1114px)': {
      marginBottom: theme.spacing(5.25),
      marginRight: theme.spacing(0),
    },
    '@media screen and (max-width: 835px)': {
      marginRight: theme.spacing(2.25),
      marginBottom: theme.spacing(0),
    },
    '@media screen and (max-width: 768px)': {
      margin: theme.spacing(1.8),
    },
  },
  appleLink: {
    width: 120,
    height: 40,
    cursor: 'pointer',
    '@media screen and (max-width: 768px)': {
      margin: theme.spacing(1.8),
    },
  },
  huaweiLink: {
    width: 120,
    height: 40,
    cursor: 'pointer',
    '@media screen and (max-width: 768px)': {
      margin: theme.spacing(1.8),
    },
  },
  storesText: {
    maxWidth: 815,
    color: 'inherit',
  },
  storesSubTitle: {
    color: theme.colors.textMiddleGrey,
  },
  storesTitle: {
    fontSize: 16,
    font: theme.fonts.regular,
  },
}));

export const AppStoresDesc = (props: AppStoresProps) => {
  const { isShow, className } = props;
  const css = useStyles(props);

  return (
    <div className={clsx(css.root, className, { [css.hidden]: !isShow })}>
      <div className={css.stores}>
        <a href="https://play.google.com/store/apps/details?id=pro.reagent&hl=ru" className={css.googleLink}>
          <img src={google} alt="Google Play" />
        </a>
        <a href="https://apps.apple.com/ru/app/reagent/id1087001957" className={css.appleLink}>
          <img src={apple} alt="App Store" />
        </a>
        <a href="https://appgallery.huawei.com/app/C105489579" className={css.huaweiLink}>
          <img src={huawei} alt="Huawei AppGallery" />
        </a>
      </div>
      <Typography className={css.storesText} size="subtitle2(16px)" font="semibold">
        <span className={css.storesTitle}>
          GetAgent - Приложение Ассоциации Профессиональных риэлторов
        </span>
      </Typography>
    </div>
  );
};

export const AppStoresMob = (props: AppStoresProps) => {
  if (!props.isShow) {
    return null;
  }

  return (
    <div>
      <p className={styles.message}>
        GetAgent - Приложение Ассоциации Профессиональных риэлторов
      </p>

      <div className={styles.stories}>
        <a className={styles['story-badge']} href="https://play.google.com/store/apps/details?id=pro.reagent&hl=ru" target="_blank" rel="noreferrer">
          <img src={google} alt="Google Play" />
        </a>

        <a className={styles['story-badge']} href="https://apps.apple.com/ru/app/reagent/id1087001957" target="_blank" rel="noreferrer">
          <img src={apple} alt="App Store" />
        </a>

        <a
          className={styles['story-badge']}
          href="https://appgallery.huawei.com/app/C105489579"
          target="_blank"
          rel="noreferrer"
        >
          <img src={huawei} alt="Huawei AppGallery" />
        </a>
      </div>
    </div>
  );
};
