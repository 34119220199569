import React from 'react';
import { makeStyles } from 'hooks/makeStyles';
import clsx from 'clsx';

interface DefaultPanelProps {
  header?: () => React.ReactNode
  children?: React.ReactNode
  className?: string
}

const useStyles = makeStyles((theme) => ({
  panelContainer: {
    width: '100%',
    minWidth: '200px',
    marginLeft: 0,
    backgroundColor: theme.colors.background.paper.default,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    '&:focus': {
      outline: 'none',
    },
  },
  scrollContainer: {
    height: '100%',
    boxSizing: 'border-box',
    backgroundColor: theme.colors.background.paper.default,
  },
}));

export const DefaultPanel = (props: DefaultPanelProps) => {
  const { header, children, className } = props;
  const css = useStyles();

  return (
    <div className={clsx(css.panelContainer, className)}>
      {header ? header() : null}
      <div className={css.scrollContainer}>{children}</div>
    </div>
  );
};
