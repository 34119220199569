import React from 'react';

import { PictureProps } from '_types';

import styles from './Picture.module.css';

const Picture = ({
  src, alt, srcset = [], classList,
}: PictureProps) => (
  <picture
    className={`
      ${styles.picture}
      ${classList}
    `}
  >
    {
      srcset.map((source) => (
        <source
          key={source.srcset}
          srcSet={source.srcset}
          media={source.media}
          type={source.type}
        />
      ))
    }
    <img src={src} alt={alt} />
  </picture>
);

export default Picture;
