import React from 'react';
import { CreateSvgIcon } from '../icon';
import { IconProps } from '../names';

export const Cross = (props: IconProps) => (
  <CreateSvgIcon {...props}>
    {({ color }) => (
      <path
        d="M2.26699 0.360269L8.50015 6.59283L14.7333 0.379156C14.856 0.254296 15.0033 0.156257 15.1659 0.0912389C15.3285 0.0262209 15.5028 -0.00436174 15.6777 0.00142497C16.0211 0.0236438 16.3447 0.170065 16.588 0.413349C16.8313 0.656632 16.9777 0.980146 16.9999 1.32348C17.0016 1.49229 16.969 1.65969 16.9041 1.81553C16.8392 1.97136 16.7432 2.11238 16.6222 2.23004L10.3701 8.50037L16.6222 14.7707C16.8677 15.0087 17.0037 15.3373 16.9999 15.6773C16.9777 16.0206 16.8313 16.3441 16.588 16.5874C16.3447 16.8307 16.0211 16.9771 15.6777 16.9993C15.5028 17.0051 15.3285 16.9745 15.1659 16.9095C15.0033 16.8445 14.856 16.7464 14.7333 16.6216L8.50015 10.4079L2.28588 16.6216C2.16316 16.7464 2.01586 16.8445 1.8533 16.9095C1.69073 16.9745 1.51645 17.0051 1.34146 16.9993C0.991686 16.9811 0.661055 16.834 0.413392 16.5863C0.165729 16.3387 0.018593 16.0081 0.000386603 15.6584C-0.00132242 15.4896 0.0312805 15.3222 0.0962177 15.1663C0.161155 15.0105 0.257073 14.8695 0.378154 14.7518L6.63021 8.50037L0.359266 2.23004C0.241594 2.11079 0.149167 1.96906 0.0875055 1.8133C0.0258444 1.65754 -0.00378532 1.49095 0.000386603 1.32348C0.0226076 0.980146 0.169043 0.656632 0.41235 0.413349C0.655658 0.170065 0.979203 0.0236438 1.32257 0.00142497C1.4962 -0.00682083 1.66966 0.0207861 1.83215 0.0825247C1.99463 0.144263 2.14266 0.23881 2.26699 0.360269Z"
        fill={color}
      />
    )}
  </CreateSvgIcon>
);
