import React from 'react';
import { makeStyles } from 'hooks/makeStyles';
import {
  Button, Icons, Input, Typography,
} from 'ui/shared';

export interface WhatsAppModalBodyProps {
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  onClose?: (e?: React.MouseEvent | React.KeyboardEvent) => void
}

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: `${theme.spacing(5)} ${theme.spacing(6)}`,
    backgroundColor: theme.colors.background.paper.default,
  },
  icon: {
    marginRight: `${theme.spacing(3.5)}`,
  },
  body: {
    padding: `${theme.spacing(5)}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  subtitle: {
    fontSize: 15,
  },
  inputContainer: {
    backgroundColor: theme.colors.background.paper.darkGrey,
    border: `0.5px solid ${theme.colors.grey.light}`,
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    width: '100%',
    marginBottom: `${theme.spacing(8)}`,
    overflow: 'hidden',
  },
  helperTextWrap: {
    padding: `${theme.spacing(1.75)}`,
    borderRight: `0.5px solid ${theme.colors.grey.light}`,
  },
  helperText: {
    fontSize: 15,
  },
  input: {
    width: '100%',
    border: 'none',
    backgroundColor: 'transparent',
    padding: `${theme.spacing(1.75)}`,
    fontSize: 16,
    '&:focus': {
      outline: 'none',
      border: 'none',
    },
  },
  socialAddButton: {
    flexGrow: 0,
    color: theme.colors.textSecondary,
    backgroundColor: theme.colors.blue.dark,
    borderRadius: 6,
    textTransform: 'capitalize',
    fontSize: 20,
    paddingLeft: theme.spacing(6.5),
    paddingRight: theme.spacing(6.5),
    marginTop: theme.spacing(14),
  },
}));

export const WhatsAppModalHeader = () => {
  const css = useStyles();

  return (
    <div className={css.header}>
      <Icons.Whatsapp className={css.icon} width={20} height={20} viewBox="0 0 32 32" />
      <Typography size="subtitle1(18px)" align="center" font="semibold">
        WhatsApp
      </Typography>
    </div>
  );
};

export const WhatsAppModalBody = (props: WhatsAppModalBodyProps) => {
  const {
    value, onChange, placeholder, onClose,
  } = props;
  const css = useStyles();

  return (
    <div className={css.body}>
      <div className={css.inputContainer}>
        <div className={css.helperTextWrap}>
          <Typography
            className={css.helperText}
            size="subtitle2(16px)"
            align="center"
            font="semibold"
          >
            https://wa.me/
          </Typography>
        </div>
        <Input
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className={css.input}
          type="phone"
        />
      </div>
      <Typography className={css.subtitle} size="subtitle2(16px)" align="left" font="regular">
        Введите номер телефона без кода страны на котором установлен WhatsApp
      </Typography>
      <Button className={css.socialAddButton} onClick={onClose}>
        Сохранить
      </Button>
    </div>
  );
};
