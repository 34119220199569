import React from 'react';
import { CreateSvgIcon } from '../icon';
import { IconProps } from '../names';

export const Camera = (props: IconProps) => (
  <CreateSvgIcon {...props}>
    {({ color }) => (
      <>
        <path
          d="M20.1693 29.5531C19.1721 29.5531 18.1972 29.2582 17.368 28.7059C16.5388 28.1535 15.8925 27.3684 15.5109 26.4498C15.1292 25.5312 15.0294 24.5205 15.2239 23.5453C15.4185 22.5702 15.8987 21.6745 16.6039 20.9714C17.3091 20.2684 18.2075 19.7896 19.1856 19.5956C20.1637 19.4016 21.1776 19.5012 22.0989 19.8817C23.0203 20.2622 23.8077 20.9065 24.3618 21.7332C24.9158 22.5599 25.2116 23.5318 25.2116 24.5261C25.2099 25.8588 24.6781 27.1365 23.7329 28.0789C22.7876 29.0213 21.5061 29.5514 20.1693 29.5531ZM20.1693 22.0125C19.6707 22.0125 19.1832 22.16 18.7687 22.4361C18.3541 22.7123 18.0309 23.1049 17.8401 23.5642C17.6493 24.0235 17.5994 24.5288 17.6966 25.0164C17.7939 25.504 18.034 25.9519 18.3866 26.3034C18.7392 26.6549 19.1884 26.8943 19.6775 26.9913C20.1665 27.0883 20.6734 27.0385 21.1341 26.8482C21.5948 26.658 21.9885 26.3358 22.2656 25.9225C22.5426 25.5091 22.6904 25.0232 22.6904 24.5261C22.6898 23.8596 22.4239 23.2207 21.9513 22.7495C21.4786 22.2782 20.8378 22.0132 20.1693 22.0125Z"
          fill={color}
        />
        <path
          d="M30.2539 33.3222H10.0849C9.41626 33.3222 8.77501 33.0574 8.3022 32.586C7.8294 32.1147 7.56378 31.4753 7.56378 30.8087V19.4979C7.56378 18.8313 7.8294 18.1919 8.3022 17.7206C8.77501 17.2492 9.41626 16.9844 10.0849 16.9844H14.2196L14.904 14.9334C15.0713 14.4326 15.3924 13.9971 15.8219 13.6886C16.2513 13.38 16.7672 13.214 17.2966 13.2141H23.0422C23.5716 13.214 24.0875 13.38 24.517 13.6886C24.9464 13.9971 25.2675 14.4326 25.4348 14.9334L26.1193 16.9844H30.2539C30.9226 16.9844 31.5638 17.2492 32.0366 17.7206C32.5094 18.1919 32.775 18.8313 32.775 19.4979V30.8087C32.775 31.4753 32.5094 32.1147 32.0366 32.586C31.5638 33.0574 30.9226 33.3222 30.2539 33.3222ZM10.0849 30.8087H30.2539V19.4979H24.3041L23.0435 15.7276H17.2953L16.0348 19.4979H10.0849V30.8087Z"
          fill={color}
        />
        <path
          d="M30.2536 10.7007H32.7748V13.2142H30.2536V10.7007Z"
          fill={color}
        />
        <path
          d="M30.2536 5.67358H32.7748V8.1871H30.2536V5.67358Z"
          fill={color}
        />
        <path
          d="M30.2536 0.646729H32.7748V3.16024H30.2536V0.646729Z"
          fill={color}
        />
        <path
          d="M25.2115 0.646729H27.7326V3.16024H25.2115V0.646729Z"
          fill={color}
        />
        <path
          d="M20.1693 0.646729H22.6904V3.16024H20.1693V0.646729Z"
          fill={color}
        />
        <path
          d="M15.1271 0.646729H17.6482V3.16024H15.1271V0.646729Z"
          fill={color}
        />
        <path
          d="M10.0849 0.646729H12.606V3.16024H10.0849V0.646729Z"
          fill={color}
        />
        <path
          d="M5.04268 0.646729H7.56381V3.16024H5.04268V0.646729Z"
          fill={color}
        />
        <path
          d="M0.000488281 0.646729H2.52162V3.16024H0.000488281V0.646729Z"
          fill={color}
        />
        <path
          d="M0.000488281 5.67358H2.52162V8.1871H0.000488281V5.67358Z"
          fill={color}
        />
        <path
          d="M0.000488281 10.7007H2.52162V13.2142H0.000488281V10.7007Z"
          fill={color}
        />
        <path
          d="M0.000488281 15.7275H2.52162V18.2411H0.000488281V15.7275Z"
          fill={color}
        />
        <path
          d="M0.000488281 20.7544H2.52162V23.2679H0.000488281V20.7544Z"
          fill={color}
        />
        <path
          d="M0.000488281 25.7815H2.52162V28.295H0.000488281V25.7815Z"
          fill={color}
        />
        <path
          d="M0.000488281 30.8086H2.52162V33.3221H0.000488281V30.8086Z"
          fill={color}
        />
      </>
    )}
  </CreateSvgIcon>
);
