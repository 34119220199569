import React, { useContext } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';

import { FieldState } from '_types/Field';
import { ObjectFilterFieldsContext } from 'global-stores/fields';
import { useParams } from '../../../pages/objects/_helpers/add-params';
import { URLParams } from '../../../pages/objects/_types/urlParams';

import Field from 'ui/components/Field';

import BaseStyles from 'styles/BaseStyles.module.css';
import styles from './RangeFields.module.css';

type RangeFieldsProps = FieldState;

const RangeFields = observer(({ label, type }: RangeFieldsProps) => {
  const [, setParam] = useParams<URLParams>();
  const objectSearchResults = useContext(ObjectFilterFieldsContext);

  const handleChangeValueFrom = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    objectSearchResults.handleChange(`${type}[from]`, value);
  };

  const handleBlurValueFrom = () => {
    setParam(`${type}[from]`, objectSearchResults.values[`${type}[from]`]);
  };

  const handleChangeValueTo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    objectSearchResults.handleChange(`${type}[to]`, value);
  };

  const handleBlurValueTo = () => {
    setParam(`${type}[to]`, objectSearchResults.values[`${type}[to]`]);
  };

  return (
    <div className={classNames(BaseStyles.backdrop, styles.backdrop)}>
      <p className={classNames(BaseStyles.label, styles.label)}>
        {label}
      </p>

      <div className={styles.fields}>
        <Field
          value={objectSearchResults.values[`${type}[from]`]}
          placeholder="От"
          onChange={handleChangeValueFrom}
          onBlur={handleBlurValueFrom}
          theme="filter"
        />

        <Field
          value={objectSearchResults.values[`${type}[to]`]}
          placeholder="До"
          onChange={handleChangeValueTo}
          onBlur={handleBlurValueTo}
          theme="filter"
        />
      </div>
    </div>
  );
});

export default RangeFields;
