import { createContext } from 'react';
import { action, makeAutoObservable, observable } from 'mobx';
import { FieldState } from '_types/Field';

class Fields {
  @observable fields: Record<string, FieldState> = {};

  @observable fieldsByKeys: number[] = [];

  @observable values: Record<string, string> = {};

  constructor() {
    makeAutoObservable(this);
  }

  @action
  setFields(fields: FieldState[]) {
    this.fields = {};
    this.fieldsByKeys = [];
    this.values = {};

    fields.forEach((field) => {
      this.fields[field.type] = field;

      Object.keys(field.values).forEach((key) => {
        // eslint-disable-next-line no-prototype-builtins
        if (field.values.hasOwnProperty(key)) {
          this.values[key] = field.values[key].value;
        }
      });

      this.fieldsByKeys.push(field.id);
    });
  }

  handleChange(fieldName: string, value: string) {
    this.values[fieldName] = value;
  }

  @action
  reset() {
    Object.keys(this.values).forEach((key) => {
      this.values[key] = '';
    });
  }
}

export default Fields;

export const ObjectFilterFieldsContext = createContext<Fields>(
  new Fields(),
);
