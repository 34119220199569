import ky, { NormalizedOptions } from 'ky';
import Config from '../config';

const baseUrl = `${Config.API_URL}/api`;

const headers = {
  'Content-Type': 'application/json',
  Origin: Config.API_URL,
};

const defaulOptions = {
  prefixUrl: baseUrl,
  headers,
  throwHttpErrors: false,
};

const storedToken = localStorage.getItem('token');

class ApiService {
  token: string | null = storedToken;

  api = ky.create({
    ...defaulOptions,
    headers: this.token
      ? {
        Authorization: `Token ${this.token}`,
      }
      : {},
    hooks: {
      afterResponse: [
        async (
          _request: Request,
          _options: NormalizedOptions,
          response: Response,
        ): Promise<Response | void> => {
          const contentType = response.headers.get('content-type');
          if (contentType === 'application/json') {
            const data = await response.json();
            if (
              Object.prototype.hasOwnProperty.call(data, 'token')
              || Object.prototype.hasOwnProperty.call(data, 'temp_token')
            ) {
              this.setToken(data.token || data.temp_token);
            }
          }

          return response;
        },
      ],
    },
  });

  setToken(token: string) {
    this.token = token;
    localStorage.setItem('token', token);
  }

  clearToken() {
    this.token = null;
  }

  get(path: string, params: any = null) {
    return this.api.get(path, {
      ...{ searchParams: params },
      headers: this.token
        ? {
          Authorization: `Token ${this.token}`,
        }
        : {},
    });
  }

  post(path: string, options: any) {
    return this.api.post(path, {
      ...options,
      headers: this.token
        ? {
          Authorization: `Token ${this.token}`,
        }
        : {},
    });
  }

  postForm(path: string, formData: FormData) {
    return this.api.post(path, {
      body: formData,
      headers: this.token
        ? {
          Authorization: `Token ${this.token}`,
        }
        : {},
    });
  }

  patch(path: string, options: any) {
    return this.api.patch(path, {
      ...options,
      headers: this.token
        ? {
          Authorization: `Token ${this.token}`,
        }
        : {},
    });
  }

  patchFormData(path: string, formData: FormData) {
    return this.api.patch(path, {
      body: formData,
      headers: this.token
        ? {
          Authorization: `Token ${this.token}`,
        }
        : {},
    });
  }

  delete(path: string, params: any) {
    this.api.delete(path, {
      json: params,
      headers: this.token
        ? {
          Authorization: `Token ${this.token}`,
        }
        : {},
    });
  }
}

export default ApiService;
