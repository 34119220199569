import React, { useContext, useEffect } from 'react';
import { makeStyles } from 'hooks/makeStyles';
import { Theme } from 'lib/theme';
import {
  Redirect, Route, Switch, useLocation,
} from 'react-router-dom';
import { observer } from 'mobx-react';
import { ApplicationContext } from 'global-stores/application-context';
import { SignIn } from 'ui/pages/sign-in/sign-in';
import { SignUp } from 'ui/pages/sign-up/sign-up';
import { ProfessionalInfo } from 'ui/pages/sign-up/professional-info';
import AddPhoto from 'ui/pages/sign-up/add-photo';
import SocialNetworks from 'ui/pages/sign-up/social-networks';
import AboutMe from 'ui/pages/sign-up/about-me';

import { ServicesRoutes } from 'domains/services/routes';
import { ServicesPage } from 'domains/services/pages/services';
import { ProfileRoutes } from 'ui/pages/profile/routes';
import { DocsRoutes } from 'domains/docs/routes';
import { Routes as DocsRouteConstants } from 'domains/docs/constants';
import Objects from 'ui/pages/objects/Objects';
import { Support } from 'ui/pages/support/support';
import { Analytics } from 'ui/pages/analytics/analytics';

import Sidebar from 'ui/components/sidebar/sidebar';
import { Header } from 'ui/components/header/header';
import { Background } from '../../background/background';
import { getShortPhoneNumber } from '_helpers/get-short-phone-number';
import { useParams } from 'ui/pages/objects/_helpers/add-params';
import { ServerDownPage } from 'domains/server-down/pages/server-down';

const useStyles = makeStyles((theme: Theme) => ({
  signWrap: {
    display: 'flex',
    padding: '56px 16px',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  appContainer: {
    height: '100vh',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media screen and (max-width: 1000px)': {
      alignItems: 'flex-start',
    },
  },
  main: {
    maxHeight: '100%',
    overflowX: 'hidden',
    padding: '20px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  mainLayout: {
    height: 'calc(100% - var(--header-size-height))',
  },
  mainContainer: {
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '350px 1fr',
  },
  mainContainerSmall: {
    gridTemplateColumns: '60px 1fr',
  },
  whiteBackground: {
    background: theme.colors.background.paper.default,
  },
}));

export const DesktopLayout = observer(() => {
  const css = useStyles();
  const location = useLocation();
  const applicationContext = useContext(ApplicationContext);
  const isSingInPage = location.pathname.startsWith('/signin');
  const isDocsPage = location.pathname.startsWith('/docs');

  const [params] = useParams<any>();

  useEffect(() => {
    const authParamsKey = 'auth-params';

    // eslint-disable-next-line
    const { first_name, last_name, phone, email } = params;

    if (typeof phone === 'undefined' || getShortPhoneNumber(phone) === '') {
      localStorage.removeItem(authParamsKey);
      return;
    }

    const shortPhoneNumber = getShortPhoneNumber(phone);

    const haveAuthParams = [first_name, last_name, shortPhoneNumber, email].every(Boolean);

    if (haveAuthParams) {
      localStorage.setItem(authParamsKey, JSON.stringify({
        first_name, last_name, phone: shortPhoneNumber, email,
      }));
    }
  }, [params]);

  if (!(isSingInPage || isDocsPage) && !applicationContext.isAuthenticated) {
    return <Redirect to="/signin" />;
  }

  if (applicationContext.serverIsNotAvailable) {
    return (
      <ServerDownPage />
    );
  }

  return (
    <>
      <React.Suspense fallback={null}>
        {applicationContext.isRegistered ? (
          <div className={css.root}>
            <Header />
            <div className={css.mainLayout}>
              <div
                className={`
                  ${css.mainContainer}
                  ${applicationContext.sidebarSmall && css.mainContainerSmall}
                `}
              >
                <Sidebar />
                <Switch>
                  <Route
                    exact
                    path="/"
                    component={ServicesPage}
                  />

                  <Route
                    path="/profile"
                    component={ProfileRoutes}
                  />

                  <Route
                    path="/analytics"
                    component={Analytics}
                  />

                  <Route
                    path="/services"
                    component={ServicesRoutes}
                  />

                  <Route
                    path="/support"
                    component={Support}
                  />

                  <Route
                    path="/objects"
                    component={Objects}
                  />

                  <Route
                    path={DocsRouteConstants.DOCS_HOME}
                    component={DocsRoutes}
                  />

                  <Route path="/" render={(props) => <Redirect to="/" {...props} />} />
                </Switch>
              </div>
            </div>
          </div>
        ) : (
          <Switch>
            <Route exact path="/signin" component={SignIn} />
            <div>
              <Route exact path="/signup" component={SignUp} />
              <Route exact path="/signup/information" component={ProfessionalInfo} />
              <Route exact path="/signup/photo" component={AddPhoto} />
              <Route exact path="/signup/social" component={SocialNetworks} />
              <Route exact path="/signup/aboutme" component={AboutMe} />

              <Route
                path={DocsRouteConstants.DOCS_HOME}
                component={DocsRoutes}
              />

              {!isDocsPage && (
                <Background />
              )}
            </div>
            <Route>
              <div>Not found</div>
            </Route>
          </Switch>
        )}
      </React.Suspense>
    </>
  );
});
