import { NB_SP, LAQUO, RAQUO } from 'text-constants';
import Ruble from '../service-info/ruble.svg';
import UrbanAgent from 'assets/logo.svg';
import SochiDeal from 'assets/sochisdelka.png';
import Cloud from '../service-info/cloud.svg';
import Blockchain from '../service-info/blockchain.svg';

type ImageSize = {
  height?: number;
  width?: number;
};

type Service = {
  label: string;
  title: string;
  text: string;
  image?: string;
  imageSize?: ImageSize;
  theme?: 'normal' | 'blue';
  link?: {
    component?: 'native' | 'route' | 'button' | 'button_external';
    to: string;
    text: string;
    secondaryText?: string;
  }
};

type Services = Record<string, Service>;

export const services: Services = {
  account: {
    label: 'Ваш аккаунт',
    title: 'Годовой взнос в Ассоциацию',
    // eslint-disable-next-line
    // text: `Подключить PRO аккаунт и${NB_SP}получите доступ к${NB_SP}закрытой базе риэлторов и${NB_SP}расширенному функционалу.`,
    text: 'Обслуживание подписки',
    image: UrbanAgent,
    theme: 'blue',
    link: {
      component: 'button',
      to: '/services/subscriptions',
      text: 'Подробнее',
      secondaryText: 'Подробнее',
    },
  },
  academy: {
    label: 'Обучение',
    title: 'Realtor certificate',
    text: `Квалификация риэлтора в${NB_SP}соответствии с${NB_SP}требованиями профессионального стандарта РГР, НОК. Аттестация и${NB_SP}тестирование он-лайн с${NB_SP}выдачей сертификата в${NB_SP}цифровом и${NB_SP}бумажном виде.`,
    image: Blockchain,
  },
  payment: {
    label: 'Оплата взносов',
    title: 'Членские взносы',
    text: `Оплата годовых членских взносов в${NB_SP}гильдию риэлторов`,
    image: Ruble,
  },
  extracts: {
    label: 'Выписки',
    title: 'Realty Cloud',
    text: `Онлайн проверка квартиры на${NB_SP}юридические риски`,
    image: Cloud,
  },
  deals: {
    label: '',
    title: 'Чистая сделка',
    // image: SochiDeal,
    // imageSize: {
    //   width: 100,
    // },
    text: `Сопровождение сделок с${NB_SP}недвижимостью: проверка юридической чистоты объектов, составление и регистрация договоров сделки в Росреестре без МФЦ.`,
    link: {
      component: 'button_external',
      to: 'https://xn--80ahdkmfrxc2d.xn--p1ai/grsochi',
      text: 'Записаться на сделку',
      secondaryText: 'Записаться',
    },
  },
  marketing: {
    label: 'Маркетинг',
    title: 'Мой агент',
    text: `Наши специалисты создают правильную рекламную стратегию для вас и${NB_SP}ваших объектов. Брендинг, таргетинг, упаковка.`,
  },
} as const;

export type ServiceType = keyof typeof services;

export const servicesTypes: ServiceType[] = [
  'account',
  'deals',
  'academy',
  'payment',
  'extracts',
  'marketing',
];
