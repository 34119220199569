import React, { Fragment, useContext, useState } from 'react';

import ShowMoreText from 'react-show-more-text';
import classNames from 'classnames/bind';
import YouTube from 'react-youtube';

import { ObjectServerProps } from '_types';
import { getAgoDate, getApartmentSize, getArea, getFormattedPrice } from '_helpers';

import { Text } from 'components/text';
import HeaderGallery from 'ui/components/HeaderGallery';
import GeneralInfo from 'ui/components/GeneralInfo';
import Button from 'ui/components/Button';
import MapSection from 'ui/components/MapSection';
import HeaderToggleButtons from 'ui/components/HeaderToggleButtons';
import HeaderToggleButton from 'ui/components/HeaderToggleButton';
import { AddToPropertyButton } from 'ui/components/add-to-property-button/add-to-property-button';

import { ReactComponent as ArrowIcon } from 'assets/svg/Arrow.svg';
import { ReactComponent as GalleryCameraIcon } from './camera.svg';
import { ReactComponent as GalleryVideoIcon } from './video.svg';
import { ReactComponent as GalleryMapIcon } from './map.svg';
import { ReactComponent as GalleryTourIcon } from './tour.svg';

import MoreTextStyles from 'styles/MoreText.module.css';
import styles from './ObjectOfSearchResult.module.css';
import Media from 'react-media';
import { useParams } from '../../../../pages/objects/_helpers/add-params';
import { URLParams } from '../../../../pages/objects/_types/urlParams';
import { ApplicationContext } from '../../../../../global-stores/application-context';
import CartOfUser from '../../../CartOfUser';
import VideoSection from 'ui/components/VideoSection';

const cx = classNames.bind(styles);

interface ObjectOfSearchResultProps {
  apartment: ObjectServerProps;
}

const ObjectOfSearchResult = ({ apartment: apartments }: ObjectOfSearchResultProps) => {
  const [params] = useParams<URLParams>();

  const applicationContext = useContext(ApplicationContext);

  const { currentUser } = applicationContext;

  const {
    id, city, region, street, home_number: homeNumber, floors_number: floorsNumber,
    photos, user, rooms, type,
    land_area: landArea, area, exclusive, townhouse,
    price, owner_price: ownerPrice, commission, apartment, ad_type: adType,
    vr_3d_tour: vr3dTour, video, new: newApartment, mortgage,
  } = apartments;

  const [collapsed, setCollapsed] = useState(false);

  const [visible, setVisible] = useState('gallery' as 'gallery' | 'map' | 'video' | '3d-tour');

  const toggleCollapse = (e: React.SyntheticEvent) => {
    const element = e.target as HTMLDivElement;

    if (
      !element.closest(`.${styles.AddToPropertyButton}`)
      && !element.classList.contains(styles.AddToPropertyButton)
      && (
        element.closest(`.${styles.apartment}`)
        || element.classList.contains(styles.apartment)
      )
      && !(
        element.classList.contains(styles.titleLink)
        || element.closest(`.${styles.titleLink}`)
      )
      && !(
        element.classList.contains(styles.pictureLink)
        || element.closest(`.${styles.pictureLink}`)
      )
    ) {
      setCollapsed(!collapsed);
    }
  };

  const videoId = video && (
    new URL(video).searchParams.get('v') || (/youtu.be/.test(video)
      ? new URL(video).pathname.replace('/', '')
      : false)
  );

  if (!currentUser) {
    return null;
  }

  const userEvaluations = params.withClient
    ? [
      currentUser.avg_expertise,
      currentUser.avg_local_know,
      currentUser.avg_conversation,
      currentUser.avg_responsive,
    ]
    : [
      user.avg_expertise,
      user.avg_local_know,
      user.avg_conversation,
      user.avg_responsive,
    ];

  const userEvaluation = userEvaluations.reduce((sum, avg) => sum + avg, 0) / userEvaluations.length;

  return (
    <li>
      <div
        className={cx('apartment')}
        onClick={toggleCollapse}
      >
        <div className={cx('apartment-wrap')}>
          <a className={styles.pictureLink} href={`https://catalog.reagent.pro/${apartments.encrypted_id}/${params.withClient ? currentUser?.public_id || '' : ''}`} target="_blank" rel="noreferrer">
            <picture
              className={cx('picture', {
                pictureNoImage: !photos[0]?.medium,
              })}
            >
              {photos[0]?.medium && (
                <img
                  src={photos[0]?.medium}
                  alt=""
                />
              )}
              {!photos[0]?.medium && (
                <>
                  <GalleryCameraIcon className={styles['placeholder-image']} />
                  Нет фото
                </>
              )}
            </picture>
          </a>

          <a className={styles.titleLink} href={`https://catalog.reagent.pro/${apartments.encrypted_id}/${params.withClient ? currentUser?.public_id || '' : ''}`} target="_blank" rel="noreferrer">
            <h3 className={styles.title}>
              {
                [
                  city.name, region?.name, street, homeNumber,
                ].filter(Boolean).join(', ')
              }
            </h3>
          </a>

          <div className={styles.apartmentSize}>
            {getApartmentSize({ rooms, type, floors_number: floorsNumber })}
            {(area || landArea) && ` / ${getArea({ area, type, land_area: landArea })}`}

            <Media query="(max-width: 1200px)">
              <p>
                {/*{!params.withClient && user.agency_name}*/}
              </p>
            </Media>
          </div>

          <Media query="(min-width: 1201px)">
            <p>
              {/*{!params.withClient && user.agency_name}*/}
            </p>
          </Media>

          <Media query="(max-width: 1200px)">
            <div className={styles.prices}>
              <div className={styles.price}>
                {getFormattedPrice(price)}
              </div>

              <span className={styles.commission}>
                {!params.withClient && !!ownerPrice && getFormattedPrice(ownerPrice)}
              </span>
            </div>
          </Media>

          <Media query="(min-width: 1201px)">
            <>
              <div className={styles.prices}>
                {!params.withClient && !!ownerPrice && (
                  <span className={styles.commission}>
                    {getFormattedPrice(ownerPrice)}
                  </span>
                )}
                {!params.withClient && !!commission && (
                  <>
                    {!!ownerPrice && (
                      <Fragment>
                        {' '}/{' '}
                      </Fragment>
                    )}
                    <span className={styles.ownerPrice}>
                      {getFormattedPrice(commission)}
                    </span>
                  </>
                )}
              </div>

              <div className={styles.price}>
                {getFormattedPrice(price)}
              </div>
            </>
          </Media>

          <Media query="(max-width: 1200px)">
            {!params.withClient && (
              <>
                {
                  !!commission && (
                    <p className={styles.ownerPrice}>
                      <Text size="m" semibold>
                        {getFormattedPrice(commission)}
                      </Text>
                    </p>
                  )
                }
              </>
            )}
          </Media>

          <ul className={styles.tags}>
            {
              adType === 'rent' && (
                <li
                  className={styles.tag}
                  style={{
                    backgroundColor: 'var(--color-green)',
                  }}
                >
                  Аренда
                </li>
              )
            }

            {
              adType === 'sell' && (
                <li
                  className={styles.tag}
                  style={{
                    backgroundColor: '#0C83FF',
                  }}
                >
                  Продажа
                </li>
              )
            }

            {
              exclusive && (
                <li
                  className={styles.tag}
                  style={{
                    backgroundColor: 'var(--color-black)',
                  }}
                >
                  Эксклюзив {params.withClient && user.agency_name}
                </li>
              )
            }

            {
              mortgage && (
                <li
                  className={styles.tag}
                  style={{
                    backgroundColor: '#47cdea',
                  }}
                >
                  Ипотека
                </li>
              )
            }

            {
              apartment && (
                <li
                  className={styles.tag}
                  style={{
                    backgroundColor: '#7000ff',
                  }}
                >
                  Апартаменты
                </li>
              )
            }

            {
              newApartment && (
                <li
                  className={styles.tag}
                  style={{
                    backgroundColor: '#2502ff',
                  }}
                >
                  Новостройка
                </li>
              )
            }

            {
              vr3dTour && (
                <li
                  className={styles.tag}
                  style={{
                    backgroundColor: '#017283',
                  }}
                >
                  VR 3D тур
                </li>
              )
            }

            {
              video && (
                <li
                  className={styles.tag}
                  style={{
                    backgroundColor: '#ea47a9',
                  }}
                >
                  Видео
                </li>
              )
            }

            {
              townhouse && (
                <li
                  className={styles.tag}
                  style={{
                    backgroundColor: '#ff9c00',
                  }}
                >
                  Таунхаус
                </li>
              )
            }
          </ul>

          <div className={styles['header-controls']}>
            <AddToPropertyButton adsId={id} />

            <Media query="(min-width: 1201px)">
              <div className={styles.expandApartmentButtonWrap}>
                <button
                  className={`
                    ${styles.expandApartmentButton}
                    ${collapsed && styles.expandApartmentButtonUp}
                  `}
                  type="button"
                >
                  <ArrowIcon />
                </button>
              </div>
            </Media>
          </div>

          {apartments.updated_at && (
            <p className={cx('updated-date')}>
              <Text size="xs">
                {getAgoDate(apartments.updated_at)}
              </Text>
            </p>
          )}
        </div>

        <Media query="(max-width: 1200px)">
          <div className={styles.expandApartmentButtonWrap}>
            <button
              className={`
                  ${styles.expandApartmentButton}
                  ${collapsed && styles.expandApartmentButtonUp}
                `}
              type="button"
            >
              <ArrowIcon />
            </button>
          </div>
        </Media>
      </div>

      {
        collapsed && (
          <div className={styles.info}>
            <div>
              <div className={styles['presentation-block']}>
                {visible === 'video' && video && (
                  <VideoSection videoUrl={video} />
                )}

                {visible === 'video' && !video && (
                  <div className={styles['presentation-block-section']}>
                    У этого объекта нет видео
                  </div>
                )}

                {visible === '3d-tour' && vr3dTour && (
                  <iframe
                    className={cx('vr-3d-tour', 'presentation-block-area', 'presentation-block-section')}
                    src={vr3dTour}
                    title="Виртуальный 3d тур"
                  />
                )}

                {visible === '3d-tour' && !vr3dTour && (
                  <div className={styles['presentation-block-section']}>
                    У этого объекта нет 3D тура
                  </div>
                )}

                {visible === 'map' && apartments.longitude && apartments.latitude && (
                  <MapSection
                    className={styles['presentation-block-area']}
                    latitude={apartments.latitude}
                    longitude={apartments.longitude}
                  />
                )}

                {visible === 'map' && !apartments.longitude && !apartments.latitude && (
                  <div className={styles['presentation-block-section']}>
                    У этого объекта нет карты
                  </div>
                )}

                {visible === 'gallery' && apartments.photos.length !== 0 && (
                  <HeaderGallery
                    className={styles['presentation-block-area']}
                    {...apartments}
                  />
                )}

                {visible === 'gallery' && apartments.photos.length === 0 && (
                  <div className={styles['presentation-block-section']}>
                    У этого объекта нет фотографий
                  </div>
                )}

                <HeaderToggleButtons className={styles['presentation-block-buttons']}>
                  <HeaderToggleButton
                    className={styles['presentation-block-button']}
                    onClick={() => setVisible('video')}
                    icon={<GalleryVideoIcon />}
                    active={visible === 'video'}
                  >
                    Видео
                  </HeaderToggleButton>

                  <HeaderToggleButton
                    className={styles['presentation-block-button']}
                    onClick={() => setVisible('3d-tour')}
                    icon={<GalleryTourIcon />}
                    active={visible === '3d-tour'}
                  >
                    3D тур
                  </HeaderToggleButton>

                  <HeaderToggleButton
                    className={styles['presentation-block-button']}
                    onClick={() => setVisible('map')}
                    icon={<GalleryMapIcon />}
                    active={visible === 'map'}
                    disabled={!apartments.latitude && !apartments.longitude}
                  >
                    Карта
                  </HeaderToggleButton>

                  <HeaderToggleButton
                    className={styles['presentation-block-button']}
                    onClick={() => setVisible('gallery')}
                    icon={<GalleryCameraIcon />}
                    active={visible === 'gallery'}
                  >
                    Фотографии
                  </HeaderToggleButton>
                </HeaderToggleButtons>
              </div>

              {apartments.description && (
                <>
                  <p className={styles.infoTitle}>
                    Описание
                  </p>

                  <ShowMoreText
                    className={MoreTextStyles.description}
                    lines={8}
                    more={(
                      <button
                        className={MoreTextStyles.descriptionButton}
                        type="button"
                      >
                        Ещё

                        <ArrowIcon
                          className={`
                            ${MoreTextStyles.descriptionButtonArrow}
                          `}
                        />
                      </button>
                    )}
                    less={(
                      <button
                        className={MoreTextStyles.descriptionButton}
                        type="button"
                      >
                        Свернуть
                        <ArrowIcon
                          className={`
                            ${MoreTextStyles.descriptionButtonArrow}
                            ${MoreTextStyles.descriptionButtonArrowUp}
                          `}
                        />
                      </button>
                    )}
                  >
                    {/* eslint-disable-next-line */}
                    <div dangerouslySetInnerHTML={{ __html: apartments.description }} />
                  </ShowMoreText>
                </>
              )}
            </div>

            <div className={styles.right}>
              <div className={styles['right-top']}>
                <div className={styles['general-info']}>
                  <GeneralInfo
                    object={apartments}
                  />
                </div>

                <CartOfUser
                  wwwLink={params.withClient ? currentUser.www_link : user.www_link}
                  firstName={params.withClient ? currentUser.first_name : user.first_name}
                  lastName={params.withClient ? currentUser.last_name : user.last_name}
                  middleName={params.withClient ? currentUser.middle_name : user.middle_name}
                  avatar={params.withClient ? currentUser.avatar?.original : user.avatar?.original}
                  evaluation={userEvaluation || 0}
                  rating={params.withClient ? currentUser.rating : user.rating}
                  agentType={params.withClient ? currentUser.user_type : user.user_type}
                  licenceNumber={params.withClient ? currentUser.licence_number : user.licence_number}
                  otherLicences={params.withClient ? currentUser.other_licences : user.other_licences}
                  phone={params.withClient ? currentUser.phone : user.phone}
                  email={params.withClient ? currentUser.email : user.email}
                  whatsapp={params.withClient ? currentUser.whatsapp : user.whatsapp}
                />
              </div>

              <div className={styles.generalInfoButtons}>
                <Button
                  component="link"
                  theme="black-full"
                  href={`https://catalog.reagent.pro/${apartments.encrypted_id}/${params.withClient ? currentUser?.public_id || '' : ''}`}
                  target="_blank"
                >
                  Подробнее
                </Button>
              </div>
            </div>
          </div>
        )
      }
    </li>
  );
};

export default ObjectOfSearchResult;
