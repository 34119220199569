import React, { useContext } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';

import { FieldState } from '_types/Field';
import { ObjectFilterFieldsContext } from 'global-stores/fields';
import { useParams } from '../../../pages/objects/_helpers/add-params';
import { URLParams } from '../../../pages/objects/_types/urlParams';

import SwitchComponent from 'ui/components/Switch';

import BaseStyles from 'styles/BaseStyles.module.css';
import styles from './Switch.module.css';

type SwitchProps = FieldState;

const Switch = observer(({ label, type }: SwitchProps) => {
  const [, setParam] = useParams<URLParams>();
  const objectSearchResults = useContext(ObjectFilterFieldsContext);

  const handleChange = (checked: boolean) => {
    objectSearchResults.handleChange(type, checked ? 'true' : '');
    setParam(type, checked ? 'true' : null);
  };

  return (
    <div className={classNames(BaseStyles.backdrop, styles.backdrop)}>
      <p className={classNames(BaseStyles.label, styles.label)}>
        {label}
      </p>

      <div className={styles.fields}>
        <SwitchComponent
          checked={!!objectSearchResults.values[type]}
          onChange={handleChange}
        />
      </div>
    </div>
  );
});

export default Switch;
