import { includes } from 'lodash/fp';
import ApiService from './api-service';
import { IUser } from '../interfaces';

export interface ConfirmCodeResponse {
  token: string
  registration_token: string
  user: IUser
}

class AuthService {
  private apiService: ApiService;

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  async requestCode(phone: string) {
    await this.apiService.post('v3/send_code', { json: { user: { phone } } });
    return true;
  }

  async confirmCode(phone: string, code: string): Promise<ConfirmCodeResponse> {
    const response = await this.apiService.post('v3/confirm_code', {
      json: {
        user: { phone },
        sms_code: code,
      },
    });

    if (!includes(response.status, [200, 201])) {
      return Promise.reject((await response.json()).errors[0]);
      // throw new Error(`Fetch error: ${response.statusText}`)
    }

    return (await response.json()) as ConfirmCodeResponse;
  }
}

export default AuthService;
