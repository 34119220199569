import React from 'react';
import { CreateSvgIcon } from '../icon';
import { IconProps } from '../names';

export const Analytics = (props: IconProps) => (
  <CreateSvgIcon {...props}>
    {({ color }) => (
      <>
        <rect y="18" width="7" height="10" fill={color} />
        <rect x="10" y="10" width="7" height="18" fill={color} />
        <rect x="20" width="7" height="28" fill={color} />
      </>
    )}
  </CreateSvgIcon>
);
