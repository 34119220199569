import React from 'react';
import cn from 'classnames';

import { YMaps, Map, Placemark, ZoomControl, FullscreenControl } from 'react-yandex-maps';

import styles from './MapSection.module.css';

interface Props {
  className?: string;
  latitude: number;
  longitude: number;
}

const MapSection = ({
  className,
  latitude,
  longitude,
}: Props) => (
  <div className={cn(styles.container, className)}>
    <YMaps>
      <Map className={styles.map} defaultState={{ center: [latitude, longitude], zoom: 17, controls: [] }}>
        <FullscreenControl />
        <ZoomControl />
        <Placemark geometry={[latitude, longitude]} />
      </Map>
    </YMaps>
  </div>
);

export default MapSection;
