import React from 'react';
import Media from 'react-media';
import { Theme } from 'lib/theme';
import { makeStyles } from 'hooks/makeStyles';
import { Typography, Icons, AnimatedPage } from 'ui/shared';

import { fade } from 'utils';
import { observer } from 'mobx-react';

import { Header } from 'components/header';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
    padding: '20px 20px 60px',
    position: 'relative',
    background: '#ffffff',
    '@media screen and (max-width: 768px)': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  container: {
    maxWidth: 390,
    borderRadius: 10,
    backgroundColor: theme.colors.background.paper.darkGrey,
    padding: `${theme.spacing(5.75)} ${theme.spacing(1)} ${theme.spacing(3.25)}`,
  },
  title: {
    fontSize: 22,
    textAlign: 'center',
    fontFamily: `${theme.fonts.semibold}, sans-serif`,
    marginBottom: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  subtitle: {
    fontSize: 16,
    textAlign: 'center',
    fontFamily: `${theme.fonts.semibold}, sans-serif`,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  link: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    color: theme.colors.textTertiary,
    width: 166,
    height: 44,
    padding: `${theme.spacing(2)} ${theme.spacing(6.25)}`,
    margin: theme.spacing(2.25),
    border: `0.5px solid ${theme.colors.grey.light}`,
    borderRadius: 5,
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover, &:focus': {
      backgroundColor: fade(theme.colors.common.black, 0.1),
    },
  },
  icon: {
    marginRight: theme.spacing(4.75),
  },
}));

export const Support = observer(() => {
  const css = useStyles();

  return (
    <AnimatedPage animation="horizontal">
      <Media query="(max-width: 768px)">
        <Header path="/" title="Поддержка" />
      </Media>
      <div className={css.root}>
        <div className={css.container}>
          <Typography className={css.title}>Нужна помощь?</Typography>
          <Typography className={css.subtitle}>Выберите удобный способ связи с нами</Typography>
          <div className={css.wrapper}>
            <a className={css.link} href="mailto:Support@reagent.pro">
              <Icons.Email className={css.icon} height={32} width={32} viewBox="0 0 32 32" color="#136FFB" />
              Email
            </a>
            <a className={css.link} href="https://wa.me/79882334272">
              <Icons.Whatsapp className={css.icon} height={32} width={32} viewBox="0 0 32 32" />
              Whatsapp
            </a>
            <a className={css.link} href="https://t.me/monty_pyton">
              <Icons.Telegram className={css.icon} height={32} width={32} viewBox="0 0 32 32" color="#32AFED" />
              Telegram
            </a>
            {/* <a className={css.link} href="https://www.instagram.com/reagent.pro/">
              <Icons.Instagram className={css.icon} height={32} width={32} viewBox="0 0 32 32" />
              Instagram
            </a> */}
          </div>
        </div>
      </div>
    </AnimatedPage>
  );
});
