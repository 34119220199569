/* eslint-disable max-len */
import React from 'react';
import { CreateSvgIcon } from '../icon';
import { IconProps } from '../names';

export const Checkmark = (props: IconProps) => (
  <CreateSvgIcon {...props}>
    {({ color }) => (
      <>
        <g clipPath="url(#clip-checkmark)">
          <path
            d="M14.2308 0.649701C14.5647 0.277488 15.1222 0.209151 15.5362 0.489698L16.1568 0.910316C16.6226 1.22606 16.7363 1.86389 16.4082 2.32112L7.79607 14.3225C7.60824 14.5842 7.30579 14.7394 6.98361 14.7394H5.80802C5.49069 14.7394 5.1922 14.5888 5.00366 14.3336L0.538958 8.28915C0.233184 7.87519 0.291539 7.29674 0.673833 6.95219L1.52355 6.18636C1.90417 5.84332 2.48249 5.84348 2.86293 6.18672L5.65558 8.70641C6.06645 9.07712 6.70028 9.04366 7.06983 8.63174L14.2308 0.649701Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip-checkmark">
            <rect width="17" height="17" fill="white" />
          </clipPath>
        </defs>
      </>
    )}
  </CreateSvgIcon>
);
