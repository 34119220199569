import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import { Button as LegacyButton, Checkbox, Dropdown, Icons, Input, Typography } from 'ui/shared';
import Button from 'ui/components/Button';
import Modal from 'ui/components/Modal';
import { Text } from 'components/text';
import { EditProfessionalInformationProps } from './types';
import { ReactComponent as CloseIcon } from 'assets/svg/Close.svg';
import { ReactComponent as UploadIcon } from './upload.svg';
import { ReactComponent as UploadedIcon } from './uploaded.svg';
import { getTerritorialCertificationAuthorities } from 'domains/objects/requests';

import styles from './styles.module.css';

const cx = classNames.bind(styles);

interface Authority{
  id: number;
  name: string;
}

export const USER_TYPES = [
  { key: 'agent', value: 'Агент' },
  { key: 'expert', value: 'Эксперт' },
  { key: 'broker', value: 'Брокер' },
];

// @ts-ignore
const getGenderValue = (key?: string) => USER_TYPES.find((gender) => gender.key === key) || null;

export const EditProfessionalInformation = ({
  userProperties,
  updateDataByKey,
  onChangeUserProperty,
  onLeaveAgency,
  onOpenLeaveAgencyModal,
  onCloseLeaveAgencyModal,
  isOpenInAgencyModal,
  uploadLicense,
  certPhoto,
}: EditProfessionalInformationProps) => {
  const [authorities, setAuthorities] = useState<Authority[]>([]);

  useEffect(() => {
    Promise.any([
      getTerritorialCertificationAuthorities(),
    ]).then((authorityList) => setAuthorities(authorityList));
  }, []);

  const getAuthority = (id?: number) => authorities.find((a) => a.id === id) || null;

  return (
    <div className={cx('wrap')}>
      <p className={cx('title')}>
        Профессиональная информация
      </p>

      <div className={cx('field')}>
        <Dropdown
          preferTop
          items={USER_TYPES}
          renderItem={(item) => (
            <LegacyButton
              key={`user-type-${item.key}`}
              onClick={() => updateDataByKey('user_type', item.key)}
              className={cx('dropdown-button')}
            >
              {item.value}
            </LegacyButton>
          )}
          overlayClassName={cx('dropdown-overlay')}
        >
          <LegacyButton className={cx('dropdown')}>
            <span>
              {getGenderValue(userProperties?.user_type)?.value || (
                <Typography size="subtitle1(18px)" font="semibold" className={cx('dropdown-grey-text')}>
                  Квалификация
                </Typography>
              )}
            </span>
            <Icons.TriangularArrow
              width={19}
              height={20}
              viewBox="0 0 32 32"
              color="#C4C4C4"
              className={cx('dropdown-arrow')}
            />
          </LegacyButton>
        </Dropdown>
      </div>
      <div className={cx('field')}>
        <Input
          type="number"
          inputMode="numeric"
          value={userProperties?.year_career_start || undefined}
          onChange={onChangeUserProperty('year_career_start')}
          placeholder="Год начала карьеры"
          secondary
        />
      </div>
      <div className={cx('field', 'relative')}>
        <Input
          type="text"
          value={userProperties?.agency_name || ''}
          onChange={onChangeUserProperty('agency_name')}
          placeholder="Название агентства"
          disabled={!!userProperties?.agency_id}
          secondary
        />

        {!!userProperties?.agency_id && (
          <button className={styles.leaveAgencyButton} onClick={onOpenLeaveAgencyModal} type="button">
            <CloseIcon className={styles.leaveAgencyButtonIcon} />
          </button>
        )}
      </div>
      <div className={cx('field', 'upload-license-field')}>
        <p className={cx('upload-license-title')}>
          Документ квалификации специалиста
        </p>
        <div className={cx('upload-license-row')}>
          <div>
            <p className={cx('upload-license-description')}>
              действующий аттестат/сертификат/свидетельство РГР, НОК, ГРС и др.
            </p>
            {certPhoto && (
              <p className={cx('uploaded-license-text')}>
                Документ загружен
                <UploadedIcon />
              </p>
            )}
          </div>
          <label
            className={cx('upload-license', {
              'upload-license_uploaded': !!certPhoto,
            })}
          >
            {certPhoto && (
              <img className={cx('uploaded-license')} src={certPhoto} alt="" />
            )}
            <p className={cx('upload-license-text')}>
              <span className={cx('upload-license-icon')}>
                <UploadIcon />
              </span>
              Загрузить документ
              <input className={cx('input-hidden')} type="file" onChange={uploadLicense} />
            </p>
          </label>
        </div>
      </div>
      <div className={cx('field')}>
        <Input
          type="text"
          value={userProperties?.other_licences || ''}
          onChange={onChangeUserProperty('other_licences')}
          placeholder="Название документа"
          secondary
        />
      </div>
      <div className={cx('field')}>
        <div className={cx('field-licence-number-symbol')}>
          <Text semibold>
            №
          </Text>
        </div>
        <Input
          type="text"
          value={userProperties?.licence_number || ''}
          className={cx('field-licence-number')}
          onChange={onChangeUserProperty('licence_number')}
          placeholder="Номер документа"
          secondary
        />
      </div>
      <div className={cx('field')}>
        <Input
          type="text"
          inputMode="numeric"
          value={userProperties?.cert_expiration_date || ''}
          onChange={onChangeUserProperty('cert_expiration_date')}
          placeholder="Дата окончания срока действия документа"
          mask="00.00.0000"
          secondary
        />
      </div>
      <div className={cx('field')}>
        <Dropdown
          preferTop
          items={authorities}
          renderItem={(item) => (
            <LegacyButton
              key={`cert-issuer-${item.id}`}
              onClick={() => updateDataByKey('territorial_certification_authority_id', item.id)}
              className={cx('dropdown-button')}
            >
              {item.name}
            </LegacyButton>
          )}
          overlayClassName={cx('dropdown-overlay')}
        >
          <LegacyButton className={cx('dropdown')}>
            <span>
              {getAuthority(userProperties?.territorial_certification_authority_id)?.name || (
                <Typography size="subtitle1(18px)" font="semibold" className={cx('dropdown-grey-text')}>
                  Кем выдан документ
                </Typography>
              )}
            </span>
            <Icons.TriangularArrow
              width={19}
              height={20}
              viewBox="0 0 32 32"
              color="#C4C4C4"
              className={cx('dropdown-arrow')}
            />
          </LegacyButton>
        </Dropdown>
      </div>
      <div className={cx('field')}>
        <Checkbox
          className={cx('checkbox')}
          checked={!!userProperties?.push_notifications}
          onChange={(value) => updateDataByKey('push_notifications', value)}
          label="Пуши"
          labelColorGrey
        />
      </div>
      <div className={cx('field')}>
        <Checkbox
          className={cx('checkbox')}
          checked={!!userProperties?.hide_from_search}
          onChange={(value) => updateDataByKey('hide_from_search', value)}
          label="Скрыть мой профиль от поиска"
          labelColorGrey
        />
      </div>

      {isOpenInAgencyModal && (
        <Modal
          onClose={onCloseLeaveAgencyModal}
        >
          <Modal.Body>
            Вы&nbsp;уверены, что хотите выйти из&nbsp;агентства &laquo;{userProperties!.agency_name}&raquo;
          </Modal.Body>

          <Modal.Buttons>
            <Button
              component="button"
              onClick={onCloseLeaveAgencyModal}
              theme="black"
            >
              Отмена
            </Button>

            <Button
              component="button"
              onClick={onLeaveAgency}
              theme="red"
            >
              Да, выйти
            </Button>
          </Modal.Buttons>
        </Modal>
      )}
    </div>
  );
};
