import React from 'react';
import ReactDOM from 'react-dom';
import smoothscroll from 'smoothscroll-polyfill';
import { configureTheme } from 'lib/theme';
import { ThemeProvider } from 'react-jss';
import { App } from './app';
import * as serviceWorker from './serviceWorker';
import './index.css';
import 'react-day-picker/lib/style.css';

// kick off the polyfill!
smoothscroll.polyfill();

const theme = configureTheme('light');

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={null}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
