import React from 'react';
import { CreateSvgIcon } from '../icon';
import { IconProps } from '../names';

export const SmallArrow = (props: IconProps) => (
  <CreateSvgIcon {...props}>
    {({ color }) => (
      <>
        <path
          d="M4.29303 7.707L0.353617 11.6464C0.158356 11.8417 0.158357 12.1583 0.353619 12.3536L1.06051 13.0604C1.25578 13.2557 1.57236 13.2557 1.76762 13.0604L7.76751 7.06055C7.96278 6.86529 7.96277 6.54871 7.76751 6.35345L1.76762 0.353553C1.57236 0.158291 1.25578 0.158291 1.06051 0.353553L0.35362 1.06045C0.158358 1.25571 0.158358 1.57229 0.35362 1.76755L4.29307 5.707L4.89873 6.36967C5.0733 6.56068 5.0733 6.85332 4.89872 7.04432L4.29303 7.707Z"
          fill={color}
        />
      </>
    )}
  </CreateSvgIcon>
);
