import React from 'react';
import { makeStyles } from 'hooks/makeStyles';
import clsx from 'clsx';

import { Typography } from 'ui/shared';

interface ProLabelProps {
  className?: string
  children?: React.ReactNode
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
    backgroundColor: theme.colors.red.main,
    padding: `${theme.spacing(1.5)} ${theme.spacing(2.75)}`,
    borderRadius: 6,
  },
  text: {
    color: theme.colors.textSecondary,
    fontSize: 15,
  },
}));

export const ProLabel = (props: ProLabelProps) => {
  const { className } = props;
  const css = useStyles(props);

  return (
    <div className={clsx(css.root, className)}>
      <Typography className={css.text}>PRO</Typography>
    </div>
  );
};
