import React from 'react';
import cn from 'classnames';

import { Text } from 'components/text';

import { ReactComponent as InfoIcon } from './Info.svg';
import { ReactComponent as CloseIcon } from './Close.svg';

import styles from './Alert.module.css';

export interface AlertProps {
  className?: string;
  type: 'error' | 'success';
  children: React.ReactNode;
}

const Alert = ({ children, className, type }: AlertProps) => (
  <div className={cn(styles.alert, className, {
    [styles.typeError]: type === 'error',
    [styles.typeSuccess]: type === 'success',
  })}
  >
    <Text>
      <InfoIcon className={styles.infoIcon} />

      {children}

      <button className={styles.closeButton} type="button">
        <CloseIcon />
      </button>
    </Text>
  </div>
);

export default Alert;
