import { clone } from 'lodash';
import dayjs from 'dayjs';
import { formatRFC3339 } from 'date-fns';
import { IUser } from 'interfaces';

const RFC3339ToString = (date: string | null) => (date ? dayjs(date).format('DD.MM.YYYY') : '');

export const normalizeUserDataForUI = (user?: IUser | null): IUser | null => {
  const clonedUser: IUser | null = clone(user) || null;

  if (clonedUser) {
    clonedUser.birth_date = RFC3339ToString(clonedUser.birth_date);

    clonedUser.cert_expiration_date = RFC3339ToString(clonedUser?.cert_expiration_date);
  }

  return clonedUser;
};

const stringToRFC3339 = (date: string | null) => {
  let result = '';

  if (date === null) {
    return result;
  }

  const a = date.split('.');

  const isValid = a.length === 3 && a[0].length === 2 && a[1].length === 2 && a[2].length === 4;

  if (isValid) {
    result = formatRFC3339(new Date(`${a[2]}-${a[1]}-${a[0]}`));
  }

  return result;
};

export const normalizeUserDataForSafe = (user: IUser): IUser => {
  const clonedUser: IUser | null = clone(user) || user;

  clonedUser.birth_date = stringToRFC3339(clonedUser?.birth_date || null);

  clonedUser.cert_expiration_date = stringToRFC3339(clonedUser?.cert_expiration_date || null);

  return clonedUser;
};
