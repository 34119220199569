import React from 'react';
import { CreateSvgIcon } from '../icon';
import { IconProps } from '../names';

export const Vkontakte = (props: IconProps) => (
  <CreateSvgIcon {...props}>
    {({ color }) => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9053 23.914H17.6231C17.6231 23.914 18.1385 23.8293 18.3962 23.5754C18.6539 23.3215 18.6539 22.8136 18.6539 22.8136C18.6539 22.8136 18.6539 20.6127 19.6846 20.2741C20.7153 19.9355 22.0896 22.475 23.4639 23.4061C24.4946 24.1679 25.2677 23.9986 25.2677 23.9986L29.047 23.914C29.047 23.914 31.0226 23.8293 30.0777 22.221C29.9918 22.1364 29.5624 21.1206 27.2432 19.0044C24.8382 16.8035 25.1818 17.2267 28.0163 13.4175C29.7342 11.132 30.4213 9.77763 30.2495 9.10044C30.0777 8.50789 28.7893 8.67719 28.7893 8.67719H24.4946C24.4946 8.67719 24.1511 8.59254 23.9793 8.76184C23.7216 8.93114 23.6357 9.18508 23.6357 9.18508C23.6357 9.18508 22.9486 10.9627 22.0896 12.4864C20.1999 15.6184 19.4269 15.7877 19.1692 15.6184C18.4821 15.1952 18.6539 13.7561 18.6539 12.825C18.6539 9.77763 19.1692 8.50789 17.709 8.1693C17.2796 8.08465 16.936 8 15.7335 8C14.1874 8 12.899 8 12.2118 8.3386C11.6965 8.59254 11.3529 9.10044 11.6106 9.10044C11.8682 9.10044 12.5554 9.26973 12.899 9.69298C13.2426 10.4548 13.2425 11.7246 13.2425 11.7246C13.2425 11.7246 13.5002 15.3645 12.6413 15.7877C12.04 16.1263 11.267 15.4491 9.54912 12.571C8.69018 11.132 8.00303 9.43903 8.00303 9.43903C8.00303 9.43903 7.91713 9.10044 7.65945 8.93114C7.40177 8.76184 6.9723 8.67719 6.9723 8.67719H2.93529C2.93529 8.67719 2.33403 8.67719 2.07635 8.93114C1.90456 9.18509 2.07635 9.60833 2.07635 9.60833C2.07635 9.60833 5.25442 16.8881 8.86196 20.6127C12.2118 24.1679 15.9053 23.914 15.9053 23.914Z"
        fill={color}
      />
    )}
  </CreateSvgIcon>
);
