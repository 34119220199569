import { useEffect, useState } from 'react';

export const useAnimation = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  return visible;
};
