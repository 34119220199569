import React, { useContext, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import classNames from 'classnames/bind';
import { ApplicationContext } from 'global-stores/application-context';
import Logo from 'domains/docs/assets/urban-agent-logo.png';
import Logo2x from 'domains/docs/assets/urban-agent-logo@2x.png';
import Logo3x from 'domains/docs/assets/urban-agent-logo@3x.png';
import styles from 'domains/docs/docs.module.css';

const cx = classNames.bind(styles);

export const PoliticsPage = () => {
  const { isAuthenticated } = useContext(ApplicationContext);

  return (
    <Fragment>
      <Helmet>
        <title>Политика в отношении обработки персональных данных в ооо «реагент.про»</title>
      </Helmet>

      <section className={cx('page')}>
        {!isAuthenticated && (
          <header className={cx('header')}>
            <a href="/">
              <picture>
                <source srcSet={`${Logo2x} 2x, ${Logo3x} 3x`} />
                <img src={Logo} width={256} height={60} alt="" />
              </picture>
            </a>
          </header>
        )}

        <h1 className={cx('title')}>
          ПОЛИТИКА В&nbsp;ОТНОШЕНИИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ В&nbsp;ООО &laquo;РЕАГЕНТ.ПРО&raquo;
        </h1>

        <ol className={cx('list')}>
          <li>
            Общие положения
            <ol className={cx('list')}>
              <li>
                Политика в&nbsp;отношении обработки персональных данных (далее&nbsp;&mdash; Политика) направлена на&nbsp;защиту прав и&nbsp;свобод физических лиц, персональные данные которых обрабатывает ООО &laquo;РЕАГЕНТ.ПРО&raquo; (далее&nbsp;&mdash; Оператор).
              </li>
              <li>
                Политика разработана в&nbsp;соответствии с&nbsp;п.&nbsp;2&nbsp;ч.&nbsp;1&nbsp;ст.&nbsp;18.1 Федерального закона от&nbsp;27&nbsp;июля 2006&nbsp;г. &#8470;&nbsp;152-ФЗ &laquo;О&nbsp;персональных данных&raquo; (далее&nbsp;&mdash; ФЗ&nbsp;&laquo;О&nbsp;персональных данных&raquo;).
              </li>
              <li>
                Политика содержит сведения, подлежащие раскрытию в&nbsp;соответствии с&nbsp;ч.&nbsp;1&nbsp;ст.&nbsp;14&nbsp;ФЗ &laquo;О&nbsp;персональных данных&raquo;, и&nbsp;является общедоступным документом.
              </li>
            </ol>
          </li>
          <li>
            Сведения об&nbsp;операторе
            <ol className={cx('list')}>
              <li>
                Оператор ведет свою деятельность по&nbsp;адресу Краснодарский край, город Сочи, улица Навагинская (Центральный Р-Н), дом&nbsp;9д, офис 427
              </li>
              <li>
                Администратор Иволин Андрей Михайлович (телефон&nbsp;+7 (988) 233-4272) назначен ответственным за&nbsp;организацию обработки персональных данных.
              </li>
              <li>
                База данных информации, содержащей персональные данные граждан Российской Федерации, находится по&nbsp;адресу: 354000, Сочи, улица Навагинская (Центральный Р-Н), дом&nbsp;9д, офис 427
              </li>
            </ol>
          </li>
          <li>
            Сведения об&nbsp;обработке персональных данных
            <ol className={cx('list')}>
              <li>
                Оператор обрабатывает персональные данные на&nbsp;законной и&nbsp;справедливой основе для выполнения возложенных законодательством функций, полномочий и&nbsp;обязанностей, осуществления прав и&nbsp;законных интересов Оператора, работников Оператора и&nbsp;третьих лиц.
              </li>
              <li>
                Оператор получает персональные данные непосредственно у&nbsp;субъектов персональных данных.
              </li>
              <li>
                Оператор обрабатывает персональные данные автоматизированным и&nbsp;неавтоматизированным способами, с&nbsp;использованием средств вычислительной техники и&nbsp;без использования таких средств.
              </li>
              <li>
                Действия по&nbsp;обработке персональных данных включают сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление и&nbsp;уничтожение.
              </li>
              <li>
                Базы данных информации, содержащей персональные данные граждан Российской Федерации, находятся на&nbsp;территории Российской Федерации
              </li>
            </ol>
          </li>
          <li>
            Обработка персональных данных клиентов
            <ol className={cx('list')}>
              <li>
                Оператор обрабатывает персональные данные клиентов в&nbsp;рамках правоотношений с&nbsp;Оператором, урегулированных частью второй Гражданского Кодекса Российской Федерации от&nbsp;26&nbsp;января 1996&nbsp;г. &#8470;&nbsp;14-ФЗ, (далее&nbsp;&mdash; клиентов).
              </li>
              <li>
                Оператор обрабатывает персональные данные клиентов в&nbsp;целях соблюдения норм законодательства&nbsp;РФ, а&nbsp;также с&nbsp;целью:<br />
                &mdash;&nbsp;заключать и&nbsp;выполнять обязательства по&nbsp;договорам с&nbsp;клиентами;<br />
                &mdash;&nbsp;информировать о&nbsp;новых товарах, специальных акциях и&nbsp;предложениях.
              </li>
              <li>
                Оператор обрабатывает персональные данные клиентов с&nbsp;их&nbsp;согласия, предоставляемого на&nbsp;срок действия заключенных с&nbsp;ними договоров. В&nbsp;случаях, предусмотренных ФЗ&nbsp;&laquo;О&nbsp;персональных данных&raquo;, согласие предоставляется в&nbsp;письменном виде. В&nbsp;иных случаях согласие считается полученным при заключении договора или при совершении конклюдентных действий.
              </li>
              <li>
                Оператор обрабатывает персональные данные клиентов в&nbsp;течение сроков действия заключенных с&nbsp;ними договоров. Оператор может обрабатывать персональные данные клиентов после окончания сроков действия заключенных с&nbsp;ними договоров в&nbsp;течение срока, установленного&nbsp;п.&nbsp;5&nbsp;ч.&nbsp;3&nbsp;ст.&nbsp;24&nbsp;части первой НК&nbsp;РФ, ч.&nbsp;1&nbsp;ст.&nbsp;29&nbsp;ФЗ &laquo;О&nbsp;бухгалтерском учёте&raquo; и&nbsp;иными нормативными правовыми актами.
              </li>
              <li>
                Оператор обрабатывает следующие персональные данные клиентов:<br />
                &mdash;&nbsp;Фамилия, имя, отчество;<br />
                &mdash;&nbsp;Номер контактного телефона;<br />
                &mdash;&nbsp;Адрес электронной почты;<br />
                &mdash;&nbsp;Социальные сети;<br />
                &mdash;&nbsp;Должность;<br />
                &mdash;&nbsp;Место работы;<br />
                &mdash;&nbsp;Фотография;<br />
                &mdash;&nbsp;Пол;<br />
                &nbsp;&mdash; Возраст;<br />
                &mdash;&nbsp;Геолокацию;<br />
                &mdash;&nbsp;Другую профессиональную иформацию;<br />
                &mdash;&nbsp;Сведения об&nbsp;оказанных услугах.
              </li>
            </ol>
          </li>
          <li>
            Сведения об&nbsp;обеспечении безопасности персональных данных
            <ol className={cx('list')}>
              <li>
                Оператор назначает ответственного за&nbsp;организацию обработки персональных данных для выполнения обязанностей, предусмотренных ФЗ&nbsp;&laquo;О&nbsp;персональных данных&raquo; и&nbsp;принятыми в&nbsp;соответствии с&nbsp;ним нормативными правовыми актами.
              </li>
              <li>
                Оператор применяет комплекс правовых, организационных и&nbsp;технических мер по&nbsp;обеспечению безопасности персональных данных для обеспечения конфиденциальности персональных данных и&nbsp;их&nbsp;защиты от&nbsp;неправомерных действий:<br />
                &mdash;&nbsp;обеспечивает неограниченный доступ к&nbsp;Политике, копия которой размещена по&nbsp;адресу нахождения Оператора, а&nbsp;также может быть размещена на&nbsp;сайте Оператора (при его наличии);<br />
                &mdash;&nbsp;во&nbsp;исполнение Политики утверждает и&nbsp;приводит в&nbsp;действие документ &laquo;Положение об&nbsp;обработке персональных данных&raquo; (далее&nbsp;&mdash; Положение) и&nbsp;иные локальные акты;<br />
                &mdash;&nbsp;производит ознакомление работников с&nbsp;положениями законодательства о&nbsp;персональных данных, а&nbsp;также с&nbsp;Политикой и&nbsp;Положением;<br />
                &mdash;&nbsp;осуществляет допуск работников к&nbsp;персональным данным, обрабатываемым в&nbsp;информационной системе Оператора, а&nbsp;также к&nbsp;их&nbsp;материальным носителям только для выполнения трудовых обязанностей;<br />
                &mdash;&nbsp;устанавливает правила доступа к&nbsp;персональным данным, обрабатываемым в&nbsp;информационной системе Оператора, а&nbsp;также обеспечивает регистрацию и&nbsp;учёт всех действий с&nbsp;ними;<br />
                &mdash;&nbsp;производит оценку вреда, который может быть причинен субъектам персональных данных в&nbsp;случае нарушения ФЗ&nbsp;&laquo;О&nbsp;персональных данных&raquo;;<br />
                &mdash;&nbsp;производит определение угроз безопасности персональных данных при их&nbsp;обработке в&nbsp;информационной системе Оператора;<br />
                &mdash;&nbsp;применяет организационные и&nbsp;технические меры и&nbsp;использует средства защиты информации, необходимые для достижения установленного уровня защищенности персональных данных;<br />
                &mdash;&nbsp;осуществляет обнаружение фактов несанкционированного доступа к&nbsp;персональным данным и&nbsp;принимает меры по&nbsp;реагированию, включая восстановление персональных данных, модифицированных или уничтоженных вследствие несанкционированного доступа к&nbsp;ним;<br />
                &mdash;&nbsp;производит оценку эффективности принимаемых мер по&nbsp;обеспечению безопасности персональных данных до&nbsp;ввода в&nbsp;эксплуатацию информационной системы Оператора;<br />
                &mdash;&nbsp;осуществляет внутренний контроль соответствия обработки персональных данных ФЗ&nbsp;&laquo;О&nbsp;персональных данных&raquo;, принятым в&nbsp;соответствии с&nbsp;ним нормативным правовым актам, требованиям к&nbsp;защите персональных данных, Политике, Положению и&nbsp;иным локальным актам, включающий контроль за&nbsp;принимаемыми мерами по&nbsp;обеспечению безопасности персональных данных и&nbsp;их&nbsp;уровня защищенности при обработке в&nbsp;информационной системе Оператора.
              </li>
            </ol>
          </li>
          <li>
            Права субъектов персональных данных
            <ol className={cx('list')}>
              <li>
                Субъект персональных данных имеет право:<br />
                &mdash;&nbsp;на&nbsp;получение персональных данных, относящихся к&nbsp;данному субъекту, и&nbsp;информации, касающейся их&nbsp;обработки;<br />
                &mdash;&nbsp;на&nbsp;уточнение, блокирование или уничтожение его персональных данных в&nbsp;случае, если они являются неполными, устаревшими, неточными, незаконно полученными или не&nbsp;являются необходимыми для заявленной цели обработки;<br />
                &mdash;&nbsp;на&nbsp;отзыв данного им&nbsp;согласия на&nbsp;обработку персональных данных;<br />
                &mdash;&nbsp;на&nbsp;защиту своих прав и&nbsp;законных интересов, в&nbsp;том числе на&nbsp;возмещение убытков и&nbsp;компенсацию морального вреда в&nbsp;судебном порядке;<br />
                &mdash;&nbsp;на&nbsp;обжалование действий или бездействия Оператора в&nbsp;уполномоченный орган по&nbsp;защите прав субъектов персональных данных или в&nbsp;судебном порядке.
              </li>
              <li>
                Для реализации своих прав и&nbsp;законных интересов субъекты персональных данных имеют право обратиться к&nbsp;Оператору либо направить запрос лично или с&nbsp;помощью представителя. Запрос должен содержать сведения, указанные в&nbsp;ч.&nbsp;3&nbsp;ст.&nbsp;14&nbsp;ФЗ &laquo;О&nbsp;персональных данных&raquo;.
              </li>
            </ol>
          </li>
        </ol>
      </section>
    </Fragment>
  );
};
