import React from 'react';
import cn from 'classnames';

import { DropDownProps } from '_types/Options';

import styles from './DropDown.module.css';

interface Props extends DropDownProps {
  className?: string;
  theme?: 'search';
}

const DropDown = ({ className, selectedId, options, onChange, theme }: Props) => {
  const handleClickByOption = (index: number) => {
    onChange(index);
  };

  return (
    <div
      className={cn(styles.dropdown, className, {
        [styles.dropdownSearch]: theme === 'search',
      })}
    >
      {
        options.map((option) => (
          <button
            key={option.id}
            className={cn(styles.dropdownItem, {
              [styles.selectedItem]: option.id === selectedId,
              [styles.dropdownSearchItem]: theme === 'search',
            })}
            type="button"
            onClick={() => handleClickByOption(option.id)}
          >
            {option.name}
          </button>
        ))
      }
    </div>
  );
};

export default DropDown;
