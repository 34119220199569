import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ServicesPage } from './pages/services';
import { Subscriptions } from 'ui/pages/settings/subscriptions';
import { Routes } from './constants';

export const ServicesRoutes = () => (
  <Switch>
    <Route exact path={Routes.SERVICES_PAGE} component={ServicesPage} />
    <Route exact path={Routes.SUBSCRIPTIONS_PAGE} component={Subscriptions} />
  </Switch>
);
