import React from 'react';
import { makeStyles } from 'hooks/makeStyles';
import clsx from 'clsx';

export interface TabsProps {
  value?: string | number
  onChange: (value: string | number) => void
  className?: string
  children: React.ReactNode
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
  },
}));

export const TabsContext = React.createContext<{
  value?: string | number
  onChange:(value: string | number) => void
}>({} as any);

export const Tabs = (props: TabsProps) => {
  const {
    value, onChange, children, className, ...passthrough
  } = props;
  const css = useStyles();

  return (
    <div
      role="tablist"
      className={clsx(css.container, className)}
      {...passthrough}
    >
      <TabsContext.Provider
        value={{
          value,
          onChange,
        }}
      >
        {children}
      </TabsContext.Provider>
    </div>
  );
};
