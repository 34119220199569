import React from 'react';
import { makeStyles } from 'hooks/makeStyles';
import clsx from 'clsx';
import { Button } from 'ui/shared/button/button';

interface DateSwitchProps {
  checked: boolean
  onChange: (value: boolean) => void
  defaultBgColor?: string
  activeBgColor?: string
  defaultSwitchColor?: string
  activeSwitchColor?: string
  defaultTextColor?: string
  activeTextColor?: string
  translateX?: string
  className?: string
}

const useStyles = makeStyles((theme) => ({
  input: {
    display: 'none',
  },
  switch: {
    position: 'relative',
    padding: theme.spacing(1),
    width: 342,
    height: 52,
    textTransform: 'none',
    backgroundColor: ({ defaultBgColor }: DateSwitchProps) => defaultBgColor || theme.colors.background.paper.default,
    '&:hover, &:active, &:focus': {
      backgroundColor: ({ defaultBgColor }: DateSwitchProps) => defaultBgColor || theme.colors.background.paper.default,
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      zIndex: 1,
      left: 4,
      top: 4,
      width: 163,
      height: 44,
      borderRadius: 50,
      backgroundColor: ({ defaultSwitchColor }: DateSwitchProps) => defaultSwitchColor || theme.colors.green.main,
      transition: 'transform .2s, backgroundColor .2s',
      '@media screen and (max-width: 420px)': {
        width: 138,
      },
    },
    '@media screen and (max-width: 420px)': {
      width: 295,
    },
  },
  checked: {
    backgroundColor: ({ activeBgColor }: DateSwitchProps) => activeBgColor || theme.colors.background.paper.default,
    '&:hover, &:active, &:focus': {
      backgroundColor: ({ activeBgColor }: DateSwitchProps) => activeBgColor || theme.colors.background.paper.default,
    },
    '&:after': {
      transform: 'translateX(171px)',
      backgroundColor: ({ activeSwitchColor }: DateSwitchProps) => activeSwitchColor || theme.colors.green.main,
      '@media screen and (max-width: 420px)': {
        transform: 'translateX(148px)',
      },
    },
  },
  text: {
    zIndex: 2,
    position: 'absolute',
    top: 13,
    fontSize: 18,
    fontFamily: `${theme.fonts.semibold}, sans-serif`,
  },
  month: {
    right: 33,
    color: ({ defaultTextColor }: DateSwitchProps) => defaultTextColor || theme.colors.textBlack,
    '@media screen and (max-width: 420px)': {
      right: 21,
    },
  },
  year: {
    left: 60,
    color: ({ defaultTextColor }: DateSwitchProps) => defaultTextColor || theme.colors.textBlack,
    '@media screen and (max-width: 420px)': {
      left: 45,
    },
  },
  selectedText: {
    color: ({ activeTextColor }: DateSwitchProps) => activeTextColor || theme.colors.textSecondary,
  },
}));

export const PeriodSwitcher = (props: DateSwitchProps) => {
  const css = useStyles(props);

  const handleChange = () => {
    props.onChange(!props.checked);
  };

  return (
    <span>
      <Button
        role="switch"
        className={clsx(css.switch, props.className, {
          [css.checked]: !props.checked,
        })}
        onClick={handleChange}
        aria-checked={props.checked}
      >
        <span
          className={clsx(css.text, css.year, {
            [css.selectedText]: props.checked,
          })}
        >
          За год
        </span>
        <span
          className={clsx(css.text, css.month, {
            [css.selectedText]: !props.checked,
          })}
        >
          Ежемесячно
        </span>
      </Button>
    </span>
  );
};
