import React from 'react';
import { CreateSvgIcon } from '../icon';
import { IconProps } from '../names';

export const Loupe = (props: IconProps) => (
  <CreateSvgIcon {...props}>
    {({ color }) => (
      <path
        d="M31.4062 31.4057C31.0104 31.8019 30.5365 32 29.9844 32C29.4323 32 28.9583 31.8019 28.5625 31.4057L22.375 25.2121C19.875 27.0681 17.0833 27.9961 14 27.9961C12.1042 27.9961 10.2917 27.6259 8.5625 26.8856C6.83333 26.1453 5.34375 25.1496 4.09375 23.8983C2.84375 22.6471 1.84896 21.1613 1.10938 19.4409C0.369792 17.7204 0 15.9062 0 13.998C0 12.0899 0.369792 10.2704 1.10938 8.53959C1.84896 6.80873 2.84375 5.32291 4.09375 4.08211C5.34375 2.84132 6.83333 1.85077 8.5625 1.11046C10.2917 0.370153 12.1042 0 14 0C15.8958 0 17.7083 0.370153 19.4375 1.11046C21.1667 1.85077 22.6562 2.84132 23.9062 4.08211C25.1562 5.32291 26.151 6.80873 26.8906 8.53959C27.6302 10.2704 28 12.0847 28 13.9824C28 17.0896 27.0729 19.884 25.2188 22.3656L31.4062 28.5591C31.8021 28.9554 32 29.4298 32 29.9824C32 30.535 31.8021 31.0094 31.4062 31.4057ZM14 4.00391C12.1875 4.00391 10.5156 4.44705 8.98438 5.33333C7.45312 6.21962 6.23958 7.43434 5.34375 8.97752C4.44792 10.5207 4 12.1942 4 13.998C4 15.8019 4.44792 17.4754 5.34375 19.0186C6.23958 20.5617 7.45312 21.7765 8.98438 22.6628C10.5156 23.549 12.1875 23.9922 14 23.9922C15.8125 23.9922 17.4844 23.549 19.0156 22.6628C20.5469 21.7765 21.7604 20.5617 22.6562 19.0186C23.5521 17.4754 24 15.8019 24 13.998C24 12.1942 23.5521 10.5207 22.6562 8.97752C21.7604 7.43434 20.5469 6.21962 19.0156 5.33333C17.4844 4.44705 15.8125 4.00391 14 4.00391Z"
        fill={color}
      />
    )}
  </CreateSvgIcon>
);
