type Status = 'preparing' | 'processing' | 'ready';

const statuses = {
  preparing: 'Проект',
  processing: 'Строится',
  ready: 'Построен',
};

export const getStatus = (stage: Status): string => statuses[stage];

type Level = 'economy' | 'comfort' | 'premium';

const levels = {
  economy: 'Эконом',
  comfort: 'Комфорт',
  premium: 'Премиум',
};

export const getLevel = (level: Level): string => levels[level];

type Furnishs = 'repair_comfort' | 'rough' | 'clear' | 'repair_economy' | 'repair_premium';

const furnishs = {
  repair_comfort: 'Комфорт',
  rough: 'Черновая',
  clear: 'Комфорт',
  repair_economy: 'Эконом',
  repair_premium: 'Премиум',
};

export const getFurnish = (furnish: Furnishs): string => furnishs[furnish];
