import React from 'react';
import { makeStyles } from 'hooks/makeStyles';
import clsx from 'clsx';

interface SkeletonProps {
  width?: string | number
  height?: string | number
  size?: 'small' | 'medium' | 'large'
  className?: string
  children?: React.ReactNode
}

const useStyles = makeStyles((theme) => ({
  skeleton: {
    backgroundColor: theme.colors.grey.secondary,
    // position: "relative"
  },
  line: {
    width: '100%',
    borderRadius: 10,
    margin: '0 0 22px 0',
    '&$small': {
      height: 17,
      width: '60%',
    },
    '&$medium': {
      height: 17,
      width: '80%',
    },
    '&$large': {
      height: 17,
    },
  },
  circle: {
    borderRadius: '50%',
    margin: `${theme.spacing(0)} ${theme.spacing(8)} ${theme.spacing(
      4,
    )} ${theme.spacing(0)}`,
    '&$small': {
      width: 34,
      height: 34,
    },
    '&$medium': {
      width: 60,
      height: 60,
    },
    '&$large': {
      minWidth: 99,
      minHeight: 99,
    },
  },
  square: {
    width: '100%',
    borderRadius: 10,
    '&:not(:last-child)': {
      marginRight: theme.spacing(4.5),
    },
    '&$small': {
      height: 20,
    },
    '&$medium': {
      height: 30,
    },
    '&$large': {
      height: 40,
    },
  },
  small: {},
  medium: {},
  large: {},
}));

export const Circle = (props: SkeletonProps) => {
  const {
    width, height, size = 'medium', className, children,
  } = props;
  const css = useStyles();

  return (
    <div
      className={clsx(css.circle, css.skeleton, {
        [css.small]: size === 'small',
        [css.medium]: size === 'medium',
        [css.large]: size === 'large',
        [className!]: !!className,
      })}
      style={{
        width,
        height,
      }}
    >
      {children}
    </div>
  );
};

export const Line = (props: SkeletonProps) => {
  const {
    width = '100%', height, size = 'medium', className,
  } = props;
  const css = useStyles();

  return (
    <div
      className={clsx(css.line, css.skeleton, {
        [css.small]: size === 'small',
        [css.medium]: size === 'medium',
        [css.large]: size === 'large',
        [className!]: !!className,
      })}
      style={{
        width,
        height,
      }}
    />
  );
};

export const Square = (props: SkeletonProps) => {
  const {
    width = '100%', height, size = 'medium', className,
  } = props;
  const css = useStyles();

  return (
    <div
      className={clsx(css.square, css.skeleton, {
        [css.small]: size === 'small',
        [css.medium]: size === 'medium',
        [css.large]: size === 'large',
        [className!]: !!className,
      })}
      style={{
        width,
        height,
      }}
    />
  );
};
