import React, { useContext, useState, useCallback } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Theme } from 'lib/theme';
import { makeStyles } from 'hooks/makeStyles';

import {
  Typography, Icons, Box, Button, IconButton, Alert, AnimatedPage,
} from 'ui/shared';
import { PeriodSwitcher } from './period-switcher';
import { ApplicationContext } from '../../../global-stores/application-context';
import AccessProvide from 'ui/components/AccessProvide';
import { SubscriptionOption } from './components/subscription-option';
import { SubscriptionOptions } from './components/subscription-options';
import { Text } from '../../../components/text';
import { NB_SP } from 'text-constants';
import { sendMetrik } from '_helpers';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
    padding: '20px 20px 60px',
    position: 'relative',
    background: '#f6f6f6',
    '@media screen and (max-width: 1680px)': {
      paddingTop: 80,
    },
    '@media screen and (max-width: 768px)': {
      padding: `${theme.spacing(19)} ${theme.spacing(4.5)}`,
    },
    '@media screen and (max-width: 335px)': {
      padding: `${theme.spacing(19)} ${theme.spacing(1)}`,
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  wrapper: {
    maxWidth: 1000,
  },
  backBtn: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
    fontSize: 18,
    fontFamily: `${theme.fonts.semibold}, sans-serif`,
    position: 'absolute',
    top: 20,
    left: 50,
    '&:hover, &:focus, &:active': {
      backgroundColor: 'transparent',
    },
    '@media screen and (max-width: 768px)': {
      left: 23,
    },
  },
  backIcon: {
    marginRight: theme.spacing(2.25),
  },
  linkRules: {
    fontSize: 14,
    fontFamily: `${theme.fonts.semibold}, sans-serif`,
    fontWeight: 500,
    color: theme.colors.blue.main,
  },
  switch: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    fontSize: 23,
    marginBottom: theme.spacing(4.5),
    '@media screen and (max-width: 768px)': {
      textAlign: 'left',
    },
  },
  subtitle: {
    fontSize: 18,
    color: theme.colors.textPrimaryGray,
    '@media screen and (max-width: 768px)': {
      textAlign: 'left',
      color: theme.colors.blue.main,
    },
  },
  alert: {
    marginTop: theme.spacing(8.5),
  },
  cartsWrapper: {
    display: 'flex',
    '@media (max-width: 1024px)': {
      flexWrap: 'wrap',
    },
  },
  cart: {
    borderRadius: 10,
    backgroundColor: theme.colors.background.paper.default,
    padding: 60,
    flexGrow: 1,
    margin: 16,
    '@media (max-width: 1440px)': {
      padding: 24,
    },
    '@media (max-width: 1024px)': {
      padding: 32,
    },
  },
  cartTitle: {
    fontSize: 28,
    marginBottom: theme.spacing(2.5),
  },
  priceWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: 16,
  },
  price: {
    fontSize: 28,
  },
  month: {
    fontSize: 26,
  },
  yearPrice: {
    fontSize: 22,
  },
  yearMonth: {
    fontSize: 22,
  },
  comment: {
    marginBottom: 24,
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    padding: '0 32px',
    borderRadius: 8,
    color: theme.colors.textSecondary,
    fontSize: 18,
    lineHeight: 1,
    textTransform: 'none',
    marginBottom: 32,
  },
  option: {
    display: 'flex',
    flexWrap: 'nowrap',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(4.5),
    },
  },
  checkIcon: {
    flexShrink: 0,
    marginRight: theme.spacing(4.25),
  },
  label: {
    fontFamily: `${theme.fonts.semibold}, sans-serif`,
    fontSize: 16,
  },
  exampleLink: {
    // marginLeft: 4,
    color: 'var(--color-blue)',
  },
  footnote: {
    marginTop: 30,
    // color: 'var(--color-blue)',
  },
}));

const Option = (props: any) => {
  const css = useStyles();
  return (
    <div className={css.option}>
      <Icons.Checkmark className={css.checkIcon} width={17} height={17} viewBox="0 0 17 17" color="#136FFB" />
      <span className={css.label}>{props.label}</span>
    </div>
  );
};

export const Subscriptions = observer(() => {
  const css = useStyles();
  const history = useHistory();
  const [isYear, setIsYear] = useState<boolean>(true);
  const { currentUser } = useContext(ApplicationContext);

  // if (currentUser && !currentUser?.expired) {
  //   return <Redirect to="/services" />;
  // }
  const availableSubscriptionPlan = isYear
    ? currentUser?.available_subscription_plans?.annual
    : currentUser?.available_subscription_plans?.monthly;
  // const availableSubscriptionPlan = currentUser?.available_subscription_plans?.annual;

  const paymentBaseUrl = currentUser?.available_subscription_plans?.base_url;

  const handleSendMetrik = useCallback(() => {
    sendMetrik('reachGoal', 'BuyNowButtonClick');
  }, []);

  return (
    <AnimatedPage className={css.root} animation="horizontal">
      <AccessProvide>
        <IconButton className={css.backBtn} onClick={() => history.push('/services')}>
          <Icons.LongBackArrow className={css.backIcon} />
          Назад
        </IconButton>
        <div className={css.container}>
          <div className={css.wrapper}>
            {history.location.search.indexOf('failed') > -1 && (
              <Alert className={css.alert}>
                Произошла ошибка при обработке оплаты. Обратитесь в&nbsp;
                <Link to="/services/subscriptions" className={css.linkRules}>
                  службу поддержки
                </Link>
                , чтобы оперативно исправить ситуацию&nbsp;
              </Alert>
            )}

            <Text size="xl" align="center" as="p" mb={16} semibold>
              Развивайся как профессионал.<br />
              Продавайте больше с{NB_SP}качественной презентацией себя и{NB_SP}своих объектов
            </Text>

            {/* <Text size="l" align="center" as="p" color="grey" mb={16} semibold>
              Все что нужно для старта. Начните бесплатно и платите по мере роста.
            </Text> */}

            <Alert className={css.alert} warning>
              <Text size="s" semibold>
                Мы можем отказать в доступе в мобильное приложение и к объектам других риэлторов, если вы не пройдете
                модерацию и если вы не являетесь действуещем агентом по недвижимости.
              </Text>
              {' '}
              <a
                href="https://reagent.pro/wp-content/uploads/2019/12/privacy_ru.pdf"
                className={css.linkRules}
                target="_blank"
                rel="noreferrer"
              >
                Наши правила
              </a>
            </Alert>

            <Box mt={14.5} mb={16.25} className={css.switch}>
              <PeriodSwitcher checked={isYear} onChange={() => setIsYear(!isYear)} />
            </Box>

            <Box className={css.cartsWrapper}>
              {/* <Box className={css.cart}>
                <Typography className={css.cartTitle} font="semibold">
                  FREE
                </Typography>
                <div className={css.priceWrap}>
                  <Typography className={css.price} font="semibold">
                    0 руб/
                    <span className={css.month}>месяц</span>
                  </Typography>
                </div>
                <p className={css.comment}>
                  <Text>
                    Все что нужно для специалиста
                  </Text>
                </p>
                <form action={paymentBaseUrl} target="_top" method="POST">
                  <input type="hidden" name="Description" value={availableSubscriptionPlan?.Description} />
                  <input type="hidden" name="MerchantLogin" value={availableSubscriptionPlan?.MerchantLogin} />
                  <input type="hidden" name="OutSum" value={availableSubscriptionPlan?.OutSum} />
                  <input type="hidden" name="InvId" value={availableSubscriptionPlan?.InvId} />
                  <input type="hidden" name="Receipt" value={availableSubscriptionPlan?.Receipt} />
                  <input type="hidden" name="Shp_Period" value={availableSubscriptionPlan?.Shp_Period} />
                  <input type="hidden" name="Shp_UserId" value={availableSubscriptionPlan?.Shp_UserId} />
                  <input type="hidden" name="Shp_Time" value={availableSubscriptionPlan?.Shp_Time} />
                  <input type="hidden" name="SignatureValue" value={availableSubscriptionPlan?.SignatureValue} />
                  {availableSubscriptionPlan?.IsTest && <input type="hidden" name="IsTest" value={1} />}
                  {currentUser && currentUser?.expired && (
                    <Button type="submit" className={css.button}>
                      Улучшить до PRO
                    </Button>
                  )}
                </form>
                <Box>
                  <SubscriptionOptions>
                    <SubscriptionOption>
                      Персональный сайт визитка
                      <a
                        className={css.exampleLink}
                        href="https://show.reagent.pro/NDY4Mg=="
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        пример
                      </a>
                    </SubscriptionOption>
                    <SubscriptionOption>
                      1 мессенджер или социальная сеть
                    </SubscriptionOption>
                    <SubscriptionOption>
                      Возможность оставлять вам отзывы
                    </SubscriptionOption>
                    <SubscriptionOption>
                      Короткая ссылка
                    </SubscriptionOption>
                    <SubscriptionOption>
                      Форма для заявки от клиента
                    </SubscriptionOption>
                    <SubscriptionOption>
                      QR код на профиль
                    </SubscriptionOption>
                  </SubscriptionOptions>
                </Box>
              </Box> */}

              <Box className={css.cart}>
                <Typography className={css.cartTitle} font="semibold">
                  Профессиональный участник Ассоциации *
                </Typography>
                <div className={css.priceWrap}>
                  {isYear ? (
                    availableSubscriptionPlan && [
                      // <Typography className={css.price} font="semibold">
                      //   {`${availableSubscriptionPlan.OutSum} руб`}
                      // </Typography>,
                      <Typography className={css.price} font="semibold">
                        {`${availableSubscriptionPlan.OutSum / 12} руб/месяц`}
                      </Typography>,
                      <Typography className={css.yearPrice} font="semibold">
                        {`${availableSubscriptionPlan.OutSum} руб/год`}
                      </Typography>,
                    ]
                  ) : (
                    <Typography className={css.price} font="semibold">
                      {availableSubscriptionPlan ? `${availableSubscriptionPlan?.OutSum} руб/месяц` : ''}
                    </Typography>
                  )}
                </div>
                <p className={css.comment}>
                  <Text>
                    Все что нужно для профессионала рынка
                  </Text>
                </p>
                <form action={paymentBaseUrl} target="_top" method="POST">
                  <input type="hidden" name="Description" value={availableSubscriptionPlan?.Description} />
                  <input type="hidden" name="MerchantLogin" value={availableSubscriptionPlan?.MerchantLogin} />
                  <input type="hidden" name="OutSum" value={availableSubscriptionPlan?.OutSum} />
                  <input type="hidden" name="InvId" value={availableSubscriptionPlan?.InvId} />
                  <input type="hidden" name="Receipt" value={availableSubscriptionPlan?.Receipt} />
                  <input type="hidden" name="Shp_Period" value={availableSubscriptionPlan?.Shp_Period} />
                  <input type="hidden" name="Shp_UserId" value={availableSubscriptionPlan?.Shp_UserId} />
                  <input type="hidden" name="Shp_Time" value={availableSubscriptionPlan?.Shp_Time} />
                  <input type="hidden" name="SignatureValue" value={availableSubscriptionPlan?.SignatureValue} />
                  {availableSubscriptionPlan?.IsTest && <input type="hidden" name="IsTest" value={1} />}
                  {currentUser && currentUser?.expired && (
                    <Button
                      type="submit"
                      className={css.button}
                      onClick={handleSendMetrik}
                    >
                      Оплатить сейчас
                    </Button>
                  )}
                </form>
                <Box>
                  <SubscriptionOptions>
                    <SubscriptionOption>
                      {/* eslint-disable-next-line */}
                      Аттестат подтверждения квалификации &quot;Специалист по недвижимости (Агент, Эксперт, Брокер)&quot;
                    </SubscriptionOption>
                    <SubscriptionOption>
                      {/* eslint-disable-next-line */}
                      Доступ в закрытую MLS базу Ассоциации (базу объектов вашего города) и выгрузка в Федеральную базу недвижимости
                    </SubscriptionOption>
                    <SubscriptionOption>
                      Доступ к эксклюзивам ассоциации
                    </SubscriptionOption>
                    <SubscriptionOption>
                      Персональный сайт визитка
                    </SubscriptionOption>
                    <SubscriptionOption>
                      Возможность оставлять вам отзывы
                    </SubscriptionOption>
                    <SubscriptionOption>
                      Публикация вашего профиля в реестре
                    </SubscriptionOption>
                    <SubscriptionOption>
                      Короткая ссылка на ваш профиль
                    </SubscriptionOption>
                    <SubscriptionOption>
                      Форма для заявки от клиента
                    </SubscriptionOption>
                    <SubscriptionOption>
                      QR код на профиль
                    </SubscriptionOption>
                    <SubscriptionOption>
                      QR коды на объекты и на профиль
                    </SubscriptionOption>
                    <SubscriptionOption>
                      Доступ в закрытое мобильное приложение для участников ассоциации
                    </SubscriptionOption>
                    <SubscriptionOption>
                      Добавляйте актуальные и проданные объекты
                    </SubscriptionOption>
                    <SubscriptionOption>
                      Создавайте подборки для клиентов
                    </SubscriptionOption>
                    <SubscriptionOption>
                      Сайт презентация на каждый объект&nbsp;(
                      <a
                        className={css.exampleLink}
                        href="https://catalog.reagent.pro/58478d405379acbb4edf83d6770042b9"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        пример
                      </a>
                      )
                    </SubscriptionOption>
                    <SubscriptionOption>
                      Сайт презентация на каждую подбороку&nbsp;(
                      <a
                        className={css.exampleLink}
                        href="https://selection.reagent.pro/F5vj/1a05f2e6e3a95373a0e917c9850019bf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        пример
                      </a>
                      )
                    </SubscriptionOption>
                    <SubscriptionOption>
                      Загрузите фон на свою страницу для более индивидуального профиля
                    </SubscriptionOption>
                    <SubscriptionOption>
                      Автопубликации ваших объектов в телеграмм чатах
                    </SubscriptionOption>
                  </SubscriptionOptions>
                </Box>
                <Box>
                  <p className={css.footnote}>
                    <Text>
                      * верифицированный, подтвержденный статус
                    </Text>
                  </p>
                </Box>
              </Box>
            </Box>
          </div>
        </div>
      </AccessProvide>
    </AnimatedPage>
  );
});
