import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

interface PortalProps {
  onRendered?: () => void
  children: React.ReactNode
}

const useEnhancedEffect = typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect;

export const Portal = (props: PortalProps) => {
  const [container, setContainer] = useState<HTMLElement>();
  const { onRendered } = props;

  useEnhancedEffect(() => {
    if (document.body) {
      setContainer(document.body);
    }
  }, []);

  useEffect(() => {
    if (onRendered && container) {
      onRendered();
    }
  }, [container, onRendered]);

  return container ? ReactDOM.createPortal(props.children, container) : null;
};
