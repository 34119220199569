import React, { forwardRef, InputHTMLAttributes, LegacyRef } from 'react';
import classNames from 'classnames/bind';
import { IMaskInput } from 'react-imask';
import { Typography } from '../typography/typography';
import { Box, BoxProps, pickBoxProps } from '../box/box';
import styles from './styles.module.css';

const cx = classNames.bind(styles);

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  fullWidth?: boolean
  helperText?: string
  error?: boolean
  succeed?: boolean
  helperTextClassName?: string
  secondary?: boolean
  mask?: string;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (props: InputProps & BoxProps, ref: LegacyRef<HTMLInputElement>) => {
    const { boxProps, other } = pickBoxProps(props);
    const {
      fullWidth,
      error,
      helperText,
      succeed,
      className,
      helperTextClassName,
      secondary,
      mask,
      ...passthrough
    } = other;

    return (
      <Box {...boxProps} className={cx('container')}>
        {mask
          ? (
            <IMaskInput
              mask={mask}
              {...passthrough}
              className={cx('input', {
                secondary,
                'full-width': fullWidth,
                error,
                succeed,
              }, className)}
            />
          )
          : (
            <input
              ref={ref}
              {...passthrough}
              className={cx('input', {
                secondary,
                'full-width': fullWidth,
                error,
                succeed,
              }, className)}
            />
          )}
        {helperText && (
          <Box mt={2}>
            <Typography
              align="center"
              className={cx({
                'error-text': error,
                'succeed-text': succeed,
                helperTextClassName,
              })}
            >
              {helperText}
            </Typography>
          </Box>
        )}
      </Box>
    );
  },
);
