import React from 'react';
import { CreateSvgIcon } from '../icon';
import { IconProps } from '../names';

export const Person = (props: IconProps) => (
  <CreateSvgIcon {...props}>
    {({ color }) => (
      <>
        <circle
          cx="14.5906"
          cy="8.57937"
          r="6.06423"
          stroke={color}
          strokeWidth="4"
        />
        <path
          d="M1.91846 29.7004C5.93098 22.7456 16.4639 13.0089 26.4952 29.7004"
          stroke={color}
          strokeWidth="4"
        />
      </>
    )}
  </CreateSvgIcon>
);
