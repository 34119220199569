import { useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { ApplicationContext } from './global-stores/application-context';

const Chatwoot = observer(() => {
  const { currentUser } = useContext(ApplicationContext);
  // @ts-ignore
  const { $chatwoot } = window;

  useEffect(() => {
    if (!currentUser?.id) return;
    $chatwoot?.setUser(currentUser?.id, {
      email: currentUser?.email,

      name: currentUser?.first_name,
      avatar_url: currentUser?.avatar?.small,
    });
  }, [currentUser, $chatwoot]);

  return null;
});

export default Chatwoot;
