import React from 'react';
import { CreateSvgIcon } from '../icon';
import { IconProps } from '../names';

export const TooltipTail = (props: IconProps) => (
  <CreateSvgIcon {...props}>
    {({ color }) => (
      <>
        <path d="M11.5 0L22.3253 9.75H0.674683L11.5 0Z" fill={color} />
      </>
    )}
  </CreateSvgIcon>
);
