import React from 'react';
import { CreateSvgIcon } from '../icon';
import { IconProps } from '../names';

export const LongBackArrow = (props: IconProps) => (
  <CreateSvgIcon {...props}>
    {({ color }) => (
      <path
        d="M0.292893 15.2929C-0.0976311 15.6834 -0.0976311 16.3166 0.292893 16.7071L6.65685 23.0711C7.04738 23.4616 7.68054 23.4616 8.07107 23.0711C8.46159 22.6805 8.46159 22.0474 8.07107 21.6569L2.41421 16L8.07107 10.3431C8.46159 9.95262 8.46159 9.31946 8.07107 8.92893C7.68054 8.53841 7.04738 8.53841 6.65685 8.92893L0.292893 15.2929ZM31.0167 15H1V17H31.0167V15Z"
        fill={color}
      />
    )}
  </CreateSvgIcon>
);
