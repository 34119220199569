/**
 * Manage appearance and position of the overlay
 */
import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from 'hooks/makeStyles';
import { useEventCallback } from 'hooks/useEventCallback';
import isEqual from 'lodash/isEqual';
import { calculateOverlayPosition } from 'utils';
import { Overlay } from './overlay';

export interface OverlayPositionerProps {
  preferRight?: boolean
  preferTop?: boolean
  preferCenter?: boolean
  trigger: React.RefObject<HTMLButtonElement> | null
  visible: boolean
  open: () => void
  close: (force?: boolean) => void
  onOverlayKeyDown: (e: KeyboardEvent) => void
  onOverlayBlur: (e: React.FocusEvent<HTMLDivElement>) => void
  onTriggerKeyUp: (e: KeyboardEvent) => void
  overlayId: string
  on?: 'hover' | 'click'
  withTail?: boolean
  withArrow?: boolean
  overlayClassName?: string
  useVisibility?: boolean
  role?: string
  children: React.ReactNode
  vertPosition?: string,
  horzPosition?: string,
}

const useStyles = makeStyles(() => ({
  top: {
    bottom: '100%',
  },
  left: {
    right: 0,
  },
  center: {
    left: '50%',
    transform: 'translateX(-50%)',
  },
  arrowBottomLeft: {
    marginTop: 6,
    right: -5,
  },
  arrowBottomRight: {
    marginTop: 6,
    left: -5,
  },
  arrowBottomCenter: {
    marginTop: 6,
  },
  arrowTopLeft: {
    marginBottom: 6,
    right: -5,
  },
  arrowTopRight: {
    marginBottom: 6,
    left: -5,
  },
  arrowTopCenter: {
    marginBottom: 6,
  },
  bottomLeft: {
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 27,
      right: 6,
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '0 10px 15px 10px',
      borderColor: 'transparent transparent #fff transparent',
    },
  },
  bottomRight: {
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 27,
      left: 6,
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '0 10px 15px 10px',
      borderColor: 'transparent transparent #fff transparent',
    },
  },
  bottomCenter: {
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 27,
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '0 10px 15px 10px',
      borderColor: 'transparent transparent #fff transparent',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  topLeft: {
    '&:before': {
      content: '""',
      position: 'absolute',
      bottom: 25,
      left: 6,
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '15px 10px 0 10px',
      borderColor: '#fff transparent transparent transparent',
    },
  },
  topRight: {
    '&:before': {
      content: '""',
      position: 'absolute',
      bottom: 25,
      left: '50%',
      transform: 'translateX(-50%)',
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '15px 10px 0 10px',
      borderColor: '#fff transparent transparent transparent',
    },
  },
  topCenter: {
    '&:before': {
      content: '""',
      position: 'absolute',
      bottom: 25,
      right: 6,
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '15px 10px 0 10px',
      borderColor: '#fff transparent transparent transparent',
    },
  },
}));

export const OverlayPositioner = React.forwardRef(
  (props: OverlayPositionerProps, ref: React.Ref<HTMLDivElement>) => {
    const [overlayPosition, setOverlayPosition] = useState<string>();
    const mouseIn = useRef<boolean | null>(false);
    const triggerTimeoutID = useRef<NodeJS.Timeout | null>(null);
    const overlayTimeoutID = useRef<NodeJS.Timeout | null>(null);
    const [triggerRect, setTriggerRect] = useState<ClientRect | undefined>();
    const {
      preferTop,
      preferCenter,
      preferRight,
      overlayId,
      trigger,
      visible,
      open,
      close,
      onOverlayKeyDown,
      onOverlayBlur,
      onTriggerKeyUp,
      on,
      useVisibility,
      role,
      children,
      vertPosition,
      horzPosition,
    } = props;
    const css = useStyles();

    const handleOverlayOnMouseEnter = useEventCallback(() => {
      mouseIn.current = true;
    });

    const handleOverlayOnMouseLeave = useEventCallback(() => {
      mouseIn.current = false;
      overlayTimeoutID.current = setTimeout(() => {
        if (!mouseIn.current && visible) {
          close(false);
        }
      }, 250);
    });

    const getTriggerRect = useEventCallback((): ClientRect | undefined => trigger?.current?.getBoundingClientRect());

    useEffect(() => {
      const triggerRectForUpdate = getTriggerRect();

      if (triggerRectForUpdate) {
        setTriggerRect(triggerRectForUpdate);
      }
    }, [getTriggerRect]);

    useEffect(() => {
      const overlay = ref && (ref as any).current;

      if (overlay) {
        overlay.addEventListener('keydown', onOverlayKeyDown);
        if (on === 'hover') {
          overlay.addEventListener('mouseenter', handleOverlayOnMouseEnter);
          overlay.addEventListener('mouseleave', handleOverlayOnMouseLeave);
        }
      }

      return () => {
        if (overlay) {
          overlay.removeEventListener('keydown', onOverlayKeyDown);
          if (on === 'hover') {
            overlay.addEventListener('mouseenter', handleOverlayOnMouseEnter);
            overlay.addEventListener('mouseleave', handleOverlayOnMouseLeave);
          }
        }
      };
    }, [
      onOverlayKeyDown,
      getTriggerRect,
      handleOverlayOnMouseEnter,
      handleOverlayOnMouseLeave,
      on,
      ref,
    ]);

    const handleTriggerOnMouseEnter = useEventCallback(() => {
      mouseIn.current = true;
      if (!visible) {
        open();
      }
    });

    const handleTriggerOnMouseLeave = useEventCallback(() => {
      mouseIn.current = false;
      triggerTimeoutID.current = setTimeout(() => {
        if (!mouseIn.current && visible) {
          close();
        }
      }, 250);
    });

    useEffect(() => {
      const triggerEl = trigger && trigger.current;

      if (triggerEl) {
        triggerEl.addEventListener('keydown', onTriggerKeyUp);
        if (on === 'hover') {
          triggerEl.addEventListener('focus', handleTriggerOnMouseEnter);
          triggerEl.addEventListener('blur', handleTriggerOnMouseLeave);
          triggerEl.addEventListener('mouseenter', handleTriggerOnMouseEnter);
          triggerEl.addEventListener('mouseleave', handleTriggerOnMouseLeave);
        }
      }

      return () => {
        if (triggerEl) {
          triggerEl.removeEventListener('keyup', onTriggerKeyUp);
          if (on === 'hover') {
            triggerEl.removeEventListener('focus', handleTriggerOnMouseEnter);
            triggerEl.addEventListener('blur', handleTriggerOnMouseLeave);
            triggerEl.removeEventListener(
              'mouseenter',
              handleTriggerOnMouseEnter,
            );
            triggerEl.addEventListener('mouseleave', handleTriggerOnMouseLeave);
          }
        }
      };
    }, [
      onTriggerKeyUp,
      trigger,
      on,
      handleTriggerOnMouseEnter,
      handleTriggerOnMouseLeave,
    ]);

    useEffect(
      () => () => {
        if (triggerTimeoutID && triggerTimeoutID.current) {
          clearTimeout(triggerTimeoutID.current);
        }
        if (overlayTimeoutID && overlayTimeoutID.current) {
          clearTimeout(overlayTimeoutID.current);
        }
      },
      [],
    );

    const onOverlayEnter = useEventCallback(() => {
      if (ref && (ref as any).current) {
        const triggerNewRect = getTriggerRect();
        const overlayPositionForUpdate = triggerNewRect && typeof (ref as any).current.clientWidth === 'number'
          ? calculateOverlayPosition(
            triggerNewRect,
            (ref as any).current.clientWidth,
            (ref as any).current.clientHeight,
            preferRight,
            preferTop,
            preferCenter,
            vertPosition || '',
            horzPosition || '',
          )
          : undefined;

        setOverlayPosition(overlayPositionForUpdate);

        if (!isEqual(triggerRect, triggerNewRect)) {
          setTriggerRect(triggerNewRect);
        }
      }
    });

    const onOverlayExit = useEventCallback(() => {
      setOverlayPosition(undefined);
    });

    return (
      <div
        className={clsx({
          [css.bottomLeft]: overlayPosition === 'BottomLeft' && props.withArrow,
          [css.bottomRight]:
            overlayPosition === 'BottomRight' && props.withArrow,
          [css.bottomCenter]:
            overlayPosition === 'BottomCenter' && props.withArrow,
          [css.topLeft]: overlayPosition === 'TopLeft' && props.withArrow,
          [css.topRight]: overlayPosition === 'TopRight' && props.withArrow,
          [css.topCenter]: overlayPosition === 'TopCenter' && props.withArrow,
        })}
      >
        <Overlay
          id={overlayId}
          tabIndex={-1}
          role={role || 'menu'}
          visible={visible}
          readyToRender={visible && !!overlayPosition}
          onEnter={onOverlayEnter}
          onExit={onOverlayExit}
          onBlur={onOverlayBlur}
          ref={ref}
          useVisibility={useVisibility}
          style={{ maxHeight: '300px', overflowY: 'auto' }}
          className={clsx(
            {
              [css.top]: overlayPosition && overlayPosition!.startsWith('Top'),
              [css.left]: overlayPosition && overlayPosition!.endsWith('Left'),
              [css.center]:
                overlayPosition && overlayPosition!.endsWith('Center'),
              [css.arrowBottomLeft]:
                overlayPosition === 'BottomLeft' && props.withArrow,
              [css.arrowBottomRight]:
                overlayPosition === 'BottomRight' && props.withArrow,
              [css.arrowBottomCenter]:
                overlayPosition === 'BottomCenter' && props.withArrow,
              [css.arrowTopLeft]:
                overlayPosition === 'TopLeft' && props.withArrow,
              [css.arrowTopRight]:
                overlayPosition === 'TopRight' && props.withArrow,
              [css.arrowTopCenter]:
                overlayPosition === 'TopCenter' && props.withArrow,
            },
            props.overlayClassName,
          )}
        >
          {children}
        </Overlay>
      </div>
    );
  },
);
