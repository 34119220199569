import { CSSProperties } from 'react';

export enum IconNames {
  'Facebook' = 'Facebook',
  'Twitter' = 'Twitter',
  'ArrowInCircle' = 'ArrowInCircle',
  'TriangularArrow' = 'TriangularArrow',
  'BackArrow' = 'BackArrow',
  'Calendar' = 'Calendar',
  'Person' = 'Person',
  'Whatsapp' = 'Whatsapp',
  'Telegram' = 'Telegram',
  'Email' = 'Email',
  'Youtube' = 'Youtube',
  'Instagram' = 'Instagram',
  'Vkontakte' = 'Vkontakte',
  'Cross' = 'Cross',
  'Exit' = 'Exit',
  'SmallArrow' = 'SmallArrow',
  'Objects' = 'Objects',
  'Analytics' = 'Analytics',
  'Settings' = 'Settings',
  'Support' = 'Support',
  'Share' = 'Share',
  'Copy' = 'Copy',
  'QrCode' = 'QrCode',
  'Alert' = 'Alert',
  'Loupe' = 'Loupe',
  'Camera' = 'Camera',
  'Planet' = 'Planet',
  'Video' = 'Video',
  'Checkmark' = 'Checkmark',
  'LongBackArrow' = 'LongBackArrow',
  'TooltipTail' = 'TooltipTail',
  'CameraSuccess' = 'CameraSuccess',
  'QuestionMark' = 'QuestionMark',
  'Add' = 'Add',
}

export type IconProps = {
  width?: number
  height?: number
  viewBox?: string
  color?: string
  secondaryColor?: string
  ['data-cy']?: string
  hover?: boolean
  className?: string
  style?: CSSProperties
};
