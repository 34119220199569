import React, { useContext, ButtonHTMLAttributes } from 'react';
import { makeStyles } from 'hooks/makeStyles';
import { Button, TabsContext } from 'ui/shared';
import clsx from 'clsx';

export interface TabProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  value: string | number
  children: React.ReactNode
  small?: boolean;
}

const useStyles = makeStyles((theme) => ({
  tab: {
    padding: `${theme.spacing(2)} 0`,
    margin: 0,
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.colors.textPrimary,
    backgroundColor: 'transparent',
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    fontSize: 15,
    borderRadius: 0,
    '@media screen and (max-width: 768px)': {
      padding: `${theme.spacing(2.5)} 0`,
      fontSize: 18,
    },
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.colors.grey.tertiary}`,
    },
    '&:focus, &:hover': {
      animation: 'none',
      color: theme.colors.blue.main,
      backgroundColor: 'transparent',
      transition: 'color .1s linear',

      '& .arrow': {
        fill: theme.colors.blue.main,
      },
    },
    '&.active': {
      color: theme.colors.blue.main,
      backgroundColor: 'transparent',
    },
    '& .arrow': {
      display: 'none',
      '@media screen and (max-width: 768px)': {
        display: 'block',
        marginLeft: 'auto',
        fill: '#989898',
      },
    },
    '&.small': {
      border: 'none',
      alignItems: 'center',
      justifyContent: 'center',
      '& svg': {
        margin: 0,
      },
      '& .arrow': {
        display: 'none',
      },
    },
  },
}));

export const Tab = (props: TabProps) => {
  const {
    value,
    children,
    small = false,
    ...passthrough
  } = props;
  const css = useStyles();

  const { value: contextValue, onChange } = useContext(TabsContext);

  return (
    <Button
      id={`tab-${value}`}
      role="tab"
      onClick={() => onChange(value)}
      className={clsx(css.tab, {
        small,
        active: String(value).includes(String(contextValue)),
      })}
      {...passthrough}
    >
      {children}
    </Button>
  );
};
