import React from 'react';
import { CreateSvgIcon } from '../icon';
import { IconProps } from '../names';

export const Email = (props: IconProps) => (
  <CreateSvgIcon {...props}>
    {({ color }) => (
      <path
        d="M27.2 10L16 17.5L4.8 10V7L16 14.5L27.2 7V10ZM27.2 4H4.8C3.246 4 2 5.335 2 7V25C2 25.7956 2.295 26.5587 2.8201 27.1213C3.3452 27.6839 4.05739 28 4.8 28H27.2C27.9426 28 28.6548 27.6839 29.1799 27.1213C29.705 26.5587 30 25.7956 30 25V7C30 6.20435 29.705 5.44129 29.1799 4.87868C28.6548 4.31607 27.9426 4 27.2 4Z"
        fill={color}
      />
    )}
  </CreateSvgIcon>
);
