import React from 'react';
import classNames from 'classnames/bind';
import Button from 'ui/components/Button';
import { Text } from 'components/text';
import styles from './not-found.module.css';
import { EnumApartment } from '_enums';
import { NB_SP } from 'text-constants';
import { NotFoundProps } from './types';

const cx = classNames.bind(styles);

const dict: Record<EnumApartment, string> = {
  [EnumApartment.Flat]: `Ни${NB_SP}одна квартира не${NB_SP}соответствует вашим параметрам, попробуйте:`,
  [EnumApartment.House]: `Ни${NB_SP}один дом не${NB_SP}соответствует вашим параметрам, попробуйте:`,
  [EnumApartment.Land]: `Ни${NB_SP}однин участок не${NB_SP}соответствует вашим параметрам, попробуйте:`,
  [EnumApartment.Commerce]: `Ни${NB_SP}одна коммерция не${NB_SP}соответствует вашим параметрам, попробуйте:`,
};

export const NotFound = React.memo(({
  apartmentType,
  onReset,
}: NotFoundProps) => (
  <div className={cx('not-found')}>
    <h2 className={cx('title')}>
      <Text size="xl">
        Нет результатов
      </Text>
    </h2>

    {apartmentType && (
      <p className={cx('message')}>
        <Text>
          {dict[apartmentType]}
        </Text>
      </p>
    )}

    <ul className={cx('list')}>
      <li className={cx('list-item')}>
        <Text>
          Другое местоположение
        </Text>
      </li>

      <li className={cx('list-item')}>
        <Text>
          Другой ценовой диапазон
        </Text>
      </li>

      <li className={cx('list-item')}>
        <Text>
          Укажите другой город в{NB_SP}настройках своего профиля
        </Text>
      </li>

      <li className={cx('list-item')}>
        <Text>
          Очистить фильтры, чтобы начать все сначала
        </Text>
      </li>
    </ul>

    {onReset && (
      <div className={cx('reset-wrap')}>
        <Button
          component="button"
          theme="black"
          onClick={onReset}
        >
          Сбросить
        </Button>
      </div>
    )}
  </div>
));
