import React from 'react';
import cn from 'classnames';

import styles from './FieldWrap.module.css';

interface Props {
  className?: string;
  children: React.ReactNode;
}

interface FieldWrapProps {
  alignment?: 'start';
  className?: string;
  children: React.ReactNode;
}

const FieldWrap = ({ children, alignment, className }: FieldWrapProps) => (
  <div
    className={cn(styles.wrap, className, {
      [styles.wrapAlignmentStart]: alignment === 'start',
    })}
  >
    {children}
  </div>
);

FieldWrap.Title = ({ children }: Props) => (
  <p className={styles.title}>
    {children}
  </p>
);

FieldWrap.Backdrop = ({ children }: Props) => (
  <div className={styles.backdropLegacy}>
    {children}
  </div>
);

FieldWrap.BackdropGroup = ({ children }: Props) => (
  <div className={styles.backdropGroup}>
    {children}
  </div>
);

interface HorizontalListLegacyProps extends Props {
  row?: boolean;
}

FieldWrap.HorizontalList = ({ children, row }: HorizontalListLegacyProps) => (
  <div
    className={cn(styles.horizontalListLegacy, {
      [styles.horizontalListLegacyRow]: row,
    })}
  >
    {children}
  </div>
);

FieldWrap.VerticalList = ({ children }: Props) => (
  <div className={styles.verticalListLegacy}>
    {children}
  </div>
);

FieldWrap.Column = ({ children }: Props) => (
  <div className={styles.column}>
    {children}
  </div>
);

export default FieldWrap;

type BackdropProps = {
  children: React.ReactNode;
  className?: string;
};

export const Backdrop = ({ children, className }: BackdropProps) => (
  <div className={cn(styles.backdrop, className)}>
    {children}
  </div>
);

type LabelProps = {
  children: React.ReactNode;
  className?: string;
};

export const Label = ({ children, className }: LabelProps) => (
  <p className={cn(styles.label, className)}>
    {children}
  </p>
);

type HorizontalListProps = {
  children: React.ReactNode;
  className?: string;
  row?: boolean;
  column?: boolean;
  verticalCenter?: boolean;
  horizontalCenter?: boolean;
  wrap?: boolean;
  gap?: number;
  grid?: number | 'auto';
};

export const HorizontalList = (props : HorizontalListProps) => {
  const {
    children,
    className,
    row = true,
    column,
    verticalCenter,
    horizontalCenter,
    wrap = false,
    gap,
    grid,
  } = props;

  return (
    <div
      className={cn(styles.horizontalList, className, {
        [styles.horizontalListRow]: row,
        [styles[`horizontalListRowGap${gap}`]]: row && gap,
        [styles[`horizontalListColumnGap${gap}`]]: column && gap,
        [styles[`horizontalListRowGrid${grid}`]]: row && grid,
        [styles.horizontalListColumn]: column,
        [styles.horizontalListVerticalCenter]: verticalCenter,
        [styles.horizontalListHorizontalCenter]: horizontalCenter,
        [styles.horizontalListWrap]: wrap,
      })}
    >
      {children}
    </div>
  );
};
