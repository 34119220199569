import React from 'react';
import { CreateSvgIcon } from '../icon';
import { IconProps } from '../names';

export const Whatsapp = (props: IconProps) => (
  <CreateSvgIcon {...props}>
    {() => (
      <>
        <path
          d="M0.999931 30.9999L3.11216 23.2967C1.8097 21.0373 1.12455 18.475 1.12548 15.867C1.12548 7.66908 7.80204 1 15.9924 1C19.9732 1 23.703 2.55095 26.5094 5.35736C29.3158 8.16391 30.8595 11.901 30.8595 15.8744C30.8595 24.0722 24.183 30.7413 15.9925 30.7413H15.9852C13.4962 30.7413 11.0515 30.1136 8.88022 28.9319L0.999931 31V30.9999Z"
          fill="url(#paint0_whatsapp)"
        />
        <path
          d="M12.1441 9.4205C11.856 8.77793 11.5532 8.76309 11.28 8.75574C11.0585 8.74838 10.8 8.74838 10.5414 8.74838C10.283 8.74838 9.86932 8.84438 9.51487 9.22838C9.1603 9.6125 8.17063 10.5431 8.17063 12.4412C8.17063 14.3318 9.55178 16.1634 9.74377 16.4218C9.93577 16.6804 12.4099 20.6907 16.3168 22.2343C19.5665 23.5194 20.2313 23.2608 20.9328 23.1944C21.6418 23.1279 23.2076 22.2638 23.5325 21.3628C23.85 20.4618 23.85 19.6937 23.754 19.5312C23.658 19.3686 23.3996 19.2728 23.0155 19.0806C22.6315 18.8886 20.7408 17.9581 20.3863 17.8252C20.0318 17.6923 19.7733 17.6332 19.5222 18.0172C19.2637 18.4012 18.5252 19.2653 18.3035 19.5239C18.082 19.7823 17.8531 19.8119 17.469 19.6199C17.085 19.4279 15.8442 19.0215 14.3746 17.707C13.2298 16.6878 12.4543 15.4248 12.2326 15.0408C12.0111 14.6568 12.2106 14.4499 12.4026 14.258C12.5724 14.0881 12.7866 13.8074 12.9787 13.5859C13.1707 13.3643 13.2371 13.2019 13.3627 12.9433C13.4882 12.6848 13.4291 12.4633 13.3331 12.2713C13.2371 12.0792 12.4839 10.1811 12.1441 9.4205Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_whatsapp"
            x1="15.9291"
            y1="30.9979"
            x2="15.9291"
            y2="0.998199"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#20B038" />
            <stop offset="1" stopColor="#60D66A" />
          </linearGradient>
        </defs>
      </>
    )}
  </CreateSvgIcon>
);
