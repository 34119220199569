import React from 'react';
import classNames from 'classnames/bind';

import Block from 'ui/components/Block';
import Title from 'ui/components/Title';
import Button from 'ui/components/Button';
import { Text } from 'components/text';

import { ReactComponent as ModerationRejectIcon } from './moderation-reject.svg';
import { ReactComponent as ModerationInProgressIcon } from './moderation-in-progress.svg';

import styles from './ModerationBlock.module.css';

const cx = classNames.bind(styles);

interface ModerationBlockProps {
  status: 'rejected' | 'inactive';
}

const ModerationBlock = ({ status }: ModerationBlockProps) => (
  <Block className={styles.moderationBlock}>
    {status === 'rejected' && (
      <>
        <Title>
          Вы&nbsp;не&nbsp;прошли модерацию
        </Title>

        <ModerationRejectIcon />
        <br />

        <p>
          <Text>
            К&nbsp;сожалению вы&nbsp;не&nbsp;прошли модерацию.
            Если вы&nbsp;считаете это ошибкой, обратитесь в&nbsp;поддержку.
          </Text>
        </p>
      </>
    )}

    {status === 'inactive' && (
      <>
        <Title>
          Ваш профиль на&nbsp;модерации.
        </Title>

        <ModerationInProgressIcon className={styles.loaderSpin} />
        <br />

        <p>
          <Text>
            Доступ к&nbsp;функциям откроется после завершения проверки.
          </Text>
        </p>
      </>
    )}

    <div className={styles.button}>
      <Button
        component="link"
        href="/support"
        target="_blank"
        theme="blue-full"
      >
        {status === 'inactive'
          ? 'Ускорить модерацию'
          : 'Перейти в поддержку'}
      </Button>
    </div>

    <p className={cx('warning-message')}>
      <Text>
        Модерация нужна, чтобы создать качественную экосистему.
        Мы&nbsp;не&nbsp;пропускаем недействующих и&nbsp;недобросовестных риэлторов,
        чтобы&nbsp;вы работали в&nbsp;кругу профессионалов.
      </Text>
    </p>
  </Block>
);

export default ModerationBlock;
