import React from 'react';
import { CreateSvgIcon } from '../icon';
import { IconProps } from '../names';

export const ArrowInCircle = (props: IconProps) => (
  <CreateSvgIcon {...props}>
    {({ color }) => (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.5 30.9375C20.3291 30.9375 24.0013 29.4164 26.7089 26.7089C29.4164 24.0013 30.9375 20.3291 30.9375 16.5C30.9375 12.6709 29.4164 8.9987 26.7089 6.29115C24.0013 3.58359 20.3291 2.0625 16.5 2.0625C12.6709 2.0625 8.9987 3.58359 6.29115 6.29115C3.58359 8.9987 2.0625 12.6709 2.0625 16.5C2.0625 20.3291 3.58359 24.0013 6.29115 26.7089C8.9987 29.4164 12.6709 30.9375 16.5 30.9375ZM16.5 33C20.8761 33 25.0729 31.2616 28.1673 28.1673C31.2616 25.0729 33 20.8761 33 16.5C33 12.1239 31.2616 7.92709 28.1673 4.83274C25.0729 1.73839 20.8761 0 16.5 0C12.1239 0 7.92709 1.73839 4.83274 4.83274C1.73839 7.92709 0 12.1239 0 16.5C0 20.8761 1.73839 25.0729 4.83274 28.1673C7.92709 31.2616 12.1239 33 16.5 33Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.7695 23.4176V16.6656V11.0426C16.7695 9.5824 16.7695 11.0426 16.7695 9.5824C16.8654 9.48651 16.9792 9.41046 17.1045 9.35857C17.2298 9.30668 17.3641 9.27997 17.4997 9.27997C17.6353 9.27997 17.7695 9.30668 17.8948 9.35857C18.0201 9.41046 18.1339 9.48651 18.2298 9.5824L24.4173 15.7699C24.5133 15.8657 24.5895 15.9795 24.6415 16.1048C24.6935 16.2301 24.7202 16.3644 24.7202 16.5C24.7202 16.6357 24.6935 16.77 24.6415 16.8953C24.5895 17.0206 24.5133 17.1344 24.4173 17.2301L18.2298 23.4176C18.134 23.5137 18.0202 23.5899 17.8949 23.6419C17.7696 23.6939 17.6353 23.7206 17.4997 23.7206C17.364 23.7206 17.2297 23.6939 17.1044 23.6419C16.9791 23.5899 16.8653 23.5137 16.7695 23.4176Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.28125 16.5C6.28125 16.2265 6.3899 15.9642 6.5833 15.7708C6.77669 15.5774 7.039 15.4688 7.3125 15.4688H17.625C17.8985 15.4688 18.1608 15.5774 18.3542 15.7708C18.5476 15.9642 18.6562 16.2265 18.6562 16.5C18.6562 16.7735 18.5476 17.0358 18.3542 17.2292C18.1608 17.4226 17.8985 17.5312 17.625 17.5312H7.3125C7.039 17.5312 6.77669 17.4226 6.5833 17.2292C6.3899 17.0358 6.28125 16.7735 6.28125 16.5Z"
          fill={color}
        />
      </>
    )}
  </CreateSvgIcon>
);
