import React from 'react';
import { CreateSvgIcon } from '../icon';
import { IconProps } from '../names';

export const Video = (props: IconProps) => (
  <CreateSvgIcon {...props}>
    {() => (
      <>
        <path
          d="M30 24.5714V7.42857C30 5.54286 28.5176 4 26.7059 4H5.29412C3.48235 4 2 5.54286 2 7.42857V24.5714C2 26.4571 3.48235 28 5.29412 28H26.7059C28.5176 28 30 26.4571 30 24.5714ZM11.8824 22.8571V9.14286L21.7647 16L11.8824 22.8571Z"
          fill="url(#paint0_video)"
        />
        <defs>
          <linearGradient
            id="paint0_video"
            x1="16"
            y1="4"
            x2="16"
            y2="28"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#136FFB" />
            <stop offset="1" stopColor="#2EC6F7" />
          </linearGradient>
        </defs>
      </>
    )}
  </CreateSvgIcon>
);
