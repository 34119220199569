import React from 'react';
import { CreateSvgIcon } from '../icon';
import { IconProps } from '../names';

export const Support = (props: IconProps) => (
  <CreateSvgIcon {...props}>
    {({ color }) => (
      <>
        <circle cx="14.5" cy="14.5" r="13" stroke={color} strokeWidth="3" />
        <path
          d="M13.0762 18.2383C13.0762 17.459 13.1729 16.8379 13.3662 16.375C13.5596 15.9121 13.9404 15.4053 14.5088 14.8545C15.083 14.2979 15.4463 13.9023 15.5986 13.668C15.833 13.3105 15.9502 12.9238 15.9502 12.5078C15.9502 11.957 15.8125 11.5381 15.5371 11.251C15.2676 10.958 14.8691 10.8115 14.3418 10.8115C13.8379 10.8115 13.4307 10.9551 13.1201 11.2422C12.8154 11.5234 12.6631 11.9072 12.6631 12.3936H10.5273C10.5391 11.3564 10.8906 10.5361 11.582 9.93262C12.2793 9.3291 13.1992 9.02734 14.3418 9.02734C15.5195 9.02734 16.4365 9.32617 17.0928 9.92383C17.7549 10.5215 18.0859 11.3564 18.0859 12.4287C18.0859 13.3838 17.6406 14.3242 16.75 15.25L15.6689 16.3135C15.2822 16.7529 15.083 17.3945 15.0713 18.2383H13.0762ZM12.9268 20.9717C12.9268 20.626 13.0352 20.3477 13.252 20.1367C13.4688 19.9199 13.7617 19.8115 14.1309 19.8115C14.5059 19.8115 14.8018 19.9229 15.0186 20.1455C15.2354 20.3623 15.3438 20.6377 15.3438 20.9717C15.3438 21.2939 15.2383 21.5635 15.0273 21.7803C14.8164 21.9971 14.5176 22.1055 14.1309 22.1055C13.7441 22.1055 13.4453 21.9971 13.2344 21.7803C13.0293 21.5635 12.9268 21.2939 12.9268 20.9717Z"
          fill={color}
        />
      </>
    )}
  </CreateSvgIcon>
);
