import React from 'react';

import styles from './Switch.module.css';

interface Props {
  checked: boolean;
  onChange: (checked: boolean) => void;
  theme?: 'default' | 'color';
}

const Switch = ({ onChange, checked, theme = 'default' }: Props) => {
  const onClick = () => {
    onChange(!checked);
  };

  return (
    <div
      className={`
        ${styles.switch}
        ${theme === 'color' && styles.themeColor}
        ${checked && styles.checked}
      `}
      onClick={onClick}
    >
      <div className={styles.button} />
    </div>
  );
};

export default Switch;
