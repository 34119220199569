import { EnumApartment } from '../_enums';
import { declOfNum } from './declOfNum';

interface Props {
  area: number;
  land_area: number;
  type: EnumApartment;
}

export const getArea = ({ area, type, land_area }: Props): string => {
  let unit = 'м²';
  let currentArea = area;

  if (type === EnumApartment.Land) {
    unit = declOfNum(land_area, [' сотка', ' сотки', ' соток']);
    currentArea = land_area;
  }

  return `${currentArea}${unit}`;
};
