import React, { useContext, useState } from 'react';
import Media from 'react-media';

import { ObjectServerProps } from '_types';
import { getArea, getApartmentSize, getFormattedPrice } from '_helpers';

import Picture from 'ui/components/Picture';
import Button from 'ui/components/Button';
import Modal from 'ui/components/Modal';

import { ReactComponent as CloseIcon } from 'assets/svg/Close.svg';
import { ReactComponent as HeartIcon } from 'assets/svg/Heart.svg';

import { Icons } from 'ui/shared';
import { useParams } from '../../pages/objects/_helpers/add-params';
import { URLParams } from '../../pages/objects/_types/urlParams';
import { ApplicationContext } from '../../../global-stores/application-context';
import styles from './Apartment.module.css';

interface ApartmentProps extends ObjectServerProps {
  selectedId: number;
  onDelete: (adsId: number) => Promise<any>;
}

const Apartment = ({
  id,
  photos,
  city,
  region,
  street,
  home_number,
  price,
  owner_price,
  rooms,
  type,
  floors_number,
  area,
  land_area,
  like,
  onDelete,
  user,
}: ApartmentProps) => {
  const applicationContext = useContext(ApplicationContext);

  const [params] = useParams<URLParams>();

  const [error, setError] = useState(false);
  const [animate, setAnimate] = useState(false);

  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false);

  const onClickOnRemoveButton = () => {
    setIsOpenRemoveModal(true);
  };

  const onClickOnRemoveButtonClose = () => {
    setIsOpenRemoveModal(false);
  };

  const handleDeleteClick = () => {
    onDelete(id)
      .then(() => {
        setIsOpenRemoveModal(false);
        setAnimate(false);
        setError(false);
      }).catch(() => {
        setAnimate(true);
        setError(true);

        setTimeout(() => {
          setAnimate(false);
          setError(false);
        }, 1000);
      });
  };

  return (
    <div className={styles.apartment}>
      <div className={styles.picture}>
        <Picture src={photos[0]?.medium} alt="" />

        {like && (
          <HeartIcon className={styles.like} />
        )}
      </div>

      <p className={styles.title}>
        {[city.name, region?.name, street, home_number].filter(Boolean).join(', ')}
      </p>

      <p className={styles.apartmentSize}>
        {getApartmentSize({ rooms, type, floors_number })}
        {!!area && ` / ${getArea({ area, land_area, type })}`}
      </p>

      <Media query="(min-width: 768px)">
        <>
          <div className={styles.commission}>
            {!params?.withClient && owner_price ? getFormattedPrice(price - owner_price) : ''}
          </div>

          <div className={styles.ownerPrice}>
            {!params?.withClient && owner_price ? getFormattedPrice(owner_price) : ''}
          </div>
        </>
      </Media>

      <div className={styles.price}>
        {getFormattedPrice(price)}
      </div>

      <div className={styles.controls}>
        {user.www_link && (
          <a className={styles.avatar} href={params.withClient ? applicationContext?.currentUser?.www_link : user.www_link} target="_blank" rel="noreferrer">
            {params.withClient && applicationContext?.currentUser?.avatar?.original && (
              <img src={applicationContext?.currentUser?.avatar?.original} alt="" />
            )}

            {!params.withClient && user?.avatar?.original && (
              <img src={user?.avatar?.original} alt="" />
            )}

            {((params.withClient && !applicationContext?.currentUser?.avatar?.original)
             || (!params.withClient && !user?.avatar?.original)) && (
             <Icons.Person height={15} width={13} viewBox="0 0 29 25" />
            )}
          </a>
        )}

        <button
          className={styles.closeButton}
          onClick={onClickOnRemoveButton}
          type="button"
        >
          <CloseIcon />
        </button>

        {
          isOpenRemoveModal && (
            <Modal
              onClose={onClickOnRemoveButtonClose}
              error={error}
              animate={animate}
            >
              <Modal.Body>
                Вы уверены, что хотите удалить объект из подборки?
              </Modal.Body>

              <Modal.Buttons>
                <Button
                  component="button"
                  onClick={onClickOnRemoveButtonClose}
                  theme="black"
                >
                  Отменить
                </Button>

                <Button
                  component="button"
                  theme="red"
                  onClick={handleDeleteClick}
                >
                  Удалить
                </Button>
              </Modal.Buttons>
            </Modal>
          )
        }
      </div>
    </div>
  );
};

export default Apartment;
