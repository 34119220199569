import React from 'react';
import { CreateSvgIcon } from '../icon';
import { IconProps } from '../names';

export const Calendar = (props: IconProps) => (
  <CreateSvgIcon {...props}>
    {({ color }) => (
      <>
        <path
          d="M25.8746 4.00024H23.1662V5.66691H25.6662V24.0002H2.3329V5.66691H4.8329V4.00024H2.12456C1.92976 4.0035 1.73751 4.0451 1.55879 4.12267C1.38006 4.20024 1.21837 4.31225 1.08295 4.45232C0.947524 4.59239 0.841019 4.75776 0.769518 4.939C0.698017 5.12023 0.662919 5.31378 0.66623 5.50858V24.1586C0.662919 24.3534 0.698017 24.5469 0.769518 24.7282C0.841019 24.9094 0.947524 25.0748 1.08295 25.2148C1.21837 25.3549 1.38006 25.4669 1.55879 25.5445C1.73751 25.6221 1.92976 25.6637 2.12456 25.6669H25.8746C26.0694 25.6637 26.2616 25.6221 26.4403 25.5445C26.6191 25.4669 26.7808 25.3549 26.9162 25.2148C27.0516 25.0748 27.1581 24.9094 27.2296 24.7282C27.3011 24.5469 27.3362 24.3534 27.3329 24.1586V5.50858C27.3362 5.31378 27.3011 5.12023 27.2296 4.939C27.1581 4.75776 27.0516 4.59239 26.9162 4.45232C26.7808 4.31225 26.6191 4.20024 26.4403 4.12267C26.2616 4.0451 26.0694 4.0035 25.8746 4.00024Z"
          fill={color}
        />
        <path d="M5.6665 10.667H7.33317V12.3337H5.6665V10.667Z" fill={color} />
        <path d="M10.667 10.667H12.3337V12.3337H10.667V10.667Z" fill={color} />
        <path
          d="M15.6665 10.667H17.3332V12.3337H15.6665V10.667Z"
          fill={color}
        />
        <path
          d="M20.6665 10.667H22.3332V12.3337H20.6665V10.667Z"
          fill={color}
        />
        <path d="M5.6665 14.8334H7.33317V16.5H5.6665V14.8334Z" fill={color} />
        <path d="M10.667 14.8334H12.3337V16.5H10.667V14.8334Z" fill={color} />
        <path d="M15.6665 14.8334H17.3332V16.5H15.6665V14.8334Z" fill={color} />
        <path d="M20.6665 14.8334H22.3332V16.5H20.6665V14.8334Z" fill={color} />
        <path
          d="M5.6665 19.0002H7.33317V20.6669H5.6665V19.0002Z"
          fill={color}
        />
        <path
          d="M10.667 19.0002H12.3337V20.6669H10.667V19.0002Z"
          fill={color}
        />
        <path
          d="M15.6665 19.0002H17.3332V20.6669H15.6665V19.0002Z"
          fill={color}
        />
        <path
          d="M20.6665 19.0002H22.3332V20.6669H20.6665V19.0002Z"
          fill={color}
        />
        <path
          d="M7.33333 7.33341C7.55435 7.33341 7.76631 7.24562 7.92259 7.08934C8.07887 6.93306 8.16667 6.72109 8.16667 6.50008V1.50008C8.16667 1.27907 8.07887 1.06711 7.92259 0.910826C7.76631 0.754545 7.55435 0.666748 7.33333 0.666748C7.11232 0.666748 6.90036 0.754545 6.74408 0.910826C6.5878 1.06711 6.5 1.27907 6.5 1.50008V6.50008C6.5 6.72109 6.5878 6.93306 6.74408 7.08934C6.90036 7.24562 7.11232 7.33341 7.33333 7.33341Z"
          fill={color}
        />
        <path
          d="M20.6668 7.33341C20.8878 7.33341 21.0998 7.24562 21.2561 7.08934C21.4124 6.93306 21.5002 6.72109 21.5002 6.50008V1.50008C21.5002 1.27907 21.4124 1.06711 21.2561 0.910826C21.0998 0.754545 20.8878 0.666748 20.6668 0.666748C20.4458 0.666748 20.2339 0.754545 20.0776 0.910826C19.9213 1.06711 19.8335 1.27907 19.8335 1.50008V6.50008C19.8335 6.72109 19.9213 6.93306 20.0776 7.08934C20.2339 7.24562 20.4458 7.33341 20.6668 7.33341Z"
          fill={color}
        />
        <path
          d="M9.83301 4.00024H18.1663V5.66691H9.83301V4.00024Z"
          fill={color}
        />
      </>
    )}
  </CreateSvgIcon>
);
