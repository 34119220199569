import React from 'react';
import { EditAboutMe } from 'ui/pages/profile/components/edit-about-me';
import { EditAboutMeContainerProps } from './types';

export const EditAboutMeContainer = ({
  userProperties,
  updateDataByKey,
  onChangeUserProperty,
}: EditAboutMeContainerProps) => {
  console.log(userProperties);
  const handleChangeAboutMe = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length <= 400) {
      onChangeUserProperty('description')(e);
    }
  };

  const handleChangeInterests = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length <= 400) {
      onChangeUserProperty('interests')(e);
    }
  };

  return (
    <EditAboutMe
      userProperties={userProperties}
      updateDataByKey={updateDataByKey}
      onChangeUserProperty={onChangeUserProperty}
      onChangeAboutMe={handleChangeAboutMe}
      onChangeInterests={handleChangeInterests}
    />
  );
};
