import React from 'react';

import styles from './context-menu-group.module.css';

interface ContextMenuGroupProps {
  children: React.ReactNode;
  title?: string;
}

export const ContextMenuGroup = ({ children, title }: ContextMenuGroupProps) => (
  <div className={styles['context-menu-group']}>
    {title && (
      <p className={styles['context-menu-title']}>
        {title}
      </p>
    )}
    {children}
  </div>
);
