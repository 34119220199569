import React from 'react';
import classNames from 'classnames/bind';
import { Text } from 'components/text';
import { ReactComponent as ArrowUp } from './arrow-up.svg';
import { ReactComponent as ArrowDown } from './arrow-down.svg';
import styles from './styles.module.css';

const cx = classNames.bind(styles);

export type SortTypes = 'price' | 'price_for_meter' | 'area' | 'land_area' | 'updated_at';
export type SortDirections = 'asc' | 'desc';

type SortProps = {
  type: SortTypes | null;
  direction: SortDirections | null;
  onChange: (type: SortTypes, direction: SortDirections) => void;
};

export const Sort = ({
  type,
  direction,
  onChange,
}: SortProps) => {
  const handleChangeType = (sortType: SortTypes, sortDirection: SortDirections) => () => {
    if (sortType === type) {
      onChange(sortType, sortDirection);
    } else {
      onChange(sortType, 'desc');
    }
  };

  return (
    <div className={cx('sort')}>
      <button
        className={cx('button', {
          button_active: type === 'price',
        })}
        type="button"
        onClick={handleChangeType('price', direction === 'asc' ? 'desc' : 'asc')}
      >
        <Text size="s" color={type === 'price' ? 'blue' : 'grey'} semibold>
          По цене
        </Text>
        {type === 'price' && direction === 'asc' && (
          <ArrowUp className={cx('arrow')} />
        )}
        {type === 'price' && direction === 'desc' && (
          <ArrowDown className={cx('arrow')} />
        )}
      </button>

      <button
        className={cx('button', {
          button_active: type === 'price_for_meter',
        })}
        type="button"
        onClick={handleChangeType('price_for_meter', direction === 'asc' ? 'desc' : 'asc')}
      >
        <Text size="s" color={type === 'price_for_meter' ? 'blue' : 'grey'} semibold>
          По цене за метр
        </Text>
        {type === 'price_for_meter' && direction === 'asc' && (
          <ArrowUp className={cx('arrow')} />
        )}
        {type === 'price_for_meter' && direction === 'desc' && (
          <ArrowDown className={cx('arrow')} />
        )}
      </button>

      <button
        className={cx('button', {
          button_active: type === 'area',
        })}
        type="button"
        onClick={handleChangeType('area', direction === 'asc' ? 'desc' : 'asc')}
      >
        <Text size="s" color={type === 'area' ? 'blue' : 'grey'} semibold>
          По площади
        </Text>
        {type === 'area' && direction === 'asc' && (
          <ArrowUp className={cx('arrow')} />
        )}
        {type === 'area' && direction === 'desc' && (
          <ArrowDown className={cx('arrow')} />
        )}
      </button>

      <button
        className={cx('button', {
          button_active: type === 'updated_at',
        })}
        type="button"
        onClick={handleChangeType('updated_at', direction === 'asc' ? 'desc' : 'asc')}
      >
        <Text size="s" color={type === 'updated_at' ? 'blue' : 'grey'} semibold>
          По дате
        </Text>
        {type === 'updated_at' && direction === 'asc' && (
          <ArrowUp className={cx('arrow')} />
        )}
        {type === 'updated_at' && direction === 'desc' && (
          <ArrowDown className={cx('arrow')} />
        )}
      </button>
    </div>
  );
};
