import dayjs from 'dayjs';
import { declOfNum } from './declOfNum';

const isToday = (date: string): boolean => {
  const d = new Date(date);
  const today = new Date();

  return d.getDate() === today.getDate()
    && d.getMonth() === today.getMonth()
    && d.getFullYear() === today.getFullYear();
};

const isYesterday = (date: string): boolean => {
  const d = new Date(date);
  const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));

  return d.getDate() === yesterday.getDate()
    && d.getMonth() === yesterday.getMonth()
    && d.getFullYear() === yesterday.getFullYear();
};

const getTimeAgo = (date: string): string => {
  const d = new Date(date);
  const today = new Date();

  const values = [];

  if (d.getHours() !== today.getHours()) {
    const diff = today.getHours() - d.getHours();
    return `${diff} ${declOfNum(diff, ['час', 'часа', 'часов'])} назад`;
  }

  if (d.getMinutes() !== today.getMinutes()) {
    const diff = today.getMinutes() - d.getMinutes();
    return `${diff} ${declOfNum(diff, ['минута', 'минуты', 'минут'])} назад`;
  }

  if (d.getSeconds() !== today.getSeconds()) {
    const diff = today.getSeconds() - d.getSeconds();
    return `${diff} ${declOfNum(diff, ['секунда', 'секунды', 'секунд'])} назад`;
  }

  return 'Только что';
};

export const getAgoDate = (date: string): string => {
  if (date === '') {
    return '';
  }

  if (isToday(date)) {
    return getTimeAgo(date);
  }

  if (isYesterday(date)) {
    return 'Вчера';
  }

  return dayjs(date).format('DD.MM.YYYY');
};
