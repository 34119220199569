import React from 'react';
import cn from 'classnames';

import { getName, getAgentType } from '_helpers';
import { UserType } from 'interfaces';

import Avatar from 'ui/components/Avatar';

import { ReactComponent as PhoneIcon } from 'assets/svg/Phone.svg';
import { ReactComponent as EmailIcon } from 'assets/svg/Email.svg';
import { ReactComponent as WhatsAppIcon } from 'assets/svg/WhatsApp.svg';
import { ReactComponent as StarIcon } from 'assets/svg/Star.svg';

import styles from './CartOfUser.module.css';

interface CartOfUserProps {
  wwwLink: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  avatar?: string;
  evaluation: number;
  rating: number;
  agentType: UserType;
  licenceNumber?: string;
  otherLicences?: string;
  phone?: string;
  email?: string;
  whatsapp?: string;
}

const CartOfUser = ({
  firstName, lastName, middleName,
  avatar, evaluation, rating, agentType,
  licenceNumber, otherLicences,
  phone, email, whatsapp,
  wwwLink,
}: CartOfUserProps) => {
  const name = getName({ firstName, lastName, middleName });
  const typeOfAgent = getAgentType(agentType);

  return (
    <div className={styles.cart}>
      {name && (
        <p className={styles.name}>
          <a className={styles['name-link']} href={wwwLink} target="_blank" rel="noreferrer">
            {name}
          </a>
        </p>
      )}

      <div className={styles['cart-info']}>
        <div className={styles.avatar}>
          <Avatar
            src={avatar}
          />
        </div>

        <div>
          {(evaluation !== 0 || rating !== 0) && (
            <div className={styles.rating}>
              {evaluation !== 0 && (
                <p className={styles.evaluation}>
                  <span className={styles['evaluation-star']}>
                    <StarIcon />
                  </span>

                  {evaluation}<span className={styles['evaluation-from']}>/5</span>
                </p>
              )}

              {typeof rating !== 'undefined' && rating !== null && rating !== 0 && (
                <p>
                  Репутация <span className={styles.reputation}>{rating}</span>
                </p>
              )}
            </div>
          )}

          <p className={styles['agent-type']}>
            {typeOfAgent}
          </p>

          {
            licenceNumber && otherLicences && (
              <p className={styles.licence}>
                {otherLicences}<br />
                <span className={styles['other-licences']}>№ {licenceNumber}</span>
              </p>
            )
          }
        </div>
      </div>

      <p className={styles['user-socials']}>
        {phone && (
          <a className={styles['user-social']} href={`tel:${phone}`}>
            <span className={styles['social-icon']}>
              <PhoneIcon />
            </span>

            Позвонить
          </a>
        )}

        {email && (
          <a className={styles['user-social']} href={`mailto:${email}`}>
            <span className={styles['social-icon']}>
              <EmailIcon />
            </span>
            Email
          </a>
        )}

        {whatsapp && (
          <a className={cn(styles['user-social'], styles['user-social-whatsapp'])} href={`https://wa.me/${phone}`} target="_blank" rel="noreferrer">
            <span className={styles['social-icon']}>
              <WhatsAppIcon />
            </span>
            WhatsApp
          </a>
        )}
      </p>
    </div>
  );
};

export default CartOfUser;
