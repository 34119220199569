import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';

import { OptionProps } from '_types/Options';

import DropDown from 'ui/components/DropDown';

import styles from './Field.module.css';

const cx = classNames.bind(styles);

interface Props {
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  type?: 'text' | 'phone' | 'email' | 'number';
  theme?: 'filter' | 'small' | 'search';
  placeholder?: string;
  autoFocus?: boolean;
  options?: OptionProps[];
  selectedId?: number;
  onSelected?: (selectedId: number) => void;
  className?: string;
  readonly?: boolean;
}

const Field = (props: Props) => {
  const {
    onChange,
    onKeyUp,
    onBlur,
    value,
    placeholder,
    type = 'text',
    autoFocus = false,
    theme,
    options,
    selectedId,
    onSelected,
    className,
    readonly,
  } = props;

  const fieldRef = useRef<HTMLInputElement>(null);

  const [inFocus, setInFocus] = useState(false);

  useEffect(() => {
    const handleFocusField = () => {
      setInFocus(true);
    };

    const clickOutsideDropDown = (e: MouseEvent) => {
      const element = e.target as HTMLDivElement;

      if (!fieldRef.current?.contains(element)) {
        setInFocus(false);
      }
    };

    fieldRef.current?.addEventListener('focus', handleFocusField);

    document.documentElement.addEventListener('click', clickOutsideDropDown);

    const fieldRefCurrent = fieldRef.current;

    return () => {
      fieldRefCurrent?.removeEventListener('focus', handleFocusField);

      document.documentElement.removeEventListener('click', clickOutsideDropDown);
    };
  }, []);

  const onBlurHandle = () => {
    if (onBlur) {
      onBlur();
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (onKeyUp) {
      onKeyUp(e);
    }
  };

  const handleClickByOption = (id: number) => {
    if (onSelected) {
      onSelected(id);
      setInFocus(false);
    }
  };

  return (
    <div
      className={cx('wrap', className, {
        wrapSearch: theme === 'search',
      })}
    >
      <input
        className={cx('field', {
          fieldFilter: theme === 'filter',
          fieldSmall: theme === 'small',
          fieldFilterWithValue: theme === 'filter' && value,
          fieldSearch: theme === 'search',
          'field-with-dropdown': options && options?.length !== 0 && inFocus && !!value && typeof selectedId === 'number',
        })}
        style={{
          zIndex: inFocus ? 3 : 0,
        }}
        type={type}
        onChange={onChange}
        onBlur={onBlurHandle}
        onKeyUp={handleKeyUp}
        placeholder={placeholder}
        autoFocus={autoFocus}
        ref={fieldRef}
        readOnly={readonly}
        value={value || ''}
      />

      {
        (inFocus && !!value) && (
          <>
            {
              (options && typeof selectedId === 'number') && (
                <DropDown
                  theme={theme === 'search' ? 'search' : undefined}
                  selectedId={selectedId}
                  options={options}
                  onChange={handleClickByOption}
                />
              )
            }
          </>
        )
      }
    </div>
  );
};

export default Field;
