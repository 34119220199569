import React from 'react';
import { Theme } from 'lib/theme';
import { makeStyles } from 'hooks/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  bg: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: -1,
    background: '#FAFAFA',
  },
}));

export const Background = () => {
  const css = useStyles();

  return <div className={css.bg} />;
};
