import React, { useContext, useState } from 'react';
import {
  AnimatedPage,
  Avatar,
  Box,
  Button,
  DefaultPanel,
  EmptyContent,
  Icons,
  Modal,
  Typography,
} from 'ui/shared';
import { makeStyles } from 'hooks/makeStyles';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import { observer } from 'mobx-react';
import { WhatsAppModalBody, WhatsAppModalHeader } from './modals/whatsapp';
import { TelegramModalBody, TelegramModalHeader } from './modals/telegram';
import { ApplicationContext } from '../../../global-stores/application-context';
import { BackButton } from '../../components/layout/back-button';
import { Text } from '../../../components/text';

const useStyles = makeStyles((theme) => ({
  wrap: {
    display: 'flex',
    alignItems: 'center',
    padding: '56px 16px',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  container: {
    width: '100%',
    maxWidth: 359,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '&$animating': {
      overflowY: 'hidden',
    },
  },
  title: {},
  subtitle: {
    fontSize: 20,
  },
  avatarContainer: { position: 'relative' },
  avatar: { position: 'absolute', top: -59, left: 0 },
  emptyAvatar: {
    width: 113,
    height: 113,
  },
  wrapper: {
    paddingLeft: 137,
  },
  paper: {
    backgroundColor: theme.colors.background.paper.default,
    borderRadius: 10,
  },
  socialContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(4.25)} ${theme.spacing(2)} ${theme.spacing(4.25)} ${theme.spacing(
      2,
    )}`,
  },
  socialItem: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2.75),
    },
  },
  socialIcon: {
    display: 'inline-flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    flexGrow: 0,
  },
  socialLabel: {
    flexGrow: 1,
    fontSize: 20,
    color: theme.colors.textDarkGrey,
    marginLeft: theme.spacing(4.25),
  },
  socialAddButton: {
    flexGrow: 0,
    color: theme.colors.textSecondary,
    backgroundColor: theme.colors.blue.dark,
    borderRadius: 6,
    textTransform: 'capitalize',
    fontSize: 20,
    paddingLeft: theme.spacing(6.5),
    paddingRight: theme.spacing(6.5),
  },
  extraSocialContainer: {
    display: 'flex',
    padding: `${theme.spacing(4)} ${theme.spacing(5.75)} ${theme.spacing(4.25)} ${theme.spacing(
      5,
    )}`,
    '@media screen and (max-width: 768px)': {
      padding: `${theme.spacing(4)} ${theme.spacing(2.5)} ${theme.spacing(4.25)} ${theme.spacing(
        2.5,
      )}`,
    },
  },
  extraSocialWrap: {
    display: 'flex',
    flexDirection: 'column',
  },
  extraSocialItem: {
    display: 'inline-flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  extraSocialLabel: {
    flexGrow: 1,
    fontSize: 20,
    color: theme.colors.textDarkGrey,
    // marginLeft: theme.spacing(4.25)
  },
  icon: {
    minWidth: 43,
    maxWidth: 43,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(3.75),
    '@media screen and (max-width: 768px)': {
      marginRight: theme.spacing(0),
    },
  },
  button: {
    width: 230,
    height: 50,
    borderRadius: 6,
    fontSize: 22,
    padding: `${theme.spacing(2)}`,
    border: '2px solid #020202',
    color: '#020202',
    '&:hover, &:focus, &:active': {
      outline: 'none',
      borderColor: 'var(--color-blue)',
      color: 'var(--color-blue)',
    },
    '@media screen and (max-width: 768px)': {
      marginBottom: `${theme.spacing(22.5)}`,
    },
  },
  buttonSkip: {
    border: 'none',
    fontSize: 22,
    color: '#020202',
    marginBottom: `${theme.spacing(6)}`,
    cursor: 'pointer',
    '&:hover, &:focus, &:active': {
      outline: 'none',
      backgroundColor: 'transparent',
      color: 'var(--color-blue)',
    },
    '@media screen and (max-width: 768px)': {
      marginBottom: `${theme.spacing(22.5)}`,
    },
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  skeletonContainer: {
    padding: `${theme.spacing(4.5)} ${theme.spacing(4.5)} ${theme.spacing(4.5)} ${theme.spacing(
      4.5,
    )}`,
  },
  modalPanel: {
    width: '85%',
    maxWidth: 359,
    height: 'auto',
    '&:focus': {
      outline: 0,
    },
  },
}));

const SocialNetworks = observer(() => {
  const css = useStyles();
  const history = useHistory();
  const app = useContext(ApplicationContext);

  const [openWhatsappModal, setOpenWhatsappModal] = useState(false);
  const [openTelegramModal, setOpenTelegramModal] = useState(false);
  const [socialAccounts, setSocialAccounts] = useState({
    whatsapp: app.newUser?.whatsapp,
    telegram: app.newUser?.telegram,
  });

  const setSocialAccount = (name: string) => (e: any) => {
    e.persist();
    setSocialAccounts((prevState) => ({
      ...prevState,
      [name]: e.target.value,
    }));
  };
  const setWhatsApp = setSocialAccount('whatsapp');
  const setTelegram = setSocialAccount('telegram');

  const goToNextPage = () => history.push('/signup/aboutme');

  const saveAndGoNext = () => {
    app.updateNewUserProperties(socialAccounts);
    goToNextPage();
  };

  return (
    <div className={css.wrap}>
      <AnimatedPage animation="horizontal">
        <div className={css.container}>
          <BackButton />
          <Box mb={8.25} mt={9} pl={25}>
            <Text size="xl" as="p" semibold>
              Способы связи и социальные сети
            </Text>
          </Box>

          <Box mb={2.75} className={clsx(css.paper, css.skeletonContainer)}>
            <Box className={clsx(css.centered, css.avatarContainer)}>
              <Box className={css.avatar}>
                {app.newUser?.avatarSrc ? (
                  <Avatar.Big img={app.newUser?.avatarSrc} border />
                ) : (
                  <EmptyContent.Circle size="large" className={clsx(css.centered, css.emptyAvatar)}>
                    <Icons.Person height={29} width={25} viewBox="0 0 29 25" />
                  </EmptyContent.Circle>
                )}
              </Box>
              <Box className={clsx(css.column, css.wrapper)}>
                <EmptyContent.Line height={17} size="large" />
                <EmptyContent.Line width="60%" height={17} size="small" />
              </Box>
            </Box>
            <Box>
              <EmptyContent.Line width="80%" height={17} size="medium" />
            </Box>
            <Box className={css.row}>
              <EmptyContent.Square size="large" />
              <EmptyContent.Square size="large" />
              <EmptyContent.Square size="large" />
            </Box>
          </Box>

          <Box mb={6.25} className={clsx(css.paper, css.socialContainer)}>
            <Box className={css.socialItem}>
              <Box className={css.socialIcon}>
                <Icons.Whatsapp width={39} height={39} viewBox="0 0 32 32" />
                <Typography className={css.socialLabel} font="semibold">
                  WhatsApp
                </Typography>
              </Box>
              <Button className={css.socialAddButton} onClick={() => setOpenWhatsappModal(true)}>
                {socialAccounts.whatsapp ? 'Изменить' : 'Добавить'}
              </Button>
            </Box>
            <Box className={css.socialItem}>
              <Box className={css.socialIcon}>
                <Icons.Telegram width={39} height={30} viewBox="0 0 32 32" color="#32AFED" />
                <Typography className={css.socialLabel} font="semibold">
                  Telegram
                </Typography>
              </Box>
              <Button className={css.socialAddButton} onClick={() => setOpenTelegramModal(true)}>
                {socialAccounts.telegram ? 'Изменить' : 'Добавить'}
              </Button>
            </Box>
          </Box>

          <Box mb={6.25}>
            <Text size="l" as="p" align="center" semibold>
              Другие способы связи сможете добавить далее в личном кабинете
            </Text>
          </Box>

          <Box mb={12.75} className={clsx(css.paper, css.extraSocialContainer)}>
            <Box className={css.extraSocialWrap}>
              <Box mb={4} className={css.extraSocialItem}>
                <Box className={css.icon}>
                  <Icons.Facebook width={32} height={32} viewBox="0 0 32 32" color="#0084FF" />
                </Box>
                <Box>
                  <Typography className={css.extraSocialLabel} font="semibold">
                    Facebook
                  </Typography>
                </Box>
              </Box>
              <Box className={css.extraSocialItem}>
                <Box className={css.icon}>
                  <Icons.Instagram width={32} height={32} viewBox="0 0 32 32" />
                </Box>
                <Box>
                  <Typography className={css.extraSocialLabel} font="semibold">
                    Instagram
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className={css.extraSocialWrap}>
              <Box mb={4} className={css.extraSocialItem}>
                <Box className={css.icon}>
                  <Icons.Youtube width={32} height={32} viewBox="0 0 32 32" color="#FF0000" />
                </Box>
                <Box>
                  <Typography className={css.extraSocialLabel} font="semibold">
                    Youtube
                  </Typography>
                </Box>
              </Box>
              <Box className={css.extraSocialItem}>
                <Box className={css.icon}>
                  <Icons.Vkontakte width={32} height={32} viewBox="0 0 32 32" color="#4680C2" />
                </Box>
                <Box>
                  <Typography className={css.extraSocialLabel} font="semibold">
                    Vkontakte
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className={css.centered}>
            <Button secondary className={css.button} onClick={saveAndGoNext}>
              Далее
            </Button>
          </Box>
          <Box className={css.centered}>
            <Button secondary className={css.buttonSkip} onClick={goToNextPage}>
              Пропустить
            </Button>
          </Box>

          <Modal
            open={openWhatsappModal}
            onClose={() => setOpenWhatsappModal(false)}
            withCloseButton={false}
          >
            <DefaultPanel header={() => <WhatsAppModalHeader />} className={css.modalPanel}>
              <WhatsAppModalBody
                value={socialAccounts.whatsapp || ''}
                onChange={setWhatsApp}
                placeholder="79000000000"
                onClose={() => setOpenWhatsappModal(false)}
              />
            </DefaultPanel>
          </Modal>

          <Modal
            open={openTelegramModal}
            onClose={() => setOpenTelegramModal(false)}
            withCloseButton={false}
          >
            <DefaultPanel header={() => <TelegramModalHeader />} className={css.modalPanel}>
              <TelegramModalBody
                value={socialAccounts.telegram || ''}
                onChange={setTelegram}
                placeholder="example"
                onClose={() => setOpenTelegramModal(false)}
              />
            </DefaultPanel>
          </Modal>
        </div>
      </AnimatedPage>
    </div>
  );
});

export default SocialNetworks;
