import axios from 'axios';
import Config from 'config';
import suggestItemsJSON from './suggestItems.json';

interface ISearchAttributes {
  suggested_text: string
}
interface IAdmDiv {
  id: string,
  name: string,
  type: string
}
interface ISuggestItem {
  id: number
  full_name: string
  address_name: string
  name: string
  type: string
  subtype: string
  search_attributes: ISearchAttributes,
  adm_div: IAdmDiv[],
  suggested_text: string
}

export type SuggestItem = ISuggestItem;

export const searchCities = async (q: string) => {
  const token = localStorage.getItem('token');

  const { data } = await axios(`${Config.API_URL}/api/v1/location/cities_search`, {
    method: 'GET',
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      q,
      limit: 10,
    },
  });

  return data.cities || [];
};

const prepareSuggestText = (suggest: SuggestItem) => {
  if (suggest.type === 'adm_div') {
    if (['district', 'living_area', 'settlement'].includes(suggest.subtype)) {
      return suggest.name;
    }
  }

  if (suggest.type === 'street') {
    return suggest.name;
  }

  const admDivNames = suggest.adm_div
    .filter(({ type }) => ['district', 'living_area', 'settlement'].includes(type))
    .map(({ name }) => name);
  return admDivNames.concat([suggest.address_name]).filter((n) => n).join(', ');
};

export const suggestAddress = async (q: string) => {
  const token = localStorage.getItem('token');

  const { data } = await axios(`${Config.API_URL}/api/v3/geocoding/suggests`, {
    method: 'GET',
    headers: {
      Authorization: `Token ${token}`,
    },
    params: { q },
  });

  // @ts-ignore
  return data.result.items
    .filter((item: SuggestItem) => (
      item.subtype !== 'place'
    ))
    .map((item: SuggestItem) => ({
      id: item.id,
      full_name: item.full_name,
      name: item.name,
      adm_div: item.adm_div,
      suggested_text: prepareSuggestText(item),
      // suggested_text: item.search_attributes.suggested_text,
    })) || [];
};

export const getUserCities = async () => {
  const token = localStorage.getItem('token');

  const { data } = await axios(`${Config.API_URL}/api/v1/location/cities`, {
    method: 'GET',
    headers: {
      Authorization: `Token ${token}`,
    },
  });

  return data.locations || [];
};

export const getTerritorialCertificationAuthorities = async () => {
  const token = localStorage.getItem('token');

  const { data } = await axios(`${Config.API_URL}/api/v3/territorial_certification_authorities`, {
    method: 'GET',
    headers: {
      Authorization: `Token ${token}`,
    },
  });

  return data.territorial_certification_authorities || [];
};
