import React, { memo, useContext, useState } from 'react';
import { Button, Typography } from '../../ui/shared';
import Field from '../../ui/components/Field';
import { Text } from '../text';
import { ApplicationContext } from '../../global-stores/application-context';
import { makeStyles } from 'hooks/makeStyles';
import { Theme } from '../../lib/theme';

const useStyles = makeStyles((theme: Theme) => ({
  paymentBtn: {
    borderRadius: 10,
    color: theme.colors.textSecondary,
    textTransform: 'none',
    height: 40,
    padding: `${theme.spacing(2.25)} ${theme.spacing(4)}`,
  },
}));

const PromocodeForm = memo(() => {
  const css = useStyles();
  const app = useContext(ApplicationContext);
  const [promocode, setPromocode] = useState(null as any);

  const handleApplyPromocode = () => {
    // sendMetrik('reachGoal', 'ApplyPromocodeMenuButtonClick');
    app.applyPromocode(promocode);
  };

  const handlePromocode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPromocode(e.target.value);
    e.target.focus();
  };

  return (
    <div style={{ paddingTop: '20px' }}>
      <Text size="m" semibold>
        Применить промокод
      </Text>
      <div style={{ paddingTop: '10px', display: 'flex' }}>
        <Field value={promocode} onChange={handlePromocode} placeholder="Промокод" />
        <Button
          style={{ marginLeft: '5px' }}
          className={css.paymentBtn}
          onClick={handleApplyPromocode}
        >
          <Text size="s" semibold>Применить</Text>
        </Button>
      </div>
    </div>
  );
});

export default PromocodeForm;
