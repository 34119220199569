import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';

import { ObjectServerProps, PhotoProps } from '_types';

import { ReactComponent as GalleryPlaceholderIcon } from 'assets/svg/gallery-placeholder-icon.svg';

import PhotoGallery from 'ui/components/PhotoGallery';

import { ReactComponent as SlideArrowIcon } from './SlideArrow.svg';

import styles from './HeaderGallery.module.css';

interface HeaderGalleryProps extends ObjectServerProps {
  className?: string;
}

const HeaderGallery = ({ photos, className }: HeaderGalleryProps) => {
  const [mobile, setMobile] = useState(false);
  const [slideNum, setSlideNum] = useState(0);
  const [slides, setSlides] = useState([] as PhotoProps[][]);
  const [inTouch, setInTouch] = useState(false);
  const [touchStartPosition, setTouchStartPosition] = useState(0);
  const [touchEndPosition, setTouchEndPosition] = useState(0);
  const [translate, setTranslate] = useState('0%');
  const [selectedSliderPhoto, setSelectedSliderPhoto] = useState(null as null | number);

  const getTranslate = useCallback(() => `-${slideNum * 100}%`, [slideNum]);

  useEffect(() => {
    setTranslate(getTranslate());
  }, [slideNum, inTouch, getTranslate]);

  useEffect(() => {
    const onResize = () => {
      setMobile(window.innerWidth <= 768);
    };

    onResize();

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    setSlideNum(0);
  }, [mobile]);

  useEffect(() => {
    const gallery = [...photos];
    const currentSlides = [];

    while (gallery.length > 0) {
      if (!mobile) {
        currentSlides.push(gallery.splice(0, gallery.length === photos.length ? 5 : 8));
      } else {
        currentSlides.push(gallery.splice(0, 1));
      }
    }

    setSlides(currentSlides);
  }, [photos, mobile]);

  const onNextSlide = () => {
    setSlideNum(slideNum < slides.length - 1 ? slideNum + 1 : 0);
  };

  const onPrevSlide = () => {
    setSlideNum(slideNum > 0 ? slideNum - 1 : slides.length - 1);
  };

  const onTouchStart = (e: React.TouchEvent) => {
    setInTouch(true);
    setTouchStartPosition(e.touches[0].clientX);
  };

  const onTouchEnd = () => {
    if (
      touchEndPosition - touchStartPosition < 0
      && touchEndPosition - touchStartPosition < document.documentElement.clientWidth * -0.1
      && slideNum < slides.length - 1
    ) {
      setSlideNum(slideNum + 1);
    }
    if (
      touchEndPosition - touchStartPosition > 0
      && touchEndPosition - touchStartPosition > document.documentElement.clientWidth * 0.1
      && slideNum > 0
    ) {
      setSlideNum(slideNum - 1);
    }

    setInTouch(false);
  };

  const onTouchMove = (e: React.TouchEvent) => {
    if (inTouch) {
      setTouchEndPosition(e.touches[0].clientX);
      setTranslate(`calc(${getTranslate()} + ${e.touches[0].clientX - touchStartPosition}px)`);
    }
  };

  const handlerClickOnPhoto = (photo: number) => {
    setSelectedSliderPhoto(photo);
  };

  const handlerClickOnGalleryClose = () => {
    setSelectedSliderPhoto(null);
  };

  return (
    <div className={cn(styles.wrap, className)}>
      {slides.length === 0 && (
        <div className={styles.placeholder}>
          <GalleryPlaceholderIcon className={styles['placeholder-image']} />
          Нет фотографий
        </div>
      )}

      {slides.length > 0 && (
        <>
          <ul
            className={`
              ${styles.gallery}
              ${inTouch && styles.galleryInTouch}
            `}
            style={{
              transform: `translateX(${translate})`,
            }}
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
            onTouchMove={onTouchMove}
          >
            {
              slides.map((slide, deepIndex) => (
                <li key={`${slide.length}-${slide[0].original}`} className={styles.slide}>
                  {
                    slide.map((photo: PhotoProps, photoIndex) => (
                      <div
                        key={photo.original}
                        className={styles.image}
                      >
                        <div
                          className={styles.imageLink}
                          onClick={() => handlerClickOnPhoto(
                            slides.slice(0, deepIndex).flat().length + photoIndex,
                          )}
                        >
                          <img
                            src={photo.original}
                            alt=""
                          />
                        </div>
                      </div>
                    ))
                  }
                </li>
              ))
            }
          </ul>

          <button
            className={`
              ${styles.slideButton}
              ${styles.prevSlideButton}
            `}
            type="button"
            onClick={onPrevSlide}
            aria-label="Предыдущий слайд"
          >
            <SlideArrowIcon />
          </button>

          <button
            className={`
              ${styles.slideButton}
              ${styles.nextSlideButton}
            `}
            type="button"
            onClick={onNextSlide}
            aria-label="Следующий слайд"
          >
            <SlideArrowIcon />
          </button>

          <div className={styles.count}>
            {slideNum + 1} / {slides.length}
          </div>

          <PhotoGallery
            open={selectedSliderPhoto !== null}
            selectedPhotoIndex={selectedSliderPhoto}
            photos={slides}
            onClose={handlerClickOnGalleryClose}
          />
        </>
      )}
    </div>
  );
};

export default HeaderGallery;
