export function hexToRgb(color: any) {
  const substrColor = color.substr(1);

  const re = new RegExp(`.{1,${substrColor.length >= 6 ? 2 : 1}}`, 'g');
  let colors = substrColor.match(re);

  if (colors && colors[0].length === 1) {
    colors = colors.map((n: any) => n + n);
  }

  return colors
    ? `rgb${colors.length === 4 ? 'a' : ''}(${colors
      .map((n: any, index: any) => (
        index < 3 ? parseInt(n, 16) : Math.round((parseInt(n, 16) / 255) * 1000) / 1000
      ))
      .join(', ')})`
    : '';
}

export function decomposeColor(color: any): any {
  if (color.type) {
    return color;
  }

  if (color.charAt(0) === '#') {
    return decomposeColor(hexToRgb(color));
  }

  const marker = color.indexOf('(');
  const type = color.substring(0, marker);

  if (['rgb', 'rgba', 'hsl', 'hsla'].indexOf(type) === -1) {
    throw new Error(
      [
        `Unsupported \`${color}\` color.`,
        'We support the following formats: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla().',
      ].join('\n'),
    );
  }

  let values = color.substring(marker + 1, color.length - 1).split(',');
  values = values.map((value: any) => parseFloat(value));

  return { type, values };
}

export function recomposeColor(color: any) {
  const { type } = color;
  let { values } = color;

  if (type.indexOf('rgb') !== -1) {
    // Only convert the first 3 values to int (i.e. not alpha)
    values = values.map((n: any, i: any) => (i < 3 ? parseInt(n, 10) : n));
  } else if (type.indexOf('hsl') !== -1) {
    values[1] = `${values[1]}%`;
    values[2] = `${values[2]}%`;
  }

  return `${type}(${values.join(', ')})`;
}

function clamp(value: any, min = 0, max = 1) {
  if (value < min || value > max) {
    console.error(`The value provided ${value} is out of range [${min}, ${max}].`);
  }

  return Math.min(Math.max(min, value), max);
}

export function fade(color: any, value: any) {
  const decomposedColor = decomposeColor(color);
  const clampValue = clamp(value);

  if (decomposedColor.type === 'rgb' || decomposedColor.type === 'hsl') {
    decomposedColor.type += 'a';
  }
  decomposedColor.values[3] = clampValue;

  return recomposeColor(decomposedColor);
}

export function lighten(color: any, coefficient: any) {
  const decomposedColor = decomposeColor(color);
  const clampCoefficient = clamp(coefficient);

  if (decomposedColor.type.indexOf('hsl') !== -1) {
    decomposedColor.values[2] += (100 - decomposedColor.values[2]) * clampCoefficient;
  } else if (color.type?.indexOf('rgb') !== -1) {
    for (let i = 0; i < 3; i += 1) {
      decomposedColor.values[i] += (255 - decomposedColor.values[i]) * clampCoefficient;
    }
  }

  return recomposeColor(decomposedColor);
}

export function darken(color: any, coefficient: any) {
  const decomposedColor = decomposeColor(color);
  const clampCoefficient = clamp(coefficient);

  if (decomposedColor.type.indexOf('hsl') !== -1) {
    decomposedColor.values[2] *= 1 - clampCoefficient;
  } else if (decomposedColor.type.indexOf('rgb') !== -1) {
    for (let i = 0; i < 3; i += 1) {
      decomposedColor.values[i] *= 1 - clampCoefficient;
    }
  }
  return recomposeColor(decomposedColor);
}
