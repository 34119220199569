import React from 'react';
import classNames from 'classnames/bind';
import { TextProps } from './types';
import styles from './text.module.css';

const cx = classNames.bind(styles);

export const Text = ({
  children,
  size = 's',
  semibold,
  align = 'left',
  color = 'black',
  as = 'span',
  mt,
  mr,
  mb,
  ml,
}: TextProps) => {
  const CustomTextTag = as;

  return (
    <CustomTextTag
      className={cx(
        'text',
        `size-${size}`,
        `color-${color}`,
        `align-${align}`,
        { semibold },
      )}
      style={{
        marginTop: mt,
        marginRight: mr,
        marginBottom: mb,
        marginLeft: ml,
      }}
    >
      {children}
    </CustomTextTag>
  );
};
