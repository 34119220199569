import React, { useContext, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import classNames from 'classnames/bind';
import { ApplicationContext } from 'global-stores/application-context';
import { Text } from 'components/text';
import Logo from 'domains/docs/assets/urban-agent-logo.png';
import Logo2x from 'domains/docs/assets/urban-agent-logo@2x.png';
import Logo3x from 'domains/docs/assets/urban-agent-logo@3x.png';
import styles from 'domains/docs/docs.module.css';

const cx = classNames.bind(styles);

export const AgreementPage = () => {
  const { isAuthenticated } = useContext(ApplicationContext);

  return (
    <Fragment>
      <Helmet>
        <title>Пользовательское соглашение сервиса reagent.pro (публичная оферта)</title>
      </Helmet>

      <section className={cx('page')}>
        {!isAuthenticated && (
          <header className={cx('header')}>
            <a href="/">
              <picture>
                <source srcSet={`${Logo2x} 2x, ${Logo3x} 3x`} />
                <img src={Logo} width={256} height={60} alt="" />
              </picture>
            </a>
          </header>
        )}

        <h1 className={cx('title')}>
          ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ СЕРВИСА REAGENT.PRO<br />
          (публичная оферта)
        </h1>

        <Text as="p" size="m">
          Настоящим ООО &laquo;РЕАГЕНТ.ПРО&raquo; в&nbsp;лице Генерального директора Иволина Андрея Михайловича, действующего на&nbsp;основании Устава (далее&nbsp;&mdash; Администрация Сервиса) предлагает дееспособному лицу физическому лицу, индивидуальному предпринимателю или юридическому лицу (далее&nbsp;&mdash; Пользователь), использовать сайт, расположенный в&nbsp;сети Интернет по&nbsp;адресу reagent.pro (далее&nbsp;&mdash; Сервис).
        </Text>

        <Text as="p" size="m">
          В&nbsp;соответствии с&nbsp;настоящим пользовательским соглашением (далее&nbsp;&mdash; Соглашение) Администрация Сервиса предлагает Пользователю услуги по&nbsp;использованию Сервиса в&nbsp;качестве закрытого информационного портала о&nbsp;недвижимости в&nbsp;коммерческих и&nbsp;не&nbsp;коммерческих целях.
        </Text>

        <Text as="p" size="m">
          Пользовательское соглашение признается офертой. В&nbsp;соответствии со&nbsp;статьей 438 Гражданского Кодекса Российской Федерации (ГК&nbsp;РФ), безусловным принятием (акцептом) условий настоящей оферты считается осуществление Пользователем регистрации в&nbsp;Сервисе путем заполнения регистрационной формы и&nbsp;выражения согласия с&nbsp;условиями Соглашения, а&nbsp;также использование функционала Сервиса. Настоящее Соглашение, заключаемое путем акцепта настоящей оферты, не&nbsp;требует двустороннего подписания и&nbsp;действительно в&nbsp;электронном виде. Использование Сервиса Пользователем означает, что Пользователь принимает и&nbsp;обязуется соблюдать все нижеприведенные условия Соглашения.
        </Text>

        <ol className={cx('list')}>
          <li>
            ТЕРМИНЫ И&nbsp;ОПРЕДЕЛЕНИЯ

            <Text as="p" size="m">
              Сервис&nbsp;&mdash; это идентифицированное место в&nbsp;сети Интернет (виртуальном пространстве), в&nbsp;котором располагается информация, оформленная и&nbsp;структурированная определенным образом. Для целей настоящего Соглашения Сервисом признается сайт, расположенный в&nbsp;сети Интернет по&nbsp;адресу: reagent.pro, а&nbsp;также его производные. Сервис является закрытым информационным порталом
            </Text>

            <Text as="p" size="m">
              Пользователь&nbsp;&mdash; зарегистрированный Пользователь, который прошел процедуру регистрации в&nbsp;Сервисе, исключительно способом регламентированным настоящим Соглашением и&nbsp;другими документами, которые регулируют взаимоотношение между Пользователями и&nbsp;Администрацией Сервиса.
            </Text>

            <Text as="p" size="m">
              Личный кабинет&nbsp;&mdash; совокупность защищенных страниц в&nbsp;Сервисе, создаваемых при регистрации Пользователя, с&nbsp;помощью которых Пользователь может использовать функционал Сервиса. Доступ к&nbsp;Личному кабинету осуществляется Пользователем посредством ввода Учетной записи Пользователя.
            </Text>

            <Text as="p" size="m">
              Учетная запись/профиль&nbsp;&mdash; контактный номер мобильного телефона Пользователя и&nbsp;приходящее на&nbsp;него сообщение с&nbsp;цифровым кодом для входа в&nbsp;Личный кабинет.
            </Text>

            <Text as="p" size="m">
              Правила&nbsp;&mdash; перечень норм и&nbsp;правил, на&nbsp;основании которых функционирует настоящий Сервис, а&nbsp;также происходят все процессы взаимоотношений между Администрацией Сервиса и&nbsp;Пользователями в&nbsp;Сервисе. В&nbsp;состав таких правил в&nbsp;частности входят&nbsp;&mdash; Пользовательское соглашение, Положение о&nbsp;конфиденциальности данных, Публичные оферты, а&nbsp;также другие документы, которые регулируют функционирование Сервиса и&nbsp;опубликованы в&nbsp;Сервисе.
            </Text>
          </li>
          <li>
            ОБЩИЕ ПОНЯТИЯ
            <ol className={cx('list')}>
              <li>
                Соглашение разработано Администрацией Сервиса и&nbsp;определяет условия и&nbsp;порядок использования Сервиса, а&nbsp;также права и&nbsp;обязанности его Пользователей и&nbsp;Администрации Сервиса.
              </li>
              <li>
                Соглашение регулирует порядок предоставления Администрацией Сервиса Пользователям функционал Сервиса.
              </li>
              <li>
                Администрация Сервиса оставляет за&nbsp;собой право вносить изменения в&nbsp;текст настоящего Соглашения, уведомляя об&nbsp;этом Пользователей путем публикации новой редакции Соглашения.
              </li>
              <li>
                Пользователь обязан регулярно отслеживать изменения, вносимые в&nbsp;Соглашение. Если Пользователь не&nbsp;согласен с&nbsp;новой редакцией Соглашения, то&nbsp;он&nbsp;обязан незамедлительно прекратить использование Сервиса. В&nbsp;случае если после вступления в&nbsp;действие новой редакции Соглашения Пользователь продолжает использовать Сервис, то&nbsp;такой Пользователь тем самым подтверждает свое полное согласие с&nbsp;новой редакцией Соглашения.
              </li>
              <li>
                Пользователь обязан полностью ознакомиться с&nbsp;Соглашением до&nbsp;момента прохождения процедуры регистрации в&nbsp;Сервисе. Использование Сервиса означает полное и&nbsp;безоговорочное принятие Пользователем настоящего Соглашения в&nbsp;соответствии с&nbsp;нормами действующего законодательства РФ.
              </li>
              <li>
                Акцепт настоящего Соглашения осуществляется путем принятия Пользователем условий Соглашения при регистрации в&nbsp;Сервисе или с&nbsp;момента начала использования Пользователем Сервиса без регистрации в&nbsp;той мере, в&nbsp;какой это допустимо функциональными возможностями Сервиса. Регистрация в&nbsp;Сервисе без подтверждения согласия с&nbsp;настоящим Соглашением технически невозможна. Акцепт настоящего Соглашения означает, что Пользователь гарантирует, что по&nbsp;закону его юрисдикции он&nbsp;обладает достаточной дееспособностью для заключения Соглашения.
              </li>
            </ol>
          </li>
          <li>
            РЕГИСТРАЦИЯ В&nbsp;СЕРВИСЕ И&nbsp;УЧЕТНАЯ ЗАПИСЬ ПОЛЬЗОВАТЕЛЯ
            <ol className={cx('list')}>
              <li>
                Регистрация Пользователя в&nbsp;Сервисе является обязательной, т.к. Сервис является закрытой информационной платформой.
              </li>

              <li>
                Для получения полноценного доступа к&nbsp;Сервису Пользователь должен пройти процедуру регистрации и&nbsp;получить свою уникальную Учетную запись. Один Пользователь не&nbsp;должен иметь в&nbsp;Сервисе более одной Учетной записи.
              </li>

              <li>
                Процедура регистрации проходит следующим образом:
                <ol className={cx('list')}>
                  <li>
                    Пользователь направляет Администрации Сервиса заявку со&nbsp;своими контактными данными.
                  </li>

                  <li>
                    Администрация Сервиса после получения заявки от&nbsp;Пользователя проверяет и&nbsp;подтверждает указанные в&nbsp;заявке контактные данные на&nbsp;соответствие требованиям настоящего Соглашения и&nbsp;Правил.
                  </li>

                  <li>
                    Пользователь после проверки Администрацией Сервиса заявки имеет возможность осуществить вход в&nbsp;Сервис путем указания цифрового кода доступа, получаемого при введении номера мобильного телефона Пользователя в&nbsp;активном окне при входе в&nbsp;Сервис. Номер мобильного телефона Пользователя должен соответствовать номеру мобильного телефона, указанного в&nbsp;заявке.
                  </li>
                </ol>
              </li>

              <li>
                Проходя регистрацию, Пользователь соглашается использовать Сервис в&nbsp;строгом соответствии с&nbsp;настоящим Соглашением, а&nbsp;также с&nbsp;требованиями поведения, которые определены в&nbsp;настоящем Соглашении для Пользователей Сервиса.
              </li>

              <li>
                При регистрации в&nbsp;Сервисе Пользователь обязан предоставить Администрации Сервиса необходимую достоверную и&nbsp;актуальную информацию для формирования персональной страницы Пользователя. Регистрационная форма Сервиса при необходимости может запрашивать у&nbsp;Пользователя дополнительную информацию.
              </li>

              <li>
                Пользователь обязан обеспечивать надежность и&nbsp;конфиденциальность своих персональных данных, используемых Пользователем для получения доступа к&nbsp;Сервису. Пользователю рекомендуется при завершении каждой сессии работы с&nbsp;Сервисом осуществлять безопасный выход из&nbsp;своей Учетной записи. Все действия, совершаемые под Учетной записью Пользователя, считаются совершенными данным Пользователем.
              </li>

              <li>
                Если Пользователь предоставляет неверную информацию или у&nbsp;Администрации Сервиса есть основания полагать, что предоставленная Пользователем информация неполная или недостоверная, то&nbsp;Администрация Сервиса имеет право по&nbsp;своему усмотрению заблокировать, либо удалить Учетную запись Пользователя, а&nbsp;также запретить Пользователю использование Сервиса без объяснения причин.
              </li>

              <li>
                Администрация Сервиса оставляет за&nbsp;собой право в&nbsp;любой момент потребовать от&nbsp;Пользователя подтверждения данных, указанных им&nbsp;в&nbsp;Учетной записи Сервиса. В&nbsp;случае если Пользователь их&nbsp;не&nbsp;предоставит, то&nbsp;Администрация Сервиса может расценивать это как предоставление недостоверной информации Пользователем.
              </li>

              <li>
                Пользователь уведомлен и&nbsp;соглашается с&nbsp;тем, что информация о&nbsp;нем и&nbsp;контент, размещенный им&nbsp;в&nbsp;Сервисе, становятся доступными третьим лица.
              </li>

              <li>
                Пользователь соглашается на&nbsp;получение сервисных и&nbsp;информационных сообщений по&nbsp;контактным данным, указанным Пользователем при регистрации в&nbsp;Сервисе или при внесении изменений в&nbsp;свою Учетную запись.
              </li>

              <li>
                В&nbsp;случае нарушения Пользователем условий настоящего Соглашения Администрация Сервиса вправе немедленно и&nbsp;без предварительного уведомления Пользователя по&nbsp;своему усмотрению применить к&nbsp;Пользователю такие меры ответственности, как временная или постоянная блокировка доступа Пользователя к&nbsp;своей Учетной записи, удаление какого-либо материала, в&nbsp;том числе в&nbsp;случае несоответствия материалов применимому законодательству и/или настоящему Соглашению, а&nbsp;также в&nbsp;случае если Администрацией Сервиса получено уведомление от&nbsp;третьих лиц о&nbsp;нарушении Пользователем их&nbsp;прав при размещении информационных материалов в&nbsp;рамках Сервиса. Любые положения настоящего Соглашения не&nbsp;должны толковаться, как обязанность Администрации Сервиса применять указанные меры ответственности к&nbsp;Пользователю.
              </li>

              <li>
                В&nbsp;отношении информации о&nbsp;себе Пользователь имеет право самостоятельно удалять или изменять ранее размещенную Пользователем информацию.
              </li>
            </ol>
          </li>

          <li>
            ПРАВОВОЙ СТАТУС АДМИНИСТРАЦИЯ СЕРВИСА
            <ol className={cx('list')}>
              <li>
                Администрация Сервиса имеет право:
              </li>
              <li>
                В&nbsp;любое время изменять оформление Сервиса, его контент, список сервисов, изменять или дополнять используемые или хранящиеся в&nbsp;Сервисе программное обеспечение и&nbsp;другие объекты, любые серверные приложения в&nbsp;любое время с&nbsp;предварительным уведомлением или без такового;
              </li>
              <li>
                При необходимости отправлять Пользователям по&nbsp;электронной почте, посредством Сервиса и&nbsp;другими доступными способами сообщения, касающиеся использования Сервиса;
              </li>
              <li>
                Осуществлять персонализированный контроль за&nbsp;Пользователями;
              </li>
              <li>
                Изменять или удалять любой контент (содержащийся в&nbsp;Учетной записи Пользователя), который по&nbsp;усмотрению Администрации Сервиса нарушает и/или может нарушать законодательство&nbsp;РФ, положения Соглашения и&nbsp;права третьих лиц, а&nbsp;также приостанавливать, ограничивать или прекращать доступ Пользователя ко&nbsp;всем или к&nbsp;любому из&nbsp;разделов Сервиса с&nbsp;предварительным уведомлением или без такового;
              </li>
              <li>
                Запрашивать в&nbsp;любое время и&nbsp;в&nbsp;любой форме у&nbsp;Пользователя согласие на&nbsp;обработку персональных данных в&nbsp;Сервисе и&nbsp;на&nbsp;то, что персональные данные, размещенные в&nbsp;Сервисе, будут считаться общедоступными;
              </li>
              <li>
                Устанавливать дополнительные ограничения на&nbsp;использование Сервиса, а&nbsp;также изменять такие ограничения в&nbsp;любое время;
              </li>
              <li>
                Делать предупреждения Пользователям о&nbsp;нарушении условий настоящего Соглашения;
              </li>
              <li>
                В&nbsp;случае систематический нарушений условий настоящего Соглашения и&nbsp;других юридических документов, регулирующих отношение между Пользователем и&nbsp;Сервисом блокировать Учетную Запись Пользователя;
              </li>
              <li>
                Осуществлять иные действия с&nbsp;целью улучшения качества и&nbsp;удобства использования Пользователями Сервиса.
              </li>
            </ol>
          </li>

          <li>
            ПРАВОВОЙ СТАТУС ПОЛЬЗОВАТЕЛЯ
            <ol className={cx('list')}>
              <li>
                Пользователь имеет право:
                <ol className={cx('list')}>
                  <li>
                    Зарегистрироваться в&nbsp;Сервисе в&nbsp;порядке, определенном положениями Соглашения, и&nbsp;получить доступ к&nbsp;функционалу Сервиса;
                  </li>
                  <li>
                    Обращаться к&nbsp;Администрации Сервиса с&nbsp;целью получения информации или консультаций по&nbsp;поводу использования Сервиса;
                  </li>
                  <li>
                    Требовать надлежащего предоставления услуг использования Сервиса, предусмотренных настоящим Соглашением, а&nbsp;также другими документами, которые регулируют взаимоотношения между Пользователями и&nbsp;Администрацией Сервиса.
                  </li>
                </ol>
              </li>
              <li>
                Пользователь обязуется:
                <ol className={cx('list')}>
                  <li>
                    Предоставить только правдивые персональные данные и&nbsp;в&nbsp;дальнейшем вносить необходимые правки, если такие персональные данные будут изменяться, в&nbsp;порядке, предусмотренном в&nbsp;настоящем Соглашение, а&nbsp;также других документах, которые регулируют взаимоотношения между Пользователями и&nbsp;Администрацией Сервиса.
                  </li>
                  <li>
                    Строго и&nbsp;в&nbsp;полной мере соблюдать все правила, которые регламентируются настоящим Соглашением, а&nbsp;также другими документами, которые регулируют взаимоотношения между Пользователями и&nbsp;Администрацией Сервиса;
                  </li>
                  <li>
                    Не&nbsp;использовать Сервис и&nbsp;его функционал, предоставляемый Администрацией Сервиса, в&nbsp;противоправных целях или в&nbsp;целях, которые могут каким-либо образом нанести ущерб Администрации Сервиса, Сервису и/или третьим лицам;
                  </li>
                  <li>
                    Не&nbsp;разглашать конфиденциальную информацию, которая стала ему известна в&nbsp;результате взаимоотношений с&nbsp;Администрацией Сервиса;
                  </li>
                  <li>
                    Не&nbsp;производить запрещенных Соглашением действий в&nbsp;Сервисе;
                  </li>
                  <li>
                    Не&nbsp;реже чем раз в&nbsp;2 (два) месяца знакомиться с&nbsp;содержанием Соглашения и&nbsp;иных правил, регламентирующих порядок использования Сервиса.
                  </li>
                </ol>
              </li>
              <li>
                При использовании Сервиса Пользователю запрещается:
                <ol className={cx('list')}>
                  <li>
                    Использовать Сервис любым способом, который может помешать нормальному функционированию Сервиса и&nbsp;его компонентов;
                  </li>
                  <li>
                    Загружать, хранить, публиковать, распространять вирусы и&nbsp;другие вредоносные программы посредством Сервиса;
                  </li>
                  <li>
                    Загружать, публиковать и&nbsp;передавать иным способом контент, которые нарушает действующее законодательство РФ;
                  </li>
                  <li>
                    Осуществлять действия, направленные на&nbsp;дестабилизацию функционирования Сервиса, осуществлять попытки несанкционированного доступа к&nbsp;управлению Сервисом или его закрытым разделам (разделам, доступ к&nbsp;которым разрешен только Администрации Сервиса), а&nbsp;также осуществлять любые иные аналогичные действия;
                  </li>
                  <li>
                    Использовать автоматизированные скрипты (программы) для сбора информации и/или взаимодействия с&nbsp;Сервисом;
                  </li>
                  <li>
                    Любым способом, в&nbsp;том числе путем взлома, пытаться получить доступ к&nbsp;чужой учетной записи вопреки воле зарегистрированного Пользователя, которому она принадлежит;
                  </li>
                  <li>
                    Перечень ограничений и&nbsp;обязанностей Пользователей, описанный в&nbsp;настоящем разделе не&nbsp;является исчерпывающим.
                  </li>
                </ol>
              </li>
            </ol>
          </li>

          <li>
            ЛИЧНЫЙ КАБИНЕТ ПОЛЬЗОВАТЕЛЯ
            <ol className={cx('list')}>
              <li>
                В&nbsp;момент регистрации Пользователя в&nbsp;Сервисе, для удобства пользования Сервисом и&nbsp;его функционалом, Пользователь получает доступ к&nbsp;Личному кабинету. Под Личным кабинетом понимается совокупность защищенных страниц в&nbsp;Сервисе, создаваемых при регистрации Пользователя, на&nbsp;которых Пользователь осуществляет действия для полноценного использования Сервиса.
              </li>
              <li>
                Доступ к&nbsp;Личному кабинету осуществляется Пользователем посредством ввода Учетной информации Пользователя.
              </li>
              <li>
                В&nbsp;Личном кабинете Пользователя для удобной навигации располагается меню с&nbsp;содержанием доступного функционала.
              </li>
              <li>
                Администрация Сервиса оставляет за&nbsp;собой право в&nbsp;одностороннем порядке изменять интерфейс и&nbsp;функционал Личного кабинета Пользователя с&nbsp;предварительным уведомлением об&nbsp;этом Пользователя или без такового.
              </li>
            </ol>
          </li>

          <li>
            ОТВЕТСТВЕННОСТЬ СТОРОН
            <ol className={cx('list')}>
              <li>
                Ответственность за&nbsp;информацию, размещенную Пользователями в&nbsp;Сервисе, несет Пользователь, предоставивший данную информацию.
              </li>
              <li>
                Администрация Сервиса оставляет за&nbsp;собой право блокировать Учетную Запись Пользователя в&nbsp;случае нарушения Правил, в&nbsp;том числе положений настоящего Пользовательского соглашения.
              </li>
              <li>
                Администрация Сервиса не&nbsp;несет ответственности по&nbsp;всем видам сделок, совершаемых между Пользователем и&nbsp;третьими лицами, если такие были совершены.
              </li>
              <li>
                Администрация Сервиса не&nbsp;отвечает за&nbsp;достоверность персональных данных, предоставленных Пользователями.
              </li>
              <li>
                Администрация Сервиса не&nbsp;несет ответственность по&nbsp;финансовым обязательствам между Пользователем и&nbsp;третьими лицами, которые могут возникать в&nbsp;процессе использования Сервиса.
              </li>
              <li>
                Администрация Сервиса не&nbsp;гарантирует, что программное обеспечение, сервера и&nbsp;компьютерные сети, используемые Сервисом свободны от&nbsp;ошибок и&nbsp;вредоносных программ. Администрация Сервиса не&nbsp;несет ответственности в&nbsp;случае, если использование Сервиса повлекло за&nbsp;собой утрату данных или порчу оборудования.
              </li>
              <li>
                За&nbsp;нарушение положений Соглашения доступ Пользователя к&nbsp;Сервису, отдельным разделам Сервиса может быть ограничен, приостановлен или прекращен на&nbsp;неопределенный срок. Доступ может быть восстановлен по&nbsp;письменному заявлению Пользователя по&nbsp;усмотрению Администрации Сервиса.
              </li>
            </ol>
          </li>

          <li>
            ИНФОРМАЦИОННАЯ БЕЗОПАСНОСТЬ СЕРВИ
            <ol className={cx('list')}>
              <li>
                Используя Сервис, Пользователь обязуется не&nbsp;нарушать или не&nbsp;пытаться нарушать информационную безопасность Сервиса, что включает в&nbsp;себя:
                <ol className={cx('list')}>
                  <li>
                    Доступ к&nbsp;любой информации, не&nbsp;предназначенной для использования Пользователем или вход в&nbsp;Сервис под логином (номером мобильного телефона), не&nbsp;принадлежащем данному Пользователю;
                  </li>
                  <li>
                    Попытки проверить уязвимость системы безопасности Сервиса, нарушение процедуры регистрации и&nbsp;авторизации без разрешения Сервиса;
                  </li>
                  <li>
                    Попытки создать помехи в&nbsp;использовании Сервиса другим Пользователям, что включает в&nbsp;себя распространение вредоносных программ, удаление, искажение данных, постоянную рассылку повторяющейся информации, пересылку электронной почты через сервер сайта, одновременную отправку большого количества электронной почты и/или запросов к&nbsp;Сервису с&nbsp;целью намеренно вывести сервер сайта из&nbsp;строя и&nbsp;тому подобные действия, выходящие за&nbsp;рамки нормального целевого использования Сервиса, и&nbsp;способные умышленно или по&nbsp;неосторожности повлечь сбои в&nbsp;его работе.
                  </li>
                </ol>
              </li>
              <li>
                Нарушение безопасности системы или компьютерной сети влечет за&nbsp;собой гражданскую и&nbsp;уголовную ответственность. Администрация Сервиса будет расследовать все случаи возможного нарушения безопасности со&nbsp;стороны Пользователей в&nbsp;сотрудничестве с&nbsp;компетентными органами с&nbsp;целью пресечения подобной злонамеренной деятельности.
              </li>
              <li>
                Администрация Сервиса прилагает все возможные усилия для того, чтобы избежать несанкционированного использования персональных данных Пользователей.
              </li>
              <li>
                Администрация Сервиса не&nbsp;несет ответственности за&nbsp;возможное использование персональных данных Пользователей, произошедшее из-за:
                <ol className={cx('list')}>
                  <li>
                    Технических проблем, возникших в&nbsp;программном обеспечении, серверах или компьютерных сетях, находящихся вне контроля Администрации Сервиса;
                  </li>
                  <li>
                    Перебоев в&nbsp;работе Сервиса, связанных с&nbsp;намеренным или ненамеренным использованием Сервиса не&nbsp;по&nbsp;назначению третьими лицами;
                  </li>
                  <li>
                    Передачи паролей или информации с&nbsp;Сервиса Пользователями другим лицам.
                  </li>
                </ol>
              </li>
              <li>
                Администрация Сервиса не&nbsp;отвечает за&nbsp;возможное нецелевое использование информации с&nbsp;Сервиса Пользователями или иными лицами, которое произошло без уведомления Администрации Сервиса с&nbsp;нарушением или без информационной безопасности Сервиса.
              </li>
            </ol>
          </li>

          <li>
            ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ СЕ
            <ol className={cx('list')}>
              <li>
                Сервис содержит результаты интеллектуальной деятельности, принадлежащие Администрации Сервиса, ее&nbsp;аффилированным лицам и&nbsp;другим связанным сторонам, партнерам, представителям, всем прочим лицам, действующим от&nbsp;имени Администрации Сервиса.
              </li>
              <li>
                Используя Сервис, Пользователь признает и&nbsp;соглашается с&nbsp;тем, что все содержимое Сервис и&nbsp;структура содержимого Сервиса защищены авторским правом, правом на&nbsp;товарный знак и&nbsp;другими правами на&nbsp;результаты интеллектуальной деятельности, и&nbsp;что указанные права являются действительными и&nbsp;охраняются во&nbsp;всех формах, на&nbsp;всех носителях и&nbsp;в&nbsp;отношении всех технологий, как существующих в&nbsp;настоящее время, так и&nbsp;разработанных или созданных впоследствии. Никакие права на&nbsp;любое содержимое Сервиса, не&nbsp;переходят к&nbsp;Пользователю в&nbsp;результате использования Сервиса и&nbsp;заключения настоящего Соглашения.
              </li>
              <li>
                Во&nbsp;избежание сомнений, как в&nbsp;целях защиты интеллектуальных прав, так и&nbsp;в&nbsp;любых иных целях, связанных с&nbsp;использованием Сервиса, Пользователю запрещается:
                <ol className={cx('list')}>
                  <li>
                    Копировать и/или распространять какие-либо объекты интеллектуальных прав, размещенных в&nbsp;Сервисе (включая пользовательские интерфейсы, визуальные интерфейсы, названия товарных знаков, логотипы, базы данных, фотографии, планировки, тексты, а&nbsp;также дизайн, структуру, выбор, координацию, внешний вид, общий стиль и&nbsp;расположение данного содержания, входящего в&nbsp;состав Сервиса), кроме случаев, когда такая функция прямо предусмотрена (разрешена) в&nbsp;Сервисе;
                  </li>
                  <li>
                    Копировать, либо иным способом использовать программную часть Сервиса, а&nbsp;также его дизайн.
                  </li>
                </ol>
              </li>
            </ol>
          </li>

          <li>
            РАЗРЕШЕНИЕ СПОРОВ
            <ol className={cx('list')}>
              <li>
                Настоящее Соглашение регулируется и&nbsp;толкуется в&nbsp;соответствии с&nbsp;законодательством РФ.
              </li>
              <li>
                Все споры, возникшие в&nbsp;рамках исполнения настоящего Соглашения и&nbsp;других документов Сервиса, решаются путем переговоров.
              </li>
              <li>
                При невозможности урегулирования спора путем переговоров, спор передается на&nbsp;рассмотрение судов любой юрисдикции по&nbsp;месту нахождения Администрации Сервиса.
              </li>
            </ol>
          </li>

          <li>
            ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ
            <ol className={cx('list')}>
              <li>
                Соглашение заключено между Пользователями и&nbsp;Администрацией Сервиса относительно порядка использования Сервиса, носит обязательный характер и&nbsp;действует с&nbsp;момента акцепта Пользователями на&nbsp;неопределенный срок.
              </li>
              <li>
                Адрес электронной почты Администрации Сервиса для рассмотрения обращений Пользователей: <a href="mailto:info@reagent.pro">info@reagent.pro</a>
              </li>
            </ol>
          </li>
        </ol>

        <Text as="p" size="m">
          Дата публикации: 01.02.2021г.
        </Text>
        <br />
        <Text as="p" size="m">
          Исполнитель:<br />
          ООО &laquo;РЕАГЕНТ.ПРО&raquo;<br />
          Юр.адрес: 354000, Краснодарский край, город Сочи, улица Навагинская (Центральный Р-Н), дом&nbsp;9д, офис 427<br />
          Факт.адрес: 354000, Краснодарский край, город Сочи, улица Навагинская (Центральный Р-Н), дом&nbsp;9д, офис 427<br />
          ИНН 2320232357, КПП 232001001<br />
          В&nbsp;Филиале &laquo;РОСТОВСКИЙ&raquo; АО&nbsp;&laquo;АЛЬФА-БАНК&raquo;<br />
          БИК 046015207
        </Text>
      </section>
    </Fragment>
  );
};
