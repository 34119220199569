import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import { observer } from 'mobx-react';
import Media from 'react-media';
import ReactPlayer from 'react-player';
import cn from 'classnames';

import { SelectionsContext } from 'global-stores/selections-context';

import { Popup } from 'components/popup';

import ApartmentFolders from 'ui/components/ApartmentFolders';
import CreateApartmentList from 'ui/components/CreateApartmentList';
import Pagination from 'ui/components/Pagination';

import { ReactComponent as VideoIcon } from 'assets/svg/video.svg';

import styles from './Property.module.css';
import { Text } from 'components/text';

const cx = classNames.bind(styles);

const Property = observer(() => {
  const [isPopupVisible, setIsPopupVisible] = useState(localStorage.getItem('property-tutorial-popup') !== 'true');

  const handlePopupOpen = useCallback(() => {
    setIsPopupVisible(true);
  }, []);

  const handlePopupClose = useCallback(() => {
    setIsPopupVisible(false);
    localStorage.setItem('property-tutorial-popup', 'true');
  }, []);

  const selectionsContext = useContext(SelectionsContext);

  useEffect(() => {
    selectionsContext.setScrollSelection(false);
    selectionsContext.setSmoothScrollSelection(true);

    selectionsContext.setPage({ selected: 0 });

    selectionsContext.uploadSelections();
  }, [selectionsContext]);

  useEffect(() => {
    selectionsContext.uploadSelections()
      .then(() => {
        selectionsContext.scrollUp();
      });
  }, [selectionsContext, selectionsContext.page]);

  if (!selectionsContext.selections) {
    return null;
  }

  return (
    <Fragment>
      <div
        className={cn(styles.main, {
          [styles.mainLoading]: selectionsContext.loading,
        })}
      >
        <button
          className={cx('video-tutor-button')}
          type="button"
          onClick={handlePopupOpen}
        >
          <VideoIcon className={cx('video-tutor-icon')} />

          <Text
            size="s"
            color="blue"
            semibold
          >
            Как делать подборки?
          </Text>
        </button>
        <div className={styles.titleWrap}>
          <h1 className={styles.title}>
            Подборки
          </h1>

          {selectionsContext.total !== 0 && (
            <span className={styles.titleSub}>
              ({selectionsContext.total} активных)
            </span>
          )}
        </div>

        <CreateApartmentList />

        <ApartmentFolders folders={selectionsContext.selections} />

        <div className={styles.pagination}>
          <Media query="(max-width: 425px)">
            <Pagination
              pageCount={Math.ceil(((selectionsContext.total || 0) / selectionsContext.PER_PAGE))}
              onPageChange={selectionsContext.scrollAfterCallback(styles.titleWrap, selectionsContext.setPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              disabled={selectionsContext.loading}
            />
          </Media>

          <Media query="(min-width: 426px)">
            <Pagination
              pageCount={Math.ceil(((selectionsContext.total || 0) / selectionsContext.PER_PAGE))}
              onPageChange={selectionsContext.scrollAfterCallback(styles.titleWrap, selectionsContext.setPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              disabled={selectionsContext.loading}
            />
          </Media>
        </div>
      </div>

      {isPopupVisible && (
        <Popup
          withPortal
          padding="none"
          onClose={handlePopupClose}
        >
          <div className={cx('popup-player-content')}>
            <Media query="(min-width: 769px)">
              <ReactPlayer
                width="100%"
                height="100%"
                url="https://www.youtube.com/watch?v=bNyjwTEYnu0"
              />
            </Media>
            <Media query="(max-width: 768px)">
              <ReactPlayer
                width="100%"
                height="100%"
                url="https://www.youtube.com/watch?v=FcEmbgUaXx4"
              />
            </Media>
          </div>
        </Popup>
      )}
    </Fragment>
  );
});

export default React.memo(Property);
