import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Layout } from 'ui/components/layout/layout';
import { makeStyles } from 'hooks/makeStyles';
import ApplicationManager, { ApplicationContext } from './global-stores/application-context';
import Chatwoot from './Chanwoot';
import Rollbar from 'rollbar';
import Config from './config';

const useStyles = makeStyles((theme) => ({
  '@global': {
    '*': {
      margin: 0,
      padding: 0,
      boxSizing: 'border-box',
    },
    html: {
      fontSize: '10px',
      fontFamily: `${theme.fonts.semibold}, sans-serif`,
      color: theme.colors.textPrimary,
    },
    a: {
      color: theme.colors.textSecondary,
      fontSize: '1.5rem',
    },
    '@font-face': [
      {
        fontFamily: theme.fonts.bold,
        src:
          'url("/fonts/ProximaNova-Bold.eot"), url("/fonts/ProximaNova-Bold.eot?#iefix") format("embedded-opentype"), url("/fonts/ProximaNova-Bold.woff") format("woff"), url("/fonts/ProximaNova-Bold.ttf") format("truetype")',
        fontWeight: 700,
        fontStyle: 'normal',
      },
      {
        fontFamily: theme.fonts.regular,
        src:
          'url("/fonts/ProximaNova-Regular.eot"), url("/fonts/ProximaNova-Regular.eot?#iefix") format("embedded-opentype"), url("/fonts/ProximaNova-Regular.woff") format("woff"), url("/fonts/ProximaNova-Regular.ttf") format("truetype")',
        fontWeight: 400,
        fontStyle: 'normal',
      },
      {
        fontFamily: theme.fonts.montserrat,
        src:
          'url("/fonts/Montserrat-SemiBold.eot"), url("/fonts/Montserrat-SemiBold.ttf") format("truetype"), url("/fonts/Montserrat-SemiBold.eot?#iefix") format("embedded-opentype"), url("/fonts/Montserrat-SemiBold.woff") format("woff")',
        fontStyle: 'normal',
      },
      {
        fontFamily: theme.fonts.semibold,
        src:
          'url("/fonts/ProximaNova-Semibold.eot"), url("/fonts/ProximaNova-Semibold.eot?#iefix") format("embedded-opentype"), url("/fonts/ProximaNova-Semibold.woff") format("woff"), url("/fonts/ProximaNova-Semibold.ttf") format("truetype")',
        fontWeight: 500,
        fontStyle: 'normal',
      },
      {
        fontFamily: theme.fonts.script,
        src: 'url("/fonts/segoesc.ttf")',
        fontWeight: 'normal',
        fontStyle: 'normal',
      },
    ],
    input: {
      '&[type=number]': {
        '-moz-appearance': 'textfield',
      },
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
      /* Remove inner shadow from inputs on mobile iOS */
      '&textarea, &[type=text]': {
        '-webkit-appearance': 'none',
      },
      '-webkit-appearance': 'none',
      ' -moz-appearance': 'none',
      appearance: 'none',
    },
  },
}));

const applicationManager = new ApplicationManager();
declare global {
  interface Window { rollbar: Rollbar; }
}

const rollbarConfig = {
  accessToken: Config.ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: Config.DISABLE_ROLLBAR ? 'development' : Config.ENV,
  },
};

window.rollbar = new Rollbar(rollbarConfig);

export const App = () => {
  useStyles();

  return (
    <Router>
      <ApplicationContext.Provider value={applicationManager}>
        <Chatwoot />
        <Layout />
      </ApplicationContext.Provider>
    </Router>
  );
};
