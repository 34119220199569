import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PoliticsPage } from 'domains/docs/pages/politics';
import { AgreementPage } from 'domains/docs/pages/agreement';
import { PublicOfferPage } from 'domains/docs/pages/public-offer';
import { Routes } from './constants';

export const DocsRoutes = () => (
  <Switch>
    <Route exact path={Routes.POLITICS_PAGE} component={PoliticsPage} />
    <Route exact path={Routes.AGREEMENT_PAGE} component={AgreementPage} />
    <Route exact path={Routes.PUBLIC_OFFER_PAGE} component={PublicOfferPage} />
  </Switch>
);
