import React from 'react';
import Media from 'react-media';

import { Header } from 'components/header';
import AccessProvide from 'ui/components/AccessProvide';

import styles from 'ui/pages/objects/Objects/Objects.module.css';

export const Analytics = () => (
  <div>
    <Media query="(max-width: 768px)">
      <Header path="/" title="Аналитика" />
    </Media>

    <div className={styles.container}>
      <AccessProvide>
        <div />
      </AccessProvide>
      <iframe
        title="Price changes in Sochi"
        src="http://analytics.reagent.pro/public/dashboard/3b5177cf-c8df-4002-be76-9b75bda208f7"
        frameBorder="0"
        width="70%"
        height="600"
        // allowTransparency
      />
    </div>
  </div>
);
