import React, { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames/bind';
import styles from './styles.module.css';

const cx = classNames.bind(styles);

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  primary?: boolean
  secondary?: boolean
  fullWidth?: boolean
}

export const Button = React.forwardRef((props: ButtonProps, ref: React.Ref<HTMLButtonElement>) => {
  const {
    primary, secondary, fullWidth, children, className, ...passthrough
  } = props;

  return (
    <button
      ref={ref}
      className={cx('button', {
        'full-width': fullWidth,
        primary,
        secondary,
        'button-disabled': passthrough.disabled,
      }, className)}
      type="button"
      {...passthrough}
    >
      {children}
    </button>
  );
});
