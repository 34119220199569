import React from 'react';
import classNames from 'classnames/bind';
import { Text } from 'components/text';
import { ReactComponent as CheckIcon } from './check.svg';
import styles from './subscription-option.module.css';

const cx = classNames.bind(styles);

type SubscriptionOptionProps = {
  children: React.ReactNode;
};

export const SubscriptionOption = ({
  children,
}: SubscriptionOptionProps) => (
  <div className={cx('option')}>
    <div className={cx('check-icon-wrap')}>
      <CheckIcon />
    </div>
    <Text>
      {children}
    </Text>
  </div>
);
