import React from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import { motion, MotionStyle } from 'framer-motion';
import styles from './Modal.module.css';

interface Props {
  children: React.ReactNode;
  contentClassName?: string;
  contentStyles?: MotionStyle;
  onClose?: () => void;
  theme?: 'blue';
  error?: boolean;
  animate?: boolean;
}

const Modal = ({ children, contentClassName, contentStyles, ...props }: Props) => {
  const onClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  return createPortal(
    <div
      className={`
        ${styles.modal}
        ${styles.modalThemeBlue}
      `}
      role="dialog"
    >
      <div className={styles.backdrop} onClick={onClose} />

      <motion.div
        animate={{
          x: props.error && props.animate ? [0, 10, -10, 10, -10, 0] : 0,
        }}
        className={cn(styles.modalContent, contentClassName)}
        style={contentStyles}
        role="document"
      >
        {children}
      </motion.div>
    </div>,
    document.getElementById('modal') as Element,
  );
};

interface TitleProps {
  children: React.ReactNode;
}

Modal.Title = ({ children }: TitleProps) => (
  <p className={styles.title}>
    {children}
  </p>
);

interface BodyProps {
  className?: string;
  children: React.ReactNode;
}

Modal.Body = ({ className, children }: BodyProps) => (
  <p className={cn(styles.body, className)}>
    {children}
  </p>
);

interface ButtonsProps {
  children: React.ReactNode;
}

Modal.Buttons = ({ children }: ButtonsProps) => (
  <p className={styles.buttons}>
    {children}
  </p>
);

export default Modal;
