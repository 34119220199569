import React from 'react';

import { ReactComponent as AvatarPlaceholder } from './Avatar.svg';

import styles from './Avatar.module.css';

interface AvatarProps {
  width?: number;
  height?: number;
  src?: string;
}

const Avatar = ({ width = 6, height = 6, src }: AvatarProps) => (
  <div
    className={styles['avatar-wrap']}
    style={{
      width: `${width}rem`,
      height: `${height}rem`,
    }}
  >
    {src && (
      <img className={styles['avatar-image']} src={src} alt="" />
    )}

    {!src && (
      <AvatarPlaceholder className={styles['avatar-image']} />
    )}
  </div>
);

export default Avatar;
