import React from 'react';
import Media from 'react-media';

import { SelectionProps } from '_types';

import ApartmentFolder from 'ui/components/ApartmentFolder';

import styles from './ApartmentFolders.module.css';

interface Props {
  folders: SelectionProps[];
}

const ApartmentFolders = ({ folders }: Props) => (
  <div className={styles.apartmentFolders}>
    <Media query="(min-width: 769px)">
      <div className={styles.folderHeader}>
        <p>
          Название
        </p>

        <p>
          Количество
        </p>

        <p>
          Создано
        </p>

        <p>
          Обновлено
        </p>
      </div>
    </Media>

    <div className={styles.folderList}>
      {folders.map((folder) => (
        <ApartmentFolder key={folder.encrypted_id} selection={folder} />
      ))}
    </div>
  </div>
);

export default ApartmentFolders;
