import { useAnimation } from 'hooks/useAnimation';
import React from 'react';
import { motion } from 'framer-motion';

interface AnimatedPageProps {
  animation: 'horizontal' | 'vertical'
  className?: string
  children?: React.ReactNode
}

export const AnimatedPage = (props: AnimatedPageProps) => {
  const ready = useAnimation();

  const variants = {
    slide: {
      opacity: 1,
      x: 0,
      transition: {
        type: 'spring',
        stiffness: 400,
        damping: 40,
      },
    },
    slide_out: { opacity: 0, x: '10%' },
    slide_up: {
      opacity: 1,
      y: 0,
      transition: {
        type: 'spring',
        stiffness: 400,
        damping: 40,
      },
    },
    slide_down: { opacity: 0, y: '5%' },
  };

  let pose: string;

  if (props.animation === 'horizontal') {
    if (ready) {
      pose = 'slide';
    } else {
      pose = 'slide_out';
    }
  } else {
    if (ready) {
      pose = 'slide_up';
    } else {
      pose = 'slide_down';
    }
  }

  return (
    <motion.div
      initial="slide_out"
      animate="slide"
      variants={variants}
      custom={pose}
      className={props.className}
    >
      {props.children}
    </motion.div>
  );
};
