import React from 'react';
import { CreateSvgIcon } from '../icon';
import { IconProps } from '../names';

export const Telegram = (props: IconProps) => (
  <CreateSvgIcon {...props}>
    {({ color }) => (
      <path
        d="M30.9174 6.18489L26.5411 26.5513C26.2109 27.9887 25.3499 28.3464 24.1264 27.6692L17.4584 22.8204L14.2409 25.8741C13.8848 26.2254 13.587 26.5193 12.9008 26.5193L13.3799 19.8178L25.7383 8.79776C26.2757 8.32502 25.6218 8.06309 24.9032 8.53583L9.62507 18.0291L3.0477 15.9975C1.61699 15.5567 1.59109 14.5857 3.34549 13.9085L29.0723 4.12781C30.2635 3.687 31.3058 4.38973 30.9174 6.18489Z"
        fill={color}
      />
    )}
  </CreateSvgIcon>
);
