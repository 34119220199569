import React, { useContext } from 'react';
import { ApplicationContext } from 'global-stores/application-context';
import {
  Text,
} from 'components/text';
import { SearchCityField } from 'domains/objects/components/search-city-field';

export const SearchPage = () => {
  const applicationContext = useContext(ApplicationContext);

  const {
    currentUser,
  } = applicationContext;

  if (!currentUser) {
    return null;
  }

  return (
    <Text>
      <SearchCityField />
    </Text>
  );
};
