import React from 'react';
import { makeStyles } from 'hooks/makeStyles';
import clsx from 'clsx';

export interface BoxProps {
  p?: number
  pt?: number
  pb?: number
  pr?: number
  pl?: number
  m?: number
  mt?: number
  mb?: number
  mr?: number
  ml?: number
  className?: string
  children?: React.ReactNode
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    boxSizing: 'border-box',
  },
  p: {
    padding: (props: any) => theme.spacing(props.p),
  },
  pt: {
    paddingTop: (props: any) => theme.spacing(props.pt),
  },
  pb: {
    paddingBottom: (props: any) => theme.spacing(props.pb),
  },
  pr: {
    paddingRight: (props: any) => theme.spacing(props.pr),
  },
  pl: {
    paddingLeft: (props: any) => theme.spacing(props.pl),
  },
  m: {
    margin: (props: any) => theme.spacing(props.m),
  },
  mt: {
    marginTop: (props: any) => theme.spacing(props.mt),
  },
  mb: {
    marginBottom: (props: any) => theme.spacing(props.mb),
  },
  mr: {
    marginRight: (props: any) => theme.spacing(props.mr),
  },
  ml: {
    marginLeft: (props: any) => theme.spacing(props.ml),
  },
}));

export const Box = (props: BoxProps) => {
  const css = useStyles(props as any);
  const { children, className, ...passthrough } = props;

  const cx = clsx(css.container, {
    [css.p]: props.p,
    [css.pt]: props.pt,
    [css.pb]: props.pb,
    [css.pr]: props.pr,
    [css.pl]: props.pl,
    [css.m]: props.m,
    [css.mt]: props.mt,
    [css.mb]: props.mb,
    [css.mr]: props.mr,
    [css.ml]: props.ml,
    [className!]: !!className,
  });

  return (
    <div {...passthrough} className={cx}>
      {children}
    </div>
  );
};

export function pickBoxProps(props: any): { boxProps: BoxProps; other: any } {
  const {
    p, pt, pb, pr, pl, m, mt, mb, mr, ml, ...other
  } = props;

  return {
    boxProps: {
      p,
      pt,
      pb,
      pr,
      pl,
      m,
      mt,
      mb,
      mr,
      ml,
    },
    other,
  };
}
