import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';

import classNames from 'classnames/bind';

import { SelectionsContext } from 'global-stores/selections-context';

import { ContextMenu } from 'ui/components/context-menu/context-menu';
import { ContextMenuGroup } from 'ui/components/context-menu/components/context-menu-group/context-menu-group';
import { ContextMenuButton } from 'ui/components/context-menu/components/context-menu-button/context-menu-button';

import { ReactComponent as AddToPropertyIcon } from './add-icon.svg';
import { ReactComponent as AddToPropertyTemplateIcon } from './template-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/svg/Close.svg';

import styles from './add-to-property-button.module.css';

const cx = classNames.bind(styles);

interface Props {
  adsId: number;
}

export const AddToPropertyButton = observer(({ adsId }: Props) => {
  const history = useHistory();
  const location = useLocation();

  const [isLoadingSelections, setIsLoadingSelection] = useState(false);
  const [loadingId, setLoadingId] = useState(0);

  const [isVisible, setIsVisible] = useState(false);

  const buttonRef = useRef<HTMLButtonElement>(null);

  const selectionContext = useContext(SelectionsContext);

  const handleToggleIsVisible = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsVisible(!isVisible);
  };

  const handleClose = () => {
    setIsVisible(!isVisible);
  };

  const handleCreateButton = useCallback(() => {
    const { search } = location;

    history.push({
      pathname: '/objects/property',
      search,
    });
  }, [history, location]);

  // eslint-disable-next-line consistent-return
  const handleScroll = useCallback((e: React.UIEvent<HTMLDivElement>) => {
    if (selectionContext.loading || isLoadingSelections) {
      return false;
    }

    const element = e.target as HTMLDivElement;

    const { scrollTop, scrollHeight } = element;

    const scrollSize = scrollTop + scrollHeight;

    if (scrollSize + 200 > scrollHeight) {
      setIsLoadingSelection(true);
      selectionContext.setPage({ selected: selectionContext.page + 1 });
    }
  }, [selectionContext, isLoadingSelections, setIsLoadingSelection]);

  const handleAddToSelection = useCallback((selectionId: number, adsIds?: number[]) => () => {
    setLoadingId(selectionId);

    if (adsIds?.includes(adsId)) {
      selectionContext.deleteAdsBySelectedId(selectionId, adsId)
        .then(() => {
          selectionContext.deleteAdsIdFromSelection(selectionId, adsId);
        })
        .finally(() => {
          setLoadingId(0);
        });
    } else {
      selectionContext.addAdsToSelection(selectionId, adsId)
        .then(() => {
          selectionContext.addAdsIdFromSelection(selectionId, adsId);
        })
        .finally(() => {
          setLoadingId(0);
        });
    }
  }, [setLoadingId, selectionContext, adsId]);

  useEffect(() => {
    if (selectionContext.page !== 0 && isVisible) {
      selectionContext.uploadSelections()
        .then(() => {
          setIsLoadingSelection(false);
        });
    }
  }, [isVisible, selectionContext, selectionContext.page]);

  if (!selectionContext.selections) {
    return null;
  }

  return (
    <>
      <button
        className={cx('button', {
          'button-active': isVisible,
        })}
        ref={buttonRef}
        onClick={handleToggleIsVisible}
        type="button"
      >
        <AddToPropertyIcon />
      </button>

      <ContextMenu
        isOpen={isVisible}
        button={buttonRef.current}
        onScroll={handleScroll}
      >
        <ContextMenuGroup title="Добавить в новую подборку">
          <ContextMenuButton
            icon={<AddToPropertyIcon />}
            onClick={handleCreateButton}
          >
            Создать новую подборку
          </ContextMenuButton>
        </ContextMenuGroup>

        {selectionContext.selections.length !== 0 && (
          <ContextMenuGroup title="В другую подборку">
            {selectionContext.selections.map((selection) => (
              <ContextMenuButton
                key={selection.id}
                icon={<AddToPropertyTemplateIcon />}
                active={selection.ads?.includes(adsId)}
                onClick={handleAddToSelection(selection.id, selection.ads)}
                disabled={loadingId === selection.id}
                underline
              >
                {selection.name}
              </ContextMenuButton>
            ))}
          </ContextMenuGroup>
        )}
        <ContextMenuGroup>
          <ContextMenuButton
            onClick={handleClose}
          >
            <div className={cx('close-button')}>
              <CloseIcon width={12} height={12} />
              Отмена
            </div>
          </ContextMenuButton>
        </ContextMenuGroup>
      </ContextMenu>
    </>
  );
});
