export const loadDynamicScript = (
  // URL for the third-party library being loaded.
  url: string,
  id: string,
  callback?: () => void,
) => {
  const existingScript = document.getElementById(id);

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = url;
    script.id = id;
    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback();
    };
  }

  if (existingScript && callback) callback();
};
