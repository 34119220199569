import React from 'react';
import classNames from 'classnames/bind';
import { Text } from 'components/text';
import { ReactComponent as UrbanAgent } from './urban-agent.svg';
import { ReactComponent as Selection } from './selection.svg';
import { ReactComponent as Mls } from './mls.svg';
import { ReactComponent as BlockchainCertificate } from './blockchain-certificate.svg';
import { ReactComponent as Deals } from './deals.svg';
import { ReactComponent as Agent } from './agent.svg';
import { ReactComponent as Extracts } from './extracts.svg';
import { NB_SP } from 'text-constants';
import styles from './about-agent-id-section.module.css';

const cx = classNames.bind(styles);

export const AboutAgentIdSection = () => {
  return (
    <section className={cx('section')}>
      <header className={cx('header')}>
        <h2 className={cx('title')}>
          Одна учетная запись для всех{NB_SP}сервисов{NB_SP}профессионала
        </h2>
        <div className={cx('description')}>
          <Text size="l" color="grey">
            С одним Agent ID вы можете получить доступ ко всем сервисам
          </Text>
        </div>
      </header>
      <div className={cx('services-lists')}>
        <ul className={cx('services-list')}>
          <li>
            <UrbanAgent />
          </li>
          <li>
            <Selection />
          </li>
          <li>
            <Mls />
          </li>
        </ul>
        <ul className={cx('services-list')}>
          <li>
            <BlockchainCertificate />
          </li>
          <li>
            <Deals />
          </li>
          <li>
            <Agent />
          </li>
          <li>
            <Extracts />
          </li>
        </ul>
      </div>
    </section>
  );
};
