import React from 'react';
import { useHistory } from 'react-router-dom';
import { IconButton, Icons } from '../../shared';
import { makeStyles } from '../../../hooks/makeStyles';

const useStyles = makeStyles(() => ({
  iconBack: {
    '&:hover, &:focus, &:active': {
      outline: 'none',
      backgroundColor: 'transparent',
      //   borderColor: theme.colors.blue.main
    },
  },
}));

export const BackButton = () => {
  const css = useStyles();
  const history = useHistory();

  return (
    <IconButton className={css.iconBack} onClick={() => history.go(-1)}>
      <Icons.BackArrow
        width={39}
        height={39}
        viewBox="0 0 39 39"
        color="black"
      />
    </IconButton>
  );
};
