import React from 'react';
import { motion } from 'framer-motion';

export const AnimatedWrapper = React.forwardRef((props: any, ref) => {
  const {
    open, children, id, ...passthrough
  } = props;

  const variants = {
    slide: {
      y: 0,
      opacity: 1,
      delay: 300,
    },
    unslide: {
      y: 50,
      opacity: 0,
      transition: { duration: 150 },
    },
  };

  return (
    <motion.div
      initial="slide"
      animate="unslide"
      variants={variants}
      custom={open ? 'slide' : 'unslide'}
      {...passthrough}
    >
      {children}
    </motion.div>
  );
});
