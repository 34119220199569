import React from 'react';
import Media from 'react-media';
import { DesktopLayout } from './desktop-layout/desktop-layout';
import { MobileLayout } from './mobile-layout/mobile-layout';

export const Layout = () => (
  <Media query="(max-width: 768px)">
    {(matches) => (matches ? (
      <MobileLayout />
    ) : (
      <DesktopLayout />
    ))}
  </Media>
);
