import React from 'react';
import { makeStyles } from 'hooks/makeStyles';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { IconButton } from '../icon-button/icon-button';
import { Icons } from '../icons';
import { Box } from '../box/box';
import { Typography } from '../typography/typography';

export interface BackButtonProps {
  label?: string
  path?: string
  isLink?: boolean;
  className?: string
}

const useStyles = makeStyles((theme) => ({
  backContainer: {
    display: 'flex',
    width: '100%',
    height: 60,
    flexShrink: 0,
    boxSizing: 'border-box',
    paddingLeft: theme.spacing(5),
    alignItems: 'center',
    cursor: 'pointer',
    '& p': {
      color: theme.colors.grey.light,
    },
    '&:hover p': {
      color: theme.colors.textPrimary,
    },
  },
  smallArrow: {
    transform: 'rotate(180deg)',
  },
}));

export const WithTail = (props: BackButtonProps) => {
  const {
    label, path, className, isLink = true, ...passthrough
  } = props;
  const css = useStyles();
  const history = useHistory();

  function handleBack() {
    if (isLink) {
      if (path) {
        history.push(path);
      } else {
        history.back();
      }
    }
  }

  return (
    <div className={clsx(css.backContainer, className)} onClick={handleBack} {...passthrough}>
      <IconButton>
        <Icons.BackArrow />
      </IconButton>
      {label ? (
        <Box ml={3}>
          <Typography size="h3(20px)" uppercase font="semibold">
            {label}
          </Typography>
        </Box>
      ) : null}
    </div>
  );
};

export const WithOutTail = (props: BackButtonProps) => {
  const {
    label, path, className, isLink = true, ...passthrough
  } = props;
  const css = useStyles();
  const history = useHistory();

  function handleBack() {
    if (isLink) {
      if (path) {
        history.push(path);
      } else {
        history.back();
      }
    }
  }

  return (
    <div className={clsx(css.backContainer, className)} onClick={handleBack} {...passthrough}>
      <IconButton>
        <Icons.SmallArrow className={css.smallArrow} width={8} height={14} viewBox="0 0 8 14" />
      </IconButton>
      {label ? (
        <Box ml={3}>
          <Typography size="h3(20px)" uppercase font="semibold">
            {label}
          </Typography>
        </Box>
      ) : null}
    </div>
  );
};
