import { OverlayPosition } from './overlay-position';

function checkTopFit(
  containerRect: ClientRect,
  triggerRect: ClientRect,
  overlayHeight: number,
  margin: number,
) {
  return triggerRect.top - containerRect.top - overlayHeight - margin;
}

function checkRightFit(
  containerRect: ClientRect,
  triggerRect: ClientRect,
  overlayWidth: number,
  margin: number,
) {
  return containerRect.right - triggerRect.right - overlayWidth - margin;
}

function checkLeftFit(
  containerRect: ClientRect,
  triggerRect: ClientRect,
  overlayWidth: number,
  margin: number,
) {
  return triggerRect.left - containerRect.left - overlayWidth - margin;
}

function checkBottomFit(
  containerRect: ClientRect,
  triggerRect: ClientRect,
  overlayHeight: number,
  margin: number,
) {
  return containerRect.bottom - triggerRect.bottom - overlayHeight - margin;
}

function checkHorizontalCenterFit(
  containerRect: ClientRect,
  triggerRect: ClientRect,
  overlayWidth: number,
  margin: number,
) {
  return Math.min(
    checkLeftFit(
      containerRect,
      triggerRect,
      (overlayWidth - triggerRect.width) / 2,
      margin,
    ),
    checkRightFit(
      containerRect,
      triggerRect,
      (overlayWidth - triggerRect.width) / 2,
      margin,
    ),
  );
}

export function calculateOverlayPosition(
  triggerRect: ClientRect,
  overlayWidth: number,
  overlayHeight: number,
  preferRight = false,
  preferTop = false,
  preferCenter = false,
  vertPosition = '' as string | null,
  horzPosition = '',
  container = document.documentElement,
  margin = 20,
) {
  const containerRect = container.getBoundingClientRect();

  const topFit = checkTopFit(containerRect, triggerRect, overlayHeight, margin);
  const bottomFit = checkBottomFit(
    containerRect,
    triggerRect,
    overlayHeight,
    margin,
  );
  const bestVertical = Math.max(topFit, bottomFit);

  let vert: string;

  if (vertPosition) {
    vert = vertPosition;
  } else {
    if (topFit >= 0 && bottomFit >= 0) {
      if (preferTop) {
        vert = 'Top';
      } else {
        vert = 'Bottom';
      }
    } else if (bestVertical === topFit) {
      vert = 'Top';
    } else {
      vert = 'Bottom';
    }
  }

  if (preferCenter) {
    const centerFit = checkHorizontalCenterFit(
      containerRect,
      triggerRect,
      overlayWidth,
      margin,
    );
    if (centerFit > 0) {
      return (OverlayPosition as any)[`${vert}Center`];
    }
  }

  const leftFit = checkLeftFit(containerRect, triggerRect, overlayWidth, margin);
  const rightFit = checkRightFit(
    containerRect,
    triggerRect,
    overlayWidth,
    margin,
  );
  const bestHorizontal = Math.max(leftFit, rightFit);

  let horz: string;

  if (horzPosition) {
    horz = horzPosition;
  } else {
    if (leftFit >= 0 && rightFit >= 0) {
      if (preferRight) {
        horz = 'Right';
      } else {
        horz = 'Left';
      }
    } else if (bestHorizontal === leftFit) {
      horz = 'Left';
    } else {
      horz = 'Right';
    }
  }

  return (OverlayPosition as any)[vert + horz];
}
