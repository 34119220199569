import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { EditCollapseProps } from './types';
import { ReactComponent as ArrowIcon } from './arrow.svg';
import styles from './styles.module.css';

const cx = classNames.bind(styles);

export const EditCollapse = ({
  title,
  children,
}: EditCollapseProps) => {
  const [collapsed, setCollapsed] = useState(false);

  const handleToggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div
      className={cx({
        collapsed,
      })}
    >
      <button
        className={cx('button')}
        type="button"
        onClick={handleToggleCollapse}
      >
        {title}
        <ArrowIcon className={cx('arrow')} />
      </button>

      <div
        className={cx('content')}
      >
        {children}
      </div>
    </div>
  );
};
