import React from 'react';
import { createPortal } from 'react-dom';

import styles from './CopyToClipboardPopup.module.css';

interface Props {
  text?: string;
  active?: boolean;
}

const CopyToClipboardPopup = ({ text = 'Ссылка скопирована в буфер обмена', active }: Props) => {
  const popupElement = document.getElementById('copy-to-clipboard');

  if (typeof window !== 'undefined' && popupElement) {
    return createPortal(
      <p
        className={`
          ${styles.copyToClipboard}
          ${typeof active !== 'boolean' && styles.active}
          ${active && styles.active}
        `}
      >
        {text}
      </p>,
      popupElement,
    );
  }

  return null;
};

export default React.memo(CopyToClipboardPopup);
