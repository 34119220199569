const development = {
  ENV: 'developemnt',
  API_URL: 'http://localhost:3000',
  APP_URL: 'http://localhost:3000',
  DISABLE_ROLLBAR: true,
  ROLLBAR_TOKEN: 'd9ef4eed6df1473c93b5212ddf79e0d2',
};

const staging = {
  ENV: 'staging',
  API_URL: 'http://api.staging.reagent.pro',
  APP_URL: 'http://staging.reagent.pro',
  DISABLE_ROLLBAR: false,
  ROLLBAR_TOKEN: 'd9ef4eed6df1473c93b5212ddf79e0d2',
};

const production = {
  ENV: 'production',
  API_URL: 'https://api.reagent.pro',
  APP_URL: 'https://show.reagent.pro',
  ROLLBAR_TOKEN: 'd9ef4eed6df1473c93b5212ddf79e0d2',
  DISABLE_ROLLBAR: false,
};

const Config = ((env) => {
  switch (env) {
    case 'production':
      return production;
    case 'staging':
      return staging;
    default:
      return development;
  }
})(process.env.REACT_APP_ENV || process.env.NODE_ENV);

export default Config;
