import React from 'react';
import cn from 'classnames';

import styles from './HeaderToggleButton.module.css';

export interface HeaderToggleButtonProps {
  className?: string;
  children: React.ReactNode;
  icon?: React.ReactNode;
  onClick?: () => void;
  active?: boolean;
  disabled?: boolean;
}

const HeaderToggleButton = (props: HeaderToggleButtonProps) => {
  const {
    className,
    children,
    onClick,
    icon,
    active = false,
    disabled = false,
  } = props;

  return (
    <button
      className={cn(styles['header-toggle-button'], className, {
        [styles['header-toggle-button-active']]: active,
        [styles['header-toggle-button-disabled']]: disabled,
      })}
      type="button"
      onClick={onClick}
    >
      {icon && (
        <div className={styles['header-toggle-button-icon']}>
          {icon}
        </div>
      )}

      {children}
    </button>
  );
};

export default HeaderToggleButton;
