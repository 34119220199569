import React, {
  createRef, useCallback, useContext, useEffect, useState,
} from 'react';
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';
import classNames from 'classnames/bind';
import {
  AnimatedPage, Box, Input, Typography,
} from 'ui/shared';
import { ApplicationContext } from 'global-stores/application-context';
import { AboutAgentIdSection } from './components/about-agent-id-section';
import { ReactComponent as SignButtonIcon } from './sign-button.svg';
import styles from './sign-in.module.css';
import { Footer } from './components/footer';
import { FooterLinks } from './components/footer-links';
import Logo from 'assets/logo.svg';
import { Text } from '../../../components/text';
import { useParams } from '../objects/_helpers/add-params';

const cx = classNames.bind(styles);

export const SignIn = observer(() => {
  const [params] = useParams<any>();

  const inputPhoneEl = createRef<HTMLInputElement>();
  const inputPhoneCodeEl = createRef<HTMLInputElement>();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [fullPhoneNumber, setFullPhoneNumber] = useState<string | null>(null);
  const [phoneCode, setPhoneCode] = useState<string | null>(null);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isCodeValid, setIsCodeValid] = useState<boolean | string>(true);
  const app = useContext(ApplicationContext);

  useEffect(() => {
    if (app.isCodeRequested && inputPhoneCodeEl.current) {
      inputPhoneCodeEl.current.focus();
    }
  }, [app.isCodeRequested, inputPhoneCodeEl]);

  const handlePhoneChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.target.value);
    setFullPhoneNumber(`7${e.target.value}`);
    setIsPhoneValid(true);
    setPhoneCode(null);
    app.reset();
  }, [app]);

  const handlePhoneCodeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneCode(e.target.value);
  }, []);

  const requestPhoneCode = useCallback(() => {
    if (fullPhoneNumber && fullPhoneNumber.match(/^7\d{10}$/)) {
      app.requestCode(fullPhoneNumber);
    } else {
      setIsPhoneValid(false);
    }
  }, [app, fullPhoneNumber]);

  const confirmPhoneCode = useCallback(() => {
    if (fullPhoneNumber && phoneCode) {
      app.confirmCode(fullPhoneNumber, phoneCode).catch((reason) => {
        console.error(reason);
        setIsCodeValid(reason.title);
      });
    }
  }, [app, phoneCode, fullPhoneNumber]);

  const handleKeyUpEnterPhoneNumber = useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      requestPhoneCode();
    }
  }, [requestPhoneCode]);

  const handleConfirm = useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      confirmPhoneCode();
    }
  }, [confirmPhoneCode]);

  useEffect(() => {
    const authParamsKey = 'auth-params';

    const authParams = JSON.parse(localStorage.getItem(authParamsKey) || '{}');

    // eslint-disable-next-line
    const { first_name, last_name, phone, email } = authParams;

    const haveAuthParams = [first_name, last_name, phone, email].every(Boolean);

    if (!haveAuthParams) {
      return;
    }

    setPhoneNumber(phone);
    setFullPhoneNumber(`7${phone}`);

    app.requestCode(`7${phone}`);
  }, [app]);

  if (app.isConfirmed && app.isAuthenticated && app.isUnregistered) {
    return <Redirect to="/signup" />;
  }

  return (
    <AnimatedPage animation="vertical">
      <div className={cx('sign-in-page')}>
        <div className={cx('logo')}>
          <img src={Logo} alt="" />
        </div>
        <div className={cx('sign-in-form')}>
          <div className={cx('titles')}>
            <div className={cx('title')}>
              Цифровой профиль Риэлтора
            </div>
            <div className={cx('subtitle')}>
              Вход в профессиональное сообщество
            </div>
          </div>
          <div className={cx('input-wrap')}>
            <div
              className={cx('input-wrapper', {
                'input-wrapper_open': app.isCodeRequested,
              })}
            >
              <Typography className={cx('phone-number-code')} font="semibold" size="subtitle1(18px)">
                +7
              </Typography>
              <Input
                ref={inputPhoneEl}
                onChange={handlePhoneChange}
                placeholder=""
                maxLength={10}
                minLength={10}
                step={1}
                autoFocus
                error={!isPhoneValid}
                className={cx('input', 'phone-input')}
                type="tel"
                onKeyUp={handleKeyUpEnterPhoneNumber}
                value={phoneNumber}
              />
              {!app.isCodeRequested ? (
                <button
                  className={cx('sign-button')}
                  type="button"
                  onClick={requestPhoneCode}
                >
                  <SignButtonIcon className={cx('sign-button-icon')} />
                </button>
              ) : null}
            </div>
            <div
              className={cx('code-input', {
                'code-input_open': app.isCodeRequested,
              })}
            >
              <Input
                ref={inputPhoneCodeEl}
                onChange={handlePhoneCodeChange}
                placeholder="Введите код"
                autoComplete="one-time-code"
                maxLength={4}
                minLength={4}
                step={2}
                value={phoneCode || ''}
                error={isCodeValid !== true}
                className={cx('input')}
                type="number"
                inputMode="numeric"
                onKeyUp={handleConfirm}
              />
              {app.isCodeRequested ? (
                <button
                  className={cx('sign-button')}
                  type="button"
                  onClick={confirmPhoneCode}
                >
                  <SignButtonIcon className={cx('sign-button-icon')} />
                </button>
              ) : null}
            </div>
          </div>
          <Box mb={4} className={cx('centered')}>
            <hr className={cx('line')} />
          </Box>
          <Box pb={4} className={cx('centered')}>
            <Text semibold>
              Служба поддержки
              {' '}
              <a
                className={cx('email')}
                href="mailto:support@reagent.pro"
              >
                support@reagent.pro
              </a>
            </Text>
          </Box>
        </div>
        <AboutAgentIdSection />
        <div className={cx('footer')}>
          <FooterLinks />
          <Footer />
        </div>
      </div>
    </AnimatedPage>
  );
});
