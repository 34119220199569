import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import classNames from 'classnames/bind';
import { ApplicationContext } from 'global-stores/application-context';
import { NB_SP } from 'text-constants';
import { Text } from 'components/text';
import { ServiceInfo } from '../service-info';
import styles from './service-info-list.module.css';

dayjs.locale('ru');

const cx = classNames.bind(styles);

export const ServiceInfoList = observer(() => {
  const applicationContext = useContext(ApplicationContext);

  const { currentUser } = applicationContext;

  if (!currentUser) {
    return null;
  }

  const isProAccount = !!currentUser.subscription_expires_date && !currentUser.expired;

  const expiredDate = dayjs(currentUser.subscription_expires_date).format('DD MMMM YYYY');

  return (
    <div className={cx('list')}>
      <div className={cx('item')}>
        <ServiceInfo
          type="account"
          isProAccount={isProAccount}
          isContentVisible={!isProAccount}
        >
          {/* <Text semibold>
            Ваша подписка:
          </Text>
          {NB_SP}
          {isProAccount && (
            <Text color="red" semibold>
              PRO действует до {expiredDate}
            </Text>
          )}
          {!isProAccount && (
            <Text color="red" semibold>
              FREE 0,00 Р в месяц
            </Text>
          )} */}
        </ServiceInfo>
      </div>
      <div className={cx('item')}>
        <ServiceInfo type="deals" />
      </div>
      <div className={cx('item')}>
        <ServiceInfo type="academy" />
      </div>
      <div className={cx('item')}>
        <ServiceInfo type="extracts" isInDevelopment />
      </div>
      <div className={cx('item')}>
        <ServiceInfo type="marketing" />
      </div>
    </div>
  );
});
