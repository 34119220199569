import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Link, useLocation } from 'react-router-dom';

import Alert, { AlertProps } from 'ui/components/Alert';

import { ApplicationContext } from 'global-stores/application-context';
import ModerationBlock from '../ModerationBlock';

import ImageObjectsSrc from './objects.png';
import ImageAnalyticsSrc from './analytics.png';

interface AlertAccessProps extends AlertProps {
  is: boolean;
}

interface Props {
  alertComponents?: (alerts: AlertAccessProps[]) => AlertAccessProps[];
  children: React.ReactNode;
}

const AccessProvide = observer(({ alertComponents, children }: Props) => {
  const location = useLocation();

  const applicationContext = useContext(ApplicationContext);

  if (applicationContext.loading) {
    return <div />;
  }

  const {
    currentUser,
  } = applicationContext;

  const defaultAlerts: AlertAccessProps[] = [];

  defaultAlerts.push({
    type: 'success',
    is: (!currentUser?.subscription_expires_date || currentUser?.expired) && currentUser?.status === 'active' && location.pathname !== '/services/subscriptions',
    children: (
      <>
        Вы&nbsp;прошли модерацию.
        Узнайте о&nbsp;преимуществах <Link to="/services">платных сервисов</Link> и&nbsp;попробуйте расширенные функции.
      </>
    ),
  },
  {
    type: 'error',
    is: currentUser?.status === 'inactive',
    children: (
      <>
        Ваш профиль на&nbsp;модерации.
        Когда мы&nbsp;всё проверим, сразу&nbsp;же предоставим вам доступ.
        Мы внимательно обрабатываем каждую заявку, поэтому модерация может занять некоторое время.
      </>
    ),
  });

  const getAlertList = (): AlertAccessProps[] => {
    if (alertComponents) {
      return alertComponents(defaultAlerts);
    }

    return defaultAlerts;
  };

  const alerts: AlertAccessProps[] = getAlertList();

  let imageSrc = '';

  if (location.pathname === '/objects/agents') {
    if ((!currentUser?.subscription_expires_date || currentUser?.expired) && currentUser?.status === 'active') {
      imageSrc = ImageObjectsSrc;
    }

    defaultAlerts.push({
      type: 'error',
      is: (!currentUser?.subscription_expires_date || currentUser?.expired) && currentUser?.status === 'active',
      children: (
        <>
          Чтобы добавлять свои объекты и&nbsp;использовать их&nbsp;как презентации,
          пользоваться поиском, оставлять запросы и
          демонстрировать экран клиентам, необходимо пройти модерацию
          и стать участником&nbsp;
          <Link to="/services/subscriptions">Ассоциации Профессиональных Риэлторов</Link>
        </>
      ),
    });
  }

  let inDevelopmentPage = false;

  if (location.pathname === '/analytics') {
    imageSrc = ImageAnalyticsSrc;

    defaultAlerts.push({
      type: 'error',
      is: true,
      children: (
        <>
          <b>Раздел в&nbsp;разработке...</b> Здесь будет отображаться аналитика просмотров
          ваших объектов и профиля, а так же другая информация о ваших клиентах.
        </>
      ),
    });

    inDevelopmentPage = true;
  }

  const visible = alerts.filter((alert: AlertAccessProps) => alert.is).length === 0 && currentUser?.status === 'active';

  // @ts-ignore
  return (
    <>
      {
        !visible && (
          <div
            style={{
              marginBottom: 40,
            }}
          >
            {alerts.filter((alert: AlertAccessProps) => alert.is).map((alert: AlertAccessProps, index) => (
              <Alert
                /* eslint-disable-next-line react/no-array-index-key */
                key={index}
                {...alert}
              >
                {alert.children}
              </Alert>
            ))}
          </div>
        )
      }

      {currentUser && currentUser?.status !== 'active' && !inDevelopmentPage && (
        <ModerationBlock
          status={currentUser.status}
        />
      )}

      {imageSrc && (
        <img
          src={imageSrc}
          style={{
            display: 'block',
            maxWidth: 450,
            maxHeight: 450,
            width: '100%',
            height: 'auto',
          }}
          alt=""
        />
      )}

      {visible && alerts.filter((alert: AlertAccessProps) => alert.is).length === 0 && children}
    </>
  );
});

export default AccessProvide;
